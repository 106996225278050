import React, { useEffect, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Products.scss";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { loadProductsDataByIdOrderId, loadSingleOrderData, loadUpdateOrderQty } from "../../redux/action";
import { dateFormatIST } from "../FormattedAmount";
import OrderListTable from "./OrderListTable";
import { baseURL } from "../BaseUrl";
import { TableBody, TableCell, TableRow } from "@mui/material";
import * as HeaderAuthentication from "../HeaderAuthentication";
import moment from "moment";
import OrderHistory from "./OrderHistory";

function OrderDetails() {

  const userLgnDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  let tableHeader = [
    {name: "#", align: "center", width: "40px"},
    {name: "Product Name", align: "left", width: "200px"},
    {name: "Description", align: "left", width: "200px"},
    {name: "Unit Price", align: "center", width: "100px"},
    {name: "Quantity", align: "center", width: "80px"},
    {name: "Total Price", align: "right", width: "230px"},
    {name: "To Be Dispatched", align: "center", width: "150px"},
    // {name: "Status", align: "center", width: "100px"},
    {name: "Action", align: "center", width: "200px"},
  ]

  let historyTableHeader = [
    {name: "#", align: "center", width: "80px"},
    {name: "QTY Sent", align: "center", width: "150px"},
    {name: "Sent Date", align: "left", width: "150px"},
    {name: "Description", align: "left", width: "200px"}
  ]

  let dispatch = useDispatch();

  const {orderId} = useParams();
  const {singleOrder} = useSelector(state => state.data)
  const {productsByOrder} = useSelector(state => state.data)
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [menuCollapse, setMenuCollapse] = useState(false);

  const [isLoading, setIsLoading] = useState(true)
  useEffect(() => {
      if(orderId){
        fetchOrderdetails();
      }
  }, [orderId])
  
  const fetchOrderdetails = () => {
    dispatch(loadSingleOrderData(orderId, setIsLoading))
    dispatch(loadProductsDataByIdOrderId(orderId, setIsLoading))
  }

  const [productItems,  setProductItems] = useState([])
  useEffect(() => {
    if(productsByOrder){
      setProductItems(productsByOrder)
    }
  }, [productsByOrder])

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  
  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  }

  // const goToHistory = (orderId, OrderItemID) => {
  //   navigate(`/orderHistory/${orderId}/${OrderItemID}`)
  // }
  // page navigation

  // send qty function
  const [selectedProducts, setSelectedProducts] = useState([])
  const selectProduct = (e, itemId) => {
      if(e.target.checked){
          setSelectedProducts([...selectedProducts, itemId])
      } else {
          setSelectedProducts(selectedProducts.filter(item => item !== itemId))
      }
  }

  const handleOrderInput  = (e, itemId) => {
    let arrayList = [...productItems]
    arrayList.map(item => {
      if(item.orderItemID == itemId){
        item.dispatchQty = parseInt(e.target.value)
        if(item.status){
          item.status = false
        } 
      }
    })
    setProductItems(arrayList)
  }
  // send qty function
  const pageRefresh = () => {
    fetchOrderdetails()
  }

  // Send Dispatched data
  const sendDispatchedQty = (itemId) => {
    let arrayList = [...productItems]
    let selectedSendItem = []
    if(itemId){
      arrayList.filter(item => {
        if(itemId === item.orderItemID){
             selectedSendItem.push({orderID: item.orderID, orderItemID: item.orderItemID, 
                 totalQTY: item.dispatchQty, subscriptionID: userLgnDetails.subscriptionId, statusDate: moment(new Date()).format("YYYY-MM-DD"),
                 orderDate: singleOrder.orderDate})
             }
          })
    } else {
      if(selectedProducts.length !== 0){
        arrayList.filter(item => {
           if(selectedProducts.includes(item.orderItemID)){
              if(item.dispatchQty){
                selectedSendItem.push({orderID: item.orderID, orderItemID: item.orderItemID, 
                    totalQTY: item.dispatchQty, subscriptionID: userLgnDetails.subscriptionId, statusDate: moment(new Date()).format("YYYY-MM-DD"),
                    orderDate: singleOrder.orderDate})
                } else {
                  item.status = true
                  selectedSendItem.push({orderID: item.orderID, orderItemID: item.orderItemID, 
                    totalQTY: "", subscriptionID: userLgnDetails.subscriptionId, statusDate: moment(new Date()).format("YYYY-MM-DD"),
                    orderDate: singleOrder.orderDate})
                }
              }
            })
        setProductItems(arrayList)
      }
    }
    const hasFalseStatus = selectedSendItem.some(item => item.totalQTY === "");
    if(hasFalseStatus){
      console.log("You have not entered value")
    } else {
      for(var i = 0; i < selectedSendItem.length; i++){
        let status = false;
        if(i == selectedSendItem.length-1){
          status = true
        }
        var updateRequest = {
          method: "POST",
          mode: "cors",
          headers: HeaderAuthentication.postTravelizeAuth,
          body: JSON.stringify(selectedSendItem[i]),
        };
        dispatch(loadUpdateOrderQty(updateRequest, pageRefresh, status))
      }
    }
    
  }
  // Send Dispatched data

  // View Order History
  const [showModal, setShowModal] = useState(false);
  const [prodId, setProdId] = useState("");
  const [hitoryId, setHitoryId] = useState("");

  const handleView = (orderId, prodId) => {
    setShowModal(true);
    setHitoryId(orderId)
    setProdId(prodId);

  }
  // View Order History

  return (
    <div id="main-page">

      {/* view details modal */}
      {showModal && (
        <OrderHistory
          showModal={showModal}
          setShowModal={setShowModal}
          orderId={hitoryId}
          singleOrder={singleOrder}
          prodId={prodId}
          productItems={productItems}
        />
      )}
      {/* view details modal */}

      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
          <div className="user-table">
              <Row className='search-row'>
                <Col md={3} className='cat-col'>
                  <div className='page-header-text-div'>
                    <div className="back-btn" onClick={goToBackPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                    <h6 className='page-header-text' style={{ color: "#04a96d" }}>ORDER DETAILS</h6>
                  </div>
                </Col>
                <Col md={9} className='cat-col'>
                  <div className="search-bar-header" style={{ float: "right" }}>
                    
                  </div>
                </Col>
              </Row>
            </div>
          <div className="clients-table">
            <Row className='search-row order-details-row'>
              <Col md={4} className='cat-col'>
                  <ul className="order-detail-list">
                    <li><div className="span1">Client Name:</div> &nbsp;&nbsp;<div className="span2"><b>{singleOrder.clientName}</b></div></li>
                    <li><div className="span1">Email:</div> &nbsp;&nbsp;<div className="span2"><b>{singleOrder.email}</b></div></li>
                    <li><div className="span1">Phone:</div> &nbsp;&nbsp;<div className="span2"><b>{singleOrder.phone}</b></div></li>
                    <li><div className="span1">Address:</div> &nbsp;&nbsp;<div className="span2">{singleOrder.address}</div></li>
                  </ul>
              </Col>
              <Col md={4} className='cat-col'>
                <div className="company-logo-div">
                  <img src={`${baseURL}/${userLgnDetails.profilePic}`} alt="" className="order-company-img" />
                  <b style={{fontSize: "16px", padding: "5px"}}>{userLgnDetails.fullName}</b>
                </div>
                  {/* <ul className="order-detail-list">
                    <li><b>Total Amount:</b> &nbsp;&nbsp;{singleOrder.totalAmount}</li>
                    <li><b>Quantity:</b> &nbsp;&nbsp;{singleOrder.totalQTY}</li>
                  </ul> */}
              </Col>
              <Col md={4} className='cat-col' >
                  <ul className="order-detail-list" style={{float: "right"}}>
                    <li>Order #: &nbsp;&nbsp;<b>{singleOrder.orderID}</b></li>
                    <li>Order Date:&nbsp;&nbsp;<b>{dateFormatIST(singleOrder.orderDate)}</b></li>
                    <li>Order Status &nbsp;&nbsp;<b>{singleOrder.orderStatus === "Pending" ? <span className="pending-status status-badge" >{singleOrder.orderStatus}</span> :
                    singleOrder.orderStatus === "Completed" ? <span style={{color: "#04a96d"}}>{singleOrder.orderStatus}</span> : <span style={{color: "#ffb900"}}>{singleOrder.orderStatus}</span>}</b></li>
                  </ul>
              </Col>
            </Row>

            {/* Products List */}
            <div className="product-list-div">
                <OrderListTable tableHeader={tableHeader}
                isLoading={isLoading} 
                handleView={handleView}
                // goToHistory={goToHistory}
                productItems={productItems} singleOrder={singleOrder}
                handleOrderInput={handleOrderInput} sendDispatchedQty={sendDispatchedQty}
                selectProduct={selectProduct} selectedProducts={selectedProducts}
                historyTableHeader={historyTableHeader}
                 />
            </div>
            </div>
            {/* Products List */}

          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderDetails;
