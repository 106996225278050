import React, { useEffect } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import * as HeaderData from "../../HeaderAuthentication";
import { useDispatch, useSelector } from "react-redux";
import SbAddBtn, { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import "../../../styles/category.scss";
import Select from "react-select";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import {
  loadPostCustomfields,
  loadSingleField,
  loadUpdateCustomfields,
} from "../../../redux/action";

function AddCustomField(props) {
  let dispatch = useDispatch();
  let editId = props.editId;
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const { singleField } = useSelector((state) => state.data);

  const fieldTypes = [
    { value: "Text", label: "Text" },
    { value: "Email", label: "Email" },
    { value: "Text Area", label: "Text Area" },
    { value: "Drop Down", label: "Drop Down" },
    { value: "Check Box", label: "Check Box" },
    { value: "Radio", label: "Radio" },
    { value: "File", label: "File" },
    { value: "Date", label: "Date" },
  ];

  useEffect(() => {
    if (editId) {
      dispatch(loadSingleField(editId));
    }
  }, [editId]);

  const [customFields, setCustomFields] = useState({
    subscriptionID: resPassData.subscriptionId,
    status: 1,
    addedByID: resPassData.userId,
    customePageID: 1,
    fieldName: "",
    fieldType: "",
    fieldOptions: "",
    placeHolder: "",
    isRequired: 0,
  });

  const {
    subscriptionID,
    status,
    addedByID,
    customePageID,
    customePageName,
    fieldName,
    fieldType,
    fieldOptions,
    placeHolder,
    isRequired,
  } = customFields;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCustomFields({ ...customFields, [name]: value });
  };

  const [selectedData, setSelectedData] = useState({ value: "", label: "" });
  const selectedOption = (data) => {
    setSelectedData(data);
    setCustomFields({ ...customFields, fieldType: data.value });
  };

  const cancelModale = () => {
    props.addFieldModuleClose();
    props.setEditId("");
    props.fetchCustomFields();
    setCustomFields({
      subscriptionID: "",
      status: "",
      addedByID: "",
      customePageID: "",
      fieldName: "",
      fieldType: "",
      fieldOptions: "",
      placeHolder: "",
      isRequired: "",
    });
  };

  const [statusValue, setStatusValue] = useState(true);
  const statusFlag = (statusValue) => {
    setStatusValue(!statusValue);
    if (!statusValue === true) {
      setCustomFields({ ...customFields, status: 1 });
    } else if (!statusValue === false) {
      setCustomFields({ ...customFields, status: 0 });
    }
  };

  const [fieldTypeValue, setFieldTypeValue] = useState(false);
  const fieldStatusType = (statusValue) => {
    setFieldTypeValue(!statusValue);
    if (!statusValue === true) {
      setCustomFields({ ...customFields, isRequired: 1 });
    } else if (!statusValue === false) {
      setCustomFields({ ...customFields, isRequired: 0 });
    }
  };

  useEffect(() => {
    if (editId) {
      if (singleField && Object.keys(singleField).length !== 0) {
        setCustomFields(singleField);
        let typeArray = [...fieldTypes];
        typeArray.map((type) => {
          if (singleField?.fieldType === type.value) {
            setSelectedData(type);
          }
        });
        if (singleField.status === 1) {
          setStatusValue(true);
        } else {
          setStatusValue(false);
        }
        if (singleField.isRequired === 1) {
          setFieldTypeValue(true);
        } else {
          setFieldTypeValue(false);
        }
      }
    }
  }, [singleField, editId]);

  const [fieldReq, setFieldReq] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      !fieldName ||
      !fieldType ||
      !placeHolder ||
      ((fieldType === "Drop Down" ||
        fieldType === "Check Box" ||
        fieldType === "Radio") &&
        !fieldOptions)
    ) {
      setFieldReq(true);
    } else {
      var postRequest = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify(customFields),
      };
      dispatch(loadPostCustomfields(postRequest, cancelModale));
    }
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    if (
      !fieldName ||
      !fieldType ||
      !placeHolder ||
      ((fieldType === "Drop Down" ||
        fieldType === "Check Box" ||
        fieldType === "Radio") &&
        !fieldOptions)
    ) {
      setFieldReq(true);
    } else {
      var updateRequest = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify(customFields),
      };
      dispatch(loadUpdateCustomfields(updateRequest, cancelModale));
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.addFieldModuleClose}
        className="category-modal-div"
      >
        <Row className="modale-header-div">
          <Col md={6} xs={9} className="cat-col">
            <h6 className="page-header-text">
              {editId ? "Edit Custom Field" : "Add Custom Field"}
            </h6>
          </Col>
          <Col md={6} xs={3} className="cat-col" align="right">
            <button
              onClick={cancelModale}
              className="bi bi-x close-popup"
            ></button>
          </Col>
        </Row>
        <hr />
        <div className="cat-col">
          <Form onSubmit={editId ? handleUpdate : handleSubmit}>
            <div className="form-field">
              <Form.Group
                className="mb-3_health"
                controlId="exampleForm.Health"
              >
                {/* <Form.Label className="require">Label Name</Form.Label> */}
                <FloatingLabel
                  label="Label Name"
                  className={`${
                    fieldName || ""
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <Form.Control
                    type="text"
                    value={fieldName || ""}
                    name="fieldName"
                    onChange={handleInputChange}
                    placeholder="Enter Field Name"
                    autoComplete="off"
                    required
                  />
                </FloatingLabel>
                {fieldReq && !fieldName && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter Field Name
                  </small>
                )}
              </Form.Group>
            </div>
            <div className="form-field">
              <Form.Group
                className="mb-3_health"
                controlId="exampleForm.Health"
              >
                {/* <Form.Label className="require">Place Holder</Form.Label> */}
                <FloatingLabel
                  label="Place Holder"
                  className={`${
                    placeHolder
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <Form.Control
                    type="text"
                    value={placeHolder || ""}
                    name="placeHolder"
                    onChange={handleInputChange}
                    placeholder="Enter Place Holder"
                    autoComplete="off"
                    required
                  />
                </FloatingLabel>
                {fieldReq && !placeHolder && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter Field Name
                  </small>
                )}
              </Form.Group>
            </div>
            <div className="form-field">
              <Form.Group
                className="mb-3_health"
                controlId="exampleForm.Health"
              >
                {/* <Form.Label className="require">Field Type</Form.Label> */}
                <FloatingLabel
                  label="Field Type"
                  className={`${
                    selectedData.value !== ""
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list"
                    // defaultValue={fieldTypes}
                    options={fieldTypes}
                    name="fieldType"
                    placeholder="Select Field Type"
                    value={selectedData.value ? selectedData : fieldTypes.label}
                    onInputChange={fieldTypes.label}
                    onChange={(data) => selectedOption(data)}
                    isSearchable={true}
                    required
                  />
                </FloatingLabel>
                {fieldReq && !fieldTypes && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter Field Name
                  </small>
                )}
              </Form.Group>
            </div>
            {(fieldType === "Drop Down" ||
              fieldType === "Check Box" ||
              fieldType === "Radio") && (
              <div className="form-field">
                <Form.Group
                  className="mb-3_health"
                  controlId="exampleForm.Health"
                >
                  <FloatingLabel
                    label="Place Holder"
                    className={`${
                      fieldOptions
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                  >
                    <Form.Control
                      type="text"
                      value={fieldOptions || ""}
                      name="fieldOptions"
                      onChange={handleInputChange}
                      placeholder="eg:  option1,option2"
                      autoComplete="off"
                      required
                    />
                  </FloatingLabel>
                  {fieldReq &&
                    (fieldType === "Drop Down" ||
                      fieldType === "Check Box" ||
                      fieldType === "Radio") &&
                    !fieldOptions && (
                      <small id={`Error`} className="form-text text-muted ">
                        Please Enter Field Options
                      </small>
                    )}
                </Form.Group>
              </div>
            )}
            {editId && (
              <div className="mt-3">
                <div className="form-field form-status-section">
                  <Row style={{ width: "100%" }}>
                    <Col md={6} className="cat-col">
                      <div>
                        <Form.Label className="require">Status</Form.Label>
                      </div>
                    </Col>
                    <Col md={5} className="cat-col">
                      <div className="btn-status">
                        <Form.Group className="facility-details">
                          <div
                            className={`checkbox1 ${
                              statusValue && "checkbox--on"
                            }`}
                            name="status"
                            onClick={(e) => statusFlag(statusValue)}
                          >
                            <div className="checkbox__ball">
                              <span
                                className={
                                  statusValue ? "enable-text" : "disable-text"
                                }
                              >
                                {statusValue ? "Enable" : "Disable"}
                              </span>
                            </div>
                          </div>
                        </Form.Group>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
            <div className="mt-3">
              <div className="form-field form-status-section">
                <Row style={{ width: "100%" }}>
                  <Col md={6} className="cat-col">
                    <div>
                      <Form.Label className="require">IsRequired</Form.Label>
                    </div>
                  </Col>
                  <Col md={5} className="cat-col">
                    <div className="btn-status">
                      <Form.Group className="facility-details">
                        <div
                          className={`checkbox1 ${
                            fieldTypeValue && "checkbox--on"
                          }`}
                          name="status"
                          onClick={(e) => fieldStatusType(fieldTypeValue)}
                        >
                          <div className="checkbox__ball">
                            <span
                              className={
                                fieldTypeValue ? "enable-text" : "disable-text"
                              }
                            >
                              {fieldTypeValue ? "Mandatory" : "Optional"}
                            </span>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <hr />
            <div className="sb-btn-section">
              <SBSaveUpdateBtn btnName={editId ? "Update" : "Save"} />
              <SbCancelBtn btnName="Cancel" onClickEffect={cancelModale} />
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default AddCustomField;
