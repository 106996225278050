import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { baseURL } from "../BaseUrl";
import * as swal from "../Consturl/SwalAlert";

export default function LeavesBulkUpload(props) {
  const [bulkUserFile, setBulkUserFile] = useState("");
  const [bulkUploadResponse, setBulkUploadResponse] = useState({
    res: null,
    message: null,
  });

  const [uploadExcelFile, setUploadExcelFile] = useState("");

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const salesbeeImgAuth = { Travelize_Authentication: userDetails };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadExcelFile(fileUploaded);
  };

  // console.log("EXCEL-FILE:", uploadExcelFile);

  useEffect(() => {
    setUploadExcelFile("");
    setBulkUploadResponse({ res: "", message: "" });
  }, [props.addBulkUserModel, props.handleCloseBulkUserModel]);

  const addBulkLeaves = () => {
    if (uploadExcelFile != null || uploadExcelFile != "") {
      const formData = new FormData();
      formData.append("Files", uploadExcelFile);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: salesbeeImgAuth,
        body: formData,
      };
      fetch(
        `${baseURL}/api/Import/ImportLeaveBalance?Directory=BulkUserUploads`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("UPLOAD-FILE-RES::", res);
          if (res.success) {
            setBulkUploadResponse({ res: res.success, message: res.message });
          } else {
            setBulkUploadResponse({
              res: res.success,
              message: res.errorMessage,
            });
          }
        });
    }
  };
  return (
    <div>
      {" "}
      <div>
        <Modal
          show={props.bulkUploadModel}
          onHide={props.handleClose}
          className="main_model"
        >
          <div className="add-meet-purpose-heading">
            <h6 className="add-edit-meetpurpose-head">ADD BULK LEAVES</h6>
            <i className="fa fa-times" onClick={props.handleClose} />
          </div>
          <div className="container add-edit-meetpurpose">
            <div className="company-name">
              <div className="row mb-2">
                <input
                  className="form-control form add-meetpurpose-input"
                  id="bulkuserupload"
                  type="file"
                  name="bulkUpload"
                  accept=".xls, .xlsx"
                  onChange={handleChange}
                  placeholder="Add File"
                />
              </div>
            </div>
            <span className="bulk-temp-and-res-message">
              <span
                className={
                  bulkUploadResponse.res
                    ? "upload-res-message success"
                    : "upload-res-message false"
                }
              >
                {bulkUploadResponse.message}
              </span>
              <a
                // onClick={uploadClick}
                href={`${baseURL}/TravelizeProData/Data/BulkUploadTemplate/Excel_BulkLeaveBalance_20230824180828064.xlsx`}
                className="btn template-download"
                download={"BulkUploadTemplate"}
                // Use Download File function of excel export call
              >
                <i className="fa fa-download" /> &nbsp;Template Download
              </a>
            </span>
          </div>
          <div className="btns-save-cancel">
            <div className="meetpurpose-save-button">
              <button
                onClick={addBulkLeaves}
                type="button"
                className="btn btn-save-up"
                data-bs-dismiss="modal"
              >
                <i className="fa fa-upload" /> &nbsp; Bulk Upload
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
