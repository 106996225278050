import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "../styles/Topbar.scss";
import profileimg from "../images/admin-1.png";
import { baseURL } from "./BaseUrl";
import TopBarToolTip from "./Users/TopBarToolTip";
import { Carousel, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import SbCancelBtn from "./SbButtons/SbCancelBtn";
import { SBSaveUpdateBtn } from "./SbButtons/SbAddBtn";
import { useDispatch, useSelector } from "react-redux";
import { loadGoogleCredits } from "../redux/action";

function Topbar(props) {
  const userLgnDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  let dispatch = useDispatch();
  const {ApiCreditList} = useSelector(state => state.data);

  const companyName = userLgnDetails?.subscription?.company?.companyName;

  const { pageData } = useSelector(state => state.data)
  const menuIconClick = props.menuIconClick;
  const menuCollapse = props.menuCollapse;
  const toggleshift = props.toggleshift;
  const toggleMenu = props.toggleMenu;

  const logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.href = "/";
    setShowlogOut(false)
  };

  const [showLogOut, setShowlogOut] = useState(false)

  const logoutModal = () => {
    setShowlogOut(true)
  }
  const cancelModale = () => {
    setShowlogOut(false)
  }

  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    let Flag = "GetApiCredits"
    let filterArray = []
    dispatch(loadGoogleCredits(Flag, setIsLoading, 50, 1, filterArray))
  }, [])

  const [remainingCredits, setRemainingCredits] = useState([])
  useEffect(() => {
    let creditsData = ApiCreditList.filter(item => item.SubscriptionID == userLgnDetails.subscriptionId)
    setRemainingCredits(creditsData)
  }, [ApiCreditList])

  let daysLeft = sessionStorage.getItem("daysLeft");


  // BreadCome Navigation
  const goToPage = () => {
    window.history.back();
  }
  // BreadCome Navigation
  return (
    <div>


      {/* Logout modal */}
      {showLogOut &&
        <Modal show={showLogOut} onHide={cancelModale} className="logout-modal-div">
          {/* <Row className='modale-header-div'>
                    <Col md={6} xs={9} className='cat-col'>
                        <h6 className='page-header-text'>Add Category</h6>
                    </Col>
                    <Col md={6} xs={3} className='cat-col' align="right">

                        <button onClick={props.categoryModuleClose} className='bi bi-x close-popup'></button>
                    </Col>
                </Row> */}
          {/* <hr /> */}
          <div className='cat-col'>
            <div >
              <div className='log-out-div'>
                <h6>Are you sure want to logout?</h6>
              </div>
              {/* <hr /> */}
              <div className='sb-logout-btn'>
                <SBSaveUpdateBtn onClickEffect={logout} btnName="Yes" />
                <SbCancelBtn btnName="No" onClickEffect={cancelModale} />
              </div>
            </div>
          </div>

        </Modal>
      }
      {/* Logout modal */}

      <div className="topbar">
        <nav className="navbar-custom" id="navbar-custom">
          {/* <div className='page-header-div all-page-header-section'>
            <p >
              <><span className='breadcome-link' style={{ color: "#04a96d" }} onClick={goToPage} >{pageData.mainpage}</span></>
              <>&nbsp;&nbsp;<i className='fa fa-angle-right'></i></>
              <>&nbsp;&nbsp;<span className='breadcome-text'>{pageData.page}</span></>
            </p>
          </div> */}
          <div className="dropdown list-unstyled topbar-nav float-end">
            <span className="your-days-left">
              <Link to="/settings/account" className="go-to-accounts">
                {daysLeft < 30 ? (
                  daysLeft === 0 ? (
                    <small className="days-left-in-word">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription has Expired.
                    </small>
                  ) : daysLeft === 1 ? (
                    <small className="days-left-in-word">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription will end <b>Today</b>
                    </small>
                  ) : daysLeft < 10 ? (
                    <small className="days-left-in-number red">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription will be end in <b>{daysLeft} days</b>
                    </small>
                  ) : (
                    <small className="days-left-in-number">
                      <i className="fa fa-exclamation-triangle" />
                      Your Subscription will be end in <b>{daysLeft} days</b>
                    </small>
                  )
                ) : (
                  ""
                )}
              </Link>
            </span>

            <div className="top-bar-popup">
              <div className="carousel-div">
                <Carousel
                  fade
                  pause="hover"
                  autoPlay={false}
                  controls={false}
                  indicators={false}
                  interval={2000} >
                    {remainingCredits.length != 0 &&
                    remainingCredits.map((item, i) => (
                  <Carousel.Item className="first-popup-div" key={i}>
                      <div className="first-popup-div-text">
                        <p className="carousel-text1 color-green">
                          {item.ApiName}
                        </p>
                        <p className="carousel-text1">
                          {item.RemainingCreadits <= 0 ?
                          <span style={{color: "rgb(242, 44, 61)"}}>{item.RemainingCreadits}</span>
                          :
                          <span className="color-green">{item.RemainingCreadits}</span>
                          }
                        </p>
                      </div>
                  </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>


            <div className="dropdown header-item">
              <button
                type="button"
                className="btn navdrp"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span className="d-flex align-items-center">
                  <div style={{ width: "50px", marginRight: "10px" }}>
                    <img
                      className="sidbar logo-img rounded-circle header-profile-user"
                      style={{ width: "40px" }}
                      src={
                        userLgnDetails?.profilePic !== "" &&
                          userLgnDetails?.profilePic !== null &&
                          userLgnDetails?.profilePic !== "no_img.png"
                          ? `${baseURL}/${userLgnDetails?.profilePic}`
                          : profileimg
                      }
                      alt="Profile_Image" />
                  </div>

                  <span className="text-start ms-xl-2 d-flex align-items-center">
                    <span className="d-none d-xl-inline-block fw-medium user-name-text fs-16">
                      <span>
                        <span className="sb-user-name">
                          {userLgnDetails?.fullName}
                        </span>{" "}
                        <br />
                        <span className="sb-company-name fz15">
                          {companyName}
                        </span>
                      </span>
                    </span>
                    <span>
                      &nbsp;&nbsp;
                      <i
                        className="fa fa-angle-down fs-12 ms-1"
                        style={{ fontSize: "20px" }}
                      ></i>
                    </span>
                  </span>
                </span>
              </button>

              <div
                className="dropdown-menu dropdown-menu-end custom-sb-drop"
                data-popper-placement="bottom-end"
              >
                <div
                  className="dropdown-item"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <TopBarToolTip
                    image="../images/Settings/Setting.png"
                    link="/settings"
                    tpName="Settings"
                  />
                  <div className="icon-divider"></div>
                  <TopBarToolTip
                    image="../images/Settings/Profile-image.png"
                    link="/settings/Profile"
                    tpName="Profile"
                  />
                  <div className="icon-divider"></div>
                  <OverlayTrigger
                    delay={{ hide: 450, show: 300 }}
                    overlay={(props) => <Tooltip {...props}>Log Out</Tooltip>}
                    placement="top" >
                    <div onClick={logoutModal} style={{ cursor: "pointer" }}>
                      <img src="../images/Settings/Logout.png" width="22px" alt="logout" />
                    </div>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
          </div>

          <div className="list-unstyled topbar-nav">
            <li>
              <button
                className="nav-link button-menu-mobile nav-icon"
                id="togglemenu"
                onClick={toggleMenu}
              >
                <div
                  className={`closemenu ${toggleshift.style}`}
                  onClick={menuIconClick}
                >
                  {menuCollapse ? (
                    <span className="fa fa-indent"></span>
                  ) : (
                    <span className="fa fa-dedent"></span>
                  )}
                </div>
              </button>
            </li>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Topbar;
