import Swal from "sweetalert2";

export const showAlert = (title, message, icons) => {
  Swal.fire({
    title: title,
    text: message,
    icon: icons,
    confirmButtonText: "OK",
  });
};
