import React, { useEffect, useState } from 'react'
import Sidenavbar from '../../Sidenavbar';
import Topbar from '../../Topbar';
import { Col, Row } from 'react-bootstrap';
import '../../../styles/category.scss';
import '../../../styles/CommonStyle.scss';
import { useDispatch, useSelector } from 'react-redux';
import { loadShiftData, loadSingleHoliday } from '../../../redux/action';
import AddShift from './AddShift';
import ShiftTable from './ShiftTable';
import SbAddBtn from '../../SbButtons/SbAddBtn';
import { useNavigate } from 'react-router-dom';

function Shift() {

    let dispatch = useDispatch();
    const { shiftData } = useSelector(state => state.data);

    const goToPage = () => {
        window.history.back();
    }

    let tableHeader = [
        "Sl.No",
        "Shift Name",
        "CheckIn Time",
        "CheckOut Time",
        "Is Night Shift",
        "Action"
    ]

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });

    const toggleMenu = () => {
        if (menuCollapse === true) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    useEffect(() => {
        dispatch(loadShiftData())
    }, [])


    const [show, setShow] = useState(false);

    const shiftModuleOpen = () => {
        setShow(true);
    }
    const shiftModuleClose = () => {
        setShow(false);
    }

    const [shiftId, setShiftId] = useState('')
    const handleEdit = (id) => {
        shiftModuleOpen()
        setShiftId(id)
    }

    const [searchValue, setsearchValue] = useState('')
    const clearSerachValue = () => {
        setsearchValue("")
    }

     // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

    return (
        <div id="main-page">
            {/* addHoliday modal page */}
            {show && <AddShift show={show} shiftModuleClose={shiftModuleClose}
                shiftId={shiftId} setShiftId={setShiftId} shiftData={shiftData} />}
            {/* addHoliday modal page */}
            {/* SIDEBAR START */}
            <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
            {/* SIDEBAR END */}

            {/* TOPBAR START */}
            <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
            />
            {/* TOPBAR END */}
            <div className="page-wrapper">
                <div className={`main-body ${toggleshift.style}`}>
                    
                    <div className="page-content">
                        <Row className='search-row'>
                            <Col md={3} className='cat-col'>
                                <div className='page-header-text-div'>
                                    <div
                                        className="back-btn"
                                        onClick={goToBackPage}
                                    >
                                        <img
                                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                            alt=""
                                        />
                                    </div>
                                    <h6 className='page-header-text' style={{ color: "#04a96d" }}>Shift List</h6>
                                </div>
                            </Col>
                                <Col md={5} className='cat-col'>
                                <Row className='search-bar-header'>
                                <Col md={10} className='cat-col'>
                                <div className='input-div'>
                                                            <input type="text" placeholder='Find a Name' className='form-control setting-input'
                                                                value={searchValue} onChange={(e) => setsearchValue(e.target.value)}
                                                            />
                                                            <i className='fa fa-search search-icon' ></i>
                                                        </div>
                                </Col>
                                <Col md={2} className='cat-col'>
                                    <button
                                        type="button"
                                        className="btn btn-search-track"
                                        onClick={clearSerachValue}
                                    >
                                        <span className="btn-inline-item d-flex">
                                            <i className="fa fa-eraser"> </i>
                                            <h6>Clear</h6>
                                        </span>
                                    </button>
                                </Col>
                                </Row>
                            </Col>
                            <Col md={4} className='cat-col'>
                                <div className="search-bar-header" style={{float: "right"}}>
                                <div className="page-top-bar-dropdowns page-top-Filters">
                                    <SbAddBtn onClickEffect={shiftModuleOpen} />
                                </div>
                                </div>
                            </Col>
                        </Row>
                        <ShiftTable tableHeader={tableHeader} shiftData={shiftData}
                            handleEdit={handleEdit} searchValue={searchValue} />
                    </div></div>
            </div>
        </div>
    )
}

export default Shift;
