import React, { useEffect, useState } from 'react'
import Sidenavbar from '../../Sidenavbar';
import Topbar from '../../Topbar';
import TablePage from '../../SbTables/TablePage';
import { Col, Row } from 'react-bootstrap';
import '../../../styles/category.scss';
import '../../../styles/settings.scss';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { loadClientStage, updateClientStage } from '../../../redux/action';
import * as HeaderData from '../../HeaderAuthentication';
import AddClientStage from './AddClientStage';
import SbAddBtn from '../../SbButtons/SbAddBtn';
import { useNavigate } from 'react-router-dom';
// import AddClientCategory from './AddClientCategory';

function ClientStage() {

  let dispatch = useDispatch();
  const { clientStageData } = useSelector(state => state.data);

  let tableHeader = [
    "Sl.No",
    "Stage Name",
    "Description",
    "Status",
    "Action"
  ]

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    dispatch(loadClientStage())
  }, [])

  const [btnStatus, setBtnStatus] = useState(false)
  const handleStatus = (id) => {
    setBtnStatus(true)
    let updatingData
    clientStageData.filter(element => {
      if (element.stageID === id) {
        if (element.status === 1) {
          updatingData = {
            stageID: element.stageID,
            subscriptionID: element.subscriptionID,
            name: element.name,
            description: element.description,
            addedByID: element.addedByID,
            addedByUserFullName: element.addedByUserFullName,
            addedOn: element.addedOn,
            status: 0
          }
        } else if (element.status === 0) {
          updatingData = {
            stageID: element.stageID,
            subscriptionID: element.subscriptionID,
            name: element.name,
            description: element.description,
            addedByID: element.addedByID,
            addedByUserFullName: element.addedByUserFullName,
            addedOn: element.addedOn,
            status: 1
          }
        }
      }
    })
    console.log(updatingData, "updatingData")
    var updateRequest = {
      method: "POST",
      mode: "cors",
      headers: HeaderData.postTravelizeAuth,
      body: JSON.stringify(updatingData),
    }
    dispatch(updateClientStage(updateRequest, setBtnStatus))
  }

  const [show, setShow] = useState(false);

  const categoryModuleOpen = () => {
    setShow(true);
  }
  const categoryModuleClose = () => {
    setShow(false);
  }

  const [searchValue, setsearchValue] = useState('')
  const clearSerachValue = () => {
    setsearchValue("")
  }

  const goToPage = () => {
    window.history.back();
  }

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {show && <AddClientStage show={show} categoryModuleClose={categoryModuleClose} />}
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className='page-header-div'>
            {/* <h6 className='page-header-text'>STAGE</h6> */}
            <p ><span className='breadcome-link' style={{ color: "#04a96d" }} onClick={goToPage}>Settings</span>&nbsp;&nbsp;<i className='fa fa-angle-right'></i>&nbsp;&nbsp;<span className='breadcome-text'>Stage</span></p>
          </div>
          <div className="page-content">
            <Row className='search-row'>
              <Col md={2} className='cat-col'>
                <div className='page-header-text-div'>
                  <div
                    className="back-btn"
                    onClick={goToBackPage}
                  >
                    <img
                      src="../../../../images/DefaultImages/back-btn-arrow.svg"
                      alt=""
                    />
                  </div>
                  <h6 className='page-header-text' style={{ color: "#04a96d" }}>Client Stage</h6>
                </div>
              </Col>
              <Col md={10} className='cat-col'>
                <Row className='category-search-row'>
                  <Col md={3} className='cat-col'>

                  </Col>
                  <Col md={2} className='cat-col'>

                  </Col>
                  <Col md={2} className='cat-col'>

                  </Col>
                  <Col md={2} className='cat-col'>
                    <div className='input-div'>
                      <input type="text" placeholder='Find a Stage' className='form-control setting-input'
                        value={searchValue} onChange={(e) => setsearchValue(e.target.value)}
                      />
                      <i className='fa fa-search search-icon' ></i>
                    </div>
                    {/* <Select
                      className="react-select-container-list"
                      classNamePrefix="react-select-list"
                      //   options={userList}
                      placeholder="Select Stage"
                      //   value={filterUserId == "" ? "" : userList.value}
                      //   onInputChange={userList.label}
                      //   onChange={(data) => setFilterUserId(data.value)}
                      isSearchable={true}
                    /> */}
                  </Col>
                  <Col md={1} className='cat-col'>
                    <button
                      type="button"
                      className="btn btn-search-track"
                      onClick={clearSerachValue}
                    >
                      <span className="btn-inline-item d-flex">
                        <i className="fa fa-eraser"> </i>
                        <h6>Clear</h6>
                      </span>
                    </button>
                  </Col>
                  <Col md={2} className='cat-col' align="center">
                    <SbAddBtn onClickEffect={categoryModuleOpen} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <TablePage tableHeader={tableHeader} clientCategoryData={clientStageData}
              handleStageStatus={handleStatus} btnStatus={btnStatus} page="stage" searchValue={searchValue} />
          </div></div>
      </div>
    </div>
  )
}

export default ClientStage;
