import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import SkeletonTableData from '../Skeleton Loading/SkeletonTableData';
import { baseURL } from '../BaseUrl';
import productimg from "../../images/products/headset.png";
import { FormattedAmount } from '../FormattedAmount';

function ProductsTable(props) {
    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }} >
                    <Table stickyHeader aria-label="sticky table table-responsive">
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell key={i} className={`thead-cell-products ${(name === "Quantity") && "action-class"}`}
                                    >

                                        {name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ? 
                                <>
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                  <SkeletonTableData />
                                </>
                            :
                        <>
                        {props.cartData.length === 0 ?
                            <TableBody>
                                <TableRow >
                                    <TableCell colSpan={props.tableHeader.length} align="center">
                                        <div style={{padding: "50px 20px"}}>
                                            You have not added any products to cart...!
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {props.cartData && props.cartData.map((item, i) => (
                                    <TableRow align="center" key={i} className='cart-list-row-data'>
                                        <TableCell className='prod-name'>
                                            <div className='add-quantity'>
                                                <div className='table-product-img'>
                                                    <img src={item.image ? `${baseURL}/${item.image}` : productimg} className="product-img" alt="" />
                                                </div>
                                                <div className='prod-name-td'>
                                                <h4 className='prod-name'>{item.productName}</h4>
                                                <p>{item.description}</p>
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <span style={{color: "#FFB900"}}><i className="fa fa-inr" aria-hidden="true"></i> {FormattedAmount(item.price)}</span>
                                        </TableCell>
                                        <TableCell align='center'>
                                            <div className="add-quantity justify-content-center">
                                                <button className="qty-count-btn" disabled={item.qty <= 1} onClick={() => props.decreaseCount(item.productID)}>-</button>
                                                <div className="qty-count"><b>{item.qty}</b></div>
                                                <button className="qty-count-btn" onClick={() => props.increaseCount(item.productID)}>+</button>
                                            </div>
                                        </TableCell>
                                        <TableCell>
                                            <span style={{color: "#04A96D"}}><i className="fa fa-inr" aria-hidden="true"></i> {FormattedAmount(parseFloat(item.price) * parseInt(item.qty))}</span>
                                        </TableCell>
                                        <TableCell  className='action-class'>
                                            <div className='remove-btn hover-badge' onClick={() => props.removeItem(item.productID)}>Remove</div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>


                        }
                        </>
}
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default ProductsTable
