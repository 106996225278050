import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import OnScrollLoading, { handleScroll } from '../OnScrollLoading';
import profileimg from "../../images/profile-1.png";
import { SkeletonUser } from '../Skeleton Loading/SkeletonUser';

function UserHistoryTable(props) {

    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }}
                    onScroll={(e) => handleScroll(e, props.userHistory, props.totalData, props.setLoading, props.setPageSize, props.PageSize)} >
                    <Table stickyHeader aria-label="sticky table table-responsive" className='client-table'>
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((header, i) => (
                                    < TableCell key={i} align={header.align} style={{ minWidth: `${header.width}` }} >
                                        {header.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ?
                            <>
                                {props.tableHeader.map((header, i) => (
                                    <SkeletonUser key={i} />
                                ))}
                            </>
                            :
                            <>{props.userHistory.length === 0 ?
                                <TableBody>
                                    <TableRow >
                                        <TableCell align="center" colSpan={props.tableHeader.length}>
                                            No data found...!
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                :
                                <TableBody>
                                    {props.userHistory && props.userHistory.map((data, i) => (
                                        <TableRow align="center" key={i} className='client-list-row-data'>
                                            <TableCell>
                                                <span className="fz13">
                                                    <b>{data?.userFullName}</b>
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                <div>{data?.meetingDate}, {data?.meetingTime}</div>
                                                <div className="user-mobile">
                                                    {data?.meetingType}
                                                </div>
                                            </TableCell>
                                            <TableCell>
                                                <div><b>{data?.clientName}</b></div>
                                                <div>{data?.contactPersonName}</div>
                                                <div>{data?.clientContact}</div>
                                            </TableCell>
                                            <TableCell>
                                                <span className='color-yellow'><i className="bi bi-geo-alt" /></span> &nbsp;
                                                {data?.clientLocation}
                                            </TableCell>
                                            <TableCell align="center">
                                                {data.meetingStatus === "Pending" ? (
                                                    <span className="pending-status status-badge">
                                                        Pending
                                                    </span>
                                                ) : data.meetingStatus === "Follow up" ? (
                                                    <span className="follow-status status-badge">
                                                        Follow Up
                                                    </span>
                                                ) : data.meetingStatus === "Completed" ? (
                                                    <span className="complete-status status-badge">
                                                        Completed
                                                    </span>
                                                ) : data.meetingStatus === "Cancelled" ? (
                                                    <span className="cancel-status status-badge">
                                                        Cancelled
                                                    </span>
                                                ) : (
                                                    data.meetingStatus === "Expired" && (
                                                        <span className="expire-status status-badge">
                                                            Expired
                                                        </span>
                                                    )
                                                )}
                                            </TableCell>
                                            <TableCell>
                                                <span>Purpose : </span>
                                                {data?.purpose}
                                                <br />
                                                <span>MOT :</span>{" "}
                                                {data?.travelBy}
                                                <br />
                                                <span>
                                                    Distance :{" "}
                                                </span>
                                                {data?.kmsTravelled}
                                            </TableCell>
                                            <TableCell>
                                                {data?.lessRemarks === "NA" ? (
                                                    data?.lessRemarks
                                                ) : (
                                                    <OverlayTrigger
                                                        placement={"left"}
                                                        delay={{ show: 250, hide: 300 }}
                                                        overlay={
                                                            <Tooltip id={`tooltip-remarks`}>
                                                                {data?.remarks}
                                                            </Tooltip>
                                                        }
                                                    >
                                                        <div>{data?.lessRemarks}</div>
                                                    </OverlayTrigger>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {props.loading &&
                                        <OnScrollLoading />
                                    }
                                </TableBody>
                            }
                            </>
                        }
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default UserHistoryTable;
