import * as types from './actionType';

const initialState = {

    // Topbar page breadcome
    pageData: '',
    access: '',
    totalCount: '',
    // Topbar page breadcome
    mainModules: [],
    sbcrBySubscriber: [],
    allMenus: [],
    subcriptionList: [],
    subCrDropdown: [],
    rolesDropdown: [],
    allSubcriptions: [],
    allSubsById: [],
    trackData: [],
    distanceMatrix: {},
    successMsg: {},

    // Dashboard Page Graph Api
    graphData: [],
    graphCount: [],
    // Dashboard Page Graph Api

    // Client page
    companyData: [],
    singleClient: {},
    otherContacts: [],
    contactByCompanyDrop: [],
    clientDropdown: [],
    // Client page

    // Category page
    clientCategoryData: [],
    categoryDropdown: [],
    // Category page
    // Stage page
    clientStageData: [],
    stageDropdown: [],
    // Stage page

    // Custom fields page
    customFields: [],
    singleField: {},
    // Custom fields page

    // Holiday List page
    holidayList: [],
    singleHoliday: {},
    // Holiday List page

    // shift page
    shiftData: [],
    // shift page

    // Client Complaint Page
    clientComplaints: [],
    singleClientCompaint: {},
    // Client Complaint Page

    // Products page
    productData: [],
    singleProduct: {},
    productDropdown: [],
    cartList: [],
    // Products page

    // Orders Page
    oderDetails: [],
    singleOrder: [],
    productsByOrder: [],
    orderHistory: [],
    // Orders Page

    // Meeting Page
    meetingData: [],
    // Meeting Page

    // Product Category Page
    prodCategoryDrop: [],
    prodSubcategory: [],
    // Product Category Page

    // Designation page
    desigationdrop: [],
    // Designation page

    // Basefare page
    // mot dropdown
    motDrop: [],
    // mot dropdown
    baseFareList: [],
    singleBaseFire: {},
    // Basefare page

    // Department page
    departmentDrop: [],
    departDesignation: [],
    // Department page

    // Get Apis for ZOone, State, Country, City
    zoneList: [],
    stateList: [],
    cityList: [],
    // Get Apis for ZOone, State, Country, City

    // Upload Images
    imgUrl: {},
    // Upload Images

    // Super admin Pages
    // Google credit page
    googleCreditList: [],
    apiDropdown: [],
    creditCount: [],
    ApiCreditList: [],
    apiNameDropdown: [],
    apiSingleData: {},
    apiExceptionData: [],
    apiMobileExceptionData: [],
    // Google credit page
    // Super admin Pages

    // User Page
    userDropdown: [],
    userRegionResult: [],
    userDropdownByCompany: [],
    // User Page

    // Google Hit Count
    // hitCount: [],
    // Google Hit Count

    // Subscription Pages
    subscriptionTypeDropdown: [],
    // Subscription Pages
    // Refferal Pages
    salesBeeRefferal: [],
    apiSingleRefferalData: {},
    // Refferal Pages

    loading: true,
};

const designReducers = (state = initialState, action) => {
    switch (action.type) {
        // Topbar page Breadcome
        case types.Get_BreadCome_Data:
            return {
                ...state,
                pageData: action.payload,
                loading: false
            }
        case types.Get_Admin_Access:
            return {
                ...state,
                access: action.payload,
                loading: false
            }
        case types.Get_Total_Data:
            return {
                ...state,
                totalCount: action.payload,
                loading: false
            }
        // Topbar page Breadcome
        // Upload Images
        case types.Upload_Single_Image:
            return {
                ...state,
                imgUrl: action.payload,
                loading: false
            }
        // Upload Images

        case types.Add_Designation:
        case types.Get_Main_Modules:
            return {
                ...state,
                mainModules: action.payload,
                loading: false
            }
        case types.Get_Subscription_Module:
            return {
                ...state,
                sbcrBySubscriber: action.payload,
                loading: false
            }
        case types.Get_All_Menus:
            return {
                ...state,
                allMenus: action.payload,
                loading: false
            }
        case types.Post_Menus_Subscription:
        case types.Get_Subscription:
            return {
                ...state,
                subcriptionList: action.payload,
                loading: false
            }
        case types.Get_Subscr_Dropdown:
            return {
                ...state,
                subCrDropdown: action.payload,
                loading: false
            }
        case types.Get_Roles_DropDown:
            return {
                ...state,
                rolesDropdown: action.payload,
                loading: false
            }
        case types.Get_All_Subscription:
            return {
                ...state,
                allSubcriptions: action.payload,
                loading: false
            }
        case types.Get_All_Id_Subs:
            return {
                ...state,
                allSubsById: action.payload,
                loading: false
            }
        case types.Get_Track_User:
            return {
                ...state,
                trackData: action.payload,
                loading: false
            }
        case types.Get_Distance_Matrix:
            return {
                ...state,
                distanceMatrix: action.payload,
                loading: false
            }
        case types.Get_Successs_Msg:
            return {
                ...state,
                successMsg: action.payload,
                loading: false
            }

        // Dashboard page graph API's
        case types.Get_GraphData:
            return {
                ...state,
                graphData: action.payload,
                loading: false
            }
        case types.Get_GraphCount:
            return {
                ...state,
                graphCount: action.payload,
                loading: false
            }
        // Dashboard page graph API's

        // Category page
        case types.Update_Client_Category:
        case types.Post_Client_Category:
        case types.Get_Client_Category:
            return {
                ...state,
                clientCategoryData: action.payload,
                loading: false
            }
        case types.Client_Category_DropDown:
            return {
                ...state,
                categoryDropdown: action.payload,
                loading: false
            }

        // Category page
        // Stage page
        case types.Get_Client_Stage:
            return {
                ...state,
                clientStageData: action.payload,
                loading: false
            }
        case types.Update_Client_Stage:
        case types.Post_Client_Stage:
        case types.Stage_DropDown:
            return {
                ...state,
                stageDropdown: action.payload,
                loading: false
            }

        // Stage page

        // Custom Fields Page
        case types.Post_Custom_Fields:
        case types.Get_Custom_Fields:
            return {
                ...state,
                customFields: action.payload,
                loading: false
            }
        case types.Update_Custom_Fields:
        case types.Get_Single_Filed:
            return {
                ...state,
                singleField: action.payload,
                loading: false
            }
        // Custom Fields Page

        // Company page
        case types.Get_Company_Data:
            return {
                ...state,
                companyData: action.payload,
                loading: false
            }
        case types.Post_Comapy_Data:
        case types.Update_Company_Data:
        case types.Get_Single_Client:
            return {
                ...state,
                singleClient: action.payload,
                loading: false
            }
        case types.Post_Other_Contact:
        case types.Get_Other_Contact:
            return {
                ...state,
                otherContacts: action.payload,
                loading: false
            }
        case types.ContactBy_Company_Drop:
            return {
                ...state,
                contactByCompanyDrop: action.payload,
                loading: false
            }
        case types.Client_Dropdown:
            return {
                ...state,
                clientDropdown: action.payload,
                loading: false
            }
        // Company page

        // Holiday List Page
        case types.Get_Holiday_List:
            return {
                ...state,
                holidayList: action.payload,
                loading: false
            }
        case types.Post_Holiday_List:
        case types.Update_Holiday_Data:
        case types.Single_Holiday_Data:
            return {
                ...state,
                singleHoliday: action.payload,
                loading: false
            }
        // Holiday List Page

        // Shift page
        case types.Post_Shift_List:
        case types.Get_Shift_List:
            return {
                ...state,
                shiftData: action.payload,
                loading: false
            }
        // Shift page

        // Client Compalint Page
        case types.Post_Client_Complaint:
        case types.Update_Client_Complaint:
        case types.Get_Client_Complains:
            return {
                ...state,
                // clientComplaints: action.payload,
                clientComplaints: action.payload,
                loading: false
            }
        case types.Simgle_Client_Complaint:
            return {
                ...state,
                singleClientCompaint: action.payload,
                loading: false
            }
        // Client Compalint Page

        // Products Page
        case types.Post_Product_Data:
        case types.Update_Product_Data:
        case types.Get_Products_List:
            return {
                ...state,
                productData: action.payload,
                loading: false
            }
        case types.Get_Single_Product:
            return {
                ...state,
                singleProduct: action.payload,
                loading: false
            }
        case types.Get_Product_DropDown:
            return {
                ...state,
                productDropdown: action.payload,
                loading: false
            }
        case types.Get_Cart_List:
            return {
                ...state,
                cartList: action.payload,
                loading: false
            }
        // Products Page

        // Orders Page
        case types.Get_Orders:
            return {
                ...state,
                oderDetails: action.payload,
                loading: false
            }
        case types.Get_Single_Orders:
            return {
                ...state,
                singleOrder: action.payload,
                loading: false
            }
        case types.Get_ProductsBy_Order:
            return {
                ...state,
                productsByOrder: action.payload,
                loading: false
            }
        case types.Update_Product_Qty:
        case types.Get_Order_History:
            return {
                ...state,
                orderHistory: action.payload,
                loading: false
            }
        // Orders Page

        // Meeting Page
        case types.Get_Meetings_Data:
            return {
                ...state,
                meetingData: action.payload,
                loading: false
            }
        // Meeting Page

        // Product category page
        case types.Get_ProdCategory_Dropdown:
            return {
                ...state,
                prodCategoryDrop: action.payload,
                loading: false
            }
        case types.Get_Prod_Sub_Category:
            return {
                ...state,
                prodSubcategory: action.payload,
                loading: false
            }
        // Product category page

        // Designation Page
        case types.Get_Designation_Dropdown:
            return {
                ...state,
                desigationdrop: action.payload,
                loading: false
            }
        case types.Get_Designation_Department:
            return {
                ...state,
                departDesignation: action.payload,
                loading: false
            }
        // Designation Page

        // Basefare page
        // mot dropdown
        case types.Get_Mot_Dropdown:
            return {
                ...state,
                motDrop: action.payload,
                loading: false
            }
        // mot dropdown
        case types.Post_Basefare:
        case types.Get_BaseFare_List:
            return {
                ...state,
                baseFareList: action.payload,
                loading: false
            }
        case types.Update_Basefare:
        case types.Get_Single_BaseFare:
            return {
                ...state,
                singleBaseFire: action.payload,
                loading: false
            }
        // Basefare page

        // Department page
        case types.Get_Department_Dropdown:
            return {
                ...state,
                departmentDrop: action.payload,
                loading: false
            }
        // Department page

        // Get Api's for State, country, Zone, City
        case types.Get_Zones:
            return {
                ...state,
                zoneList: action.payload,
                loading: false
            }
        case types.Get_States_ByZoneId:
            return {
                ...state,
                stateList: action.payload,
                loading: false
            }
        case types.Get_City_ByStateID:
            return {
                ...state,
                cityList: action.payload,
                loading: false
            }
        // Get Api's for State, country, Zone, City

        // Super Admin Pages
        // Google credits
        case types.Post_Google_Credits:
        case types.Post_Api_Credits:
        case types.Get_Google_Credits:
            return {
                ...state,
                googleCreditList: action.payload,
                loading: false
            }
        case types.Get_API_Dropdown:
            return {
                ...state,
                apiDropdown: action.payload,
                loading: false
            }
        case types.Get_Credits_Count:
            return {
                ...state,
                creditCount: action.payload,
                loading: false
            }
        case types.Get_API_Credits:
            return {
                ...state,
                ApiCreditList: action.payload,
                loading: false
            }
        case types.GET_APT_HITS:
            return {
                ...state,
                ApiHitList: action.payload,
                loading: false
            }
        case types.Get_GoogleApiName_Drop:
            return {
                ...state,
                apiNameDropdown: action.payload,
                loading: false
            }
        case types.Get_Single_ApiCredit:
            return {
                ...state,
                apiSingleData: action.payload,
                loading: false
            }
        case types.Get_Exception:
            return {
                apiExceptionData: action.payload,
                loading: false
            }
        case types.Get_Mobile_Exception:
            return {
                apiMobileExceptionData: action.payload,
                loading: false
            }
        // Google credits
        // Super Admin Pages

        // User Page
        case types.Get_User_Dropdown:
            return {
                ...state,
                userDropdown: action.payload,
                loading: false,
            };
        case types.Get_User_Dropdown_By_Company:
            return {
                ...state,
                userDropdownByCompany: action.payload,
                loading: false
            }
        case types.Get_User_Regions:
            return {
                ...state,
                userRegionResult: action.payload,
                loading: false,
            };
        // User Page

        // Googel Hit Count
        // case types.Get_Hit_Count:
        //     return {
        //         ...state,
        //         hitCount: action.payload,
        //         loading: false
        //     }
        // Googel Hit Count

        // Subscription Pages
        case types.Update_Subscription:
        case types.get_Subscription_Type_Dropdown:
            return {
                ...state,
                subscriptionTypeDropdown: action.payload,
                loading: false
            }
        // Subscription Pages

        // Refferal Page
        case types.post_SalesBee_Refferal:
        case types.update_SalesBee_Refferal:
        case types.get_SalesBee_Refferal:
            return {
                ...state,
                salesBeeRefferal: action.payload,
                loading: false
            }
        case types.Get_Single_Refferal_Data:
            return {
                ...state,
                apiSingleRefferalData: action.payload,
                loading: false
            }
        // Refferal Page

        default:
            return state;
    }
};
export default designReducers;