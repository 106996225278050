import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Routes.scss";
import { Col, Row } from "react-bootstrap";
import { baseURL } from "../BaseUrl";
import ReactPaginate from "react-paginate";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { optionsPageSizeList } from "../DropdownsData";
import AccessDenied from "../../AccessDenied";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../Skeleton Loading/SkeletonStdTable";
import RoutePlanDetails from "./RoutePlanDetails";
import * as swal from "../Consturl/SwalAlert";
import { loadUserDropdown } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import OnScrollLoading, { handleScroll } from '../OnScrollLoading';
import SbDropDownBtn from "../SbButtons/SbDropDownBtn";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";

export default function RoutePlan() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  let dispatch = useDispatch();
  const { userDropdown } = useSelector((state) => state.data);

  const currentDate = new Date();
  const tenDaysAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 10
  );

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [routePlanData, setRoutePlanData] = useState([]);
  const [activeStatus, setActiveStatus] = useState(2);
  const [viewRouteID, setViewRouteID] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [fromDate, setFromDate] = useState(tenDaysAgo);
  const [toDate, setToDate] = useState(new Date());
  const [editRoutePlan, setEditRoutePlan] = useState(false);
  const [viewDetails, setViewDetails] = useState(false);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filterUserId, setFilterUserId] = useState("");
  const [routeID, setRouteID] = useState(0);
  const [zoneID, setZoneID] = useState(0);
  const [stateID, setStateID] = useState(0);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const handleClose = () => {
    setShow(false);
    setEditRoutePlan(false);
  };

  const backtoRoutes = () => {
    setViewDetails(!viewDetails);
  };

  useEffect(() => {
    dispatch(loadUserDropdown());
  }, []);

  // GET PAGE START
  useEffect(() => {
    fetchRoutePlans();
  }, [activeStatus, fromDate, toDate, searchVal, currentPage, PageSize, filterUserId]);

  const fetchRoutePlans = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Route/Get?RouteID=${routeID}&Text=${searchVal}&UserID=${filterUserId}&ZoneID=${zoneID}&StateID=${stateID}&Status=${activeStatus}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("ROUTES::", res.result);
        if (res.success) {
          setRoutePlanData(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false)
        } else {
          setRoutePlanData("");
          setIsLoading(false);
          setLoading(false)
        }
      });
  };
  // GET PAGE END

  // CHANGE ROUTE STATUS START
  const changeRouteStatus = (routeId, status) => {
    if (routeId && status) {
      let statusData = {
        // subscriptionID: resPassData.subscriptionId,
        routeID: routeId,
        status: status,
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(statusData),
      };
      fetch(`${baseURL}/api/Route/UpdateRoute`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          // console.log("STATUS-CHANGE-RES::", res.result);
          if (res.success) {
            fetchRoutePlans();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            swal.showAlert("Fail", `${res.errorMessage}`, "warning");
          }
        })
        .catch((error) => {
          console.log("ERROR :", error);
        });
    } else {
      swal.showAlert("Fail", `Route-ID or Status is empty`, "warning");
    }
  };

  // CHANGE ROUTE STATUS END

  const clearSerachBySelect = () => {
    setActiveStatus(2);
    setFilterUserId("");
    setFromDate(tenDaysAgo);
    setToDate(new Date());
  };

  // Page navigation
  let navigate = useNavigate();
  const goToPage = () => {
    navigate(-1);
  };
  // page navigation

  //   const exportExcelBaseFare = () => {
  //     exportExcel(
  //       `${baseURL}/api/Export/MotBaseFareExcelExport?PageSize=100&CurrentPage=1`,
  //       "BaseFare.xlsx"
  //     );
  //   };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {!viewDetails ? (
              <div className={`main-body ${toggleshift.style}`}>
                

                <div className="page-content">
                  {/* Filter and Header Section */}
                  <div className="user-table">
                    <Row className="search-row">
                      <Col md={3} className="cat-col">
                        <div className="page-header-text-div">
                          <div
                            className="back-btn"
                            onClick={goToBackPage}
                          >
                            <img
                              src="../../../../images/DefaultImages/back-btn-arrow.svg"
                              alt=""
                            />
                          </div>
                          <h6
                            className="page-header-text"
                            style={{ color: "#04a96d" }}
                          >
                            {" "}
                            ROUTES{" "}
                          </h6>
                        </div>
                      </Col>
                      <Col md={5} className="cat-col">
                        <div className="input-div">
                          <input
                            type="text"
                            placeholder="Find a Route"
                            className="form-control setting-input"
                            value={searchVal}
                            onChange={(e) => setSearchVal(e.target.value)}
                          />
                          <i className="fa fa-search search-icon"></i>
                        </div>
                      </Col>
                      <Col md={4} className="cat-col">
                        <div
                          className="search-bar-header"
                          style={{ float: "right" }}
                        >
                          <div className="page-top-bar-dropdowns page-top-Filters">
                            <div
                              className="header-filter-btn"
                              onClick={() => setFilter(!filter)}
                            >
                              <p className="card-head">
                                <i className="bi bi-sliders" />
                                &nbsp;&nbsp;Filters
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {filter && (
                      <div className="page-filter-section">
                        <div style={{ width: "100%" }}>
                          <Row>
                            <Col md={10}>
                              <div className="clients-filter-input-boxs filter-box-div">
                                <div
                                  style={{ width: "100%", padding: "0px 5px" }}
                                >
                                  <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    options={userDropdown}
                                    placeholder="Select User"
                                    value={
                                      filterUserId == ""
                                        ? ""
                                        : userDropdown.value
                                    }
                                    onInputChange={userDropdown.label}
                                    onChange={(data) =>
                                      setFilterUserId(data.value)
                                    }
                                    isSearchable={true}
                                  />
                                </div>
                                <div
                                  style={{ width: "100%", padding: "0px 5px" }}
                                  className="input-group from-to-date-select"
                                >
                                  <span
                                    className="input-group-text"
                                    id="from-label"
                                  >
                                    From
                                  </span>
                                  <span className="date-picker-with-icon">
                                    <DatePicker
                                      selected={fromDate}
                                      className="select from-date"
                                      id="from-datepicker"
                                      dateFormat="dd/MM/yyyy"
                                      selectsEnd
                                      // startDate={fromDate}
                                      endDate={toDate}
                                      onChange={(date) => setFromDate(date)}
                                      maxDate={toDate}
                                    />
                                    <i className="fa fa-calendar-o calander-icon" />
                                  </span>
                                </div>
                                <div
                                  style={{ width: "100%", padding: "0px 5px" }}
                                  className="input-group from-to-date-select"
                                >
                                  <span
                                    className="input-group-text"
                                    id="to-label"
                                  >
                                    To
                                  </span>
                                  <span className="date-picker-with-icon">
                                    <DatePicker
                                      selected={toDate}
                                      className="select to-date"
                                      id="from-datepicker"
                                      dateFormat="dd/MM/yyyy"
                                      selectsEnd
                                      // startDate={fromDate}
                                      endDate={toDate}
                                      minDate={fromDate}
                                      onChange={(date) => setToDate(date)}
                                    // maxDate={new Date()}
                                    />
                                    <i className="fa fa-calendar-o calander-icon" />
                                  </span>
                                </div>
                                {/* <div
                                  style={{ width: "100%", padding: "0px 5px" }}
                                ></div> */}
                              </div>
                            </Col>
                            <Col md={2}>
                              <div className="clients-filter-input-boxs filter-box-div">
                                <SbCancelBtn
                                  onClickEffect={clearSerachBySelect}
                                  btnName="Clear"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Filter and Header Section */}

                  {/* USER TABLE START */}
                  <div className="routes-table">
                    <div className="table-responsive routes-scrollbar"
                    onScroll={(e) => handleScroll(e, routePlanData, totalData, setLoading, setPageSize, PageSize)}
                    >
                      <table className="table routes-table-data">
                        <thead className="thead-light routes-thead">
                          {isLoading ? (
                            <>
                              <SkeletonStdTableHead />
                            </>
                          ) : (
                            <tr>
                              <th>NAME</th>
                              <th>FREQUENCY</th>
                              <th>FROM DATE</th>
                              <th>TO DATE</th>
                              <th>ADDED BY</th>
                              <th style={{ textAlign: "center" }}>ACTION</th>
                            </tr>
                          )}
                        </thead>
                        <tbody>
                          {isLoading ? (
                            <>
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                              <SkeletonStdTableBody />
                            </>
                          ) : routePlanData !== "" ? (
                            routePlanData.map((data, i) => (
                              <tr key={i}>
                                <td>
                                  <p
                                    onClick={() => {
                                      setViewDetails(!viewDetails);
                                      setViewRouteID(data.routeID);
                                    }}
                                    className="btn routes-edit-link"
                                  >
                                    <strong>{data?.name}</strong>
                                    <p>Route No. : {data?.code}</p>
                                  </p>
                                </td>
                                <td>{data?.frequency}</td>
                                <td>{data?.fromDate}</td>
                                <td>{data?.toDate}</td>

                                <td>{data?.addedByUserFullName}</td>
                                <td style={{ textAlign: "center" }}>
                                  <p className="pause-play-btn">
                                    {data?.status == 1 ? (
                                      <i
                                        className="fa fa-pause-circle"
                                        onClick={() => {
                                          changeRouteStatus(+data.routeID, +0);
                                        }}
                                        title="PAUSE"
                                      />
                                    ) : (
                                      <i
                                        className="fa fa-play-circle"
                                        onClick={() => {
                                          changeRouteStatus(+data.routeID, +1);
                                        }}
                                        title="PLAY"
                                      />
                                    )}
                                  </p>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={6} className="text-center">
                                {" "}
                                --- NO DATA FOUND ---{" "}
                              </td>
                            </tr>
                          )}
                          {loading &&
                          <OnScrollLoading />
                        }
                        </tbody>
                      </table>
                    </div>
                    <div className="download-and-pagination">
                      <SbDownloadBtn
                      // onClickEffect={}
                      />
                    </div>
                  </div>
                  {/* USER TABLE END */}

                  {/* ADD MODEL START */}
                  {/* {show ? (
                  <AddDesignation
                    show={show}
                    handleClose={handleClose}
                    fetchDesignations={fetchDesignations}
                  />
                ) : (
                  ""
                )} */}
                  {/* ADD MODEL END */}

                  {/* EDIT MODEL START */}
                  {/* {editDesignation ? (
                  <EditDesignation
                    editDesignation={editDesignation}
                    editDepartmentId={editDepartmentId}
                    handleClose={handleClose}
                    fetchDesignations={fetchDesignations}
                  />
                ) : (
                  " "
                )} */}
                  {/* EDIT  MODEL END */}

                  {/* PAGE CONTENT END*/}
                </div>
              </div>
            ) : (
              <>
                {viewDetails ? (
                  <RoutePlanDetails
                    backtoRoutes={backtoRoutes}
                    viewRouteID={viewRouteID}
                    toggleshift={toggleshift}
                    fromDate={fromDate}
                    toDate={toDate}
                  />
                ) : (
                  ""
                )}
              </>
            )}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}
