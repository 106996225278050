import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import { SkeletonClients } from '../Skeleton Loading/SkeletonClients';
import { baseURL } from '../BaseUrl';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getRandomColor } from '../FormattedAmount';
import OnScrollLoading, { handleScroll } from '../OnScrollLoading';
import { useSelector } from 'react-redux';

function ClientTable(props) {
  const { totalCount } = useSelector(state => state.data);

  return (
    <div className="sb-table-div sb-table-setion">
      <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
        <TableContainer sx={{ maxHeight: 440 }}
          onScroll={(e) => handleScroll(e, props.clientData, totalCount, props.setLoading, props.setPageSize, props.PageSize)} >
          <Table stickyHeader aria-label="sticky table table-responsive" className='client-table'>
            <TableHead className='custom-table-header'>
              <TableRow>
                {props.tableHeader.map((header, i) => (
                  < TableCell key={i} align={header.align} style={{ minWidth: `${header.width}` }} >
                    {header.name}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {props.isLoading ?
              <>
                {props.tableHeader.map((header, i) => (
                  <SkeletonClients key={i} />
                ))}
              </>
              :
              <>{props.clientData && props.clientData.length === 0 ?
                <TableBody>
                  <TableRow >
                    <TableCell align="center" colSpan={props.tableHeader.length}>
                      No data found...!
                    </TableCell>
                  </TableRow>
                </TableBody>
                :
                <TableBody>
                  {props.clientData.map((data, i) => (
                    <TableRow align="center" key={i} className='client-list-row-data'>
                      <TableCell>
                        <div className="users-list-name">
                          <div>
                            {data.logo ?
                              <img
                                className="table-data-users-imgages company-logo"
                                src={`${baseURL}/${data.logo}`}
                                alt=""
                              />
                              :
                              <div className="table-data-users-imgages default-image"
                                style={{ backgroundColor: getRandomColor(i) }}
                              >{data?.clientName.charAt(0).toUpperCase()}</div>
                            }
                          </div>
                          <div>
                            <b onClick={(e) => props.handleView(data?.clientID)}
                              style={{ cursor: "pointer" }}> {data?.clientName} </b> <br />
                            <small className="re-tag-count">
                              <i className="fa fa-thumb-tack" /> &nbsp;
                              Re-Tag : {data?.retagCount}
                            </small>
                          </div>
                        </div>
                      </TableCell>
                      <TableCell>
                        {data?.contactPersonName}
                      </TableCell>
                      <TableCell>
                        {data?.mobile} <br />
                        <span className="client-email">
                          {data?.email}
                        </span>
                      </TableCell>
                      <TableCell>
                        {data?.clientCategoryName ? data?.clientCategoryName : "-"} <br />
                        {data?.clientStageName ? data?.clientStageName : "-"}
                      </TableCell>
                      <TableCell>
                        {data?.clientLoc?.location}
                        &nbsp;
                        <span className="client-tagged">
                          {data?.flag}
                        </span>
                      </TableCell>
                      <TableCell>
                        {data?.userFullName}
                        <Link
                          to="#"
                          onClick={() => {
                            props.setShowMappedTo(!props.showMappedTo);
                            props.setClientId(data?.clientID);
                          }}
                          className="view-mapped-user"
                        >
                          <OverlayTrigger
                            placement={"top"}
                            delay={{ show: 200, hide: 250 }}
                            overlay={
                              <Tooltip id={`tooltip-title`}>
                                Mapped To
                              </Tooltip>
                            }
                          >
                            <span>
                              <i className="fa fa-users user-icon" />
                            </span>
                          </OverlayTrigger>
                        </Link>
                      </TableCell>
                      <TableCell align="center">
                        {data?.enabledStatus === 1
                          ? <span className='status-badge complete-status'>Active</span>
                          : <span className='status-badge pending-status'>InActive</span>}
                      </TableCell>
                      <TableCell className='action-class'>
                        <button
                          onClick={() => props.editClientDetails(data?.clientID)}
                          className="btn client-edit-link"
                        >
                          <i className="bi bi-pencil-fill" /> Edit
                        </button>
                        <button
                          onClick={() => {
                            props.setHistoryclientmodel(
                              !props.historyclientmodel
                            );
                            props.setClientId(data?.clientID);
                          }}
                          className="btn client-history-link"
                        >
                          <i className="fa fa-history" /> History
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {props.loading &&
                    <OnScrollLoading tableHeader={props.tableHeader} />
                  }
                </TableBody>
              }
              </>
            }
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

export default ClientTable
