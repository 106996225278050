import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Department.scss";
import { baseURL } from "../../BaseUrl";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { exportExcel } from "../../DownloadFile";
import Select from "react-select";
import { optionsStatusList, optionsPageSizeList } from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import AddDepartment from "./AddDepartment";
import { SkeletonDepartment } from "../../Skeleton Loading/SkeletonDepartment";
import EditDepartment from "./EditDepartment";
import SbAddBtn, { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { loadPageBreadCome } from "../../../redux/action";
import { useDispatch } from "react-redux";

export default function Department() {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let dispatch = useDispatch();

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [editDepartmentModel, setEditDepartmentModel] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [activeStatus, setActiveStatus] = useState(2);
  const [departmentId, setDepartmentId] = useState(0);
  const [deptId, setDeptId] = useState(0);
  const [show, setShow] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  const handleClose = () => {
    setShow(false);
    setEditDepartmentModel(false);
  };

  // GET BASEFARE PAGE START

  useEffect(() => {
    let breadCome = {mainpage: "Department"}
    dispatch(loadPageBreadCome(breadCome))
    fetchDepartments();
  }, [departmentId, activeStatus]);

  const fetchDepartments = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Department/Get?DepartmentID=${departmentId}&Status=${activeStatus}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("DEPARTMENT::", res.result);
        if (res.success) {
          setDepartments(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setDepartments("");
          setIsLoading(false);
        }
      });
  };
  // GET BASEFARE PAGE END

  const clearSerachBySelect = () => {
    setDepartmentId(0);
    setActiveStatus(2);
  };

  const exportExcelDownload = () => {
    exportExcel(
      `${baseURL}/api/Export/DepartmentExcelExport?DepartmentID=${departmentId}&StatusID=${activeStatus}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Departments.xlsx"
    );
  };

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* Filter and Header Section */}
                <div className="user-table">
                  <Row className="search-row">
                    <Col md={8} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToSettingPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <h6
                          className="page-header-text"
                          style={{ color: "#04a96d" }}
                        >
                          DEPARTMENT
                        </h6>
                      </div>
                    </Col>
                    <Col md={4} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <Row style={{ width: "100%" }}>
                            <Col md={7}>
                              <div className="clients-filter-input-boxs filter-box-div">
                                <div
                                  style={{ width: "100%", padding: "0px 5px" }}
                                >
                                  <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    // defaultValue={optionsRoleList[0].label}
                                    options={optionsStatusList}
                                    placeholder="Select Status"
                                    value={
                                      activeStatus == 2
                                        ? ""
                                        : optionsStatusList.value
                                    }
                                    onInputChange={optionsStatusList.label}
                                    onChange={(data) =>
                                      setActiveStatus(data.value)
                                    }
                                    isSearchable={true}
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col md={5}>
                              <div className="clients-filter-input-boxs filter-box-div">
                                <SbCancelBtn
                                  onClickEffect={clearSerachBySelect}
                                  btnName="Clear"
                                />
                              </div>
                            </Col>
                          </Row>
                          <SbAddBtn onClickEffect={() => setShow(!show)} />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* Filter and Header Section */}
                {/* USER TABLE START */}
                <div className="department-table">
                  <div className="table-responsive department-scrollbar">
                    <table className="table department-table-data">
                      <thead className="thead-light department-thead">
                        <tr>
                          <th>SL.NO.</th>
                          <th>DEPARTMENT</th>
                          <th>ADDED ON</th>
                          <th>ADDED BY</th>
                          <th>STATUS</th>
                          <th>ACTION</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <>
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                            <SkeletonDepartment />
                          </>
                        ) : departments !== "" ? (
                          departments.map((data, i) => (
                            <tr key={i} className="department-table-row-data">
                              <td>{i + 1}</td>
                              <td>{data?.name}</td>
                              <td>
                                {moment(data?.addedOnDate).format("DD/MM/YYYY")}
                              </td>
                              <td>{data?.userFullName}</td>
                              <td>
                              {data?.status === "Active" ?
                                                        <span className='status-badge complete-status'>Active</span>
                                                        : <span className='status-badge pending-status'>InActive</span>
                                                    }
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    setEditDepartmentModel(
                                      !editDepartmentModel
                                    );
                                    setDeptId(JSON.parse(data.id));
                                  }}
                                  className="btn department-edit-link"
                                >
                                  <i className="bi bi-pencil-fill" /> Edit
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6}>--- NO DATA FOUND --- </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="download-and-pagination">
                  <SbDownloadBtn onClickEffect={exportExcelDownload} />
                    <span className="user-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i className="fa fa-angle-double-right" />}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <i className="fa fa-angle-double-left" />
                        }
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                      />
                    </span>
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* ADD PRODUCT MODEL START */}
                {show ? (
                  <AddDepartment
                    show={show}
                    handleClose={handleClose}
                    fetchDepartments={fetchDepartments}
                  />
                ) : (
                  ""
                )}
                {/* ADD PRODUCT MODEL END */}

                {/* EDIT PRODUCT MODEL START */}
                {editDepartmentModel ? (
                  <EditDepartment
                    editDepartmentModel={editDepartmentModel}
                    deptId={deptId}
                    handleClose={handleClose}
                    fetchDepartments={fetchDepartments}
                  />
                ) : (
                  " "
                )}
                {/* EDIT PRODUCT MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}
