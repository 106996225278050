import React, { useEffect } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import SbAddBtn, { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { useState } from "react";
import * as HeaderData from "../../HeaderAuthentication";
import { useDispatch, useSelector } from "react-redux";
import {
  loadPostHoliday,
  loadPostShift,
  loadUpdateHoliday,
} from "../../../redux/action";
import moment from "moment";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

function AddShift(props) {
  let shiftId = props.shiftId;
  let dispatch = useDispatch();
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [shiftData, setShiftData] = useState({
    shiftName: "",
    shiftCheckInTime: "",
    shiftCheckOutTime: "",
    isNightShift: 0,
  });

  // console.log(shiftData, "shiftData")
  const { shiftName, shiftCheckInTime, shiftCheckOutTime, isNightShift } =
    shiftData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShiftData({ ...shiftData, [name]: value });
  };
  const [statusValue, setStatusValue] = useState(false);
  const [checkIn, setCheckIn] = useState({ val: null, time: null });
  const [checkOut, setCheckOut] = useState({ val: null, time: null });
  useEffect(() => {
    if (shiftId) {
      if (props.shiftData) {
        props.shiftData.map((item) => {
          if (item.shiftID === shiftId) {
            setShiftData({
              shiftID: item.shiftID,
              shiftName: item.shiftName,
              shiftCheckInTime: item.shiftCheckInTime,
              shiftCheckOutTime: item.shiftCheckOutTime,
              isNightShift: item.isNightShift,
            });
            const formatString = "h:mm A";
            const checkIndateTimeString = moment(
              item.shiftCheckInTime,
              formatString
            ).format();
            const checkInObject = new Date(checkIndateTimeString);
            setCheckIn({
              val: checkInObject,
              time: `${checkInObject.$H}:${checkInObject.$m}`,
            });

            const checkOutdateObject = moment(
              item.shiftCheckOutTime,
              formatString
            ).format();
            const checkOutObject = new Date(checkOutdateObject);
            setCheckOut({
              val: checkOutObject,
              time: `${checkOutObject.$H}:${checkOutObject.$m}`,
            });
            if (item.isNightShift === 1) {
              setStatusValue(true);
            } else {
              setStatusValue(false);
            }
          }
        });
      }
    } else {
      setShiftData({
        shiftName: "",
        shiftCheckInTime: "",
        shiftCheckOutTime: "",
        isNightShift: 0,
      });
    }
  }, [shiftId, props.shiftData]);

  const cancelModale = () => {
    props.shiftModuleClose();
    props.setShiftId("");
  };

  const statusFlag = (statusValue) => {
    setStatusValue(!statusValue);
    if (!statusValue === true) {
      setShiftData({ ...shiftData, isNightShift: 1 });
    } else if (!statusValue === false) {
      setShiftData({ ...shiftData, isNightShift: 0 });
    }
  };

  const selectCheckInTime = (time) => {
    setCheckIn({ val: time, time: `${time.$H}:${time.$m}` });
    let timeS = time.$d.toTimeString().slice(0, 5);

    const currentTime = timeS.slice(0, 2);

    if (currentTime > 12) {
      let newTime = currentTime - 12;
      if (newTime < 10) {
        let str = timeS.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        // console.log("0" + exactTime + " " + "PM", "timeStimeStimeS")
        shiftData.shiftCheckInTime = "0" + exactTime + " " + "PM";
        // setCheckInTime("0" + exactTime + " " + "PM");
      } else {
        let str = timeS.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        shiftData.shiftCheckInTime = exactTime + " " + "PM";
        // setCheckInTime(exactTime + " " + "PM");
      }
    } else if (currentTime === 12) {
      // setCheckInTime(timeS + " " + "PM");
      shiftData.shiftCheckInTime = timeS + " " + "PM";
    } else if (currentTime === 0) {
      let str = timeS.split("");
      str.splice(0, 2, 12);
      let exactTime = str.join("");
      shiftData.shiftCheckInTime = exactTime + " " + "AM";
      // setCheckInTime(exactTime + " " + "AM");
    } else {
      shiftData.shiftCheckInTime = timeS + " " + "AM";
      // setCheckInTime(timeS + " " + "AM");
    }
  };

  const selectCheckOutTime = (time) => {
    // console.log(`${time.$H}:${time.$m}`, "time")
    setCheckOut({ val: time, time: `${time.$H}:${time.$m}` });
    let timeS = time.$d.toTimeString().slice(0, 5);

    const currentTime = timeS.slice(0, 2);

    if (currentTime > 12) {
      let newTime = currentTime - 12;
      if (newTime < 10) {
        let str = timeS.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        // console.log("0" + exactTime + " " + "PM", "timeStimeStimeS")
        // setCheckOutTime("0" + exactTime + " " + "PM");
        shiftData.shiftCheckOutTime = "0" + exactTime + " " + "PM";
      } else {
        let str = timeS.split("");
        str.splice(0, 2, `${newTime}`);
        let exactTime = str.join("");
        shiftData.shiftCheckOutTime = exactTime + " " + "PM";
        // setCheckOutTime(exactTime + " " + "PM");
      }
    } else if (currentTime === 12) {
      shiftData.shiftCheckOutTime = timeS + " " + "PM";
      // setCheckOutTime(timeS + " " + "PM");
    } else if (currentTime == 0) {
      let str = timeS.split("");
      str.splice(0, 2, 12);
      let exactTime = str.join("");
      shiftData.shiftCheckOutTime = exactTime + " " + "AM";
      // setCheckOutTime(exactTime + " " + "AM");
    } else {
      shiftData.shiftCheckOutTime = timeS + " " + "AM";
      // setCheckOutTime(timeS + " " + "AM");
    }
  };

  const [fieldReq, setFieldReq] = useState(false);
  const saveShiftData = (e) => {
    e.preventDefault();
    if (!shiftName || !shiftCheckInTime || !shiftCheckOutTime) {
      setFieldReq(true);
    } else {
      var postRequest = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify([shiftData]),
      };
      dispatch(loadPostShift(postRequest, cancelModale, shiftId));
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.shiftModuleClose}
        className="category-modal-div"
      >
        <Row className="modale-header-div">
          <Col md={6} xs={9} className="cat-col">
            <h6 className="page-header-text">
              {shiftId ? "Edit Shift" : "Add Shift"}
            </h6>
          </Col>
          <Col md={6} xs={3} className="cat-col" align="right">
            <button
              onClick={cancelModale}
              className="bi bi-x close-popup"
            ></button>
          </Col>
        </Row>
        <hr />
        <div className="cat-col">
          <Form onSubmit={saveShiftData}>
            <div className="form-field">
              <Form.Group
                className="mb-3_health"
                controlId="exampleForm.Health"
              >
                <FloatingLabel
                  label="Shift Name"
                  className={`${
                    shiftName || ""
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <Form.Control
                    type="text"
                    value={shiftName || ""}
                    name="shiftName"
                    onChange={handleInputChange}
                    placeholder="Shift Name"
                    autoComplete="off"
                    
                  />
                </FloatingLabel>
                {fieldReq && !shiftName && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter Shift Name
                  </small>
                )}
              </Form.Group>
            </div>
            <div className="form-field">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                {/* <Form.Label className="require">Check In Time</Form.Label> */}
                <FloatingLabel
                  label="Check In Time"
                  className={`${
                    checkIn.val !== ""
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`} >
                  <DesktopTimePicker
                    // label="Time"
                    name="shiftCheckInTime"
                    className="select-time-input"
                    value={checkIn.val}
                    onChange={(time) => {
                      selectCheckInTime(time);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                    // minTime={dayjs("2023-01-30T09:00")}
                    // maxTime={dayjs("2023-01-31T22:00")}
                    // minutesStep={15}
                  />
                </FloatingLabel>
                {fieldReq && !checkIn.val && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Select CheckIn Time
                  </small>
                )}
              </LocalizationProvider>
            </div>
            <div className="form-field">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <FloatingLabel
                  label="Check Out Time"
                  className={`${
                    checkOut.val !== ""
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <DesktopTimePicker
                    name="shiftCheckOutTime"
                    className="select-time-input"
                    value={checkOut.val}
                    onChange={(time) => {
                      selectCheckOutTime(time);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </FloatingLabel>
                {fieldReq && !checkOut.val && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Select CheckIn Time
                  </small>
                )}
              </LocalizationProvider>
            </div>
            <div className="mt-3">
              <div className="form-field form-status-section">
                <div>
                  <Form.Label className="require">Is NightShift</Form.Label>
                </div>
                <div className="btn-status">
                  <Form.Group className="facility-details">
                    <div
                      className={`checkbox ${statusValue && "checkbox--on"}`}
                      name="status"
                      onClick={(e) => statusFlag(statusValue)}
                    >
                      <div className="checkbox__ball">
                        <span
                          className={
                            statusValue ? "enable-text" : "disable-text"
                          }
                        >
                          {statusValue ? "Yes" : "No"}
                        </span>
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
            <hr />
            <div className="sb-btn-section">
              <SBSaveUpdateBtn btnName={shiftId ? "Update" : "Save"} />
              <SbCancelBtn btnName="Cancel" onClickEffect={cancelModale} />
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default AddShift;
