import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/MeetingPurpose.scss";
import { Col, Row } from "react-bootstrap";
import AddPurosemodel from "./AddPurosemodel";
import EditPurposeModel from "./EditPurposeModel";
import { baseURL } from "../../BaseUrl";
import ReactPaginate from "react-paginate";
import { exportExcel } from "../../DownloadFile";
import Select from "react-select";
import { optionsStatusList, optionsPageSizeList } from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import SbAddBtn, { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";
import { loadPageBreadCome } from "../../../redux/action";
import { useDispatch } from "react-redux";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import MeetingPurposeTable from "./MeetingPurposeTable";

function MeetingPurpose() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [editpurposemodel, setEditpurposemodel] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [show, setShow] = useState(false);
  const [meetPurposeId, setMeetPurposeId] = useState(0);
  const [activeStatus, setActiveStatus] = useState(2);
  const [purposeId, setPurposeId] = useState("");
  const [meetPurpose, setMeetPurpose] = useState([]);
  const [meetPurposeDropdown, setMeetPurposeDropdown] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let tableHeader = [
    { name: "PURPOSE", align: "left", width: "200px" },
    { name: "ADDED ON", align: "center", width: "150px" },
    { name: "ADDED BY", align: "left", width: "150px" },
    { name: "STATUS", align: "center", width: "100px" },
    { name: "ACTION", align: "center", width: "100px" },
  ];

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let dispatch = useDispatch();

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  const handleClose = () => {
    setShow(false);
    setEditpurposemodel(false);
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);

  useEffect(() => {
    let breadCome = { mainpage: "Meeting Purpose" };
    dispatch(loadPageBreadCome(breadCome));
    fetchMeetingPurposeData();
  }, [meetPurposeId, activeStatus, PageSize, currentPage]);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/MeetingPurpose/BindPurposeDropdown?status=${1}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          //  console.log("MEET-PURPOSE-DROPDOWN::", res);
          setMeetPurposeDropdown(
            res.result.map((data) => {
              return { value: data.purposeId, label: data.purpose };
            })
          );
        } else {
          setMeetPurposeDropdown([]);
        }
      });
  };

  //  MEETING PURPOSE TABLE START
  const fetchMeetingPurposeData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/MeetingPurpose/Get?PageSize=${PageSize}&CurrentPage=${currentPage}&PurposeId=${meetPurposeId}&Status=${activeStatus}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("MEET-PURPOSE-RES::", res);
        if (res.success) {
          setMeetPurpose(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false)
        } else {
          setMeetPurpose("");
          setIsLoading(false);
          setLoading(false)
        }
      });
  };
  //  MEETING PURPOSE TABLE END
  const [openFilterTab, setOpenFilterTab] = useState(false);
  const clearSerachBySelect = () => {
    setMeetPurposeId(0);
    setActiveStatus(2);
  };

  const exportExcelMeetingPurpose = () => {
    exportExcel(
      `${baseURL}/api/Export/MeetingPurposeExcelExport?PurposeId=${meetPurposeId}&Status=${activeStatus}&PageSize=${PageSize}&CurrentPage=1`,
      "MeetingPurpose.xlsx"
    );
  };

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* Header and Filter Section */}
                <div style={{ position: "relative" }}>
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div
                          className="back-btn"
                          onClick={goToBackPage}
                        >
                          <img
                            src="../../../../images/DefaultImages/back-btn-arrow.svg"
                            alt=""
                          />
                        </div>
                        <h6
                          className="page-header-text"
                          style={{ color: "#04a96d" }}
                        >
                          MEETING PURPOSES
                        </h6>
                      </div>
                    </Col>
                    <Col md={9} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setOpenFilterTab(!openFilterTab)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                          <SbAddBtn onClickEffect={() => setShow(!show)} />
                          
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab && (
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={6}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={meetPurposeDropdown}
                                  placeholder="Select Purpose"
                                  value={
                                    meetPurposeId === 0
                                      ? ""
                                      : meetPurposeDropdown.value
                                  }
                                  onInputChange={meetPurposeDropdown.label}
                                  onChange={(data) =>
                                    setMeetPurposeId(data.value)
                                  }
                                  isSearchable={true}
                                />
                              </div>
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  // defaultValue={optionsRoleList[0].label}
                                  options={optionsStatusList}
                                  placeholder="Select Status"
                                  value={
                                    activeStatus === 2
                                      ? ""
                                      : optionsStatusList.value
                                  }
                                  onInputChange={optionsStatusList.label}
                                  onChange={(data) =>
                                    setActiveStatus(data.value)
                                  }
                                  isSearchable={true}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <SbCancelBtn
                                onClickEffect={clearSerachBySelect}
                                btnName="Clear"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>
                {/* Header and Filter Section */}

                {/* USER TABLE START */}
                <MeetingPurposeTable
                  tableHeader={tableHeader}
                  meetPurpose={meetPurpose}
                  setEditpurposemodel={setEditpurposemodel}
                  editpurposemodel={editpurposemodel}
                  setPurposeId={setPurposeId}
                  loading={loading}
                  setPageSize={setPageSize}
                  PageSize={PageSize}
                  setLoading={setLoading}
                  totalData={totalData}
                />

                {/* USER TABLE END */}

                <div className="download-and-pagination">
                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // defaultValue={optionsRoleList[0].label}
                    options={optionsPageSizeList}
                    placeholder="Show Per Page"
                    value={optionsPageSizeList.value}
                    onInputChange={optionsPageSizeList.label}
                    onChange={(data) => setPageSize(data.value)}
                    isSearchable={true}
                  />
                  <SbDownloadBtn onClickEffect={exportExcelMeetingPurpose} />
                  <div className="user-pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<i className="fa fa-angle-double-right" />}
                      onPageChange={handlePageClick}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={<i className="fa fa-angle-double-left" />}
                      renderOnZeroPageCount={null}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      disabledClassName={"disabled"}
                    />
                  </div>
                </div>

                {/* ADD PURPOSE MODEL START */}
                <AddPurosemodel
                  show={show}
                  handleClose={handleClose}
                  fetchMeetingPurposeData={fetchMeetingPurposeData}
                />
                {/* ADD PURPOSE MODEL END */}

                {/* EDIT PURPOSE MODEL START */}
                <EditPurposeModel
                  editpurposemodel={editpurposemodel}
                  purposeId={purposeId}
                  handleClose={handleClose}
                  fetchMeetingPurposeData={fetchMeetingPurposeData}
                />
                {/* EDIT PURPOSE MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>
            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default MeetingPurpose;
