import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/Leaves.scss";
import "../../styles/CommonStyle.scss";
import { Form } from "react-bootstrap";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import * as swal from "../Consturl/SwalAlert";

function UpdateManagerModel(props) {
  const [managerList, setManagerList] = useState([]);
  const [managerID, setManagerID] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });
  const [designationList, setDesignationList] = useState([]);
  const [reportDesignation, setReportDesignation] = useState(0);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  useEffect(() => {
    fetchDropdown();
  }, [reportDesignation]);

  const fetchDropdown = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Designation/BindDesignationDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDesignationList(
            res.result.map((data) => {
              return { value: data.designationID, label: data.designationName };
            })
          );
        } else {
          setDesignationList([]);
        }
      });
    fetch(
      `${baseURL}/api/User/BindTopManagerDropDown/${reportDesignation}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("Manager-List::", res.result);
          setManagerList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setManagerList([]);
        }
      });
  };

  const updateManager = () => {
    const updateManagerDetails = {
      managerId: managerID,
      userId: props.updateUserId,
      desingation: {
        designationID: reportDesignation,
      },
    };
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(updateManagerDetails),
    };
    fetch(`${baseURL}/api/User/UpdateManager`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("UPDATE-MANAGER-RES::", res);
        if (res.success) {
          props.handleClose();
          props.getManagerSubordinates();
          swal.showAlert("Success", `${res.message}`, "success");
        } else {
          props.handleClose();
          swal.showAlert("Fail", `${res.errorMessage}`, "warning");
        }
        // props.getManagerSubordinates();
      });
  };

  return (
    <div>
      <Modal
        show={props.updateManagerModel}
        onHide={props.handleClose}
        className="main_model"
      >
        <div className="update-leavestatus-heading">
          <h6 className="update-leavesta-head">ASSIGN TO</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <div className="container model-update-leavestatus-body">
          <form>
            <div className="row update-leave-status">
              <div className="col-md-12">
                <div className="upadte-manager-dropdown">
                  <Select
                    className="react-select-container-list model-select"
                    classNamePrefix="react-select-list mb-3"
                    defaultValue={designationList[null]}
                    options={designationList}
                    placeholder="Designation"
                    value={designationList.value}
                    onInputChange={designationList.label}
                    onChange={(data) => setReportDesignation(data.value)}
                    isSearchable={true}
                  />
                  <Select
                    className="select-add-user-model mt-3"
                    options={managerList}
                    placeholder="Select Manager"
                    value={managerList.value}
                    onInputChange={managerList.label}
                    onChange={(data) => setManagerID(data.value)}
                    isSearchable={true}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        <div className="btns-save-cancel">
          {showAlert ? (
            <div className={`response-message ${alertMessage.alertType}`}>
              <p>{alertMessage.message}</p>
            </div>
          ) : (
            ""
          )}
          <div className="lead-save-button">
            <button
              onClick={updateManager}
              type="button"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              Save
            </button>
          </div>
          <div className="lead-cancel-button">
            <button
              onClick={props.handleClose}
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <></>
    </div>
  );
}

export default UpdateManagerModel;
