import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/Spares.scss";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import Multiselect from "multiselect-react-dropdown";
import NoImage from "../../images/No-Image.jpg";
import { Col, FloatingLabel, Row } from "react-bootstrap";
import * as swal from "../Consturl/SwalAlert";
import { salesbeeImgAuth } from "../HeaderAuthentication";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import SpareDefaultImg from "../../images/SpareDefaultImg.png";


function AddSpare(props) {
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  // console.log("SUBSCRIPTION:", resPassData.subscriptionId);

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const [spareName, setSpareName] = useState("");
  const [spareCode, setSpareCode] = useState("");
  const [productCode, setProductCode] = useState([]);
  const [products, setProducts] = useState([]);
  const [productList, setProductList] = useState([]);
  const [isInwarranty, setIsInWarranty] = useState(null);
  const [warranty, setWarranty] = useState("");
  const [serialNumber, setSerialNumber] = useState(0);
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [length, setLength] = useState("");
  const [weight, setWeight] = useState("");
  const [mrp, setMRP] = useState(0);
  const [sellingPrice, setSellingPrice] = useState(0);
  const [spareDescription, setSpareDescription] = useState("");
  const [localImage, setLocalImage] = useState([]);
  const [imagesMultiple, setImagesMultiple] = useState([]);
  const [spareLocalImg, setSpareLocalImg] = useState("");
  const [spareImg, setSpareImg] = useState("");
  const [imgFileName, setImgFileName] = useState("");
  const [enableStatus, setEnableStatus] = useState(1);
  const [fieldReq, setFieldReq] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState({
    alertType: "",
    message: "",
  });
  const hiddenFileInputSingle = useRef(null);
  const hiddenFileInput = useRef(null);

  const warrantyYesNo = [
    { value: 1, label: "Yes" },
    { value: 0, label: "No" },
  ];

  const uploadClickSingle = () => {
    hiddenFileInputSingle.current.click();
  };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    if (fileUploaded.size <= 5242880) {
      setErrorMessage("");
      setSpareImg(fileUploaded);
      setImgFileName(event.target.files[0]?.name);
      // setSpareLocalImg(fileUploaded);

      const reader = new FileReader();
      reader.readAsDataURL(fileUploaded);
      reader.onloadend = () => {
        setSpareLocalImg(reader.result);
      };
    } else {
      setFieldReq(true);
      setErrorMessage("Image size should be below 5MB");
    }
  };

  const uploadClick = () => {
    setErrorMessage("");
    hiddenFileInput.current.click();
  };

  const handleChangeMultipleImg = (event) => {
    setErrorMessage("");
    const fileUploaded = Array.from(event.target.files);
    if (fileUploaded.length <= 6) {
      fileUploaded.map((ele) => {
        if (ele?.size <= 5242880) {
          setImagesMultiple(fileUploaded);
          setLocalImage(fileUploaded);
        } else {
          setFieldReq(true);
          setErrorMessage("Image size should be below 5MB");
        }
      });
    } else {
      setLocalImage([]);
      setFieldReq(true);
      setErrorMessage("You can upload max 6 images");
    }
  };

  // FETCH SELECT DROPDOWN DATA
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Product/BindDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setProductList(res.result);
        } else {
          setProductList([]);
        }
      })
      .catch((error) => {
        // console.log("ERROR :", error);
        swal.showAlert("Fail", `${error}`, "warning");
      });
  };
  // FETCH SELECT DROPDOWN DATA

  // MULTI SELECT START
  const handleSelectProductCode = (selectedList) => {
    setProducts(selectedList);
    setProductCode(selectedList.map((p) => p.productID));
  };
  const handleRemoveProductCode = (selectedList) => {
    setProducts(selectedList);
    setProductCode(selectedList.map((p) => p.productID));
  };
  // MULTI SELECT END

  //*  ADDING IMAGE & CONVERT LINK START *//
  const addImgConvertLink = (e) => {
    e.preventDefault();
    // if (imagesMultiple.length > 0 && spareImg !== null && spareImg !== "") {
    const formData = new FormData();
    if (spareImg !== null && spareImg !== "") {
      formData.length > 0
        ? formData.unshift("Files", spareImg)
        : formData.append("Files", spareImg);
    }
    imagesMultiple.forEach((imgfile) => {
      formData.append("Files", imgfile);
    });
    formData.append("Directory", "Spares");

    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: salesbeeImgAuth,
      body: formData,
    };
    fetch(`${baseURL}/api/Upload/UploadAttachmentImage`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          const imgUrls = [];
          var bigImgUrl, gallImgUrls;
          imgUrls.push(res.result);
          let stringLinks = imgUrls.toString().split(",");
          if (spareImg !== "" && spareImg !== null) {
            bigImgUrl = stringLinks[0];
            gallImgUrls = stringLinks.slice(1);
          } else {
            bigImgUrl = "";
            gallImgUrls = stringLinks;
          }
          // console.log("IMG-RES::", gallImgUrls);
        } else {
          setLocalImage("");
          setSpareLocalImg("");
        }
        addSparePart(bigImgUrl, gallImgUrls);
      })
      .catch((error) => {
        // console.log("ERROR :", error);
        swal.showAlert("Fail", `${error}`, "warning");
      });
    // } else {
    //   setFieldReq(true);
    //   setErrorMessage("Please upload the images");
    // }
  };
  //* ADDING IMAGE & CONVERT LINK END  *//

  const addSparePart = (bigImgUrl, gallImgUrls) => {
    if (
      spareName !== "" &&
      spareCode !== "" &&
      productCode.length > 0 &&
      isInwarranty !== null &&
      (isInwarranty == "1" ? warranty !== "" : warranty === "") &&
      mrp !== "" &&
      mrp !== 0 &&
      sellingPrice !== "" &&
      sellingPrice !== 0
    ) {
      const spareDetails = {
        sparePartsID: 0,
        subscriptionID: resPassData.subscriptionId,
        addedByID: resPassData.userId,
        status: enableStatus,
        name: spareName,
        code: spareCode,
        slNo: serialNumber,
        mrp: mrp,
        price: sellingPrice,
        height: height,
        width: width,
        length: length,
        weight: weight,
        description: spareDescription,
        inWarranty: isInwarranty,
        warrantyPeriod: warranty,
        image: bigImgUrl,
        galaryImages: gallImgUrls?.toString(),
        productIds: productCode?.toString(),
        // color: "string",
        // shape: "string",
        // instruction: "string",
        // specialFeature: "string",
        // countryOfOrigin: "string",
        // courrencySign: "string",
        // warrantyDescription: "string",
        // brand: "string",
        // brandColor: "string",
        // material: "string",
        // manufacturer: "string",
      };

      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(spareDetails),
      };
      fetch(`${baseURL}/api/SpareParts/AddSpareParts`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            props.handleClose();
            props.fetchSpareDetails();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            props.handleClose();
            swal.showAlert("Fail", `${res.errorMessage}`, "warning");
          }
        })
        .catch((error) => {
          // console.log("ERROR :", error);
          swal.showAlert("Fail", `${error}`, "warning");
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required !",
      });
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-edit-spare-model"
      >
        <div className="add-edit-spare-heading">
          <h6 className="add-edit-spare-head">ADD SPARE</h6>
          <i className="fa fa-times" onClick={props.handleClose} />
        </div>
        <form>
          <div className="container add-edit-spare">
            <div className="spare-inputs">
              <div className="main-image-name-code">
                <Row>
                  <Col md={4} className="p-0">
                    <div className="upload-main-img-n-btn">
                      <div className="spare-main-image">
                        {/* {spareLocalImg && ( */}
                          <img
                            className="spare-big-image"
                            src={
                              spareLocalImg ? spareLocalImg : SpareDefaultImg
                            }
                            alt="Spare Images"
                          />
                        {/* )} */}
                      </div>
                      <div className="img-upload-btn">
                        <button
                          type="button"
                          className="btn btn-add-spare-img"
                          onClick={uploadClickSingle}
                        >
                          <input
                            id="getFile"
                            type="file"
                            name="filetobase64"
                            accept="image/*"
                            className="btn-primary upload"
                            ref={hiddenFileInputSingle}
                            onChange={handleChange}
                            style={{ display: "none" }}
                          />
                          <p>
                            <i className="fa fa-camera" />
                            &nbsp; Upload Image
                          </p>
                        </button>
                      </div>
                    </div>
                  </Col>
                  <Col md={8} className="pr-0">
                    <div className="mb-3">
                      <FloatingLabel
                        label="Spare Name"
                        className={`${
                          spareName
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          className="form-control form add-spare-input"
                          placeholder="Spare Name"
                          // value={spareName}
                          required
                          onChange={(e) => setSpareName(e.target.value)}
                        />
                      </FloatingLabel>
                      {fieldReq && spareName == "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {`${alertMessage.message}`}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="mb-3">
                      <FloatingLabel
                        label="Spare Code"
                        className={`${
                          spareCode
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          className="form-control form add-spare-input"
                          placeholder="Spare Code"
                          // value={spareCode}
                          required
                          onChange={(e) => setSpareCode(e.target.value)}
                        />
                      </FloatingLabel>
                      {fieldReq && spareCode == "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {`${alertMessage.message}`}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="mb-3">
                      <FloatingLabel
                        label="Serial Number"
                        className={`${
                          serialNumber
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          className="form-control form add-spare-input"
                          placeholder="Serial Number"
                          // value={serialNumber}
                          onChange={(e) => setSerialNumber(e.target.value)}
                        />
                      </FloatingLabel>
                    </div>

                    <div className="mb-3">
                      <FloatingLabel
                        label="Select Products"
                        className={`${
                          products.length > 0
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Multiselect
                          className="select-add-user-model"
                          options={productList} // Options to display in the dropdown
                          selectedValues={products} // Preselected value to persist in dropdown
                          onSelect={handleSelectProductCode} // Function will trigger on select event
                          onRemove={handleRemoveProductCode} // Function will trigger on remove event
                          showCheckbox={true}
                          displayValue="productName" // Property name to display in the dropdown options
                          placeholder={"Select Products"}
                          emptyRecordMsg="No options available"
                          avoidHighlightFirstOption={true}
                          closeIcon="cancel"
                          showArrow={true}
                          closeOnSelect={true}
                          required
                        />
                      </FloatingLabel>
                      {fieldReq && products.length === 0 ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {`${alertMessage.message}`}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="mb-3 dflex-j-between">
                <div style={{ marginRight: "10px" }}>
                  <FloatingLabel
                    label="Under Warranty"
                    className={`${
                      warrantyYesNo.value || isInwarranty
                        ? "float-visible-select"
                        : "float-hidden float-select"
                    }`}
                  >
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      defaultValue={warrantyYesNo[null]}
                      options={warrantyYesNo}
                      placeholder="Under Warranty"
                      value={warrantyYesNo.value}
                      onInputChange={warrantyYesNo.label}
                      onChange={(data) => setIsInWarranty(data.value)}
                      isSearchable={true}
                      isRequired={true}
                    />
                  </FloatingLabel>
                  {fieldReq &&
                  (isInwarranty === null || isInwarranty === "") ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                {isInwarranty == 1 && (
                  <div>
                    <FloatingLabel
                      label="Warranty"
                      className={`${
                        warranty
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="text"
                        className="form-control form add-spare-input"
                        placeholder="Warranty"
                        // value={warranty}
                        onChange={(e) => setWarranty(e.target.value)}
                      />
                    </FloatingLabel>
                    {fieldReq && isInwarranty == "1" && warranty === "" ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {`${alertMessage.message}`}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                )}
              </div>

              <div className="mb-3 dflex-j-between">
                <div style={{ marginRight: "10px" }}>
                  <FloatingLabel
                    label="Weight"
                    className={`${
                      weight
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                  >
                    <input
                      type="text"
                      className="form-control form add-spare-input"
                      placeholder="Weight"
                      // value={weight}
                      onChange={(e) => setWeight(e.target.value)}
                    />
                  </FloatingLabel>
                </div>
                <div>
                  <FloatingLabel
                    label="Height"
                    className={`${
                      height
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                  >
                    <input
                      type="text"
                      className="form-control form add-spare-input"
                      placeholder="Height"
                      // value={height}
                      onChange={(e) => setHeight(e.target.value)}
                    />
                  </FloatingLabel>
                </div>
              </div>

              <div>
                {" "}
                {/* <FormLabel>Dimension</FormLabel> */}
                <div className="mb-3 dflex-j-between">
                  <div style={{ marginRight: "10px" }}>
                    <FloatingLabel
                      label="Width"
                      className={`${
                        width
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="text"
                        className="form-control form add-spare-input"
                        placeholder="Width"
                        // value={width}
                        onChange={(e) => setWidth(e.target.value)}
                      />
                    </FloatingLabel>
                  </div>
                  <div>
                    <FloatingLabel
                      label="Length"
                      className={`${
                        length
                          ? "float-input-visible"
                          : "float-hidden float-input"
                      }`}
                    >
                      <input
                        type="text"
                        className="form-control form add-spare-input"
                        placeholder="Length"
                        // value={length}
                        onChange={(e) => setLength(e.target.value)}
                      />
                    </FloatingLabel>
                  </div>
                </div>
              </div>

              <div className="mb-3 dflex-j-between">
                <div style={{ marginRight: "10px" }}>
                  <FloatingLabel
                    label="MRP"
                    className={`${
                      mrp ? "float-input-visible" : "float-hidden float-input"
                    }`}
                  >
                    <input
                      type="number"
                      className="form-control form add-spare-input"
                      placeholder="MRP"
                      // value={mrp}
                      onChange={(e) => setMRP(+e.target.value)}
                    />
                  </FloatingLabel>
                  {fieldReq && (mrp === "" || mrp === 0) ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <FloatingLabel
                    label="Selling Price"
                    className={`${
                      sellingPrice
                        ? "float-input-visible"
                        : "float-hidden float-input"
                    }`}
                  >
                    <input
                      type="number"
                      className="form-control form add-spare-input"
                      placeholder="Selling Price"
                      // value={sellingPrice}
                      onChange={(e) => setSellingPrice(+e.target.value)}
                    />
                  </FloatingLabel>
                  {fieldReq && (sellingPrice === "" || sellingPrice === 0) ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {`${alertMessage.message}`}
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="mb-3">
                <FloatingLabel
                  label="Spare Description"
                  className={`${
                    spareDescription
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <textarea
                    rows="3"
                    cols="20"
                    name="usrtxt"
                    wrap="hard"
                    className="form-control form form-textarea add-spare-input"
                    id="descriptionArea"
                    placeholder="Spare Description"
                    required
                    // value={spareDescription}
                    onChange={(e) => setSpareDescription(e.target.value)}
                  />
                </FloatingLabel>
              </div>

              <div className="mb-3">
                <div className="text-center">
                  <button
                    type="button"
                    className="btn btn-add-spare-gallery-img"
                    onClick={uploadClick}
                  >
                    <input
                      id="getFile"
                      type="file"
                      multiple
                      name="uploadMultipleImg"
                      accept="image/*"
                      className="btn-primary upload"
                      ref={hiddenFileInput}
                      onChange={handleChangeMultipleImg}
                      style={{ display: "none" }}
                    />
                    <p className="color-green">
                      Upload Gallery <br />
                      <span className="fz11">(Upload atleast 3 Images)</span>
                    </p>
                  </button>

                  <div className="dflex-j-between">
                    {localImage.length > 0 &&
                      localImage.map((data, i) => (
                        <div className="spare-image-box">
                          <img
                            key={i}
                            className="spare-images"
                            src={
                              localImage
                                ? URL.createObjectURL(data)
                                : SpareDefaultImg
                            }
                            alt="Spare Images"
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>

              <div className="mt-3">
                <div className="enable-status d-flex">
                  <h6>Enable</h6>
                  <div className="enable-radio-client d-flex">
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault1"
                        checked={enableStatus == 1 ? true : false}
                        onChange={() => setEnableStatus(1)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault1"
                      >
                        Yes
                      </label>
                    </div>
                    <div className="form-check enable-radio-status">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="flexRadioDefault"
                        id="flexRadioDefault2"
                        checked={enableStatus == 0 ? true : false}
                        onChange={() => setEnableStatus(0)}
                      />
                      <label
                        className="form-check-label"
                        for="flexRadioDefault2"
                      >
                        No
                      </label>
                    </div>
                  </div>
                  {/* <div style={{ marginLeft: "10px" }}>
                    {fieldReq &&
                    (imagesMultiple.length <= 0 || spareImg.length <= 0) ? (
                      <small
                        id={`Errorfails`}
                        className="form-text text-muted "
                      >
                        {`${errorMessage}`}
                      </small>
                    ) : (
                      fieldReq && (
                        <small
                          id={`Errorfails`}
                          className="form-text text-muted "
                        >
                          {`${errorMessage}`}
                        </small>
                      )
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={addImgConvertLink} btnName="Save" />
          <SbCancelBtn btnName="Cancel" onClickEffect={props.handleClose} />
        </div>
      </Modal>
    </div>
  );
}

export default AddSpare;
