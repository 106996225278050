import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { baseURL } from "../BaseUrl";
// import { salesbeeImgAuth } from "./HeaderAuthentication";
import * as swal from "../Consturl/SwalAlert";

function AddBulkUser(props) {
  const [bulkUserFile, setBulkUserFile] = useState("");
  const [bulkUploadResponse, setBulkUploadResponse] = useState({
    res: null,
    message: null,
  });

  const [uploadExcelFile, setUploadExcelFile] = useState("");

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const salesbeeImgAuth = { Travelize_Authentication: userDetails };

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setUploadExcelFile(fileUploaded);
  };

  // console.log("EXCEL-FILE:", uploadExcelFile);

  useEffect(() => {
    setUploadExcelFile("");
    setBulkUploadResponse({ res: "", message: "" });
  }, [props.addBulkUserModel, props.handleCloseBulkUserModel]);

  const addBulkUser = () => {
    if (uploadExcelFile !== null || uploadExcelFile !== "") {
      const formData = new FormData();
      formData.append("Files", uploadExcelFile);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: salesbeeImgAuth,
        body: formData,
      };
      fetch(
        `${baseURL}/api/Import/ImportUser?Directory=BulkUserUploads`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          // console.log("UPLOAD-FILE-RES::", res);
          if (res.success) {
            setBulkUploadResponse({ res: res.success, message: res.message });
          } else {
            setBulkUploadResponse({
              res: res.success,
              message: res.errorMessage,
            });
          }
        });
    }
  };

  // console.log("UPLOAD-EXCEL-FILE-URL::", bulkUploadResponse);

  return (
    <div>
      <Modal
        show={props.addBulkUserModel}
        onHide={props.handleCloseBulkUserModel}
        className="main_model"
      >
        <div className="add-meet-purpose-heading">
          <h6 className="add-edit-meetpurpose-head">ADD BULK USER</h6>
          <i className="fa fa-times" onClick={props.handleCloseBulkUserModel} />
        </div>
        <div className="container add-edit-meetpurpose">
          <div className="company-name">
            <div className="row mb-2">
              <input
                className="form-control form add-meetpurpose-input"
                id="bulkuserupload"
                type="file"
                name="bulkUserUpload"
                accept=".xls, .xlsx"
                onChange={handleChange}
                placeholder="Add File"
              />
            </div>
          </div>
          <span className="bulk-temp-and-res-message">
            <span
              className={
                bulkUploadResponse.res
                  ? "upload-res-message success"
                  : "upload-res-message false"
              }
            >
              {bulkUploadResponse.message}
            </span>

            <a
              // onClick={uploadClick}
              href={`${baseURL}/TravelizeProData/SUB0000000001/Data/BulkUploadTemplate/Excel_BulkUser_20230831140949532.xlsx`}
              className="btn template-download"
              download={"BulkUploadTemplate"}
              // Use Download File function of excel export call
            >
              <i className="fa fa-download" /> &nbsp;Template Download
            </a>
          </span>
        </div>
        <div className="btns-save-cancel">
          <div className="meetpurpose-save-button">
            <button
              onClick={addBulkUser}
              type="button"
              className="btn btn-save-up"
              data-bs-dismiss="modal"
            >
              <i className="fa fa-upload" /> &nbsp; Bulk Upload
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddBulkUser;
