import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import { Form } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

function CustomFieldTable(props) {
    let customFields = props.customFields;

    return (
        <div className={`sb-table-div sb-table-setion`}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }} >
                    <Table stickyHeader aria-label="sticky table table-responsive">
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((header, i) => (
                                    < TableCell key={i} className={`thead-cell ${(header === "Action" || header === "Sl.No") && "action-class"}`}>
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ? 
                        <TableBody className='custom-table-body'>
                            {props.tableHeader.map(i =>(
                                <TableRow align="center" key={i} >
                                    {props.tableHeader.map(i =>(
                                        <TableCell key={i}>
                                            <Skeleton  />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                            :
                        <TableBody className='custom-table-body'>
                            {customFields && customFields.map((data, i) => (
                                <TableRow align="center" key={i}>
                                    <TableCell align="center">
                                        {i + 1}
                                    </TableCell>
                                    <TableCell>
                                        {data?.fieldName}
                                    </TableCell>
                                    <TableCell >
                                        {data?.fieldType}
                                    </TableCell>
                                    <TableCell >
                                        {data?.isRequired === 1 ? <p style={{ color: "#04a96d" }}>Mandatory</p> : <p style={{ color: "#ffb900" }}>Optional</p>}
                                    </TableCell>
                                    <TableCell >
                                        {data?.status === 1 ? <p style={{ color: "#04a96d" }}>Enable</p> : <p style={{ color: "#ffb900" }}>Disable</p>}
                                    </TableCell>
                                    {props.type === "master" &&
                                        < TableCell align="center">
                                            <input type='checkbox' id="1" name="" />
                                        </TableCell>
                                    }
                                    <TableCell align="center">
                                        <span className="user-action-td" style={{cursor: "pointer"}}>
                                            <p
                                                className="user-edit-link"
                                                onClick={(e) => props.editCustomFiled(data.customeFieldsID)}
                                            >
                                                <i className="bi bi-pencil-fill"></i>{" "}
                                                Edit
                                            </p>
                                        </span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
}
                    </Table>
                </TableContainer>
            </Paper>
        </div >
    )
}

export default CustomFieldTable;
