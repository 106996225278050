import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import { Form } from 'react-bootstrap';
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { useSelector } from 'react-redux';

function TablePage(props) {

    const { totalCount } = useSelector(state => state.data);

    return (
        <div className={`sb-table-div ${props.page === "dashboard" ? "sb-dashboard-table" : "sb-table-setion"} `}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }} 
                 onScroll={(e) => handleScroll(e, props.clientCategoryData, totalCount, props.setLoading, props.setPageSize, props.PageSize)}
                >
                    <Table stickyHeader aria-label="sticky table table-responsive">
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell key={i} className={`thead-cell ${(name === "Action" || name === "Sl.No") && "action-class"}`}
                                        style={{ minWidth: "50px" }}>

                                        &nbsp;{name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.page === "dashboard" &&
                            <TableBody className='custom-table-body'>
                                {props.dBMeetingDetails.map((data, i) => (
                                    <TableRow align="center" key={i}>
                                        <TableCell align="left">
                                            {data?.fullName}
                                        </TableCell>
                                        <TableCell align="center">
                                            {
                                                data?.directVisitMeetings
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {
                                                data?.scheduleMeetings
                                            }
                                        </TableCell>
                                        <TableCell align="center">
                                            {data?.myVisitMeetings}
                                        </TableCell>
                                        <TableCell align="center">
                                            {data?.totalMeeting}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        }
                        {props.page !== "dashboard" &&
                            <>{!props.clientCategoryData ?
                                <TableBody>
                                    <TableRow align="center" >
                                        <TableCell>
                                            No data found...!
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                                :
                                <TableBody>
                                    {props.clientCategoryData.filter((val) =>
                                        val?.name
                                            .toLowerCase()
                                            .includes(props.searchValue)
                                    ).map((data, i) => (
                                        <TableRow align="center" key={i} className='client-list-row-data'>
                                            <TableCell align='center' >
                                                {i + 1}
                                            </TableCell>
                                            <TableCell>
                                                {data.name}
                                            </TableCell>
                                            {props.page === "holiday" &&
                                                <TableCell>
                                                    {data.fromDate}
                                                </TableCell>
                                            }
                                            {props.page === "holiday" &&
                                                <TableCell>
                                                    {data.toDate}
                                                </TableCell>
                                            }
                                            <TableCell>
                                                {data.description}
                                            </TableCell>
                                            <TableCell>
                                                {props.page === "holiday" && <>{data.status === 1 ? "Active" : "Inactive"}</>}
                                                {props.page === "clientCategory" && <>{data.status === 1 ? "Active" : "Inactive"}</>}
                                                {props.page === "stage" && <>{data.status === 1 ? "Active" : "Inactive"}</>}
                                            </TableCell>
                                            <TableCell className='action-class'>
                                                {props.page === "holiday" ?
                                                    <button
                                                        onClick={(e) => props.handleEdit(data?.holidaysID)}
                                                        className="btn client-history-link"
                                                    >
                                                        <i className="fa fa-edit" /> Edit
                                                    </button>
                                                    :
                                                    <Form.Group className="facility-details">
                                                        <div className={`checkbox1 ${data.status === 1 && "checkbox--on"}`}
                                                            name="status"
                                                            onClick={(e) => { !props.btnStatus && props.page === "clientCategory" ? props.handleStatus(data.categoryID) : props.page === "stage" && props.handleStageStatus(data.stageID) }}
                                                        >
                                                            <div className="checkbox__ball">
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                }
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                     {props.loading &&
                                            <OnScrollLoading />
                                        }
                                </TableBody>


                            }</>}
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default TablePage;
