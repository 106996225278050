import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/BaseFare.scss";
import "../../../styles/googleCredits.scss";
import { Col, Form, Row } from "react-bootstrap";
import { baseURL } from "../../BaseUrl";
import { exportExcel } from "../../DownloadFile";
import Select from "react-select";
import { optionsPageSizeList } from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import Skeleton from "react-loading-skeleton";
import { SkeletonMeetPurpose } from "../../Skeleton Loading/SkeletonMeetPurpose";
import { useNavigate } from "react-router-dom";
import {
  getSingleBasefare,
  loadBaseFare,
  loadDesignationDrop,
  loadFilteredBaseFare,
  loadGoogleCredits,
  loadMotDrop,
  loadSingleBaseFare,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import GoogleCreditTable from "./GoogleCreditTable";
import AddGoogleCredits from "./AddGoogleCredits";
import moment from "moment";
import DatePicker from "react-datepicker";
import SbAddBtn from "../../SbButtons/SbAddBtn";

function GoogleCredits() {
  let tableHeader = [
    { name: "SL.NO", align: "center", width: "80px" },
    { name: "API KEY", align: "left", width: "200px" },
    { name: "CONSUMED", align: "center", width: "150px" },
    { name: "AVAILABLE", align: "center", width: "150px" },
    { name: "LIMITS", align: "center", width: "150px" },
    { name: "UPDATED ON", align: "left", width: "150px" },
    { name: "ACTION", align: "center", width: "100px" },
  ];

  let dispatch = useDispatch();
  const { desigationdrop } = useSelector((state) => state.data);
  const { googleCreditList } = useSelector((state) => state.data);
  const { motDrop } = useSelector((state) => state.data);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [activeStatus, setActiveStatus] = useState(2);
  const [selectDesignation, setSelectDesignation] = useState("");
  const [MotId, setMotId] = useState(0);
  const [basefareId, setBasefareId] = useState("");
  const [EditmotID, setEditMotID] = useState("");
  const [clickType, setClickType] = useState("");
  const [show, setShow] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [PageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filterArray, setFilterArray] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const setSearchData = (value) => {
    let list;
    if (filterArray.length !== 0) {
      list = filterArray.filter((item) => item.key !== "Key");
    } else {
      list = [];
    }
    if (value) {
      list.push({ key: "Key", value: value });
    } else {
      list = filterArray.filter((item) => item.key !== "Key");
    }
    setSearchValue(value);
    setFilterArray(list);
  };

  const setSelectSearchDrop = (data, type) => {
    let list;
    if (type === "From") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "FromDate");
      } else {
        list = [];
      }
      list.push({
        key: "FromDate",
        value: moment(new Date(data)).format("DD/MM/YYYY"),
      });
      setFromDate(data);
    } else if (type === "To") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "ToDate");
      } else {
        list = [];
      }
      list.push({
        key: "ToDate",
        value: moment(new Date(data)).format("DD/MM/YYYY"),
      });
      setToDate(data);
    }
    setFilterArray(list);
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  const onClickCredits = (key) => {
    navigate(`/apicredits/${key}`);
  };

  const [editableData, setEditableData] = useState({});
  const [googleCreditId, setGoogleCreditId] = useState("");

  const editGoogleCredit = (googleApiId) => {
    setGoogleCreditId(googleApiId);
    const dataObject = googleCreditList.filter((item) => {
      return item.GoogleApiKey === googleApiId;
    });
    const [data] = dataObject;
    setEditableData(data);
    setShow(true);
  };

  useEffect(() => {
    fetchGoogleCreditdata();
  }, [filterArray, PageSize, currentPage, setLoading]);

  const fetchGoogleCreditdata = () => {
    let Flag = "GetGoogleApiKeyCredits";
    dispatch(
      loadGoogleCredits(Flag, setIsLoading, PageSize, currentPage, filterArray, setLoading)
    );
  };

  const clearSerachBySelect = () => {
    setFilterArray([]);
    setFromDate("");
    setToDate("");
  };

  const exportExcelBaseFare = () => {
    // exportExcel(
    //   `${baseURL}/api/Export/MotBaseFareExcelExport?PageSize=100&CurrentPage=1`,
    //   "BaseFare.xlsx"
    // );
  };

  // Page navigation
  let navigate = useNavigate();
  const goToPage = () => {
    navigate("/dashboard");
  };
  const goToSettingPage = () => {
    navigate("/settings/superadminpage");
  };
  // page navigation

  const [openFilterTab, setOpenFilterTab] = useState(false);

    // Page navigation
    const Navigate = useNavigate();
    const goToBackPage = () => {
      Navigate(-1);
    };
    // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              
              <div className="page-content">
                <div style={{ position: "relative" }}>
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                      <div className="back-btn" onClick={goToBackPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <h6
                          className="page-header-text"
                          style={{ color: "#04a96d" }}
                        >
                          GOOGLE CREDITS
                        </h6>
                      </div>
                    </Col>
                    <Col md={5} className="cat-col">
                      <div className="input-div">
                        <input
                          type="text"
                          placeholder="Find a Key"
                          className="form-control setting-input"
                          value={searchValue}
                          onChange={(e) => setSearchData(e.target.value)}
                        />
                        <i className="fa fa-search search-icon"></i>
                      </div>
                    </Col>
                    <Col md={4} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setOpenFilterTab(!openFilterTab)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                          <SbAddBtn onClickEffect={() => setShow(!show)} />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab && (
                    <>
                      <Row className="page-filter-section">
                        <Col md={10}>
                          <Row className="clients-filter-input-boxs">
                            <Col md={4}>
                              <div className="input-group from-to-date-select">
                                <span
                                  className="input-group-text"
                                  id="from-label"
                                >
                                  From
                                </span>
                                <span className="date-picker-with-icon">
                                  <DatePicker
                                    selected={fromDate || new Date()}
                                    className="select from-date"
                                    id="from-datepicker"
                                    dateFormat="dd/MM/yyyy"
                                    selectsEnd
                                    onChange={(date) =>
                                      setSelectSearchDrop(date, "From")
                                    }
                                    maxDate={toDate}
                                  />
                                  <i className="fa fa-calendar-o calander-icon" />
                                </span>
                              </div>
                            </Col>
                            <Col md={4}>
                              <div className="input-group from-to-date-select">
                                <span
                                  className="input-group-text"
                                  id="to-label"
                                >
                                  To
                                </span>
                                <span className="date-picker-with-icon">
                                  <DatePicker
                                    selected={toDate || new Date()}
                                    className="select to-date"
                                    id="from-datepicker"
                                    dateFormat="dd/MM/yyyy"
                                    selectsEnd
                                    minDate={fromDate}
                                    onChange={(date) =>
                                      setSelectSearchDrop(date, "To")
                                    }
                                    maxDate={new Date()}
                                  />
                                  <i className="fa fa-calendar-o calander-icon" />
                                </span>
                              </div>
                            </Col>
                            {/* <Col md={4}>
                  <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        options={desigationdrop}
                        placeholder="Select Designation"
                        value={
                          !selectDesignation ? "" : desigationdrop.value
                        }
                        onInputChange={desigationdrop.label}
                        onChange={(data) => setSelectDesignation(data.value)}
                        isSearchable={true}
                      />
                  </Col> */}
                          </Row>
                        </Col>
                        <Col md={2} align="center">
                          <button
                            type="button"
                            className="btn btn-search-track"
                            onClick={clearSerachBySelect}
                          >
                            <span className="btn-inline-item d-flex">
                              <i className="fa fa-eraser"> </i>
                              <h6>Clear</h6>
                            </span>
                          </button>
                        </Col>
                      </Row>
                    </>
                  )}
                </div>

                {/*TABLE START */}
                <GoogleCreditTable
                  tableHeader={tableHeader}
                  googleCreditList={googleCreditList}
                  isLoading={isLoading}
                  editGoogleCredit={editGoogleCredit}
                  onClickCredits={onClickCredits}
                />
                {/*TABLE END */}

                <div className="download-and-pagination">
                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // defaultValue={optionsRoleList[0].label}
                    options={optionsPageSizeList}
                    placeholder="Show Per Page"
                    value={optionsPageSizeList.value}
                    onInputChange={optionsPageSizeList.label}
                    onChange={(data) => setPageSize(data.value)}
                    isSearchable={true}
                    // isClearable={true}
                  />
                  <button
                    type="button"
                    className="btn btn-download-excel"
                    onClick={exportExcelBaseFare}
                  >
                    <i className="fa fa-download" /> &nbsp; Download
                  </button>
                </div>

                {/* ADD & EDIT PRODUCT MODEL START */}
                {show && (
                  <AddGoogleCredits
                    show={show}
                    EditmotID={EditmotID}
                    basefareId={basefareId}
                    setBasefareId={setBasefareId}
                    handleClose={handleClose}
                    setEditMotID={setEditMotID}
                    //   clickType={clickType} setClickType={setClickType}
                  />
                )}
                {/* ADD & EDIT PRODUCT MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default GoogleCredits;
