import React, { useState } from "react";
import "../styles/Login.scss";
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Image, Row } from "react-bootstrap";
import travelizeSalesBeeLogo from "../images/SalesBeeLogo.svg";
import AlertModel from "./AlertModel";
import moving_img from "../images/Login_Page/laptop-motion.gif";
import travelize from "../images/Travelize_To_Salesbee.svg";
import { baseURL } from "./BaseUrl";
import * as swal from "../Components/Consturl/SwalAlert";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  // const baseURL = "https://api.travelize.in";

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: null,
    message: null,
  });

  let navigate = useNavigate();

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  const forgotpassword = (e) => {
    const usernameDta = {
      UserName: email,
    };

    let requestoption = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        // Accept: "application/json",
      },
      body: JSON.stringify(usernameDta),
    };
    fetch(`${baseURL}/api/Authentication/ForgotPassword`, requestoption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
        
          //   setResetPasswordId(res.result);
          swal.showAlert("Success", `${res.message}`, "success");
          const forgotPassData = { email: email, id: res.result };
          sessionStorage.setItem(
            "forgotPassData",
            JSON.stringify(forgotPassData)
          );
          setTimeout(() => navigate("/"), 1000);
        } else {
          
          swal.showAlert("Fail", `${res.errorMessage}`, "warning");
        }
      });
    e.preventDefault();
  };

  return (
    <div>
      <div>
        <div className="main-body">
          <div className="login-page-body">
            <Row className="sb-login-row">
              <Col md={5} className="sb-gif-col col-left">
                <div className="w-100">
                  <div className="sb-login-logo-div">
                    <Image
                      src={travelizeSalesBeeLogo}
                      alt="Now Travelize is SalesBee"
                      className="sb-login-logo"
                    />
                  </div>
                  <div className="sb-forgot-text">
                    <h3 className="login-head-text">Forgot Password?</h3>
                    {/* <p style={{ fontWeight: "600" }}>to access SalesBee</p> */}
                  </div>
                  <div className="sb-form-section">
                    <form onSubmit={forgotpassword} className="login-form">
                      <div className="userName-input">
                        <input
                          type="email"
                          className="form-control login-password"
                          id="login-email-id"
                          placeholder="Email Id"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </div>
                      <Row>
                        <Col md={6} className="p-0">
                          <div style={{ padding: "3% 0px" }}>
                            <Link to="/" className="forgot-pass-text">
                              Remembered? Login again
                            </Link>
                          </div>
                        </Col>
                        <Col md={6} className="p-0">
                          <div className="forget-pass-login-button">
                            <Button
                              type="submit"
                              className="btn btn-login-button"
                            >
                              Submit
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </div>
              </Col>
              <Col md={2} className="col-right">
                <div>
                  <div className="devider">
                    <div className="v1"></div>
                  </div>
                  <div className="travelize-log">
                    <Image
                      src={travelize}
                      alt="Now Travelize is SalesBee"
                      className="sb-trv-logo"
                    />
                  </div>
                  <div className="devider">
                    <div className="v1"></div>
                  </div>
                </div>
              </Col>
              <Col md={5} className="sb-gif-col col-right">
                <div>
                  <div className="sb-login-bgimg">
                    <Image
                      src={moving_img}
                      alt="Now Travelize is SalesBee"
                      className="w-100 h-100"
                    />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      <>
        {showAlert ? (
          <AlertModel
            showAlert={showAlert}
            alertMessage={alertMessage}
            closeAlertModel={closeAlertModel}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}

export default ForgotPassword;
