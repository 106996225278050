import React, { useRef, useState } from "react";
import {
  useJsApiLoader,
  GoogleMap,
  InfoWindow,
  InfoBox,
  LoadScript,
  Marker,
  Polyline,
  // DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { React_Google_API_Key } from "../GoogleApiKey";
import markerGreen from "../images/marker-green.svg";
import markerYellow from "../images/marker-yellow.svg";
import { baseURL } from "../Components/BaseUrl";
import CustomMarker from "./Dashboard/CustomMarker";
import mapStyles from "../Components/Dashboard/mapStyles.json";
import { useEffect } from "react";

function GoogleMapComp(props) {
  const refMap = useRef(null);
  const [response, setResponse] = useState(null);
  const [infoWindoisOpen, setInfoWindoisOpen] = useState(false);
  const [showClientInfoWindow, setShowClientInfoWindow] = useState(null);
  const [trackClientInfoWindoisOpen, setTrackClientInfoWindoisOpen] =
    useState(null);

  const handleZoomChanged = () => {
    // const newZoom = refMap.current.getZoom(); //get map Zoom
    // setZoom(newZoom);
    // var zoom = map?.getZoom();
    // console.log(newZoom, "newZoom");
  };

  const options = {
    closeBoxURL: "",
    enableEventPropagation: true,
    closeBoxMargin: "10px",
  };

  const infoStyle = {
    textAlign: "center",
    backgroundColor: "green",
    opacity: 1,
    padding: 3,
    borderRadius: 5,
  };

  // const libraries = ["directions"];

  const { isLoaded, loadError } = useJsApiLoader({
    id: "script-loader",
    googleMapsApiKey: React_Google_API_Key,
    // libraries,
  });

  const containerStyleTrackClient = {
    position: "relative",
    width: "100%",
    height: "calc(100vh - 190px)",
  };

  const containerStyleMeetings = {
    position: "relative",
    width: "100%",
    height: "calc(100vh - 245px)",
  };

  useEffect(() => {
    const DirectionsService = new window.google.maps.DirectionsService();

    DirectionsService.route(
      {
        origin: props.startLatLan,
        destination: props.endLatLan,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setResponse(result);
        } else {
          console.error(`error fetching directions ${result}`);
        }
      }
    );
  }, [props.routelatLan]);

  const showInfoWindow = (e) => {
    setShowClientInfoWindow(e);
  };

  const showTrackClientInfoWindow = (e) => {
    setTrackClientInfoWindoisOpen(e);
  };

  if (loadError) return "Error loading maps";
  if (!isLoaded) return "loading maps";

  let trackmeetingCenter = {
    lat: props.checkInLocation?.lat,
    lng: props.checkInLocation?.lng,
  };
  let loc_img = "../images/dashboard/map-icon.png";

  const defaultMapOptions = {
    styles: mapStyles,
  };

  // let image = `${baseURL}/${props.trackUserDetails.profileIcon}`

  return (
    <div>
      {isLoaded ? (
        <GoogleMap
          ref={refMap}
          defaultOptions={defaultMapOptions}
          mapContainerStyle={
            props.pageType === "TrackuserRoute" ||
            props.pageType === "TrackMeetings" ||
            props.pageType === "RouteMap"
              ? containerStyleMeetings
              : containerStyleTrackClient
          }
          center={
            props.pageType === "TrackuserRoute"
              ? props.routelatLan
              : props.pageType === "TrackMeetings"
              ? trackmeetingCenter
              : props.pageType === "TrackClients"
              ? props.baseLocation
              : props.pageType === "RouteMap"
              ? props.centerLatLng
              : props.latLan
          }
          zoom={
            props.pageType === "Dashboard"
              ? 2
              : props.pageType === "TrackuserRoute" ||
                props.pageType === "RouteMap"
              ? 12
              : props.pageType === "TrackMeetings"
              ? 8
              : props.pageType === "TrackClients"
              ? 7
              : props.pageType === "Tracker"
              ? 12
              : 10
          }
          zoomControl={true}
          onZoomChanged={handleZoomChanged}
          clickableIcons={true}
        >
          {/* ------ Dashboard PAGE START ------ */}
          {props.pageType === "Dashboard" && (
            <>
              {props.activityData &&
                props.activityData.map((activity, i) => (
                  <Marker
                    key={i}
                    className="dash-mark"
                    position={{
                      lat: parseFloat(activity?.cordLoc?.latitude),
                      lng: parseFloat(activity?.cordLoc?.longitude),
                    }}
                    clickable={true}
                    draggable={false}
                    style={{
                      background: "#000",
                      padding: "10px",
                      borderRadius: "50%",
                    }}
                    onClick={() => setInfoWindoisOpen(true)}
                    icon={{
                      // url: `${activity?.BaseURL}${activity?.profileIcon}`,
                      url: loc_img,
                      scale: 5,
                      scaledSize: new window.google.maps.Size(10, 10),
                      style: {
                        borderRadius: "50%",
                      },
                    }}
                    options={{
                      // optimized: false,
                      // zIndex: 2,
                      // animation: window.google.maps.Animation.BOUNCE,
                      style: {
                        borderRadius: "50%",
                      },
                    }}
                  >
                    {/* <CustomMarker userImage={`${activity?.BaseURL}${activity?.profileIcon}`} /> */}
                    {infoWindoisOpen && (
                      <InfoWindow
                        onCloseClick={() => setInfoWindoisOpen(false)}
                      >
                        <div style={{ textAlign: "center", width: "200px" }}>
                          {activity?.fullName}
                        </div>
                      </InfoWindow>
                    )}
                  </Marker>
                ))}
            </>
          )}
          {/* ------ Dashboard PAGE START ------ */}

          {/* ------ TRACKER PAGE START ------ */}

          {props.pageType === "Tracker" && (
            <>
              <Marker
                position={props.latLan}
                clickable={true}
                draggable={false}
                onClick={() => setInfoWindoisOpen(true)}
                // icon={{
                //   url: image,
                //   scaledSize: new window.google.maps.Size(32, 32),
                //   labelOrigin: new window.google.maps.Point(16, 16),
                //   anchor: new window.google.maps.Point(16, 16),
                //   // Set the custom marker icon style
                //   // Use the CSS class for the round marker icon
                //   label: { color: 'white', text: 'Marker' },
                //   options: { labelClass: 'marker-label' },
                // }}
                options={{ className: "custom-marker" }}
              >
                {infoWindoisOpen && (
                  <InfoWindow onCloseClick={() => setInfoWindoisOpen(false)}>
                    <div style={{ textAlign: "center", width: "200px" }}>
                      {props.trackUserDetails?.cordLoc?.location}
                    </div>
                  </InfoWindow>
                )}
              </Marker>
            </>
          )}

          {/* ------ TRACKER PAGE END ------ */}

          {/* ------ TRACK MEETINGS PAGE START ------ */}

          {props.pageType === "TrackMeetings" && (
            <>
              <Marker
                title={`Check-In-Time : ${props.checkInLocation.time}`}
                // label={{ text: `CI`, color: "#ffffff" }}
                position={{
                  lat: props.checkInLocation.lat,
                  lng: props.checkInLocation.lng,
                }}
                clickable={true}
                draggable={false}
                icon={{
                  url: markerGreen,
                  scale: 5,
                }}
                onClick={() => setInfoWindoisOpen(true)}
              >
                {infoWindoisOpen && (
                  <InfoWindow onCloseClick={() => setInfoWindoisOpen(false)}>
                    <div style={{ textAlign: "center", width: "150px" }}>
                      <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                        Check-In-Address :
                      </h6>
                      <div>{props.checkInLocation.location}</div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>

              {props.markerPositions.map((data, i) => (
                <Marker
                  title={data.clientName}
                  key={i}
                  position={{ lat: data.lat, lng: data.lng }}
                  clickable={true}
                  draggable={false}
                  label={{ text: `${i + 1}`, color: "#ffffff" }}
                  onClick={() => showInfoWindow(i)}
                >
                  {showClientInfoWindow == i && (
                    <InfoWindow onCloseClick={() => showInfoWindow()}>
                      <div style={{ textAlign: "center", width: "200px" }}>
                        <div>
                          <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                            Company Name : {data.clientName}
                          </h6>
                        </div>
                        <div>Location : {data.location}</div>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </>
          )}

          {/* ------ TRACK MEETINGS PAGE END ------ */}

          {/* ------ TRACK CLIENT PAGE START ------ */}

          {props.pageType === "TrackClients" && (
            <>
              <Marker
                title={`Base-Location `}
                // label={{ text: `CI`, color: "#ffffff" }}
                position={{
                  lat: props.baseLocation.lat,
                  lng: props.baseLocation.lng,
                }}
                clickable={true}
                draggable={false}
                icon={{
                  url: markerGreen,
                  scale: 5,
                }}
                onClick={() => setInfoWindoisOpen(true)}
              >
                {infoWindoisOpen && (
                  <InfoWindow onCloseClick={() => setInfoWindoisOpen(false)}>
                    <div style={{ textAlign: "center", width: "150px" }}>
                      <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                        Base-Location :
                      </h6>
                      <div>{props.baseLocation.location}</div>
                    </div>
                  </InfoWindow>
                )}
              </Marker>

              {props.clientCordinates.map((data, i) => (
                <Marker
                  title={data.clientName}
                  key={i}
                  position={{ lat: data.lat, lng: data.lng }}
                  clickable={true}
                  draggable={false}
                  label={{ text: `${i + 1}`, color: "#ffffff" }}
                  onClick={() => showTrackClientInfoWindow(i)}
                >
                  {trackClientInfoWindoisOpen == i && (
                    <InfoWindow
                      onCloseClick={() => showTrackClientInfoWindow()}
                    >
                      <div style={{ textAlign: "center", width: "200px" }}>
                        <div>
                          <h6 style={{ fontSize: "13px", fontWeight: "600" }}>
                            Client Name : {data.clientName}
                          </h6>
                        </div>
                        <div>Location : {data.location}</div>
                      </div>
                    </InfoWindow>
                  )}
                </Marker>
              ))}
            </>
          )}

          {/* ------ TRACK CLIENT PAGE END ------ */}

          {/* ------ TRACK USER ROUTE PAGE START ------ */}

          {props.pageType === "TrackuserRoute" && (
            <>
              {response && (
                <>
                  <DirectionsRenderer directions={response} />
                </>
              )}
            </>
          )}
          {/* ------ TRACK USER ROUTE PAGE END ------ */}

          {/* ------ ROUTE MAP PAGE START ------ */}
          {props.pageType === "RouteMap" && (
            <>
              {response !== null && (
                <>
                  <DirectionsRenderer
                    directions={response}
                    // options={{
                    //   directions: response,
                    // }}
                  />
                </>
              )}
            </>
          )}

          {/* ------ ROUTE MAP PAGE END ------ */}
        </GoogleMap>
      ) : (
        ""
      )}
    </div>
  );
}

export default GoogleMapComp;
