import React, { useState, useEffect, useRef } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/Meetings.scss";
import { Col, Image, Row } from "react-bootstrap";
import Viewmeetgattachmodel from "./Viewmeetgattachmodel";
import TrackuserRoute from "./TrackuserRoute";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import ReactPaginate from "react-paginate";
import InternetIssue from "../InternetIssue";
import { useDispatch } from "react-redux";
import { loadPageBreadCome } from "../../redux/action";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import MeetingTable from "./MeetingTable";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";

function Meetingdetails(props) {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  let tableHeader = [
    { name: "NAME", align: "left", width: "150px" },
    { name: "COMPANY", align: "left", width: "250px" },
    { name: "MEETING TIME", align: "left", width: "200px" },
    { name: "MEETING DETAILS", align: "left", width: "200px" },
    { name: "MOT", align: "left", width: "120px" },
    { name: "REMARKS", align: "left", width: "150px" },
    { name: "STATUS", align: "center", width: "120px" },
    { name: "ACTION", align: "center", width: "150px" }
  ];

  let dispatch = useDispatch();

  const [meetingattachmentmodel, setMeetingattachmentmodel] = useState(false);
  const [viewonuserroute, setViewonuserroute] = useState(false);
  const [userList, setUserList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [meetingDetails, setMeetingDetails] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterUserId, setFilterUserId] = useState(props.userID);
  const [fromDate, setFromDate] = useState(new Date(props.meetingdetailsDate));
  const [toDate, setToDate] = useState(new Date(props.meetingdetailsDate));
  const [stateId, setStateId] = useState(0);
  const [meetingId, setMeetingId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const setSelectSearchDrop = (data) => {
    const [start, end] = data;
    setFromDate(start);
    setToDate(end);
  }

  const handleCloseviewattachment = () => setMeetingattachmentmodel(false);

  const goBacktomeetingsdetails = () => {
    setViewonuserroute(!viewonuserroute);
  };

  const tableRef = useRef(null);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  // PAGINATION END

  const [selectedUserId, setSelectedUserId] = useState("");

  useEffect(() => {
    if (filterUserId) {
      userList.map((user) => {
        if (filterUserId == user.value) {
          setSelectedUserId(user);
        }
      });
    }
  }, [filterUserId, userList]);

  // FETCH SELECT DROPDOWN START
  useEffect(() => {
    let breadCome = { mainpage: "Meeting Details" }
    dispatch(loadPageBreadCome(breadCome))
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/Zone/GetStates`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setStateList(
            res.result.map((data) => {
              return { value: data.stateId, label: data.stateName };
            })
          );
        } else {
          setStateList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  // FETCH SELECT DROPDOWN START

  //FETCH MEETING DETAILS START
  useEffect(() => {
    fetchMeetingDetailsByUser();
  }, [fromDate, toDate, filterUserId, stateId, PageSize, currentPage]);

  const fetchMeetingDetailsByUser = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Meeting/GetMeetingDetails?PageSize=${PageSize}&CurrentPage=${currentPage}&UserId=${filterUserId}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&stateId=${stateId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setMeetingDetails(res.result);
          setTotalCount(res.recordCount[0])
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
          setLoading(false);
        } else {
          setMeetingDetails("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //FETCH MEETING DETAILS END

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId(props.userID);
    setFromDate(new Date(props.meetingdetailsDate));
    setToDate(new Date(props.meetingdetailsDate));
    setStateId(0);
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportMeetingDetails = () => {
    exportExcel(
      `${baseURL}/api/Export/MeetingDetailExcelExport?UserID=${filterUserId}&MeetingID=${meetingId}&StateId=${stateId}&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      `MeetingDetails-${moment(fromDate).format("DD/MMM/YYYY")}-${moment(
        toDate
      ).format("DD/MMM/YYYY")}.xlsx`
    );
  };
  //* EXPORT FILE END *//

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}

            {props.meetingdetails && !viewonuserroute ? (
              <div className={`main-body ${props.toggleshift.style}`}>
                <div className="page-content">

                  {/* Filter and Header Section */}
                  <div className="user-table">
                    <Row className='search-row'>
                      <Col md={3} className='cat-col'>
                        <div className='page-header-text-div'>
                          <div className="back-btn" onClick={props.goBacktomeetings}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                          <h6 className='page-header-text' style={{ color: "#04a96d" }}>MEETING DETAILS</h6>
                        </div>
                      </Col>
                      <Col md={9} className='cat-col'>
                        <div className="search-bar-header" style={{ float: "right" }}>
                          <div className="page-top-bar-dropdowns page-top-Filters">
                            <div className="header-filter-btn" onClick={() => setFilter(!filter)}>
                              <p className="card-head">
                                <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                              </p>
                            </div>
                            <button
                              className="refresh-button"
                              onClick={fetchMeetingDetailsByUser}
                              title="Refresh" >
                              <i className="fa fa-refresh refresh-meeting-details" />
                            </button>
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {filter &&
                      <div className="page-filter-section">
                        <div style={{ width: "100%" }}>
                          <Row>
                            <Col md={12}>
                              <div className="clients-filter-input-boxs filter-box-div">
                                <div style={{ width: "100%", padding: "0px 5px" }}>
                                  <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    defaultValue={userList[null]}
                                    options={userList}
                                    placeholder="Select User"
                                    value={selectedUserId}
                                    onInputChange={userList.label}
                                    onChange={(data) => setFilterUserId(data.value)}
                                    isSearchable={true}
                                  />
                                </div>
                                <div style={{ width: "100%", padding: "0px 5px" }}>
                                  <Select
                                    className="react-select-container-list"
                                    classNamePrefix="react-select-list"
                                    options={stateList}
                                    placeholder="Select State"
                                    value={stateId === 0 ? "" : stateList.value}
                                    onInputChange={stateList.label}
                                    onChange={(data) => setStateId(data.value)}
                                    isSearchable={true}
                                  // isClearable={true}
                                  />
                                </div>
                                <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                                  <span className="input-group-text" id="from-label">
                                    From - To
                                  </span>
                                  <span className="date-picker-with-icon">
                                    <DatePicker
                                      selected={fromDate}
                                      className="select from-date date-filter"
                                      id="date-range-picker"
                                      dateFormat="dd/MM/yyyy"
                                      selectsRange
                                      startDate={fromDate}
                                      endDate={toDate}
                                      onChange={(date) => setSelectSearchDrop(date, "date")}
                                      maxDate={new Date()}
                                    />
                                  </span>
                                  <span><i className="fa fa-calendar-o calander-icon" style={{ right: "15px" }} /></span>
                                </div>
                                <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                                  <SbCancelBtn onClickEffect={clearSerachBySelect}
                                    btnName="Clear" />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    }
                  </div>
                  {/* Filter and Header Section */}

                  {/* USER TABLE START */}
                  <MeetingTable tableHeader={tableHeader}
                    isLoading={isLoading} totalCount={totalCount}
                    setMeetingattachmentmodel={setMeetingattachmentmodel} meetingattachmentmodel={meetingattachmentmodel}
                    setMeetingId={setMeetingId}
                    setLoading={setLoading}
                    loading={loading}
                    setPageSize={setPageSize} PageSize={PageSize}
                    setViewonuserroute={setViewonuserroute} viewonuserroute={viewonuserroute}
                    meetingDetails={meetingDetails} />

                  {/* PAGINATION START */}
                  <div className="download-and-pagination">
                    <SbDownloadBtn btnName="Download" onClickEffect={exportMeetingDetails} />
                  </div>
                  {/* PAGINATION END */}
                  {/* USER TABLE END */}

                  {/* PAGE CONTENT END*/}
                </div>
              </div>
            ) : (
              ""
            )}
            {/* MAIN BODY END */}
            <>
              {meetingattachmentmodel ? (
                <Viewmeetgattachmodel
                  meetingattachmentmodel={meetingattachmentmodel}
                  handleCloseviewattachment={handleCloseviewattachment}
                  meetingId={meetingId}
                />
              ) : (
                ""
              )}
              {viewonuserroute ? (
                <TrackuserRoute
                  toggleshift={props.toggleshift}
                  viewonuserroute={viewonuserroute}
                  goBacktomeetingsdetails={goBacktomeetingsdetails}
                  meetingId={meetingId}
                />
              ) : (
                ""
              )}
            </>
          </div>
        </>
      )}
    </div>
  );
}

export default Meetingdetails;
