import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Leaves.scss";
import profileimg from "../../images/profile-1.png";
import Leaveupdatemodel from "./Leaveupdatemodel";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import moment from "moment";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { optionsLeaveStatusList, optionsPageSizeList } from "../DropdownsData";
import {
  SkeletonStdTableBody,
  SkeletonStdTableHead,
} from "../Skeleton Loading/SkeletonStdTable";
import * as swal from "../Consturl/SwalAlert";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import SbTableHeader from "../SbTables/SbTableHeader";
import { useNavigate } from "react-router-dom";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import AddLeaves from "./AddLeaves";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import LeavesApplied from "./LeavesApplied";
import LeaveBalance from "./LeaveBalance";

function Leaves() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const todayDate = new Date();
  const futureDate = new Date(todayDate.getTime() + 30 * 24 * 60 * 60 * 1000);
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [leaveUpdatestatus, setLeaveUpdatestatus] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [userList, setUserList] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [status, setStatus] = useState("");
  const [leaveId, setLeaveId] = useState("");
  const [fromDate, setFromDate] = useState(todayDate);
  const [toDate, setToDate] = useState(futureDate);
  const [leaveDetails, setLeaveDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            <>
              {/* SIDEBAR START */}
              <Sidenavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* SIDEBAR END */}

              {/* TOPBAR START */}
              <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* TOPBAR END */}

              {/* MAIN BODY START */}
              <div className="page-wrapper">
                {/* PAGE CONTENT START */}
                <div className={`main-body ${toggleshift.style}`}>

                  <div className="page-content">
                    {/* Filter and Header Section */}
                    <div className="user-table">
                      {/* <Col md={3} className="cat-col"> */}
                      <Tabs
                        defaultActiveKey="applied"
                        id="uncontrolled-tab-example"
                        className="local-claim-nav-tab"
                      >
                        <Tab eventKey="back" title={<div className="back-btn" onClick={goToBackPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>}>
                          Back
                        </Tab>
                        <Tab title="LEAVES" className="local-claim-head">
                          LEAVES
                        </Tab>
                        <Tab eventKey="applied" title="Applied Leaves">
                          <LeavesApplied />
                        </Tab>

                        <Tab eventKey="balance" title="Leave Balance">
                          <LeaveBalance />
                        </Tab>
                      </Tabs>
                    </div>
                    {/* Filter and Header Section */}

                    {/* PAGE CONTENT END*/}
                  </div>
                </div>

                {/* MAIN BODY END */}
              </div>

              {/* /* ADD LEAVE UPDATE STATUS MODEL END */}
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default Leaves;
