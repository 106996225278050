import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React, { useState } from 'react';
import '../../../styles/googleCredits.scss'
import { useNavigate } from 'react-router-dom';
import { SkeletonMeetPurpose } from '../../Skeleton Loading/SkeletonMeetPurpose';

function ExceptionTable(props) {

    const [showModal, setShowModal] = useState("");

    const handleHover = (apikey) => {
        setShowModal(apikey);
    };

    const removeModal = () => {
        setShowModal("");
    };

    let navigate = useNavigate();
    const navigateToGoogleHit = (key) => {
        navigate(`/googleapikeylist/${key}`)
    }

    function generateHtmlString(text) {
    // logic to format the text and add HTML tags
    // For example, wrap "Error" in <strong> tags and replace line breaks with <br>
    if(text){
    const formattedText = text
      .replace(/Error/g, "<strong>Error</strong>")
      .replace(/\n/g, "<br>")
      .trim(); // Optionally, remove leading/trailing whitespace
  
    return formattedText;
    }
  }

    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }} >
                    <Table stickyHeader aria-label="sticky table table-responsive" className='credits-table'>
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell align={name.align} key={i} className={`thead-cell-products ${name.name === "API KEY" && "sticky-header"}`} >
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ?
                            <>
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                                <SkeletonMeetPurpose />
                            </>
                            :
                            <>
                                {props.apiExceptionData && props.apiExceptionData.length === 0 ?
                                    <TableBody>
                                        <TableRow align="center" >
                                            <TableCell>
                                                No data found...!
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {props.apiExceptionData && props.apiExceptionData.map((data, i) => (
                                            <TableRow align="center" key={i} className='client-list-row-data credits-table'>
                                                <TableCell align="center">
                                                    {i + 1}
                                                </TableCell>
                                               
                                                <TableCell align="center">
                                                    <span style={{ cursor: "pointer"}} >{data.ErrorDateTime}</span>
                                                </TableCell>
                                               
                                                <TableCell align="left">
                                                        <div style={{ color: "#ffb900" }} dangerouslySetInnerHTML={{ __html: generateHtmlString(data.Exception) }} />
                                                </TableCell>
                                               
                                            
                                                {/* <TableCell className='action-class'>
                                                    <button
                                                        onClick={() => props.editGoogleCredit(data.GoogleApiKey)}
                                                        className="btn client-history-link" >
                                                        <i className="bi bi-pencil-fill"></i> Edit
                                                    </button>
                                                </TableCell> */}
                                                {/* {showModal === data.GoogleApiKey  && (
                                                    <div className="modal-box">
                                                        <div style={{position: "relative"}}>
                                                            <div className='cancel-btn' onClick={removeModal}><i class="fa fa-close x-btn"></i></div>
                                                            <span>{data.GoogleApiKey}</span>
                                                        </div>
                                                    </div>
                                                    )} */}
                                            </TableRow>
                                        ))}
                                    </TableBody>

                                }
                            </>
                        }
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default ExceptionTable;





    
    
    