import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { ProSidebar, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import "../styles/Sidenavbar.scss";
import profileimg from "../images/admin-1.png";
// import SalesBee from "../images/SalesBeeColor.svg";
import SalesBee from "../images/SalesBeeLogo.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { baseURL } from "./BaseUrl";
import { useEffect } from "react";
import ORModal from "./ORModal";

function Sidenavbar(props) {
  const [open, setOpen] = useState(false);
  const [subId, setSubId] = useState("");

  const menuCollapse = props.menuCollapse;

  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [menuItems, setMenuItems] = useState([])
  const [submenuAtt, setSubmenuAtt] = useState([])
  const [submenuClm, setSubmenuClm] = useState([])
  const [submenuStg, setSubmenuStg] = useState([])

  useEffect(() => {
    let activities = [];
    let subActAtt = [];
    let subActClm = [];
    let subActStng = [];
    if (resPassData?.subscription?.modules.length != 0) {

      resPassData?.subscription?.modules.map((item) => {
        if (item.parentModuleName === null) {
          activities.push({ name: item.moduleName, subNames: [] })
        }
        else if (item.parentModuleName === "Attendance") {
          subActAtt.push({ name: item.moduleName })
        }
        else if (item.parentModuleName === "Claims") {
          subActClm.push({ name: item.moduleName })
        }
        else if (item.parentModuleName === "Settings") {
          subActStng.push({ name: item.moduleName })
        }
      })


    } else {
      activities.push({ name: "Dashboard", subNames: [] })
    }
    setMenuItems(activities)
    setSubmenuAtt(subActAtt)
    setSubmenuClm(subActClm)
    setSubmenuStg(subActStng)
  }, [])

  useEffect(() => {
    if (menuItems) {
      menuItems.map((activities) => {
        if (activities.name === "Attendance") {
          activities.subNames = submenuAtt
        }
        if (activities.name === "Claims") {
          activities.subNames = submenuClm
        }
        if (activities.name === "Settings") {
          activities.subNames = submenuStg
        }
      })
    }
  }, [menuItems, submenuAtt, submenuClm, submenuStg])


  const loggedInRole = resPassData?.roleId;

  // const GetImage = (img, defaultImg) => {
  //   return img != undefined &&
  //     img !== null &&
  //     img !== "" &&
  //     img !== "no_img.png"
  //     ? `${baseURL}/${img}`
  //     : defaultImg;
  // };
  // if (resPassData != null) {
  //   resPassData.profilePic = GetImage(resPassData?.profilePic, profileimg);
  // }


  let passedId = sessionStorage.getItem("userID");

  const subMenuOpen = (e) => {
    setSubId(e.target.id);
    setOpen(!open);
  };

  const [openQrModal, setOpenQrModal] = useState(false)
  const openAppQrCode = () => {
    setOpenQrModal(true)
  }

  const closeQrModal = () => {
    setOpenQrModal(false)
  }

  return (
    <div>
      {openQrModal && <ORModal openQrModal={openQrModal} closeQrModal={closeQrModal} />}
      <div id="header">
        <ProSidebar collapsed={menuCollapse} className="pro-side-bar">
          <SidebarHeader className="side_bar_header_main">
            <div className="logotext">
              <div className="logo profile-mini">
                {menuCollapse ? (
                  <div className="profile-image">
                    <img
                      src={
                        resPassData?.profilePic !== "" &&
                          resPassData?.profilePic !== null &&
                          resPassData?.profilePic !== "no_img.png"
                          ? `${baseURL}/${resPassData?.profilePic}`
                          : profileimg
                      }
                      alt="Profile-Img"
                    />
                  </div>
                ) : (
                  <Link className="travelize_logo" to="/dashboard">
                    <img
                      src={SalesBee}
                      alt="product-title-img"
                      className="salesbee-logo"
                    />
                  </Link>
                )}
              </div>
            </div>
          </SidebarHeader>

          {/* {!menuCollapse &&  */}
          <div className="profile-image-sidebar-big">
            <div className="profile-controls">
              {/* <div className="beta-tag-inside">
                <h6>Beta</h6>
              </div> */}
            </div>
          </div>
          {/* } */}
          <SidebarContent
            className="sidebar_content sidenavbar-scroll"
            id="sidenavAccordion"
          >
            {/* START NAV LINK ITEMS */}
            {!menuCollapse ? (
              <div>
                {menuItems && menuItems.map((menuItems, i) => (
                  <div className="expanded-nav-bar" key={i}>
                    {menuItems.name === "Dashboard" &&
                      <Link to="/dashboard">
                        <div
                          className={
                            window.location.hash === `#/dashboard` || window.location.hash === ""
                              ? "sidebar-item plain active"
                              : "sidebar-item plain"
                          }
                        >
                          <li>
                            <div className="nav-link-item d-flex">
                              <div className="fa-icon-div">
                                {/* <span className="fa fa-desktop"></span> */}
                                <img src="../images/SideBar/desktop.svg" alt="" width="100%" />
                              </div>
                              {/* <span className="xn-text">DASHBOARD</span> */}
                              <span className="xn-text">Dashboard</span>
                            </div>
                          </li>
                        </div>
                      </Link>
                    }
                    {menuItems.name === "Users" &&
                      <Link to="/users">
                        <div
                          className={
                            window.location.hash === `#/users`
                              ? "sidebar-item plain active"
                              : "sidebar-item plain"
                          }
                        >
                          <li>
                            <div className="nav-link-item d-flex">
                              <div className="fa-icon-div">
                                <img src="../images/SideBar/users.svg" alt="" width="100%" />
                              </div>
                              <span className="xn-text">Users</span>
                            </div>
                          </li>
                        </div>
                      </Link>
                    }
                    {(menuItems.name === "Attendance") &&
                      <div
                        className={
                          (subId === "attendance" && open) ||
                            window.location.hash === `#/attendance/monthly` ||
                            window.location.hash === `#/attendance/fullday`
                            ? "sidebar-item open"
                            : "sidebar-item"
                        }
                      >
                        <li
                          name="ATTENDANCE"
                          id="attendance"
                          onClick={(e) => subMenuOpen(e)}
                          className={
                            (subId === "attendance" && open) ||
                              window.location.hash === `#/attendance/monthly` ||
                              window.location.hash === `#/attendance/fullday`
                              ? "nav-link-item drop-down-item active"
                              : "nav-link-item drop-down-item drop-main"
                          }
                        >
                          <div className="sidebar-title" id="attendance">
                            <div className="fa-icon-div">
                              <img src="../images/SideBar/attendance.svg" alt="" width="100%" />
                            </div>
                            <span
                              className="xn-text"
                              name="attendance"
                              id="attendance"
                            >
                              Attendance
                            </span>
                            <i
                              className="bi-chevron-right toggle-btn"
                              id="attendance"
                            />
                          </div>
                        </li>
                        {(subId === "attendance" && open) ||
                          window.location.hash === `#/attendance/monthly` ||
                          window.location.hash === `#/attendance/fullday` ? (
                          <>
                            {menuItems.subNames.map((subMenu) => (
                              <div>
                                {subMenu.name == "Monthly" &&
                                  <Link to="/attendance/monthly">
                                    <div
                                      className={
                                        window.location.hash === `#/attendance/monthly`
                                          ? `sidebar-content sub-active`
                                          : `sidebar-content`
                                      }
                                    >
                                      <div className="nav-link-item">
                                        <span className="bi bi-check-square-fill"></span>
                                        <span className="xn-text">Monthly</span>
                                      </div>
                                    </div>
                                  </Link>
                                }

                                {subMenu.name === "Fullday" &&
                                  <Link to="/attendance/fullday">
                                    <div
                                      className={
                                        window.location.hash === `#/attendance/fullday`
                                          ? `sidebar-content sub-active`
                                          : `sidebar-content`
                                      }
                                    >
                                      <div className="nav-link-item">
                                        <span className="bi bi-check-square-fill"></span>
                                        <span className="xn-text">Full Day</span>
                                      </div>
                                    </div>
                                  </Link>
                                }
                              </div>
                            ))
                            }
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    }
                    {menuItems.name === "Client Complaint" &&
                      <Link to="/complaints">
                        <div
                          className={
                            window.location.hash === `#/complaints`
                              ? "sidebar-item plain active"
                              : "sidebar-item plain"
                          }
                        >
                          <li>
                            <div className="nav-link-item d-flex">
                              <div className="fa-icon-div">
                                <img src="../images/SideBar/complaints.svg" alt="" width="100%" />
                              </div>
                              <span className="xn-text">Complaints</span>
                              <i
                                className="bi-chevron-right toggle-btn"
                                style={{ visibility: "hidden" }}
                              ></i>
                            </div>
                          </li>
                        </div>
                      </Link>
                    }
                    {menuItems.name === "Leaves" &&
                      <Link to="/leaves">
                        <div
                          className={
                            window.location.hash === `#/leaves`
                              ? "sidebar-item plain active"
                              : "sidebar-item plain"
                          }
                        >
                          <li>
                            <div className="nav-link-item d-flex">
                              <div className="fa-icon-div">
                                <img src="../images/SideBar/leaves.svg" alt="" width="100%" />
                              </div>
                              <span className="xn-text">Leaves</span>
                            </div>
                          </li>
                        </div>
                      </Link>
                    }
                    {menuItems.name === "Meetings" &&
                      <Link to="/meetings">
                        <div
                          className={
                            window.location.hash === `#/meetings`
                              ? "sidebar-item plain active"
                              : "sidebar-item plain"
                          }
                        >
                          <li>
                            <div className="nav-link-item d-flex">
                              <div className="fa-icon-div">
                                <img src="../images/SideBar/meetings.svg" alt="" width="100%" />
                              </div>
                              <span className="xn-text">Meetings</span>
                            </div>
                          </li>
                        </div>
                      </Link>
                    }
                    {menuItems.name === "Clients" &&

                      <Link to="/company">
                        <div
                          className={
                            window.location.hash === `#/company`
                              ? `sidebar-item plain active`
                              : `sidebar-item plain`
                          }
                        >
                          <li>
                            <div className="nav-link-item d-flex">
                              <div className="fa-icon-div">
                                <img src="../images/SideBar/company.svg" alt="" width="100%" />
                              </div>
                              <span className="xn-text">Company</span>
                            </div>
                          </li>
                        </div>
                      </Link>
                    }
                    {menuItems.name === "Orders" &&
                      <Link to="/orders">
                        <div
                          className={
                            window.location.hash === `#/orders`
                              ? `sidebar-item plain active`
                              : `sidebar-item plain`
                          }
                        >
                          <li>
                            <div className="nav-link-item d-flex">
                              <div className="fa-icon-div">
                                <img src="../images/SideBar/products.svg" alt="" width="100%" />
                              </div>
                              <span className="xn-text">{menuItems.name}</span>
                            </div>
                          </li>
                        </div>
                      </Link>
                    }
                    {menuItems.name === "Products" &&
                      <Link to="/products">
                        <div
                          className={
                            window.location.hash === `#/products`
                              ? `sidebar-item plain active`
                              : `sidebar-item plain`
                          }
                        >
                          <li>
                            <div className="nav-link-item d-flex">
                              <div className="fa-icon-div">
                                <img src="../images/SideBar/products.svg" alt="" width="100%" />
                              </div>
                              <span className="xn-text">Products</span>
                            </div>
                          </li>
                        </div>
                      </Link>
                    }
                    {menuItems.name === "Routes" &&
                      <Link to="/routes">
                        <div
                          className={
                            window.location.hash === `#/routes`
                              ? `sidebar-item plain active`
                              : `sidebar-item plain`
                          }
                        >
                          <li>
                            <div className="nav-link-item d-flex">
                              <div className="fa-icon-div">
                                <img src="../images/SideBar/routes.svg" alt="" width="100%" />
                              </div>
                              <span className="xn-text">Routes</span>
                            </div>
                          </li>
                        </div>
                      </Link>
                    }
                    {menuItems.name === "Spares" &&
                      <Link to="/spares">
                        <div
                          className={
                            window.location.hash === `#/spares`
                              ? `sidebar-item plain active`
                              : `sidebar-item plain`
                          }
                        >
                          <li>
                            <div className="nav-link-item d-flex">
                              <div className="fa-icon-div">
                                <img src="../images/SideBar/spares.svg" alt="" width="100%" />
                              </div>
                              <span className="xn-text">Spares</span>
                            </div>
                          </li>
                        </div>
                      </Link>
                    }
                    {menuItems.name === "Claims" &&
                      <div
                        className={
                          (subId === "claims" && open) ||
                            window.location.hash === `#/localclaim` ||
                            window.location.hash === `#/outstationclaim`
                            ? "sidebar-item open"
                            : "sidebar-item"
                        }
                      >
                        <li
                          name="CLAIMS"
                          className={
                            (subId === "claims" && open) ||
                              window.location.hash === `#/localclaim` ||
                              window.location.hash === `#/outstationclaim`
                              ? "nav-link-item active"
                              : "nav-link-item drop-main"
                          }
                          id="claims"
                          onClick={(e) => subMenuOpen(e)}
                        >
                          <div className="sidebar-title" id="claims">
                            <div className="fa-icon-div">
                              <img src="../images/SideBar/claims.svg" alt="" width="100%" />
                            </div>
                            <span className="xn-text" name="CLAIMS" id="claims">
                              Claims
                            </span>
                            <i
                              className="bi-chevron-right toggle-btn"
                              id="claims"
                            />
                          </div>
                        </li>
                        {(subId === "claims" && open) ||
                          window.location.hash === `#/localclaim` ||
                          window.location.hash === `#/outstationclaim` ? (
                          <>
                            {menuItems.subNames.map((subMenu) => (
                              <div>
                                {subMenu.name === "Local Claims" &&
                                  <Link to="/localclaim">
                                    <div
                                      className={
                                        window.location.hash === `#/localclaim`
                                          ? `sidebar-content sub-active`
                                          : `sidebar-content`
                                      }
                                    >
                                      <div className="nav-link-item">
                                        <span className="bi bi-check-square-fill"></span>
                                        {/* <span className="xn-text">MANAGER</span> */}
                                        <span className="xn-text">Local Claim</span>
                                      </div>
                                    </div>
                                  </Link>
                                }
                                {subMenu.name === "Outstation Claims" &&
                                  <Link to="/outstationclaim">
                                    <div
                                      className={
                                        window.location.hash === `#/outstationclaim`
                                          ? `sidebar-content sub-active`
                                          : `sidebar-content`
                                      }
                                    >
                                      <div className="nav-link-item">
                                        <span className="bi bi-check-square-fill"></span>
                                        {/* <span className="xn-text">ADMIN</span> */}
                                        <span className="xn-text">Outstation Claim</span>
                                      </div>
                                    </div>
                                  </Link>
                                }
                              </div>
                            ))}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    }
                  </div>
                ))}
                <hr />
                <div className="expanded-nav-bar">
                <div onClick={openAppQrCode}>
                        <div
                          className={`sidebar-item plain`}
                        >
                          <li>
                            <div className="nav-link-item d-flex">
                              <div className="fa-icon-div">
                                <img src="../images/SideBar/downloadApp.svg" alt="" width="100%" />
                              </div>
                              <span className="xn-text">Download SalesBee App</span>
                            </div>
                          </li>
                        </div>
                      </div>
                      </div>
              </div>
            ) : (
              //  COLLAPSED NAV BAR START
              <div>
                {menuItems && menuItems.map((menuItems, i) => (

                  <div className="collapsed-nav-bar">
                    {menuItems.name === "Dashboard" &&
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ show: 250, hide: 300 }}
                        overlay={
                          <Tooltip id={`tooltip-dashboard`}>Dashboard</Tooltip>
                        }
                      >
                        <li id="#dashboard">
                          <NavLink to="/dashboard">
                            <img src="../images/SideBar/desktop.svg" alt="" width="20px" />
                            {/* <span className="fa fa-desktop"></span> */}
                          </NavLink>
                        </li>
                      </OverlayTrigger>
                    }
                    {menuItems.name === "Users" &&
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ show: 250, hide: 300 }}
                        overlay={<Tooltip id={`tooltip-users`}>Users</Tooltip>}
                      >
                        <li id="#users">
                          <NavLink to="/users">
                          <img src="../images/SideBar/users.svg" alt="" width="20px" />
                            {/* <span className="fa fa-group"></span> */}
                          </NavLink>
                        </li>
                      </OverlayTrigger>
                    }
                    {menuItems.name === "Attendance" &&
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ show: 250, hide: 300 }}
                        overlay={
                          <Tooltip id={`tooltip-meetings`}>Attendance</Tooltip>
                        }
                      >
                        <li id="#attendance" className="xn-openable">
                          <NavLink to="/attendance/monthly">
                          <img src="../images/SideBar/attendance.svg" alt="" width="20px" />
                            {/* <span className="fa fa-calendar-check-o"></span> */}
                          </NavLink>
                        </li>
                      </OverlayTrigger>
                    }
                    {menuItems.name === "Client Complaint" &&
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ show: 250, hide: 300 }}
                        overlay={<Tooltip id={`tooltip-meetings`}>Complaints</Tooltip>}
                      >
                        <li id="leaves">
                          <NavLink to="/leaves">
                            <img src="../images/SideBar/complaints.svg" alt="" width="20px" />
                            {/* <span className="bi bi-ui-checks fa"></span> */}
                          </NavLink>
                        </li>
                      </OverlayTrigger>
                    }
                    {menuItems.name === "Leaves" &&
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ show: 250, hide: 300 }}
                        overlay={<Tooltip id={`tooltip-leaves`}>Leaves</Tooltip>}
                      >
                        <li id="leaves">
                          <NavLink to="/leaves">
                            <img src="../images/SideBar/leaves.svg" alt="" width="20px" />
                            {/* <span className="fa fa-handshake-o"></span> */}
                          </NavLink>
                        </li>
                      </OverlayTrigger>
                    }
                    {menuItems.name === "Meetings" &&
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ show: 250, hide: 300 }}
                        overlay={<Tooltip id={`tooltip-meetings`}>Meetings</Tooltip>}
                      >
                        <li id="meetings">
                          <NavLink to="/meetings">
                            <img src="../images/SideBar/meetings.svg" alt="" width="20px" />
                            {/* <span className="fa fa-handshake-o"></span> */}
                          </NavLink>
                        </li>
                      </OverlayTrigger>
                    }
                    {menuItems.name === "Clients" &&
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ show: 250, hide: 300 }}
                        overlay={<Tooltip id={`tooltip-company`}>Company</Tooltip>}
                      >
                        <li id="company" className="xn-openable">
                          <NavLink to="/company">
                            <img src="../images/SideBar/company.svg" alt="" width="20px" />
                            {/* <span className="fa fa-user-circle"></span> */}
                          </NavLink>
                        </li>
                      </OverlayTrigger>
                    }
                    {menuItems.name === "Orders" &&
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ show: 250, hide: 300 }}
                        overlay={<Tooltip id={`tooltip-orders`}>Orders</Tooltip>}
                      >
                        <li id="orders">
                          <NavLink to="/orders">
                            <img src="../images/SideBar/products.svg" alt="" width="20px" />
                            {/* <span className="fa fa-handshake-o"></span> */}
                          </NavLink>
                        </li>
                      </OverlayTrigger>
                    }
                    {menuItems.name === "Products" &&
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ show: 250, hide: 300 }}
                        overlay={<Tooltip id={`tooltip-products`}>Products</Tooltip>}
                      >
                        <li id="products" className="xn-openable">
                          <NavLink to="/products">
                            <img src="../images/SideBar/products.svg" alt="" width="20px" />
                            {/* <span className="fa fa-cube"></span> */}
                          </NavLink>
                        </li>
                      </OverlayTrigger>
                    }
                    {menuItems.name === "Spares" &&
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ show: 250, hide: 300 }}
                        overlay={<Tooltip id={`tooltip-spares`}>Spares</Tooltip>}
                      >
                        <li id="#spares">
                          <NavLink to="/spares">
                            <img src="../images/SideBar/spares.svg" alt="" width="20px" />
                            {/* <span className="fa fa-cogs"></span> */}
                          </NavLink>
                        </li>
                      </OverlayTrigger>
                    }
                    {menuItems.name === "Routes" &&
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ show: 250, hide: 300 }}
                        overlay={<Tooltip id={`tooltip-routes`}>Routes</Tooltip>}
                      >
                        <li id="routes">
                          <NavLink to="/routes">
                            <img src="../images/SideBar/routes.svg" alt="" width="20px" />
                            {/* <span className="fa fa-handshake-o"></span> */}
                          </NavLink>
                        </li>
                      </OverlayTrigger>
                    }
                    {menuItems.name === "Claims" &&
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ show: 250, hide: 300 }}
                        overlay={<Tooltip id={`tooltip-meetings`}>Claim</Tooltip>}
                      >
                        <li id="claims" className="xn-openable">
                          <NavLink to="/localclaim">
                            <img src="../images/SideBar/claims.svg" alt="" width="20px" />
                            {/* <span className="bi bi-currency-dollar fa"></span> */}
                          </NavLink>
                        </li>
                      </OverlayTrigger>
                    }

                  </div>
                ))}
                <hr />
                  <div className="collapsed-nav-bar">
                      <OverlayTrigger
                          placement={"right"}
                          delay={{ show: 250, hide: 300 }}
                          overlay={<Tooltip id={`tooltip-meetings`}>Download SalesBee App</Tooltip>}
                        >
                          <li id="claims" className="xn-openable">
                            <div onClick={openAppQrCode} style={{cursor: "pointer"}}>
                              <img src="../images/SideBar/downloadApp.svg" alt="" width="20px" />
                            </div>
                          </li>
                        </OverlayTrigger>
                  </div>
                </div>
              //  COLLAPSED NAV BAR END
            )}
            
            {/* END NAV LINK ITEMS */}
          </SidebarContent>
        </ProSidebar>
      </div>
    </div>
  );
}

export default Sidenavbar;
