import React from "react";

function SbDropDownBtn(props) {
  return (
    <div className="dropdown add-single-bulk">
      <button
        className="btn btn-add-dropdown"
        id="adduser-single-blulk"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <div className="btn-inline-item d-flex align-items-center">
          <i className="fa fa-plus-circle"> </i>
          &nbsp;&nbsp;<h6 style={{fontSize: "13px", fontWeight: "600"}}>Add</h6>
        </div>
      </button>
      <ul
        className="dropdown-menu single-bulk-upload"
        aria-labelledby="adduser-single-blulk"
      >
        <li>
          <button
            className="dropdown-item single-user"
            onClick={props.openAddModal}
          >
            <i className="fa fa-user-plus" /> &nbsp; Single {props.pageName}
          </button>
        </li>
        <li>
          <button
            className="dropdown-item bulk-user"
            onClick={props.addBulkModel}
          >
            <i className="fa fa-users" /> &nbsp; Bulk Upload
          </button>
        </li>
      </ul>
    </div>
  );
}

export default SbDropDownBtn;
