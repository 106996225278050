import { parseISO } from "date-fns";
import moment from "moment";
import React from "react";

export const FormattedAmount = (value) => {
  // Convert the value to a number if it's a string or other format
  const parsedValue = parseFloat(value);

  // Format the value as desired using Intl.NumberFormat
  const formattedValue = new Intl.NumberFormat("en-IN", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true,
  }).format(parsedValue);

  return <span>{formattedValue}</span>;
};

// export default FormattedAmount;

export function dateFormatIST(date) {
  return moment(new Date(date)).format("DD MMM YYYY");
}

// Date format DD/MM/YYYY to DD MMM YYYY

export function dateFormatLocal(date) {
  return moment(date, "DD/MM/YYYY").format("DD MMM YYYY");
}

export const textAlphabetValidation = (e) => {
  const keyPressed = e.key;
  const alphabetRegex = /^[a-zA-Z]+$/;
  if (!alphabetRegex.test(keyPressed)) {
    e.preventDefault();
  }
};
export const textNameValidation = (e) => {
  if (e.key >= '0' && e.key <= '9') {
    e.preventDefault(); // Prevent the input
  }
};

// convert Ist format
export function formatDateToIST(dateString) {
  const parts = dateString.split("/");
  const inputDate = new Date(parts[2], parts[1] - 1, parts[0]);
  const formattedDate = inputDate.toISOString();

  return formattedDate;
}

export const getRandomColor = (index) => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
