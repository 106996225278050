import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Leavetype.scss";
import { Col, Row } from "react-bootstrap";
import { baseURL } from "../../BaseUrl";
import ReactPaginate from "react-paginate";
import { exportExcel } from "../../DownloadFile";
import Select from "react-select";
import { optionsStatusList, optionsPageSizeList } from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../../Skeleton Loading/SkeletonStdTable";
import SbAddBtn from "../../SbButtons/SbAddBtn";
import AddLeaveType from "./AddLeaveType";
import EditLeaveType from "./EditLeaveType";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { useNavigate } from "react-router-dom";
import OnScrollLoading, { handleScroll } from '../../OnScrollLoading';

export default function LeaveType() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [show, setShow] = useState(false);
  const [editLeaveType, setEditLeaveType] = useState(false);
  const [leaveTypeID, setLeaveTypeID] = useState(0);
  const [editLeaveTypeID, setEditLeaveTypeID] = useState(0);
  const [activeStatus, setActiveStatus] = useState(2);
  const [leaveTypesData, setLeaveTypesData] = useState([]);
  const [leaveTypes, setLeaveTypes] = useState([]);
  // const [purposeId, setPurposeId] = useState("");
  const [isLoading, setIsLoading] = useState(true);

    // Lazy Loading
    const [loading, setLoading] = useState(false);
    const [totalData, setTotalData] = useState(0);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  const handleClose = () => {
    setShow(false);
    setEditLeaveType(false);
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/LeaveType/BindLeaveTypeDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLeaveTypes(
            res.result.map((data) => {
              return { value: data.leaveTypeID, label: data.leaveTypeName };
            })
          );
        } else {
          setLeaveTypes([]);
        }
      });
  };

  //  LEAVE TYPES TABLE START

  useEffect(() => {
    fetchLeaveTypesData();
  }, [leaveTypeID, activeStatus, PageSize, currentPage]);

  const fetchLeaveTypesData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/LeaveType/Get?PageSize=${PageSize}&CurrentPage=${currentPage}&Status=${activeStatus}&LeaveTypeID=${leaveTypeID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("LEAVE-TYPE-RES::", res);
        if (res.success) {
          setLeaveTypesData(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setLeaveTypesData("");
          setIsLoading(false);
          setLoading(false)
        }
      });
  };
  //  LEAVE TYPES TABLE END

  const clearSerachBySelect = () => {
    setLeaveTypeID(0);
    setActiveStatus(2);
  };

  // const exportExcelDownload = () => {
  //   exportExcel(
  //     `${baseURL}/api/Export/MeetingPurposeExcelExport?PurposeId=${leaveTypeID}&Status=${activeStatus}&PageSize=${PageSize}&CurrentPage=1`,
  //     "MeetingPurpose.xlsx"
  //   );
  // };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* Filter and Header Section */}
                <div className="user-table">
                  <Row className="search-row">
                    <Col md={3} className="cat-col">
                      <div className="page-header-text-div">
                        <div className="back-btn" onClick={goToBackPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <h6
                          className="page-header-text"
                          style={{ color: "#04a96d" }}
                        >
                          LEAVE TYPE
                        </h6>
                      </div>
                    </Col>
                    <Col md={9} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setFilter(!filter)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                          <SbAddBtn onClickEffect={() => setShow(!show)} />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {filter && (
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={8}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={leaveTypes}
                                  placeholder="Select Leave Type"
                                  value={leaveTypeID === 0 ? "" : leaveTypes.value}
                                  onInputChange={leaveTypes.label}
                                  onChange={(data) => setLeaveTypeID(data.value)}
                                  isSearchable={true}
                                />
                              </div>
                              <div
                                style={{
                                  width: "100%",
                                  padding: "0px 5px",
                                }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  // defaultValue={optionsRoleList[0].label}
                                  options={optionsStatusList}
                                  placeholder="Select Status"
                                  value={
                                    activeStatus === 2 ? "" : optionsStatusList.value
                                  }
                                  onInputChange={optionsStatusList.label}
                                  onChange={(data) => setActiveStatus(data.value)}
                                  isSearchable={true}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <SbCancelBtn
                                onClickEffect={clearSerachBySelect}
                                btnName="Clear"
                              />
                            </div>

                          </Col>


                        </Row>
                      </div>
                    </div>
                  )}
                </div>
                {/* Filter and Header Section */}

                {/* USER TABLE START */}
                <div className="leavetype-table">
                  <div className="table-responsive leavetype-scroll"
                  onScroll={(e) => handleScroll(e, leaveTypesData, totalData, setLoading, setPageSize, PageSize)}
                  >
                    <table className="table leavetype_table_body">
                      <thead className="thead-light leavetype-thead">
                        {isLoading ? (
                          <>
                            <SkeletonStdTableHead />
                          </>
                        ) : (
                          <tr>
                            <th>NAME</th>
                            <th>SHORT NAME</th>
                            <th>MAX NO.OF DAYS</th>
                            <th>DESCRIPTION</th>
                            <th>STATUS</th>
                            <th>ACTION</th>
                          </tr>
                        )}
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <>
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                          </>
                        ) : leaveTypesData !== "" ? (
                          leaveTypesData.map((data, i) => (
                            <tr className="leavetype-row-data">
                              <td>{data?.leaveTypeName}</td>
                              <td>{data?.leaveTypeSortName}</td>
                              <td>{data?.maxNoOfDays}</td>
                              <td style={{ maxWidth: "250px" }}>
                                {data?.description}
                              </td>
                              <td>
                                {data?.status == 1 ? (
                                  <span className="status-badge complete-status">
                                    Active
                                  </span>
                                ) : (
                                  <span className="status-badge pending-status">
                                    InActive
                                  </span>
                                )}
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    setEditLeaveType(!editLeaveType);
                                    setEditLeaveTypeID(data?.leaveTypeID);
                                  }}
                                  className="btn leavetype-edit-link"
                                >
                                  <i className="bi bi-pencil-fill" /> Edit
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={5}>----- NO DATA FOUND -----</td>
                          </tr>
                        )}
                        {loading &&
                          <OnScrollLoading />
                        }
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* USER TABLE END */}

                <div className="download-and-pagination">
                  <button
                    type="button"
                    className="btn btn-download-excel"
                  // onClick={exportExcelDownload}
                  >
                    <i className="fa fa-download" /> &nbsp; Download
                  </button>
                  <div className="user-pagination">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={<i className="fa fa-angle-double-right" />}
                      onPageChange={handlePageClick}
                      marginPagesDisplayed={2}
                      pageRangeDisplayed={2}
                      pageCount={pageCount}
                      previousLabel={<i className="fa fa-angle-double-left" />}
                      renderOnZeroPageCount={null}
                      containerClassName={"pagination"}
                      subContainerClassName={"pages pagination"}
                      activeClassName={"active"}
                      disabledClassName={"disabled"}
                    />
                  </div>
                </div>

                {/* ADD PURPOSE MODEL START */}
                <AddLeaveType
                  show={show}
                  handleClose={handleClose}
                  fetchLeaveTypesData={fetchLeaveTypesData}
                />
                {/* ADD PURPOSE MODEL END */}

                {/* EDIT PURPOSE MODEL START */}
                <EditLeaveType
                  editLeaveType={editLeaveType}
                  editLeaveTypeID={editLeaveTypeID}
                  handleClose={handleClose}
                  fetchLeaveTypesData={fetchLeaveTypesData}
                />
                {/* EDIT PURPOSE MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>
            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}
