import React, { useEffect } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { useState } from "react";
import * as HeaderData from "../../HeaderAuthentication";
import { useDispatch } from "react-redux";
import { postClientCategory } from "../../../redux/action";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";

function AddClientCategory(props) {
  let dispatch = useDispatch();
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [categoryData, setCategoryData] = useState({
    subscriptionID: "",
    name: "",
    description: "",
    addedByID: "",
    addedOn: "",
    addedByUserFullName: "",
    status: 1,
  });

  const {
    subscriptionID,
    name,
    description,
    addedByID,
    addedOn,
    addedByUserFullName,
    status,
  } = categoryData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCategoryData({ ...categoryData, [name]: value });
  };

  useEffect(() => {
    if (resPassData) {
      setCategoryData({
        subscriptionID: resPassData.subscriptionId,
        name: "",
        description: "",
        addedByID: resPassData.userId,
        addedOn: "",
        addedByUserFullName: resPassData.fullName,
        status: 1,
      });
    }
  }, []);

  const cancelModale = () => {
    props.categoryModuleClose();
  };

  const [statusValue, setStatusValue] = useState(true);
  const statusFlag = (statusValue) => {
    setStatusValue(!statusValue);
    if (!statusValue === true) {
      setCategoryData({ ...categoryData, status: 1 });
    } else if (!statusValue === false) {
      setCategoryData({ ...categoryData, status: 0 });
    }
  };

  const [fieldReq, setFieldReq] = useState(false);
  const saveCategory = (e) => {
    e.preventDefault();
    if (!name || !description) {
      setFieldReq(true);
    } else {
      var postRequest = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify(categoryData),
      };
      dispatch(postClientCategory(postRequest, cancelModale));
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.categoryModuleClose}
        className="category-modal-div"
      >
        <Row className="modale-header-div">
          <Col md={6} xs={9} className="cat-col">
            <h6 className="page-header-text">Add Category</h6>
          </Col>
          <Col md={6} xs={3} className="cat-col" align="right">
            <button
              onClick={props.categoryModuleClose}
              className="bi bi-x close-popup"
            ></button>
          </Col>
        </Row>
        <hr />
        <div className="cat-col">
          <Form>
            {/* <div className='form-field'>
                            <Form.Group className="mb-3_health" controlId="exampleForm.Health">
                                <Form.Label className="require">Subscription Id</Form.Label>
                                <Form.Control type="text" pattern="/^[0-9]{10}$/"
                                    value={subscriptionID || ""}
                                    name="subscriptionID"
                                    onChange={handleInputChange}
                                    placeholder="Select Subscription"
                                    autoComplete="off"
                                    readOnly />

                            </Form.Group>
                        </div> */}
            <div className="form-field">
              <Form.Group
                className="mb-3_health"
                controlId="exampleForm.Health"
              >
                {/* <Form.Label className="require">Category Name</Form.Label> */}
                <FloatingLabel
                  label="Enter Category Name"
                  className={`${
                    name || ""
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <Form.Control
                    type="text"
                    pattern="/^[0-9]{10}$/"
                    value={name || ""}
                    name="name"
                    onChange={handleInputChange}
                    placeholder="Enter Category Name"
                    autoComplete="off"
                  />
                </FloatingLabel>
                {fieldReq && !name && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter Category Name
                  </small>
                )}
              </Form.Group>
            </div>
            <div className="form-field">
              <Form.Group
                className="mb-3_health"
                controlId="exampleForm.Health"
              >
                {/* <Form.Label className="require">Description</Form.Label> */}
                <FloatingLabel
                  label="Description"
                  className={`${
                    description || ""
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <Form.Control
                    as="textarea"
                    type="text"
                    pattern="/^[0-9]{10}$/"
                    className="form-control form-textarea add-user-input"
                    value={description || ""}
                    name="description"
                    onChange={handleInputChange}
                    placeholder="Description"
                    autoComplete="off"
                  />
                </FloatingLabel>
                {fieldReq && !description && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter description
                  </small>
                )}
              </Form.Group>
            </div>
            <div className="mt-3">
              <div className="form-field form-status-section">
                <div>
                  <Form.Label className="require">Status</Form.Label>
                </div>
                <div className="btn-status">
                  <Form.Group className="facility-details">
                    <div
                      className={`checkbox ${statusValue && "checkbox--on"}`}
                      name="status"
                      onClick={(e) => statusFlag(statusValue)}
                    >
                      <div className="checkbox__ball">
                        <span
                          className={
                            statusValue ? "enable-text" : "disable-text"
                          }
                        >
                          {statusValue ? "Enable" : "Disable"}
                        </span>
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
            <hr />
            <div className="sb-btn-section">
              <SBSaveUpdateBtn onClickEffect={saveCategory} btnName="Save" />
              <SbCancelBtn btnName="Cancel" onClickEffect={cancelModale} />
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default AddClientCategory;
