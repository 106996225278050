import React, { useEffect, useRef, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/Dashboard.scss";
import "../../styles/CommonStyle.scss";
import profileimg from "../../images/profile-1.png";
import { Link } from "react-router-dom";
import { baseURL } from "../BaseUrl";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SkeletonCompTData from "../Skeleton Loading/SkeletonCompTData";
import SkeletonActivity from "../Skeleton Loading/SkeletonActivity";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import moment from "moment";
import ProgressBar from "./ProgressBar";
import { Badge, Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import DrilldownChartPage from "./DrilldownChartPage";
import {
  MeetingsChart,
  ClaimsChart,
  MeetingStatusChart,
  MultiChart,
  ComplaintsChart,
} from "./BarChartGraph";
import DrillChartMeeting from "./DrillChartMeeting";
import GoogleMapComp from "../GoogleMapComp";
import DashboardGoogleMap from "../Dashboard/DashboardGoogleMap";
import TablePage from "../SbTables/TablePage";
import Chart from "react-apexcharts";
// import Carousel from "react-multi-carousel";
import { Carousel } from "react-bootstrap";
import "react-multi-carousel/lib/styles.css";
import DashboardTable from "./DashboardTable";
import { useDispatch, useSelector } from "react-redux";
import {
  loadClientComplaint,
  loadGraphCount,
  loadGraphData,
  loadMeetingData,
  loadOrders,
  loadPageBreadCome,
} from "../../redux/action";
import RadialChartPage from "./RadialChartPage";
import ActivitySlider from "./ActivitySlider";
import Activities from "../SuperAdmin/UserActivity/Activities";
import { FormattedAmount } from "../FormattedAmount";
// import RadialChartPage from "./RadialChartPage";

function Dashboard() {
  /* HEADER AUTH START */

  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const userLgnDtls = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const distanceMatrixID = userLgnDtls?.subscription?.distanceMatrix;

  sessionStorage.setItem("distanceMatrixID", distanceMatrixID);

  let dispatch = useDispatch();

  const { clientComplaints } = useSelector((state) => state.data);
  const { meetingData } = useSelector((state) => state.data);
  const { graphData } = useSelector((state) => state.data);
  const { graphCount } = useSelector((state) => state.data);
  /* HEADER AUTH END */

  const chartRef = useRef(null);
  const [chartWidth, setChartWidth] = useState(0);

  useEffect(() => {
    let displayDate = moment(new Date()).format('ddd, MMM D');
    let breadCome = { mainpage: "DashBoard", page: "", subPage: "" };
    dispatch(loadPageBreadCome(breadCome));
    const handleResize = () => {
      const chartContainerWidth = chartRef.current.parentElement.offsetWidth;
      // alert(chartContainerWidth);
      setChartWidth(chartContainerWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // table header
  let tableHeader = ["User Name", "Direct", "Scheduled", "My Visit", "Total"];
  // table header

  let dslft = sessionStorage.getItem("usrlgndtls");
  const userLgnDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  let daysLeft = JSON.parse(dslft)?.subscription?.daysleft;
  sessionStorage.setItem("daysLeft", daysLeft);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [userId, setUserId] = useState("");
  const [dashBoardData, setDashBoardData] = useState("");
  const [selectBy, setSelectBy] = useState("By Week");
  const [selectByGrap, setSelectByGrap] = useState("By Month");
  const [userActivity, setUserActivity] = useState(false);
  const [dBMeetingDetails, setDBMeetingDetails] = useState([]);
  const [dBMeetingGrap, setDBMeetingGrap] = useState({});
  const [recentActivities, setRecentActivities] = useState([]);
  const [searchVal, setSearchVal] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isGraphLoading, setIsGraphLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const [directCount, setDirectCount] = useState("");
  const [scheduleCount, setScheduleCount] = useState("");
  const [myVisitCount, setMyVisitCount] = useState("");
  const [totalVisits, setTotalVisits] = useState("");
  const [latLan, setLatLan] = useState({
    lat: 21.0,
    lng: 78.0,
  });

  useEffect(() => {
    if (dBMeetingGrap) {
      setDirectCount(dBMeetingGrap.directVisitMeetings);
      setScheduleCount(dBMeetingGrap.scheduleMeetings);
      setMyVisitCount(dBMeetingGrap.myVisitMeetings);
      setTotalVisits(dBMeetingGrap.totalMeetings);
    }
  }, [dBMeetingGrap]);

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const goBacktodashboard = () => {
    setUserActivity(!userActivity);
  };

  /* FETCH CARD DATA START  */
  useEffect(() => {
    getDashboardCounts();
  }, []);

  const getDashboardCounts = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Travelize_Authentication: userDetails,
      },
    };

    fetch(`${baseURL}/api/DashBoard/GetCounts`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDashBoardData(res.result);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setDashBoardData("");
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  /* FETCH CARD DATA END  */

  /* FETCH MEETINGS COUNT DATA START  */
  useEffect(() => {
    sortMeetingBy();
    sortMeetingByGrap();
  }, [selectBy, selectByGrap]);

  const sortMeetingBy = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Travelize_Authentication: userDetails,
      },
    };
    fetch(`${baseURL}/api/DashBoard/GetMeetingCounts/${selectBy}`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDBMeetingDetails(res.result);
          setIsLoading(false);
        } else {
          setDBMeetingDetails("");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  /* FETCH MEETINGS COUNT DATA END  */

  /* FETCH RECENT ACTIVITY DATA START  */
  useEffect(() => {
    fetchRecentActivities();
  }, []);

  const sortMeetingByGrap = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Travelize_Authentication: userDetails,
      },
    };
    fetch(
      `${baseURL}/api/DashBoard/GetMeetingCounts/${selectBy}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDBMeetingGrap(res.result[0]);
          setIsLoading(false);
        } else {
          setDBMeetingDetails("");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  /* FETCH MEETINGS COUNT DATA END  */

  /* FETCH RECENT ACTIVITY DATA START  */
  useEffect(() => {
    fetchRecentActivities();
  }, []);

  const fetchRecentActivities = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Travelize_Authentication: userDetails,
      },
    };
    fetch(`${baseURL}/api/DashBoard/GetRecentActivities`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setRecentActivities(res.result);
        } else {
          setRecentActivities("");
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };

  /* FETCH RECENT ACTIVITY DATA END  */
  const [selectGraphPhase, setSelectGraphPhase] = useState(false);
  const [graphPhaseDetails, setGraphPhaseDetails] = useState(false);
  const openPhaseDetails = () => {
    setGraphPhaseDetails(!graphPhaseDetails);
  };
  const openSelectShowGraphPhase = () => {
    setSelectGraphPhase(!selectGraphPhase);
  };

  // On click event for graph
  const [graphName, setGraphName] = useState("meeting");
  const datForGraph = (event) => {
    setIsGraphLoading(true);
    setGraphName(event);
    if (event === "complaints") {
      dispatch(loadClientComplaint());
    } else if (event === "meetings") {
      dispatch(loadMeetingData());
    }
    // alert(event)
  };

  useEffect(() => {
    if (graphName) {
      if (graphName === "complaints") {
        dispatch(loadOrders());
      }
    }
    dispatch(loadGraphData(graphName, selectBy, setIsGraphLoading));
    dispatch(loadGraphCount(selectBy));
  }, [graphName, selectBy]);
  // On click event for graph

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            <>
              {/* SIDEBAR START */}
              <Sidenavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* SIDEBAR END */}

              {/* TOPBAR START */}
              <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* TOPBAR END */}
              <div className="page-wrapper">
                {/* MAIN BODY START */}
                {!userActivity ? (
                  <div className={`main-body ${toggleshift.style}`}>
                    {/* PAGE CONTENT START */}

                    <div className="page-content-dash">
                      {/* FIRST ROW START */}
                      <div className="sb-card-row">
                        {/* <div className="col-md-3"> */}
                        <div className="card card-dashboard">
                          <div className="card-body first-card">
                            <Carousel
                              style={{ width: "100%" }}
                              controls={false}
                              indicators={false}
                              interval={3000}
                            >
                              <Carousel.Item>
                                <div className="row card-row">
                                  <div className="col-md-3 card-left-img">
                                    <img
                                      src="../images/dashboard/Product.svg"
                                      alt="user"
                                      className="card-imag"
                                    />
                                  </div>
                                  <div className="col-md-9 card-right-data">
                                    <div className="verticle-line"></div>
                                    <Link className="act-body" to="/products">
                                      <p
                                        className="card-head"
                                        style={{ color: "#fff" }}
                                      >
                                        Total Products
                                      </p>
                                      <p style={{ color: "#fff" }}>
                                        <b className="act-num">
                                          {dashBoardData?.productCount ?? 0}
                                        </b>
                                      </p>
                                    </Link>
                                  </div>
                                </div>
                              </Carousel.Item>
                              <Carousel.Item>
                                <div className="row card-row">
                                  <div className="col-md-3 card-left-img">
                                    <img
                                      src="../images/dashboard/Orders.svg"
                                      alt="user"
                                      className="card-imag"
                                    />
                                  </div>
                                  <div className="col-md-9 card-right-data">
                                    <div className="verticle-line"></div>
                                    <Link className="act-body" to="/orders">
                                      <p
                                        className="card-head"
                                        style={{ color: "#fff" }}
                                      >
                                        Total Orders
                                      </p>
                                      <p style={{ color: "#fff" }}>
                                        <b className="act-num">
                                          {dashBoardData?.orderCount ?? 0}
                                        </b>
                                      </p>
                                    </Link>
                                  </div>
                                </div>
                              </Carousel.Item>
                              <Carousel.Item>
                                <div className="row card-row">
                                  <div className="col-md-3 card-left-img">
                                    <img
                                      src="../images/dashboard/Spare.svg"
                                      alt="user"
                                      className="card-imag"
                                    />
                                  </div>
                                  <div className="col-md-9 card-right-data">
                                    <div className="verticle-line"></div>
                                    <Link className="act-body" to="/spares">
                                      <p
                                        className="card-head"
                                        style={{ color: "#fff" }}
                                      >
                                        Total Spares
                                      </p>
                                      <p style={{ color: "#fff" }}>
                                        <b className="act-num">
                                          {dashBoardData?.sparePartCount ?? 0}
                                        </b>
                                      </p>
                                    </Link>
                                  </div>
                                </div>
                              </Carousel.Item>
                            </Carousel>
                          </div>
                        </div>
                        {/* </div>
                      <div className="col-md-3"> */}
                        <div className="card card-dashboard">
                          <div className="card-body">
                            <div className="row card-row">
                              <div className="col-md-3 card-left-img">
                                <img
                                  src="../images/dashboard/meeting.svg"
                                  alt="user"
                                  className="card-imag"
                                />
                              </div>
                              <div className="col-md-9 card-right-data">
                                <div className="verticle-line"></div>
                                <div className="act-body">
                                  <Row>
                                    <Col
                                      md={12}
                                      className="d-flex meeting-card align-items"
                                    >
                                      <div className="sb-data-box">
                                        <Link
                                          className="act-body"
                                          to="/meetings"
                                        >
                                          <div>
                                            <p className="card-head">
                                              Meetings
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center sb-grap-text">
                                            <span className="grap-box box1"></span>{" "}
                                            &nbsp;&nbsp;
                                            <p className="meet-detls">
                                              <span>Total </span>&nbsp;&nbsp;
                                              <span>
                                                {dashBoardData.totalMeetings}
                                              </span>
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center sb-grap-text">
                                            <span className="grap-box box2"></span>{" "}
                                            &nbsp;&nbsp;
                                            <p className="meet-detls">
                                              <span>Completed </span>
                                              &nbsp;&nbsp;
                                              <span>
                                                {
                                                  dashBoardData.completedMeetings
                                                }
                                              </span>
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center sb-grap-text">
                                            <span className="grap-box box3"></span>{" "}
                                            &nbsp;&nbsp;
                                            <p className="meet-detls">
                                              <span>Pending </span>
                                              &nbsp;&nbsp;
                                              <span>
                                                {dashBoardData.pendingMeetings}
                                              </span>
                                            </p>
                                          </div>
                                        </Link>
                                      </div>
                                    </Col>
                                  </Row>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* </div>
                      <div className="col-md-3"> */}
                        <div className="card card-dashboard">
                          <div className="card-body">
                            <div className="row card-row">
                              <div className="col-md-3 card-left-img">
                                <img
                                  src="../images/dashboard/attendance.svg"
                                  alt="user"
                                  className="card-imag"
                                />
                              </div>
                              <div className="col-md-9 card-right-data">
                                <div className="verticle-line"></div>
                                <Link
                                  className="act-body d-flex align-items-center"
                                  to="/attendance/monthly"
                                >
                                  <div className="attend-card">
                                    <p className="card-head">Attendance</p>

                                    <div className="d-flex align-items-center sb-grap-text">
                                      <span className="grap-box box1"></span>{" "}
                                      &nbsp;&nbsp;
                                      <p className="meet-detls">
                                        <span>Leave </span>&nbsp;&nbsp;
                                        <span>{dashBoardData.leaveCount}</span>
                                      </p>
                                    </div>

                                    <div className="d-flex align-items-center sb-grap-text">
                                      <span className="grap-box box1"></span>{" "}
                                      &nbsp;&nbsp;
                                      <p className="meet-detls">
                                        <span>Present </span>&nbsp;&nbsp;
                                        <span>
                                          {dashBoardData.todayAttendanceCount}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="d-flex align-items-center sb-grap-text">
                                      <span className="grap-box box3"></span>{" "}
                                      &nbsp;&nbsp;
                                      <p className="meet-detls">
                                        <span>Absent </span>&nbsp;&nbsp;
                                        <span>{dashBoardData.absentCount}</span>
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card card-dashboard">
                          <div className="card-body">
                            <div className="row card-row">
                              <div className="col-md-3 card-left-img">
                                <img
                                  src="../images/dashboard/company.svg"
                                  alt="user"
                                  className="card-imag"
                                />
                              </div>
                              <div className="col-md-9 card-right-data">
                                <div className="verticle-line"></div>

                                <Link className="act-body" to="/company">
                                  <div className="company-card">
                                    <div>
                                      <p className="card-head">Company</p>
                                    </div>

                                    <div>
                                      <div className="d-flex align-items-center sb-grap-text">
                                        <span className="grap-box box1"></span>{" "}
                                        &nbsp;&nbsp;{" "}
                                        <p className="meet-detls">
                                          <span>Total </span>&nbsp;&nbsp;
                                          <span>
                                            {dashBoardData.clientCount}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="d-flex align-items-center sb-grap-text">
                                        <span className="grap-box box2"></span>{" "}
                                        &nbsp;&nbsp;
                                        <p className="meet-detls">
                                          <span>Active Client </span>
                                          &nbsp;&nbsp;
                                          <span>
                                            {dashBoardData.activeClientCount}
                                          </span>
                                        </p>
                                      </div>
                                      <div className="d-flex align-items-center sb-grap-text">
                                        <span className="grap-box box3"></span>{" "}
                                        &nbsp;&nbsp;
                                        <p className="meet-detls">
                                          <span>Recent Client </span>
                                          &nbsp;&nbsp;
                                          <span>
                                            {dashBoardData.todaysNewClients}
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card card-dashboard">
                          <div className="card-body">
                            <div className="row card-row">
                              <div className="col-md-3 card-left-img">
                                <img
                                  src="../images/dashboard/claims.svg"
                                  alt="user"
                                  className="card-imag"
                                />
                              </div>

                              <div className="col-md-9 card-right-data">
                                <div className="verticle-line"></div>
                                <Carousel
                                  style={{ width: "100%" }}
                                  controls={false}
                                  indicators={false}
                                  interval={3000}
                                >
                                  <Carousel.Item>
                                    <Link className="act-body" to="/localclaim">
                                      <div className="claim-card">
                                        <div>
                                          <p className="card-head">
                                            Local Claims
                                          </p>
                                        </div>
                                        <div>
                                          <div className="d-flex align-items-center sb-grap-text">
                                            <span className="grap-box box1"></span>{" "}
                                            &nbsp;&nbsp;
                                            <p className="meet-detls">
                                              <span>Applied </span>&nbsp;&nbsp;
                                              <span>
                                                {
                                                  dashBoardData.appliedClaimCount
                                                }
                                              </span>
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center sb-grap-text">
                                            <span className="grap-box box2"></span>{" "}
                                            &nbsp;&nbsp;
                                            <p className="meet-detls">
                                              <span>Approved </span>&nbsp;&nbsp;
                                              <span>
                                                {
                                                  dashBoardData.approvedClaimCount
                                                }
                                              </span>
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center sb-grap-text">
                                            <span className="grap-box box3"></span>{" "}
                                            &nbsp;&nbsp;
                                            <p className="meet-detls">
                                              <span>Rejected </span>&nbsp;&nbsp;
                                              <span>
                                                {
                                                  dashBoardData.rejectedClaimCount
                                                }
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </Carousel.Item>
                                  <Carousel.Item>
                                    <Link
                                      className="act-body"
                                      to="/outstationclaim"
                                    >
                                      <div className="claim-card">
                                        <div>
                                          <p className="card-head">
                                            Out Station Claims
                                          </p>
                                        </div>
                                        <div>
                                          <div className="d-flex align-items-center sb-grap-text">
                                            <span className="grap-box box1"></span>{" "}
                                            &nbsp;&nbsp;
                                            <p className="meet-detls">
                                              <span>Applied </span>&nbsp;&nbsp;
                                              <span>
                                                {
                                                  dashBoardData.osAppliedClaimCount
                                                }
                                              </span>
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center sb-grap-text">
                                            <span className="grap-box box2"></span>{" "}
                                            &nbsp;&nbsp;
                                            <p className="meet-detls">
                                              <span>Approved </span>&nbsp;&nbsp;
                                              <span>
                                                {
                                                  dashBoardData.osApprovedClaimCount
                                                }
                                              </span>
                                            </p>
                                          </div>
                                          <div className="d-flex align-items-center sb-grap-text">
                                            <span className="grap-box box3"></span>{" "}
                                            &nbsp;&nbsp;
                                            <p className="meet-detls">
                                              <span>Rejected </span>&nbsp;&nbsp;
                                              <span>
                                                {
                                                  dashBoardData.osRejectedClaimCount
                                                }
                                              </span>
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </Carousel.Item>
                                </Carousel>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* FIRST ROW END */}

                      {/* Second row start */}
                      <div className="row-devider">
                        <Row className="sb-data-grap">
                          <Col
                            md={7}
                            className=" sb-data-col user-activity-data"
                          >
                            <Row className="mb-3">
                              <Col md={9} className="p-0">
                                <div className="table-div-dashboard meeting-graph">
                                  <div className="meeting-table-header">
                                    <div>
                                      <p className="card-head">
                                        {graphName === "order"
                                          ? "ORDERS"
                                          : graphName === "complaint"
                                          ? "COMPLAINTS"
                                          : graphName === "meeting"
                                          ? "MEETINGS"
                                          : graphName === "localclaim"
                                          ? "LOCAL CLAIMS"
                                          : graphName === "osclaim" &&
                                            "OUT STATION CLAIMS"}
                                        &nbsp;&nbsp;
                                      </p>
                                    </div>
                                    <div className="sort-div">
                                      <p className="card-head">SORT</p>
                                      <select
                                        className="meetings-sort select-by-dropdown"
                                        value={selectBy}
                                        onChange={(e) =>
                                          setSelectBy(e.target.value)
                                        }
                                      >
                                        <option value="By Day">By Day</option>
                                        <option value="By Week">By Week</option>
                                        <option value="By Month">
                                          By Month
                                        </option>
                                        <option value="By 3 Month">
                                      By 3 Month
                                    </option>
                                    <option value="By 6 Month">
                                      By 6 Month
                                    </option>
                                      </select>
                                      {/* <i className="bi bi-sliders" /> */}
                                    </div>
                                  </div>
                                  <div className="user-activity-data-col">
                                    <div
                                      className="meetings-graph"
                                      ref={chartRef}
                                    >
                                      <MeetingsChart
                                        isLoading={isGraphLoading}
                                        dBMeetingDetails={
                                          dBMeetingDetails && dBMeetingDetails
                                        }
                                        graphName={graphName}
                                        graphData={graphData && graphData}
                                        // style={{overflowX: 'auto'}}
                                        width={"100%"}
                                      />
                                      {/* <ClaimsChart width={chartWidth} /> */}
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col md={3} className="p-0">
                                <div className="table-div-dashboard graph-card">
                                  <div className="ord-comp-meet-claim-data">
                                    <div
                                      className={`title-n-data ${
                                        graphName === "order" &&
                                        "selected-graph"
                                      }`}
                                      onClick={() => datForGraph("order")}
                                    >
                                      <p className="data-title"><b>Orders</b></p>
                                      <p className="data-value">
                                        <i className="fa fa-rupee" />
                                        {FormattedAmount(graphCount[0]?.OrderAmount)}
                                      </p>
                                    </div>
                                    <hr
                                      style={{
                                        margin: "2px 0px",
                                        color: "#6deec2",
                                      }}
                                    />
                                    <div
                                      className={`title-n-data ${
                                        graphName === "complaint" &&
                                        "selected-graph"
                                      }`}
                                      onClick={() => datForGraph("complaint")}
                                    >
                                      <p className="data-title"><b>Complaints</b></p>
                                      <p className="data-value">
                                        {graphCount[0]?.ComplaintCount}
                                      </p>
                                    </div>
                                    <hr
                                      style={{
                                        margin: "2px 0px",
                                        color: "#6deec2",
                                      }}
                                    />
                                    <div
                                      className={`title-n-data ${
                                        graphName === "meeting" &&
                                        "selected-graph"
                                      }`}
                                      onClick={() => datForGraph("meeting")}
                                    >
                                      <p className="data-title"><b>Meetings</b></p>
                                      <p className="data-value">
                                        {graphCount[0]?.TotalMeetingCount}
                                      </p>
                                    </div>
                                    <hr
                                      style={{
                                        margin: "2px 0px",
                                        color: "#6deec2",
                                      }}
                                    />
                                    <div>
                                      <Carousel
                                        controls={false}
                                        indicators={false}
                                        interval={2000}
                                      >
                                        <Carousel.Item>
                                          <div
                                            className={`title-n-data ${
                                              graphName === "localclaim" &&
                                              "selected-graph"
                                            }`}
                                            onClick={() =>
                                              datForGraph("localclaim")
                                            }
                                          >
                                            <p className="data-title">
                                              <b>Local Claims</b>
                                            </p>
                                            <p className="data-value">
                                              <i className="fa fa-rupee" />
                                              {FormattedAmount(graphCount[0]?.LocalClaimAmount)}
                                            </p>
                                          </div>
                                        </Carousel.Item>
                                        <Carousel.Item>
                                          <div
                                            className={`title-n-data ${
                                              graphName === "osclaim" &&
                                              "selected-graph"
                                            }`}
                                            onClick={() =>
                                              datForGraph("osclaim")
                                            }  >
                                            <p className="data-title">
                                              <b>Out Station Claims</b>
                                            </p>
                                            <p className="data-value">
                                              <i className="fa fa-rupee" />
                                              {FormattedAmount(graphCount[0]?.OSClaimAmount)}
                                            </p>
                                          </div>
                                        </Carousel.Item>
                                      </Carousel>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={6} className="p-0">
                                <div className="table-div-dashboard third-row-div">
                                  <div className="dashbord-img-box">
                                    <img
                                      src="../images/dashboard/Gif-images/laptop-motion_1.gif"
                                      alt=""
                                      width={"100%"}
                                      className="dashboard-pop-img"
                                    />
                                    <div className="carousel-div">
                                      <Carousel
                                        fade
                                        pause="hover"
                                        controls={false}
                                        indicators={false}
                                        interval={2000} >
                                        <Carousel.Item className="first-popup-div">
                                          <Link to="/products">
                                            <div className="first-popup-div-text">
                                              <p className="carousel-text1">
                                                Top Selling Products
                                              </p>
                                              <p className="carousel-text1">
                                                {dashBoardData?.productCount}
                                              </p>
                                            </div>
                                          </Link>
                                        </Carousel.Item>
                                        <Carousel.Item className="first-popup-div">
                                          <Link to="/orders">
                                            <div className="first-popup-div-text">
                                              <p className="carousel-text1">
                                                Today's Orders
                                              </p>
                                              <p className="carousel-text1">
                                                {dashBoardData?.todayOrderCount}
                                              </p>
                                            </div>
                                          </Link>
                                        </Carousel.Item>
                                        <Carousel.Item className="first-popup-div">
                                          <Link to="/complaints">
                                            <div className="first-popup-div-text">
                                              <p className="carousel-text1">
                                                Today's Complaints
                                              </p>
                                              <p className="carousel-text1">
                                                {
                                                  dashBoardData?.todayComplaintCount
                                                }
                                              </p>
                                            </div>
                                          </Link>
                                        </Carousel.Item>
                                      </Carousel>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col md={6} className="pr-0 pb-0 pt-0 pl-2">
                                <div className="table-div-dashboard third-row-div">
                                  <div className="meeting-table-header">
                                    <div>
                                      <p className="card-head">
                                        MEETINGS COUNT
                                      </p>
                                    </div>
                                  </div>
                                  <Row>
                                    <div className="user-activity-data-map">
                                      <div className="meetings-status-chart">
                                        {(directCount && scheduleCount && myVisitCount) &&
                                          <RadialChartPage
                                          series={[
                                            directCount,
                                            scheduleCount,
                                            myVisitCount,
                                          ]}
                                          width={"100%"}
                                          height={340}
                                        />
                                        }
                                        {/* <Chart
                                        options={MeetingStatusChart?.options}
                                        series={[
                                          directCount,
                                          scheduleCount,
                                          myVisitCount,
                                        ]}
                                        type="radialBar"
                                        width={"100%"}
                                        height={340}
                                      /> */}
                                      </div>
                                    </div>
                                  </Row>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col
                            md={5}
                            className="sb-data-col user-activity-data"
                          >
                            <div className="table-div-dashboard activity-doard">
                              <div className="meeting-table-header">
                                <div>
                                  <p className="card-head">ACTIVITIES</p>
                                </div>
                                <div className="sort-div">
                                  <button
                                    className="refresh-button"
                                    onClick={fetchRecentActivities}
                                  >
                                    <i className="fa fa-refresh refresh-activity" />
                                  </button>
                                  <input
                                    type="text"
                                    className="form-control activity-search"
                                    placeholder="Search...."
                                    aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-sm"
                                    onChange={(e) =>
                                      setSearchVal(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="user-activity-data">
                                <div className="user-activity-data-col">
                                  <div className="user-activity-rowdata">
                                    <ActivitySlider
                                      isLoading={isLoading}
                                      recentActivities={recentActivities}
                                      searchVal={searchVal}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {/* Second row end */}

                      {/* Second row start */}
                      {/* <div className="row-devider">
                        <Row className="sb-data-grap">
                          <Col
                            md={5}
                            className=" sb-data-col user-activity-data"
                          >
                            <div className="table-div-dashboard">
                              <div className="meeting-table-header">
                                <div>
                                  <p className="card-head">
                                    USER MEETINGS&nbsp;&nbsp;
                                    <i className="bi bi-sliders" />
                                  </p>
                                </div>
                                <div className="sort-div">
                                  <p className="card-head">SORT BY</p>
                                  <select
                                    className="meetings-sort select-by-dropdown"
                                    value={selectBy}
                                    onChange={(e) =>
                                      setSelectBy(e.target.value)
                                    }
                                  >
                                    <option value="By Day">By Day</option>
                                    <option value="By Week">By Week</option>
                                    <option value="By Month">By Month</option>
                                    <option value="By 3 Month">
                                      By 3 Month
                                    </option>
                                  </select>
                                </div>
                              </div>
                              <div className="user-activity-data-col">
                                <div className="meetings-graph" ref={chartRef}>
                                  <MeetingsChart
                                    dBMeetingDetails={
                                      dBMeetingDetails && dBMeetingDetails
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col
                            md={2}
                            className="sb-data-col user-activity-data"
                          >
                            <div className="table-div-dashboard">
                              <div className="ord-comp-meet-claim-data">
                                <div className={`title-n-data ${graphName === "orders" && "selected-graph"}`} onClick={() => datForGraph("orders")}>
                                  <p className="data-title">Orders</p>
                                  <p className="data-value">
                                    <i className="fa fa-rupee" />
                                    20,435
                                  </p>
                                </div>
                                <div className={`title-n-data ${graphName === "complaints" && "selected-graph"}`} onClick={() => datForGraph("complaints")}>
                                  <p className="data-title">Complaints</p>
                                  <p className="data-value">1,435</p>
                                </div>
                                <div className={`title-n-data ${graphName === "meetings" && "selected-graph"}`} onClick={() => datForGraph("meetings")}>
                                  <p className="data-title">Meetings</p>
                                  <p className="data-value">8,435</p>
                                </div>
                                <div className={`title-n-data ${graphName === "claims" && "selected-graph"}`} onClick={() => datForGraph("claims")}>
                                  <p className="data-title">Claims</p>
                                  <p className="data-value">
                                    <i className="fa fa-rupee" />
                                    9,20,435
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col
                            md={5}
                            className="sb-data-col user-activity-data"
                          >
                            <div className="table-div-dashboard">
                              <div className="meeting-table-header">
                                <div>
                                  <p className="card-head">
                                    ACTIVITIES &nbsp;&nbsp;
                                    <i className="bi bi-sliders" />
                                  </p>
                                </div>
                                <div className="sort-div">
                                  <button
                                    className="refresh-button"
                                    onClick={fetchRecentActivities}
                                  >
                                    <i className="fa fa-refresh refresh-activity" />
                                  </button>
                                  <input
                                    type="text"
                                    className="form-control activity-search"
                                    placeholder="Search...."
                                    aria-label="Sizing example input"
                                    aria-describedby="inputGroup-sizing-sm"
                                    onChange={(e) =>
                                      setSearchVal(e.target.value)
                                    }
                                  />
                                </div>
                              </div>
                              <div className="user-activity-data">
                                <div className="user-activity-data-col">
                                  <div className="user-activity-rowdata">
                                    {isLoading ? (
                                      <>
                                        <SkeletonActivity />
                                        <SkeletonActivity />
                                        <SkeletonActivity />
                                        <SkeletonActivity />
                                      </>
                                    ) : 
                                    <div className="test-active-body">{recentActivities !== "" ? (
                                      recentActivities
                                        .filter((val) =>
                                          val?.fullName
                                            .toLowerCase()
                                            .includes(searchVal)
                                        )
                                        .map((data, i) => (
                                          <div
                                            key={i}
                                            className="active-section" >
                                            <div className="extend-action-row">
                                            <div className="active-div-test active-img">
                                            <div className="active-user-image">
                                                    <img
                                                      className="table-data-user-img"
                                                      src={
                                                        data?.profileIcon
                                                          ? `${baseURL}/${data?.profileIcon}`
                                                          : "../images/Admin1.png"
                                                      }
                                                      alt=""
                                                    />
                                                    {data?.fullName && (
                                                      <OverlayTrigger
                                                        delay={{
                                                          hide: 450,
                                                          show: 300,
                                                        }}
                                                        overlay={(props) => (
                                                          <Tooltip {...props}>
                                                            {data?.checkOutTime ===
                                                            null
                                                              ? "Active"
                                                              : "In Active"}
                                                          </Tooltip>
                                                        )}
                                                        placement="top"
                                                      >
                                                        <div className="blink-effect-dashboard">
                                                          <div
                                                            className={`check-in-status ${
                                                              data?.checkOutTime ===
                                                              null
                                                                ? "status-active"
                                                                : "status-inactive"
                                                            }`}
                                                          ></div>
                                                        </div>
                                                      </OverlayTrigger>
                                                    )}
                                                  </div>
                                              <p>
                                                <b>{data.fullName ?? ""}</b>
                                              </p>
                                            </div>
                                            <div className="active-div-test active-address-div">
                                            <div className="td-user-location">
                                              
                                                    {data?.activityType ===
                                                      "Re-Check-In" ||
                                                    data?.activityType ===
                                                      "Check-In" ? (
                                                      <>
                                                        <p style={{fontSize: "12px"}}>
                                                          <b>(
                                                            {data?.activityType}{" "}
                                                            @ &nbsp;
                                                            {data?.checkInTime}
                                                          </b>)
                                                        </p>
                                                        <p
                                                          style={{
                                                            fontSize: "11px",
                                                          }}
                                                        >
                                                          {data?.cordLoc
                                                          ?.location.length > 30
                                                          ? data?.cordLoc
                                                          ?.location.substring(0, 30) + "..."
                                                          : data?.cordLoc
                                                          ?.location}
                                                        </p>
                                                      </>
                                                    ) : data?.activityType ===
                                                      "Check-Out" ? (
                                                      <>
                                                        <p style={{fontSize: "12px"}}>
                                                          <b>(
                                                            {data?.activityType}{" "}
                                                            @ &nbsp;
                                                            {data?.checkOutTime}
                                                          )
                                                          </b>
                                                        </p>
                                                        <p
                                                          style={{
                                                            fontSize: "11px",
                                                          }}
                                                        >
                                                          {data?.cordLoc
                                                          ?.location.length > 30
                                                          ? data?.cordLoc
                                                          ?.location.substring(0, 30) + "..."
                                                          : data?.cordLoc
                                                          ?.location}
                                                        </p>
                                                      </>
                                                    ) : (
                                                      <>
                                                        <p style={{fontSize: "12px"}}>(
                                                          {data?.activityType !=
                                                            null && (
                                                            <b>
                                                              {
                                                                data?.activityType
                                                              }{" "}
                                                              with
                                                            </b>
                                                          )}
                                                          &nbsp;{" "}
                                                          <b>
                                                            {data?.clientName} @
                                                            {moment(
                                                              data?.date
                                                            ).format("hh:MM a")}
                                                          </b>{" "}
                                                        )
                                                        </p>
                                                        <p
                                                          style={{
                                                            fontSize: "11px",
                                                          }}
                                                        >
                                                          {data?.cordLoc
                                                          ?.location.length > 30
                                                          ? data?.cordLoc
                                                          ?.location.substring(0, 30) + "..."
                                                          : data?.cordLoc
                                                          ?.location}
                                                        </p>
                                                      </>
                                                    )}
                                                  </div>
                                            </div>
                                            <div className="active-div-test badge-section">
                                              <Link
                                                to={`/trackbyuser/${data?.userID}/${data?.checkOutTime}`}
                                                className=""
                                                onClick={() => {
                                                  sessionStorage.setItem(
                                                    "userID",
                                                    data?.userID
                                                  );
                                                }}
                                              >
                                                <div className="activity-badge">
                                                  <i className="bi bi-geo-alt-fill fa"></i>
                                                  <p>Location</p>
                                                </div>
                                                
                                              </Link>
                                            </div>
                                            <div className="active-div-test badge-section">
                                              <Link
                                                to="/attendance/fullday"
                                                className=""
                                              >
                                                <div className="activity-badge">
                                                  <i className="fa fa-clock-o"></i>
                                                  <p>
                                                    {data?.checkInTime}
                                                  </p>
                                                </div>
                                              </Link>
                                            </div>
                                            <div className="active-div-test badge-section">
                                              <div className="activity-badge">
                                              <i className="fa fa-signal"></i>
                                                <p>
                                                {data?.mobileNetwork}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="active-div-test badge-section">
                                              <div className="activity-badge">
                                              <i className="fa fa-battery-full"></i>
                                                <p>
                                                {
                                                            data?.batteryStrength
                                                          }
                                                </p>
                                              </div>
                                            </div>

                                            <div className="active-div-test badge-section">
                                              <Link
                                                          to={`/meetings`}
                                                          className="attendance-page-link sb-ds-link"
                                                        >
                                                          <div className="activity-icons meeting">
                                                            <i className="fa fa-handshake-o"></i>
                                                          </div>

                                                          <p>Meetings</p>
                                                        </Link>
                                                        <span className="recent-activity-meeting-count">
                                                          {data?.meetingCount}
                                                        </span>
                                            </div>

                                            </div>
                                          </div>
                                        ))
                                    ) : (
                                      <div className="no-activity-found">
                                        ------ No Activities Found ------
                                      </div>
                                    )}
                                    </div>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div> */}
                      {/* Second row end */}

                      {/* Third Row India Map */}
                      {/* {recentActivities && <div className="dashboard-user-details">
                        <Row className="sb-data-grap">
                          <Col md={7}>
                            <div className="sb-db-map">
                              <DashboardGoogleMap activityData={recentActivities} />
                            </div>
                          </Col>
                        </Row>
                      </div>
                      } */}
                      {/* Third Row India Map */}
                    </div>

                    {/* PAGE CONTENT END*/}
                    {/* MAIN BODY END */}
                  </div>
                ) : (
                  <>
                    <Activities
                      userId={userId}
                      userActivity={userActivity}
                      toggleshift={toggleshift}
                      goBacktodashboard={goBacktodashboard}
                    />
                  </>
                )}
              </div>
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default Dashboard;
