import React, { useEffect, useState } from "react";
import "../../styles/Login.scss";
import "../../styles/CommonStyle.scss";
import { Button, Form, Alert, Image, Row, Col } from "react-bootstrap";
import { baseURL } from "../BaseUrl";
import travelizeSalesBeeLogo from "../../images/SalesBeeLogo.svg";
import moving_img from "../../images/Login_Page/laptop-motion.gif";
import travelize from "../../images/metric-favicon.svg";
import ReCAPTCHA from "react-google-recaptcha";
import { NumberValidation } from "../ValidationFormat";

function CreateSubscription() {
  const [companyName, setCompanyName] = useState("");
  const [clientName, setClientName] = useState("");
  const [email, setEmail] = useState("");
  const [countryCode, setCountryCode] = useState("+91");
  const [contactNo, setContactNo] = useState(null);
  const [subscriptionResMessage, setSubscriptionResMessage] = useState({
    success: null,
    message: null,
  });
  const [captchaVerification, setCaptchaVerification] = useState(false);

  let userLoginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  let countryCodeList = [
    // { label: "+1", value: "+1" },
    // { label: "+233", value: "+233" },
    // { label: "+52", value: "+52" },
    { label: "+91", value: "+91" },
    // { label: "+962", value: "+962" },
    // { label: "+968", value: "+968" },
    // { label: "+97", value: "+97" },
    // { label: "+971", value: "+971" },
    // { label: "+973", value: "+973" },
    // { label: "+974", value: "+974" },
  ];

  let currentYear = new Date().getFullYear();

  const [captchValue, setCaptchValue] = useState('')
  const onChange = (value) => {
    setCaptchValue(value)
    console.log("CAPTCHA:", value);
    if (value !== "" && value !== null) {
      setCaptchaVerification(true);
    }
  };

  // function onChange(e) {
  //   e.preventDefault();
  //   window.grecaptcha.enterprise.ready(async () => {
  //     const token = await window.grecaptcha.enterprise.execute(
  //       "6LdQa8wnAAAAAFyTI6Dxk2XTZroe2l9-hZC_VESi",
  //       { action: "LOGIN" }
  //     );
  //     // IMPORTANT: The 'token' that results from execute is an encrypted response sent by
  //     // reCAPTCHA Enterprise to the end user's browser.
  //     // This token must be validated by creating an assessment.
  //     // See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment
  //   });
  // }

  // VALIDATIONS START
  // const checkValidation = (e) => {
  //   e.preventDefault();
  //   const re = /^[0-9\b]+$/;
  //   if (e.target.value === "" || re.test(e.target.value)) {
  //     setContactNo(e.target.value);
  //   } else {
  //     alert("Accept only numbers");
  //   }
  // };
  // VALIDATIONS END

  const [capValidation, setCapValidation] = useState(false)
  const createSubscription = (e) => {
    if (email && companyName && contactNo && countryCode) {
      if(!captchValue){
        setCapValidation(true)
      } else {
      const subscriptionData = {
        subscriptionTypeID: 0,
        companyName: companyName,
        clientName: clientName,
        email: email,
        countryCode: countryCode,
        contact: contactNo,
        password: `salesbee_${currentYear}`, // Password for New Subscriptions
      };
      let requestoption = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(subscriptionData),
      };
      fetch(`${baseURL}/api/Subscription/AddSubscription`, requestoption)
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            setSubscriptionResMessage({
              success: res.success,
              message: "Your Subscription has Created Successfully",
            });
          } else {
            setSubscriptionResMessage({
              success: res.success,
              message: res.errorMessage,
            });
          }
        });
      }
    } else {
      setSubscriptionResMessage({
        success: false,
        message: "Captcha is Required",
      });
    }
    e.preventDefault();
  };

  const resetAll = () => {
    setTimeout(
      () =>
        setSubscriptionResMessage({
          success: null,
          message: null,
        }),
      5000
    );
    window.location.reload();
  };

  const [trailFlag, setTrailFlag] = useState(false);
  useEffect(() => {
    let url = window.location.href;
    if (url.includes("Trail")) {
      setTrailFlag(true);
    }
  }, [window.location.href]);

  return (
    <div>
      <div>
        <div>
        <div
            className="main-body"
            style={{ backgroundImage: 'url("images/Login_Page/bg4.jpg")' }}
          >
            <div className="login-page-body subscription-box">
                
              <Row className="sb-login-row" style={{position: "relative", overflow: "hidden"}}>
              {trailFlag &&
                <div className="beta-tag-trail">
                  <h6>Trail</h6>
                </div>
              }
                <Col md={5} className="sb-gif-col col-left">
                  <div className="w-100">
                    <div className="sb-login-logo-div">
                      <Image
                        src={travelizeSalesBeeLogo}
                        alt="Now Travelize is SalesBee"
                        className="sb-login-logo"
                      />
                    </div>
                    <div className="pt-2">
                      <h3 className="login-head-text">Sign Up</h3>
                      {/* <p style={{ fontWeight: "600" }}>to access SalesBee</p> */}
                    </div>
                    <div className="sb-form-section">
                      <form 
                        onSubmit={createSubscription}
                        className="login-form">
                        <div className="userName-input">
                          <input
                            type="text"
                            className="form-control login-email"
                            id="cpname"
                            placeholder="Company Name"
                            // value={companyName}
                            onChange={(e) => setCompanyName(e.target.value)}
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div className="userName-input">
                          <input
                            type="text"
                            className="form-control login-email"
                            id="clname"
                            placeholder="Contact Person"
                            // value={clientName}
                            onChange={(e) => setClientName(e.target.value)}
                            autoComplete="off"
                            required
                          />
                        </div>
                        <div className="userName-input">
                          <input
                            type="email"
                            className="form-control login-email"
                            id="subemail"
                            placeholder="Email ID"
                            // value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            autoComplete="off"
                            required
                          />
                        </div>
                        {/* <div className="userName-input d-flex"> */}
                        <div className="contact-input sub-inputs d-flex">
                          <Form.Select
                            aria-label="Default select example"
                            name="countrycode"
                            className="cntry-code"
                            // value={countryCode}
                            onChange={(event) =>
                              setCountryCode(event.target.value)
                            }
                          >
                            {countryCodeList.map((data, i) => (
                              <option key={i}>{data?.value}</option>
                            ))}
                          </Form.Select>
                          <input
                            type="tel"
                            className="form-control "
                            id="contact"
                            placeholder="Mobile Number"
                            maxLength={10}
                            // value={contactNo}
                            onChange={(e) => setContactNo(e.target.value)}
                            onKeyDown={NumberValidation}
                            required
                            autoComplete="off"
                          />
                        </div>
                        <div className="login-captcha">
                        <ReCAPTCHA
                          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                          onChange={onChange}
                          size={"normal"}
                          style={{
                            transform: "scale(0.7)", // Adjust the scale
                            transformOrigin: "1 0", // Adjust the transform origin
                            // Add more custom styles here
                          }}
                        />
                        {capValidation && (
                      <small
                        id={`Error`}
                        className="form-text text-muted "
                      >
                        Please Validate Captcha
                      </small>
                    )}
                        </div>
                        {/* </div> */}
                        <Row>
                          <Col md={12} className="p-0">
                            <div className="forget-pass-login-button">
                              <Button
                                type="submit"
                                className="btn btn-login-button"
                              >
                                Submit
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        <div className="pt-4" style={{ textAlign: "center" }}>
                          <p style={{ fontWeight: "600" }}>
                            Do you have an account ?{" "}
                            <a href="#/login" className="login-signup-text">
                              Sign In
                            </a>
                          </p>
                        </div>
                      </form>
                      <div className="subscription-res-message">
                        {subscriptionResMessage.success ? (
                          <Alert variant="success">
                            {subscriptionResMessage.message}
                          </Alert>
                        ) : subscriptionResMessage.success == false ? (
                          <Alert variant="danger">
                            {subscriptionResMessage.message}
                          </Alert>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Col>
                <Col md={2} className="col-right">
                  <div>
                    <div className="devider">
                      <div className="v1"></div>
                    </div>
                    <div className="travelize-log">
                      <Image
                        src={travelize}
                        alt="Now Travelize is SalesBee"
                        className="sb-trv-logo"
                      />
                    </div>
                    <div className="devider">
                      <div className="v1"></div>
                    </div>
                  </div>
                </Col>
                <Col md={5} className="sb-gif-col col-right">
                  <div>
                    <div className="sb-login-bgimg">
                      <Image
                        src={moving_img}
                        height="350"
                        alt="Now Travelize is SalesBee"
                        className="w-100"
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateSubscription;
