import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import moment from "moment";
import "../../styles/MeetingDetailsModel.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function MeetingDetailsModel(props) {
  const [meetingDetails, setMeetingDetails] = useState([]);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  useEffect(() => {
    fetchMeetingDetailsByUser();
  }, []);

  const fetchMeetingDetailsByUser = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Meeting/GetMeetingDetails?PageSize=100&CurrentPage=1&UserId=${props.UserId}&FromDate=${props.ViewDate}&ToDate=${props.ViewDate}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setMeetingDetails(res.result);
        } else {
          setMeetingDetails("");
        }
      });
  };

  return (
    <div>
      <Modal
        show={props.visitDetailsModel}
        onHide={props.closeVisitModel}
        className="view-meet-details-model"
      >
        <div className="add-user-heading">
          <h6 className="add-user-head">{props.userName} : Meeting Details</h6>
          <i className="fa fa-times" onClick={props.closeVisitModel} />
        </div>
        <div className="details-body">
          <div className="table-responsive meetings-scrollbar">
            <table className="table meetings-list-table">
              <thead className="thead-light meetings-list-thead">
                <tr>
                  <th>COMPANY</th>
                  <th>MEETING</th>
                  <th>MOT</th>
                  <th>REMARKS</th>
                  <th>STATUS</th>
                </tr>
              </thead>
              <tbody>
                {meetingDetails !== ""
                  ? meetingDetails.map((data, i) => (
                      <tr className="meeting-details-list-row-data" key={i}>
                        <td style={{ maxWidth: "220px" }}>
                          <span className="client-meet d-flex">
                            <p>
                              <b>{data?.clientName}</b>
                            </p>
                            &nbsp;
                            <p className="client-meet-status">
                              {data?.meetingType}
                            </p>
                          </span>
                          <i className="fa fa-user mtdtls-icon" />
                          &nbsp;
                          {data?.contactPersonName ??
                            data?.contactPerson ??
                            data?.clientName}
                          <br />
                          <i className="fa fa-mobile mtdtls-icon" /> &nbsp;
                          {data?.contactPersonNumber ?? data?.contact}
                          <br />
                          <i className="fa fa-thumb-tack mtdtls-icon" /> &nbsp;
                          {data?.clientName == "Check-Out"
                            ? data?.endLoc?.location
                            : data?.clientLoc?.location}
                          <span className="client-meet-location">
                            <p className="client-meet-lat">
                              <i className="bi bi-geo-alt"></i>
                              {data?.startLoc?.latitude ?? "NA"},
                              {data?.startLoc?.longitude ?? "NA"}
                            </p>
                            <p className="client-meet-lan">
                              <i className="bi bi-geo-alt" />
                              {data?.endLoc?.latitude ?? "NA"},
                              {data?.endLoc?.longitude ?? "NA"}
                            </p>
                          </span>
                        </td>
                        <td className="td-meeting-details">
                          <span className="client-meet-purpose d-flex">
                            <p>
                              <b>Purpose :</b>
                            </p>
                            &nbsp;
                            <p className="client-meet-purpose-status">
                              {data?.purpose === null ? "NA" : data?.purpose}
                            </p>
                          </span>
                          <p>Date : {data?.meetingDate} </p>
                          <p>Scheduled : {data?.meetingTime}</p>
                          <p>
                            In Time :{" "}
                            {data?.inTime === null || data?.inTime === ""
                              ? "NA"
                              : data?.inTime}
                          </p>
                          <p>
                            Out Time :{" "}
                            {data?.outTime === null || data?.outTime === ""
                              ? "NA"
                              : data?.outTime}
                          </p>
                          <p>
                            <b>Duration : </b> &nbsp;{data?.timeSpent}
                          </p>
                          <p>
                            <b>Distance Travelled : </b>&nbsp;
                            {data?.distanceTravelled === null ||
                            data?.distanceTravelled === ""
                              ? 0
                              : data?.distanceTravelled}
                          </p>
                        </td>
                        <td>{data?.mot}</td>
                        <td style={{ maxWidth: "150px" }}>
                          {data?.lessRemark === "NA" ? (
                            data?.lessRemark
                          ) : (
                            <OverlayTrigger
                              placement={"bottom"}
                              delay={{ show: 250, hide: 300 }}
                              overlay={
                                <Tooltip id={`tooltip-remarks`}>
                                  {data?.remark}
                                </Tooltip>
                              }
                            >
                              <div>{data?.lessRemark}</div>
                            </OverlayTrigger>
                          )}
                        </td>
                        <td>
                          <p className={`meet-status ${data?.status}`}>
                            {data?.status === "PCancelled"
                              ? "Pre-Cancelled"
                              : data?.status === "ReSchedule"
                              ? "Re-Scheduled"
                              : data?.status}
                          </p>
                        </td>
                      </tr>
                    ))
                  : "-----NO DATA FOUND----"}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default MeetingDetailsModel;
