import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import { SkeletonMeetPurpose } from '../../Skeleton Loading/SkeletonMeetPurpose';
import Skeleton from 'react-loading-skeleton';
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";

function DesignationTable(props) {
    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }}
                    onScroll={(e) => handleScroll(e, props.designationData,props.totalData, props.setLoading, props.setPageSize, props.PageSize)}>
                    <Table stickyHeader aria-label="sticky table table-responsive">
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell key={i} align={name.align} className={`thead-cell-products`}
                                        style={{ minWidth: `${name.width}` }}>
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ?
                            <TableBody>
                                {props.tableHeader.map((name, i) => (
                                    <TableRow align="center" >
                                        {props.tableHeader.map((name, i) => (
                                            <TableCell>
                                                <Skeleton />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                            :
                            <>
                                {props.designationData && props.designationData.length === 0 ?
                                    <TableBody>
                                        <TableRow align="center" >
                                            <TableCell align="center" colSpan={props.tableHeader.length}>
                                                <div style={{ padding: "20px 20px" }}>
                                                    <img src="../../../../images/DefaultImages/no-data-found.png" alt="" style={{ width: "10%" }} /><br />
                                                    <span>No Data Found...!</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {props.designationData && props.designationData.map((data, i) => (
                                            <TableRow align="center" key={i} className='client-list-row-data'>
                                                <TableCell align="center">
                                                    {i + 1}
                                                </TableCell>
                                                <TableCell>
                                                    <b>{data?.designationName}</b>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <span className='color-yellow'>L-{data?.designationLevel}</span>
                                                </TableCell>
                                                <TableCell>
                                                    {data?.departmentName}
                                                </TableCell>
                                                <TableCell>
                                                    {data?.role.roleName}
                                                </TableCell>
                                                <TableCell>
                                                    {data?.reportingDesignationName}
                                                </TableCell>
                                                <TableCell>
                                                    {data?.description}
                                                </TableCell>
                                                <TableCell>
                                                    {data?.addedByUserFullName}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {data?.status === 1 ?
                                                        <span className='status-badge complete-status'>Active</span>
                                                        : <span className='status-badge pending-status'>InActive</span>
                                                    }
                                                </TableCell>
                                                <TableCell className='action-class'>
                                                    <button
                                                        onClick={() => {
                                                            props.setEditDesignation(!props.editDesignation);
                                                            props.setEditDesignationID(data.designationID);
                                                        }}
                                                        className="btn client-edit-link"
                                                    >
                                                        <span>
                                                            <i className="bi bi-pencil-fill" />
                                                            &nbsp;Edit
                                                        </span>
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {props.loading &&
                                            <OnScrollLoading />
                                        }
                                    </TableBody>

                                }
                            </>
                        }
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default DesignationTable;
