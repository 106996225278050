import React, { useEffect, useState } from 'react'
import Sidenavbar from '../../Sidenavbar';
import Topbar from '../../Topbar';
import TablePage from '../../SbTables/TablePage';
import { Col, Row } from 'react-bootstrap';
import '../../../styles/category.scss';
import Select from "react-select";
import { useDispatch, useSelector } from 'react-redux';
import { loadClientCategory, updateClientCategory } from '../../../redux/action';
import * as HeaderData from '../../HeaderAuthentication';
import AddClientCategory from './AddClientCategory';
import SbAddBtn from '../../SbButtons/SbAddBtn';
import { useNavigate } from 'react-router-dom';

function ClientCategory() {

  let dispatch = useDispatch();
  const { clientCategoryData } = useSelector(state => state.data);

  let tableHeader = [
    "Sl.No",
    "Category Name",
    "Description",
    "Status",
    "Action"
  ]

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [loading, setLoading] = useState(false);
  const [PageSize, setPageSize] = useState(10);

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    dispatch(loadClientCategory())
  }, [])

  const [btnStatus, setBtnStatus] = useState(false)
  const handleStatus = (id) => {
    setBtnStatus(true)
    let updatingData
    clientCategoryData.filter(element => {
      if (element.categoryID === id) {
        if (element.status === 1) {
          updatingData = {
            categoryID: element.categoryID,
            subscriptionID: element.subscriptionID,
            name: element.name,
            description: element.description,
            addedByID: element.addedByID,
            addedByUserFullName: element.addedByUserFullName,
            addedOn: element.addedOn,
            status: 0
          }
        } else if (element.status === 0) {
          updatingData = {
            categoryID: element.categoryID,
            subscriptionID: element.subscriptionID,
            name: element.name,
            description: element.description,
            addedByID: element.addedByID,
            addedByUserFullName: element.addedByUserFullName,
            addedOn: element.addedOn,
            status: 1
          }
        }
      }
    })

    var updateRequest = {
      method: "POST",
      mode: "cors",
      headers: HeaderData.postTravelizeAuth,
      body: JSON.stringify(updatingData),
    }
    dispatch(updateClientCategory(updateRequest, setBtnStatus))
  }

  const [show, setShow] = useState(false);

  const categoryModuleOpen = () => {
    setShow(true);
  }
  const categoryModuleClose = () => {
    setShow(false);
  }

  const [searchValue, setsearchValue] = useState('')
  const clearSerachValue = () => {
    setsearchValue("")
  }

  const goToPage = () => {
    window.history.back();
  }

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {show && <AddClientCategory show={show} categoryModuleClose={categoryModuleClose} />}
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          
          <div className="page-content">
            <Row className='search-row'>
              <Col md={2} className='cat-col'>
                <div className='page-header-text-div'>
                  <div
                    className="back-btn"
                    onClick={goToBackPage}
                  >
                    <img
                      src="../../../../images/DefaultImages/back-btn-arrow.svg"
                      alt=""
                    />
                  </div>
                  <h6 className='page-header-text' style={{ color: "#04a96d" }}>Client Caregory</h6>
                </div>
              </Col>
              <Col md={10} className='cat-col'>
                <Row className='category-search-row'>
                  <Col md={6} className='cat-col'>

                  </Col>
                  <Col md={3} className='cat-col'>
                    <div className='input-div'>
                      <input type="text" placeholder='Find a Category' className='form-control setting-input'
                        value={searchValue} onChange={(e) => setsearchValue(e.target.value)}
                      />
                      <i className='fa fa-search search-icon' ></i>
                    </div>
                  </Col>
                  <Col md={1} className='cat-col'>
                    <button
                      type="button"
                      className="btn btn-search-track"
                      onClick={clearSerachValue}
                    >
                      <span className="btn-inline-item d-flex">
                        <i className="fa fa-eraser"> </i>
                        <h6>Clear</h6>
                      </span>
                    </button>
                  </Col>
                  <Col md={2} className='cat-col' align="center">
                    <SbAddBtn onClickEffect={categoryModuleOpen} />
                  </Col>
                </Row>
              </Col>
            </Row>
            <TablePage tableHeader={tableHeader}
              clientCategoryData={clientCategoryData}
              handleStatus={handleStatus}
              btnStatus={btnStatus}
              page="clientCategory" 
              searchValue={searchValue}
              loading={loading}
              setPageSize={setPageSize}
              PageSize={PageSize}
              setLoading={setLoading}
              />
          </div></div>
      </div>
    </div>
  )
}

export default ClientCategory;
