import React, { useEffect } from "react";
import { Col, FloatingLabel, Form, Modal, Row } from "react-bootstrap";
import SbAddBtn, { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import { useState } from "react";
import * as HeaderData from "../../HeaderAuthentication";
import { useDispatch, useSelector } from "react-redux";
import { loadPostHoliday, loadUpdateHoliday } from "../../../redux/action";
import moment from "moment";

function Addholidays(props) {
  let holidayId = props.holidayId;
  let dispatch = useDispatch();
  const { singleHoliday } = useSelector((state) => state.data);
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [holidayData, setHolidayData] = useState({
    subscriptionID: "",
    name: "",
    fromDate: "",
    toDate: "",
    description: "",
    addedByID: "",
    addedOn: "",
    addedByUserFullName: "",
    status: 1,
  });

  const {
    subscriptionID,
    name,
    description,
    fromDate,
    toDate,
    addedByID,
    addedOn,
    addedByUserFullName,
    status,
  } = holidayData;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setHolidayData({ ...holidayData, [name]: value });
  };

  useEffect(() => {
    if (holidayId) {
      if (singleHoliday) {
        setHolidayData({
          holidaysID: holidayId,
          subscriptionID: singleHoliday.subscriptionID,
          name: singleHoliday.name,
          fromDate: moment(singleHoliday.fromDate, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          toDate: moment(singleHoliday.toDate, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          ),
          description: singleHoliday.description,
          addedByID: singleHoliday.addedByID,
          addedOn: singleHoliday.addedOn,
          addedByUserFullName: resPassData.fullName,
          status: 1,
        });
        if (singleHoliday.status === 1) {
          setStatusValue(true);
        } else {
          setStatusValue(false);
        }
      }
    } else {
      if (resPassData) {
        setHolidayData({
          subscriptionID: resPassData.subscriptionId,
          name: "",
          fromDate: "",
          toDate: "",
          description: "",
          addedByID: resPassData.userId,
          addedOn: "",
          addedByUserFullName: resPassData.fullName,
          status: 1,
        });
      }
    }
  }, [holidayId, singleHoliday]);

  const cancelModale = () => {
    props.holidayModuleClose();
    props.setHolidayId("");
  };

  const [statusValue, setStatusValue] = useState(true);
  const statusFlag = (statusValue) => {
    setStatusValue(!statusValue);
    if (!statusValue === true) {
      setHolidayData({ ...holidayData, status: 1 });
    } else if (!statusValue === false) {
      setHolidayData({ ...holidayData, status: 0 });
    }
  };

  const [fieldReq, setFieldReq] = useState(false);
  const saveHolidaData = (e) => {
    e.preventDefault();
    if (!name || !fromDate || !toDate || !description) {
      setFieldReq(true);
    } else {
      var postRequest = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify(holidayData),
      };
      dispatch(loadPostHoliday(postRequest, cancelModale));
    }
  };

  const updateHolidayData = (e) => {
    e.preventDefault();
    if (!name || !fromDate || !toDate || !description) {
      setFieldReq(true);
    } else {
      var updateRequest = {
        method: "POST",
        mode: "cors",
        headers: HeaderData.postTravelizeAuth,
        body: JSON.stringify(holidayData),
      };
      dispatch(loadUpdateHoliday(updateRequest, cancelModale));
    }
  };

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.holidayModuleClose}
        className="category-modal-div"
      >
        <Row className="modale-header-div">
          <Col md={6} xs={9} className="cat-col">
            <h6 className="page-header-text">
              {holidayId ? "Edit Holiday Application" : "Holiday Application"}
            </h6>
          </Col>
          <Col md={6} xs={3} className="cat-col" align="right">
            <button
              onClick={cancelModale}
              className="bi bi-x close-popup"
            ></button>
          </Col>
        </Row>
        <hr />
        <div className="cat-col">
          <Form onSubmit={holidayId ? updateHolidayData : saveHolidaData}>
            <div className="form-field">
              <Form.Group
                className="mb-3_health"
                controlId="exampleForm.Health"
              >
                {/* <Form.Label className="require">Name</Form.Label> */}
                <FloatingLabel
                  label="Enter Name"
                  className={`${
                    name || ""
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <Form.Control
                    type="text"
                    value={name || ""}
                    name="name"
                    onChange={handleInputChange}
                    placeholder="Enter Name"
                    autoComplete="off"
                  />
                  {fieldReq && !name && (
                    <small id={`Error`} className="form-text text-muted ">
                      Please Enter description
                    </small>
                  )}
                </FloatingLabel>
              </Form.Group>
            </div>
            <div className="form-field">
              <Form.Group
                className="mb-3_health"
                controlId="exampleForm.Health"
              >
                {/* <Form.Label className="require">Start Date</Form.Label> */}
                <FloatingLabel
                  label="Select Start Date"
                  className={`${
                    fromDate
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Form.Control
                    type="date"
                    value={fromDate || ""}
                    name="fromDate"
                    onChange={handleInputChange}
                    placeholder="Select Date"
                    autoComplete="off"
                    
                  />
                </FloatingLabel>
                {fieldReq && !fromDate && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter description
                  </small>
                )}
              </Form.Group>
            </div>
            <div className="form-field">
              <Form.Group
                className="mb-3_health"
                controlId="exampleForm.Health"
              >
                {/* <Form.Label className="require">End Date</Form.Label> */}
                <FloatingLabel
                  label="Select End Date"
                  className={`${
                    toDate
                      ? "float-visible-select"
                      : "float-hidden float-select"
                  }`}
                >
                  <Form.Control
                    type="date"
                    value={toDate || ""}
                    name="toDate"
                    onChange={handleInputChange}
                    placeholder="Select Date"
                    autoComplete="off"
                    
                  />
                </FloatingLabel>
                {fieldReq && !toDate && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter description
                  </small>
                )}
              </Form.Group>
            </div>
            <div className="form-field">
              <Form.Group
                className="mb-3_health"
                controlId="exampleForm.Health"
              >
                {/* <Form.Label className="require">Description</Form.Label> */}
                <FloatingLabel
                  label="Description"
                  className={`${
                    description || ""
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <Form.Control
                    as="textarea"
                    type="text"
                    value={description || ""}
                    name="description"
                    onChange={handleInputChange}
                    placeholder="Description"
                    autoComplete="off"
                    className="form-control form form-textarea add-spare-input"
                    
                  />
                </FloatingLabel>
                {fieldReq && !description && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Enter description
                  </small>
                )}
              </Form.Group>
            </div>
            <div className="mt-3">
              <div className="form-field form-status-section">
                <div>
                  <Form.Label className="require">Status</Form.Label>
                </div>
                <div className="btn-status">
                  <Form.Group className="facility-details">
                    <div
                      className={`checkbox ${statusValue && "checkbox--on"}`}
                      name="status"
                      onClick={(e) => statusFlag(statusValue)}
                    >
                      <div className="checkbox__ball">
                        <span
                          className={
                            statusValue ? "enable-text" : "disable-text"
                          }
                        >
                          {statusValue ? "Enable" : "Disable"}
                        </span>
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </div>
            </div>
            <hr />
            <div className="sb-btn-section">
              <SBSaveUpdateBtn btnName={holidayId ? "Update" : "Save"} />
              <SbCancelBtn btnName="Cancel" onClickEffect={cancelModale} />
            </div>
          </Form>
        </div>
      </Modal>
    </div>
  );
}

export default Addholidays;
