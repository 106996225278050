import React from 'react'
import { SBSaveUpdateBtn } from './SbButtons/SbAddBtn'
import SbCancelBtn from './SbButtons/SbCancelBtn'
import { Modal } from 'react-bootstrap'

function GoogleAccessModal(props) {
  return (
    <div>
        <Modal show={props.accessModal} onHide={props.closeAccess} className="logout-modal-div">
                <div className='cat-col'>
                    <div >
                        <div className='log-out-div'>
                            <h6>Google Map Feature is not available in your Free Trial. If you still want to view the map Click on "Yes" </h6>
                            <p><b>Note:</b> <span className='color-yellow'>If you click on yes, we will deduct the credits from your account</span></p>
                        </div>
                        {/* <hr /> */}
                        <div className='sb-logout-btn'>
                            <SBSaveUpdateBtn onClickEffect={props.mapAccesss} btnName="Yes" />
                            <SbCancelBtn btnName="No" onClickEffect={props.closeAccess} />
                        </div>
                    </div>
                </div>

            </Modal>
    </div>
  )
}

export default GoogleAccessModal
