import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import SkeletonTableData from '../Skeleton Loading/SkeletonTableData';
import { baseURL } from '../BaseUrl';
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import OnScrollLoading, { handleScroll } from '../OnScrollLoading';
import profileimg from "../../images/profile-1.png";

function UserTable(props) {

    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }}
                onScroll={(e) => handleScroll(e, props.users, props.totalUserCount, props.setLoading, props.setPageSize, props.PageSize)} >
                    <Table stickyHeader aria-label="sticky table table-responsive" className='client-table'>
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((header, i) => (
                                    < TableCell key={i} align={header.align} style={{minWidth: `${header.width}`}} >
                                        {header.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ? 
                                <>
                                {props.tableHeader.map((header, i) => (
                                  <SkeletonTableData key={i} />
                                ))}
                              </>
                            :
                        <>{props.users.length === 0 ?
                            <TableBody>
                                <TableRow >
                                    <TableCell align="center" colSpan={props.tableHeader.length}>
                                        No data found...!
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {props.users && props.users.map((user, i) => (
                                    <TableRow align="center" key={i} className='client-list-row-data'>
                                        <TableCell>
                                        <span className="users-list-name">
                                        <div>
                                          <img
                                            className="table-data-users-imgages"
                                            src={
                                              user.profilePic
                                                ? `${baseURL}/${user.profilePic}`
                                                : profileimg
                                            }
                                            alt=""
                                          />
                                        </div>
                                        <div>
                                          <div>
                                            <strong>{user.fullName}</strong>
                                          </div>
                                          <div>
                                            <strong className="color-yellow">
                                              {user.code}
                                            </strong>
                                          </div>
                                        </div>
                                      </span>
                                        </TableCell>
                                        <TableCell>
                                        <div>{user.email}</div>
                                      <div className="user-mobile">
                                        {user.countryCode} &nbsp;{user.contact}
                                      </div>
                                        </TableCell>
                                        <TableCell>
                                        <span className='color-yellow'>L{user?.desingation?.designationLevel}</span>-
                                      {user?.desingation?.designationName}
                                        </TableCell>
                                        <TableCell>
                                        {user.desingation?.role?.roleName}
                                        </TableCell>
                                        <TableCell>
                                        {user.desingation?.departmentName}
                                        </TableCell>
                                        <TableCell>
                                        {user.hasSubordinates > 0 ? (
                                        <p className="subbordinate-link">
                                          {user?.managerName}
                                          <Link
                                            to="#"
                                            onClick={() => {
                                              props.setManagerId(user.userId);
                                              props.setManagerName(user.fullName);
                                              props.goBacktoUser();
                                            }}
                                          >
                                            <OverlayTrigger
                                              placement={"right"}
                                              delay={{ show: 200, hide: 250 }}
                                              overlay={
                                                <Tooltip id={`tooltip-title`}>
                                                  View Subordinates
                                                </Tooltip>
                                              }
                                            >
                                              <span>
                                                <i className="fa fa-users user-icon" />
                                              </span>
                                            </OverlayTrigger>
                                          </Link>
                                        </p>
                                      ) : (
                                        user.managerName
                                      )}
                                        </TableCell>
                                        <TableCell align="center">
                                        {user.enableStatus == 1
                                    ? <span className='status-badge complete-status'>Active</span>
                                    : <span className='status-badge pending-status'>InActive</span>}
                                        </TableCell>
                                        <TableCell className='action-class'>
                                        <span className="user-action-td">
                                        <Link
                                          to="#"
                                          onClick={() => {
                                            props.showEditModel();
                                            props.setEditUserId(user.userId);
                                          }}
                                          className="user-edit-link"
                                        >
                                          <i className="bi bi-pencil-fill"></i>{" "}
                                          Edit
                                        </Link>

                                        <Link
                                          to="#"
                                          className="user-view-link"
                                          onClick={() => {
                                            props.setEditUserId(user.userId);
                                            props.userHistoryView();
                                          }}
                                        >
                                          <i className="fa fa-eye"></i> History
                                        </Link>
                                      </span>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {props.loading &&  
                                  <OnScrollLoading />
                                }
                            </TableBody>


                        }
                        </>
}
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default UserTable;
