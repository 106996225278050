import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../../styles/MeetingPurpose.scss";
import { baseURL } from "../../BaseUrl";
import { SBSaveUpdateBtn } from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import * as swal from "../../Consturl/SwalAlert";
import { FloatingLabel } from "react-bootstrap";

function AddPurosemodel(props) {
  const [meetPurpose, setMeetPurpose] = useState("");
  const [showAlert, setShowAlert] = useState(false);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  //  ADDING MEETING PURPOSE START
  const addMeetingPurpose = (e) => {
    e.preventDefault();
    if (meetPurpose) {
      const purposeData = {
        purpose: meetPurpose,
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(purposeData),
      };
      fetch(`${baseURL}/api/MeetingPurpose/AddPurpose`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          // console.log("ADD-MEET-PURPOSE::", res);
          if (res.success) {
            props.handleClose();
            props.fetchMeetingPurposeData();
            swal.showAlert(
              "Success",
              `Meeting Purpose Added Successfully`,
              "success"
            );
          } else {
            props.handleClose();
            swal.showAlert(
              "Fail",
              `Somethin went wrong, ${res.errorMessage}...!`,
              "warning"
            );
          }
        });
    } else {
      setShowAlert(true);
    }
  };
  //  ADDING MEETING PURPOSE START END
  // close modal
  const closeModal = () => {
    props.handleClose();
    setShowAlert(false);
  };
  // close modal

  return (
    <div>
      <Modal
        show={props.show}
        onHide={props.handleClose}
        className="main_model add-meet-purpose-model"
      >
        <div className="add-meet-purpose-heading">
          <h6 className="add-edit-meetpurpose-head">ADD PURPOSE</h6>
          <i className="fa fa-times" onClick={closeModal} />
        </div>
        <form>
          <div className="container add-edit-meetpurpose">
            <div className="company-name">
              <div className="mb-2">
                <FloatingLabel
                  label="Meeting Purpose"
                  className={`${
                    meetPurpose
                      ? "float-input-visible"
                      : "float-hidden float-input"
                  }`}
                >
                  <input
                    type="text"
                    className="form-control form add-input"
                    id="exampleFormControlInput1"
                    placeholder="Meeting Purpose"
                    value={meetPurpose}
                    onChange={(e) => setMeetPurpose(e.target.value)}
                  />
                </FloatingLabel>
                {showAlert === true && !meetPurpose && (
                  <small id={`Error`} className="form-text text-muted ">
                    Please Select Meeting Purpose
                  </small>
                )}
              </div>
            </div>
          </div>
        </form>
        <div className="btns-save-cancel">
          <SBSaveUpdateBtn onClickEffect={addMeetingPurpose} btnName="Save" />
          <SbCancelBtn btnName="Cancel" onClickEffect={closeModal} />
        </div>
      </Modal>
    </div>
  );
}

export default AddPurosemodel;
