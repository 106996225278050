import React, { useState, useEffect } from "react";
import "../../styles/CommonStyle.scss";
import "../../styles/Routes.scss";
import { baseURL } from "../BaseUrl";
import ReactPaginate from "react-paginate";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import {
  optionsPageSizeList,
  optionsRoutPlanStatus,
} from "../DropdownsData";
import AccessDenied from "../../AccessDenied";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../Skeleton Loading/SkeletonStdTable";
import RouteMap from "./RouteMap";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { Col, Row } from "react-bootstrap";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";

export default function RoutePlanDetails(props) {
  const currentDate = new Date();
  const tenDaysAgo = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 10
  );

  const [routePlanData, setRoutePlanData] = useState([]);
  const [viewRouteID, setViewRouteID] = useState(0);
  const [viewClientID, setViewClientID] = useState(0);
  const [fromDate, setFromDate] = useState(props.fromDate);
  const [toDate, setToDate] = useState(props.toDate);
  const [searchVal, setSearchVal] = useState("");
  const [openRouteMap, setOpenRouteMap] = useState(false);
  const [routeStatus, setRouteStatus] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  // GET PAGE START
  useEffect(() => {
    fetchRoutePlans();
  }, [props.viewRouteID, fromDate, toDate, PageSize, currentPage, searchVal]);

  const fetchRoutePlans = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Route/GetRouteClient?RouteId=${
        props.viewRouteID
      }&Text=${searchVal}&FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("ROUTES::", res.result);
        if (res.success) {
          setRoutePlanData(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setRoutePlanData("");
          setIsLoading(false);
        }
      });
  };
  // GET PAGE END

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const clearSerachBySelect = () => {
    setRouteStatus("");
    setFromDate(props.fromDate);
    setToDate(props.toDate);
  };

  const closeRouteMap = () => {
    setOpenRouteMap(false);
  };

  //   const exportExcelBaseFare = () => {
  //     exportExcel(
  //       `${baseURL}/api/Export/MotBaseFareExcelExport?PageSize=100&CurrentPage=1`,
  //       "BaseFare.xlsx"
  //     );
  //   };

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* MAIN BODY START */}
          <div className="page-wrapper">

            {/* PAGE CONTENT START */}
            <div className={`main-body ${props.toggleshift.style}`}>
              <div className="page-content">

                {/* Filter and Header Section */}
            <div className="user-table">
              <Row className='search-row'>
                <Col md={3} className='cat-col'>
                  <div className='page-header-text-div'>
                    <div className="back-btn" onClick={props.backtoRoutes}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                    <h6 className='page-header-text' style={{ color: "#04a96d" }}>ROUTE PLAN DETAILS</h6>
                  </div>
                </Col>
                <Col md={5} className='cat-col'>
                  <div className='input-div'>
                    <input type="search" placeholder='Search Company / Number' className='form-control setting-input'
                      value={searchVal} onChange={(e) => setSearchVal(e.target.value)}
                    />
                    <i className='fa fa-search search-icon' ></i>
                  </div>
                </Col>
                <Col md={4} className='cat-col'>
                  <div className="search-bar-header" style={{ float: "right" }}>
                    <div className="page-top-bar-dropdowns page-top-Filters">
                      <div className="header-filter-btn" onClick={() => setFilter(!filter)}>
                        <p className="card-head">
                          <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              {filter &&
                <div className="page-filter-section">
                  <div style={{ width: "100%" }}>
                    <Row>
                      <Col md={12}>
                        <div className="clients-filter-input-boxs filter-box-div">
                          <div style={{ width: "100%", padding: "0px 5px" }}>
                          <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        options={optionsRoutPlanStatus}
                        placeholder="Select Status"
                        value={
                          routeStatus == "" ? "" : optionsRoutPlanStatus.value
                        }
                        onInputChange={optionsRoutPlanStatus.label}
                        onChange={(data) => setRouteStatus(data.value)}
                        isSearchable={true}
                      />
                          </div>
                          <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                            <span className="input-group-text" id="from-label">
                                  From
                                </span>
                                <span className="date-picker-with-icon">
                                <DatePicker
                            selected={fromDate}
                            className="select from-date"
                            id="from-datepicker"
                            dateFormat="dd/MM/yyyy"
                            selectsEnd
                            // startDate={fromDate}
                            endDate={toDate}
                            onChange={(date) => setFromDate(date)}
                            maxDate={toDate}
                          />
                                </span>
                                <span><i className="fa fa-calendar-o calander-icon" style={{ right: "15px" }} /></span>
                          </div>
                          <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                            <span className="input-group-text" id="from-label">
                                  To
                                </span>
                                <span className="date-picker-with-icon">
                                <DatePicker
                            selected={toDate}
                            className="select to-date"
                            id="from-datepicker"
                            dateFormat="dd/MM/yyyy"
                            selectsEnd
                            // startDate={fromDate}
                            endDate={toDate}
                            minDate={fromDate}
                            onChange={(date) => setToDate(date)}
                            // maxDate={new Date()}
                          />
                                </span>
                                <span><i className="fa fa-calendar-o calander-icon" style={{ right: "15px" }} /></span>
                          </div>
                          <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                          <SbCancelBtn onClickEffect={clearSerachBySelect}
                            btnName="Clear" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              }
            </div>
            {/* Filter and Header Section */}
            
                {/* USER TABLE START */}
                <div className="routes-table">
                  <div className="table-responsive routes-scrollbar">
                    <table className="table routes-plan-table-data">
                      <thead className="thead-light routes-thead">
                        {isLoading ? (
                          <>
                            <SkeletonStdTableHead />
                          </>
                        ) : (
                          <tr>
                            <th>COMPANY </th>
                            <th>LOCATION</th>
                            <th>MEETING DETAILS</th>
                            <th>STATUS</th>
                            <th>USER</th>
                            <th>
                              DISTANCE
                              <br />
                              <small>(APPROXIMATE )</small>
                            </th>
                          </tr>
                        )}
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <>
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                          </>
                        ) : routePlanData !== "" ? (
                          routePlanData.map((data, i) => (
                            <tr
                              key={i}
                              onClick={() => {
                                setOpenRouteMap(!openRouteMap);
                                setViewClientID(data.clientID);
                                setViewRouteID(data.routeID);
                              }}
                            >
                              <td>
                                <p className="color-green">
                                  <strong>{data?.clientName}</strong>
                                </p>
                                {data.mobile}
                              </td>
                              <td>
                                <p style={{ maxWidth: "350px" }}>
                                  <i className="fa fa-map-marker color-yellow" />{" "}
                                  &nbsp;
                                  {data?.clientLoc?.location}
                                </p>
                              </td>
                              <td>
                                <p>
                                  {" "}
                                  Date : &nbsp;
                                  <strong>
                                    {data?.planedClientMeetings[0]?.meetingDate}
                                  </strong>
                                </p>
                                <p>
                                  Time : &nbsp;
                                  <strong>
                                    {data?.planedClientMeetings[0]?.meetingTime}
                                  </strong>
                                </p>
                              </td>
                              <td>
                                <span
                                  className={`status ${data?.planedClientMeetings[0]?.planingStatus}`}
                                >
                                  {data?.planedClientMeetings[0]?.planingStatus}
                                </span>
                              </td>
                              <td>{data?.userFullName}</td>
                              <td>
                                <p className="color-green">
                                  <strong>{data?.baseClientDistance} KM</strong>
                                </p>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={8} className="text-center">
                              {" "}
                              --- NO DATA FOUND ---{" "}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="download-and-pagination">
                    <SbDownloadBtn
                    // onClickEffect={exportExcelActiivity}
                    />
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {openRouteMap ? (
              <RouteMap
                openRouteMap={openRouteMap}
                viewClientID={viewClientID}
                closeRouteMap={closeRouteMap}
                viewRouteID={viewRouteID}
              />
            ) : (
              ""
            )}

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}
