import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/ProductCategory.scss";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { baseURL } from "../../BaseUrl";
// import { travelizeAuth } from "../HeaderAuthentication";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { exportExcel } from "../../DownloadFile";
import Select from "react-select";
import { optionsStatusList, optionsPageSizeList } from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import Skeleton from "react-loading-skeleton";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../../Skeleton Loading/SkeletonStdTable";
import AddCategory from "./AddCategory";
import AddSubCategory from "./AddSubCategory";
import SbAddBtn from "../../SbButtons/SbAddBtn";
import EditCategory from "./EditCategory";
import EditSubCategory from "./EditSubCategory";
import { useNavigate } from "react-router-dom";
import OnScrollLoading, { handleScroll } from '../../OnScrollLoading';

export default function ProductCategory() {
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [productCategoryList, setProductCategoryList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [activeStatus, setActiveStatus] = useState(2);
  const [subCategoryID, setSubCategoryID] = useState(0);
  const [categoryId, setCategoryID] = useState(0);
  const [subCategoryName, setSubCategoryName] = useState("");
  const [parentCategoryID, setParentCategoryID] = useState(0);
  const [addCategory, setAddCategory] = useState(false);
  const [addSubCategory, setAddSubCategory] = useState(false);
  const [editCategory, setEditCategory] = useState(false);
  const [editSubCategory, setEditSubCategory] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [subCatDescription, setSubCatDescription] = useState("");
  const [tbodyShow, setTbodyShow] = useState(false);
  const [showSubCateById, setShowSubCateById] = useState(null);

  // Lazy Loading
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);


  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  const handleClose = () => {
    setAddCategory(false);
    setAddSubCategory(false);
    setEditCategory(false);
    setEditSubCategory(false);
  };

  useEffect(() => {
    fetchDropdownData();
  }, [categoryId]);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Category/BindParentCategoryDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setCategoryList(
            res.result.map((data) => {
              return {
                value: data.categoryID,
                label: data.name,
              };
            })
          );
        } else {
          setCategoryList([]);
        }
      });
    // fetch(
    //   `${baseURL}/api/Category/BindSubCategoryDropDown/${categoryId}`,
    //   requestOption
    // )
    //   .then((res) => res.json())
    //   .then((res) => {
    //     if (res.success) {
    //       //   setSubCategoryID(
    //       //     res.result.map((data) => {
    //       //       return { value: data.roleID, label: data.roleName };
    //       //     })
    //       //   );
    //     } else {
    //       setSubCategoryID([]);
    //     }
    //   });
  };

  // GET PRODUCTCATEGORY START
  useEffect(() => {
    fetchProductCategory();
  }, [categoryId, activeStatus, PageSize, currentPage]);

  const fetchProductCategory = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Category/Get?CategoryID=${categoryId}&ParentCateogoryID=0&StatusID=${activeStatus}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("CATEGORY::", res.result);
        if (res.success) {
          setProductCategoryList(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false);
        } else {
          setProductCategoryList("");
          setIsLoading(false);
          setLoading(false);
        }
      });
  };
  // GET PRODUCTCATEGORY END

  useEffect(() => {
    fetchSubCategoryById();
  }, [showSubCateById]);

  const fetchSubCategoryById = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Category/Get?ParentCateogoryID=${showSubCateById}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setSubCategoryList(res.result);
        } else {
          setSubCategoryList([]);
        }
      });
  };

  const clearSerachBySelect = () => {
    // setSubCategoryID(0);
    setCategoryID(0);
    setActiveStatus(2);
  };

  const exportExcelDownload = () => {
    exportExcel(
      `${baseURL}/api/Export/ProductCategoryExcelExport?CategoryID=${categoryId}&ParentCateogoryID=0&StatusID=${activeStatus}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "ProductCategory.xlsx"
    );
  };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
                {/* USER TABLE START */}
                <div className="product-category-table">
                  <div className="seleting-menus">
                    <div
                      className="back-btn"
                      onClick={goToBackPage}
                    >
                      <img
                        src="../../../../images/DefaultImages/back-btn-arrow.svg"
                        alt=""
                      />
                    </div>
                    <div className="page-title">PRODUCT CATEGORY</div>

                    <div className="page-top-bar-dropdowns">
                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={productCategoryList[0].label}
                        options={categoryList}
                        placeholder="Select Category"
                        value={categoryId == 0 ? "" : categoryList.value}
                        onInputChange={categoryList.label}
                        onChange={(data) => setCategoryID(data.value)}
                        isSearchable={true}
                      />

                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        options={optionsStatusList}
                        placeholder="Select Status"
                        value={activeStatus == 2 ? "" : optionsStatusList.value}
                        onInputChange={optionsStatusList.label}
                        onChange={(data) => setActiveStatus(data.value)}
                        isSearchable={true}
                      />

                      <button
                        type="button"
                        className="btn btn-search-track"
                        onClick={clearSerachBySelect}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-eraser" />
                          <h6>Clear</h6>
                        </span>
                      </button>

                      <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        options={optionsPageSizeList}
                        placeholder="Show Per Page"
                        value={optionsPageSizeList.value}
                        onInputChange={optionsPageSizeList.label}
                        onChange={(data) => setPageSize(data.value)}
                        isSearchable={true}
                      />

                      <SbAddBtn
                        onClickEffect={() => setAddCategory(!addCategory)}
                      />
                    </div>
                  </div>
                  <div className="table-responsive product-category-scrollbar"
                    onScroll={(e) => handleScroll(e, productCategoryList, totalData, setLoading, setPageSize, PageSize)}
                  >
                    <table className="table product-category-table-data">
                      <thead className="product-category-thead">
                        {isLoading ? (
                          <>
                            <SkeletonStdTableHead />
                          </>
                        ) : (
                          <tr>
                            <th>SL.NO.</th>
                            <th>CATEGORY</th>
                            <th>DESCRIPTION</th>
                            <th className="text-center">ACTION</th>
                            <th></th>
                          </tr>
                        )}
                      </thead>
                      <tbody>
                        {isLoading ? (
                          <>
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                            <SkeletonStdTableBody />
                          </>
                        ) : productCategoryList !== "" ? (
                          productCategoryList.map((data, i) => (
                            <>
                              <tr className="product-category-row-data" key={i}>
                                <td>{i + 1}</td>
                                <td>
                                  <p
                                    className="edit-link"
                                    onClick={() => {
                                      setEditCategory(!editCategory);
                                      setParentCategoryID(data?.categoryID);
                                    }}
                                  >
                                    {data?.name}
                                  </p>
                                </td>
                                <td>{data?.description}</td>
                                <td className="text-center">
                                  {/* <button
                                    //   onClick={() => {
                                    //     setEditCategory(
                                    //       !editCategory
                                    //     );
                                    //     setEditDepartmentId(data.designationID);
                                    //   }}
                                    className="btn product-category-edit-link"
                                  >
                                    <i className="bi bi-pencil-fill" /> Edit
                                  </button> */}
                                  <button
                                    onClick={() => {
                                      setAddSubCategory(!addSubCategory);
                                      setParentCategoryID(data?.categoryID);
                                    }}
                                    className="btn product-category-edit-link"
                                  >
                                    <i className="fa fa-plus" /> Sub-Category
                                  </button>
                                </td>
                                <td>
                                  {" "}
                                  <i
                                    className={
                                      tbodyShow &&
                                        showSubCateById == data.categoryID
                                        ? "fa fa-chevron-down"
                                        : "fa fa-chevron-right"
                                    }
                                    onClick={() => {
                                      setTbodyShow(!tbodyShow);
                                      setShowSubCateById(data.categoryID);
                                    }}
                                  />
                                </td>
                              </tr>
                              {tbodyShow &&
                                showSubCateById == data.categoryID && (
                                  <tr>
                                    <td colSpan={5}>
                                      <table className="table sub-category-table">
                                        <thead className="product-category-thead">
                                          <tr>
                                            <th></th>
                                            <th></th>
                                            <th>SUB-CATEGORY</th>
                                            <th>DESCRIPTION</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {subCategoryList.map((sub, i) => (
                                            <tr
                                              className="product-category-row-data sub-row-accordian-body"
                                              key={i}
                                            >
                                              <td></td>
                                              <td></td>
                                              <td>
                                                {/* <i className="fa fa-dot-circle-o" />{" "} */}
                                                &nbsp;
                                                {sub.name} &nbsp;{" "}
                                                <i
                                                  className="fa fa-pencil"
                                                  onClick={() => {
                                                    setEditSubCategory(
                                                      !editSubCategory
                                                    );
                                                    setParentCategoryID(
                                                      sub?.parentCategoryID
                                                    );
                                                    setSubCategoryName(
                                                      sub?.name
                                                    );
                                                    setSubCatDescription(
                                                      sub?.description
                                                    );
                                                    setSubCategoryID(
                                                      sub?.categoryID
                                                    );
                                                  }}
                                                />
                                              </td>
                                              <td> {sub.description}</td>
                                            </tr>
                                          ))}
                                          {loading &&
                                            <OnScrollLoading />
                                          }
                                        </tbody>
                                      </table>
                                    </td>
                                  </tr>
                                )}
                            </>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={4} className="text-center">
                              {" "}
                              --- NO DATA FOUND ---{" "}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="download-and-pagination">
                    <button
                      type="button"
                      className="btn btn-download-excel"
                      onClick={exportExcelDownload}
                    >
                      <i className="fa fa-download" /> &nbsp; Download
                    </button>
                    <span className="user-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i className="fa fa-angle-double-right" />}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <i className="fa fa-angle-double-left" />
                        }
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                      />
                    </span>
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* ADD MODEL START */}
                {addCategory ? (
                  <AddCategory
                    addCategory={addCategory}
                    handleClose={handleClose}
                  // fetchDesignations={fetchDesignations}
                  />
                ) : (
                  ""
                )}
                {/* ADD MODEL END */}

                {/* ADD MODEL START */}
                {addSubCategory ? (
                  <AddSubCategory
                    addSubCategory={addSubCategory}
                    handleClose={handleClose}
                    parentCategoryID={parentCategoryID}
                    fetchSubCategoryById={fetchSubCategoryById}
                  />
                ) : (
                  ""
                )}
                {/* ADD MODEL END */}

                {/* EDIT MODEL START */}
                {editCategory ? (
                  <EditCategory
                    editCategory={editCategory}
                    parentCategoryID={parentCategoryID}
                    handleClose={handleClose}
                    fetchProductCategory={fetchProductCategory}
                  />
                ) : (
                  " "
                )}
                {/* EDIT  MODEL END */}

                {editSubCategory ? (
                  <EditSubCategory
                    editSubCategory={editSubCategory}
                    parentCategoryID={parentCategoryID}
                    handleClose={handleClose}
                    subCategoryID={subCategoryID}
                    subCategoryName={subCategoryName}
                    subCatDescription={subCatDescription}
                    fetchProductCategory={fetchProductCategory}
                    fetchSubCategoryById={fetchSubCategoryById}
                  />
                ) : (
                  ""
                )}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}
