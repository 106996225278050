// import { travelizeAuth } from "./HeaderAuthentication";

let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

const travelizeAuth = {
  "Content-Type": "application/json",
  Travelize_Authentication: userDetails,
};

export const DownloadFile = (resBlob, filenameToDownload) => {
  const url = window.URL.createObjectURL(resBlob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filenameToDownload);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const exportExcel = (apiUrl, filenameToDownload) => {
  
  let HasFile = false;
  let requestOption = {
    method: "GET",
    mode: "cors",
    headers: travelizeAuth,
  };
  fetch(apiUrl, requestOption)
    .then((res) => {

      HasFile = true;
      return res.blob();
    })
    .then((res) => {
      console.log(res, "res")
      if (HasFile) {
        DownloadFile(res, filenameToDownload);
      } else {
        // console.log(res.errorMessage);
      }
    });
};
