import React from "react";

function SbAddBtn(props) {
  return (
    <div className="dropdown add-single-bulk">
      <button
        className="btn btn-add-dropdown"
        id="adduser-single-blulk"
        onClick={props.onClickEffect && props.onClickEffect}
        disabled={props.disableProp}
      >
        <div className="btn-inline-item d-flex align-items-center">
          <i className="fa fa-plus-circle"> </i>
          &nbsp;&nbsp;<h6 style={{fontSize: "13px", fontWeight: "600"}}>Add</h6>
        </div>
      </button>
    </div>
  );
}
export default SbAddBtn;

export function SbBuyNowBtn(props) {
  return (
    <div>
      <button
        type={!props.onClickEffect && "submit"}
        className="btn btn-buynow-btn"
        onClick={props.onClickEffect && props.onClickEffect}
      >
        <div className="btn-inline-item d-flex">
          <h6>{props.bntName}</h6>
        </div>
      </button>
    </div>
  );
}

export function SBSaveUpdateBtn(props){
  return (
    <div className="dropdown add-single-bulk">
      <button
        type={!props.onClickEffect && "submit"}
        className="btn btn-add-save-update"
        id="adduser-single-blulk"
        onClick={props.onClickEffect && props.onClickEffect}
        disabled={props.disableProp}
      >
        {props.btnName}
      </button>
    </div>
  )
}


export function SbCreateListBtn(props) {
  return (
    <div>
      <button
        type={!props.clickEffect && "submit"}
        className="btn btn-createList-btn"
        onClick={props.clickEffect}
      >
        <div className="btn-inline-item d-flex">
          <h6>{props.bntName} {props.createList.length !== 0 && <span className="list-count">{props.createList.length}</span>}</h6>
        </div>
      </button>
    </div>
  );
}

export function SbCheckoutBtn(props) {
  return (
    <div className="btn-div">
      <button
        type="submit"
        className="btn btn-checkoutList-btn"
        onClick={props.clickEffect}
      >
        <div className="btn-inline-item d-flex">
          <h6>{props.bntName}</h6>
        </div>
      </button>
    </div>
  );
}

export function SbDownloadBtn(props) {
  return (
    <div className="dropdown add-single-bulk">
      <button
        className="btn btn-add-dropdown"
        id="adduser-single-blulk"
        onClick={props.onClickEffect && props.onClickEffect}
      >
        <div className="btn-inline-item d-flex align-items-center">
        <i className="fa fa-download" />
          &nbsp;&nbsp;<h6 style={{fontSize: "13px", fontWeight: "600"}}>{props.btnName ? props.btnName : "Download"}</h6>
        </div>
      </button>
    </div>
  );
}


