import React, { useEffect, useState } from 'react'
import Sidenavbar from '../../Sidenavbar';
import Topbar from '../../Topbar';
import TablePage from '../../SbTables/TablePage';
import { Col, Row } from 'react-bootstrap';
import '../../../styles/category.scss';
import { useDispatch, useSelector } from 'react-redux';
import { loadHolidayList, loadSingleHoliday } from '../../../redux/action';
import Addholidays from './Addholidays';
import SbAddBtn from '../../SbButtons/SbAddBtn';
import { useNavigate } from 'react-router-dom';

function Holiday() {

  let dispatch = useDispatch();
  const { holidayList } = useSelector(state => state.data);

  let tableHeader = [
    "Sl.No",
    "Name",
    "From Date",
    "To Date",
    "Description",
    "Status",
    "Action"
  ]

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    dispatch(loadHolidayList())
  }, [])


  const [show, setShow] = useState(false);

  const holidayModuleOpen = () => {
    setShow(true);
  }
  const holidayModuleClose = () => {
    setShow(false);
  }

  const [holidayId, setHolidayId] = useState('')
  const handleEdit = (id) => {
    holidayModuleOpen()
    setHolidayId(id)
    dispatch(loadSingleHoliday(id))
  }

  const [searchValue, setsearchValue] = useState('')
  const clearSerachValue = () => {
    setsearchValue("")
  }
  const goToPage = () => {
    window.history.back();
  }

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    Navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {/* addHoliday modal page */}
      {show && <Addholidays show={show} holidayModuleClose={holidayModuleClose}
        holidayId={holidayId} setHolidayId={setHolidayId} />}
      {/* addHoliday modal page */}
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}
      <div className="page-wrapper">
        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            <Row className='search-row'>
              <Col md={3} className='cat-col'>
                <div className='page-header-text-div'>
                  <div
                    className="back-btn"
                    onClick={goToBackPage}
                  >
                    <img
                      src="../../../../images/DefaultImages/back-btn-arrow.svg"
                      alt=""
                    />
                  </div>
                  <h6 className='page-header-text' style={{ color: "#04a96d" }}>Holiday List</h6>
                </div>
              </Col>
              <Col md={5} className='cat-col'>
                <Row className='search-bar-header'>
                  <Col md={10} className='cat-col'>
                    <div className='input-div'>
                      <input type="text" placeholder='Find a Name' className='form-control setting-input'
                        value={searchValue} onChange={(e) => setsearchValue(e.target.value)}
                      />
                      <i className='fa fa-search search-icon' ></i>
                    </div>
                  </Col>
                  <Col md={2} className='cat-col'>
                    <button
                      type="button"
                      className="btn btn-search-track"
                      onClick={clearSerachValue}
                    >
                      <span className="btn-inline-item d-flex">
                        <i className="fa fa-eraser"> </i>
                        <h6>Clear</h6>
                      </span>
                    </button>
                  </Col>
                </Row>
              </Col>
              <Col md={4} className='cat-col'>
                <div className="search-bar-header" style={{float: "right"}}>
                  <div className="page-top-bar-dropdowns page-top-Filters">
                  <SbAddBtn onClickEffect={holidayModuleOpen} />
                  </div>
                </div>
              </Col>
            </Row>
            <TablePage tableHeader={tableHeader} clientCategoryData={holidayList}
              handleEdit={handleEdit} page="holiday" searchValue={searchValue} />
          </div></div>
      </div>
    </div>
  )
}

export default Holiday;
