import React, { useState, useEffect } from "react";
import "../../../styles/CommonStyle.scss";
import "../../../styles/BaseFare.scss";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  loadFilterReferalData,
  loadRefferalData,
} from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import SbAddBtn from "../../SbButtons/SbAddBtn";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import RefferalTable from "./RefferalTable";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import AddRefferal from "./AddRefferal";
import DatePicker from "react-datepicker";
import moment from "moment";


function Refferal() {
  let tableHeader = [
    {name: "REFERRED BY", align: "left", width: "150px"},
    {name: "REFERRED ON", align: "left", width: "150px"},
    {name: "NAME", align: "left", width: "150px"},
    {name: "EMAIL", align: "left", width: "150px"},
    {name: "MOBILE", align: "left", width: "120px"},
    {name: "NOTE", align: "left", width: "250px"},
    {name: "COMMISSION", align: "center", width: "100px"},
    {name: "PRODUCT", align: "center", width: "150px"},
    {name: "REF. TYPE", align: "left", width: "150px"},
    {name: "REFFERAL CODE", align: "center", width: "150px"},
    {name: "STATUS", align: "center", width: "100px"}
  ];

  let dispatch = useDispatch();
  const { salesBeeRefferal } = useSelector((state) => state.data)
  console.log(salesBeeRefferal)
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [show, setShow] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));


  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleClose = () => {
    setShow(false);
  };


  const [openFilterTab, setOpenFilterTab] = useState(false);

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation


  //code for edit

  const [salesBeeReferelId, setSalesBeeReferelId] = useState()
  const editRefferal = (refId) => {
    setSalesBeeReferelId(refId)
    setShow(!show)
  }


  //code for filter

  const [fromDate, setFromDate] = useState(
    new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
  );
  const [toDate, setToDate] = useState(new Date());
  const [filterArray, setFilterArray] = useState([])

  //code for search bar

  const [searchValue, setSearchValue] = useState('')

  const setSearchData = (value) => {
    let list;
    if (filterArray.length !== 0) {
      list = filterArray.filter(item => item.key !== "Text")
    } else {
      list = []
    }
    if (value) {
      list.push({ key: "Text", value: value })
    } else {
      list = filterArray.filter(item => item.key !== "Text")
    }
    setSearchValue(value)
    setFilterArray(list)
  }

  const setSelectSearchDrop = (data) => {
    const [start, end] = data;
    let list = []
      if (filterArray.length !== 0) {
        if(start){
          list = filterArray.filter((item) => item.key !== "FromDate");
        }
        if(end){
          list = filterArray.filter((item) => item.key !== "ToDate");
        }
      }
      if(start && end == null){
        list.push({ key: "FromDate", value: moment(new Date(start)).format("DD/MM/YYYY") });
      }
      setFromDate(start);
      if(end){
        list.push({ key: "ToDate", value: moment(new Date(end)).format("DD/MM/YYYY") });
      } 
      setToDate(end);
      setFilterArray(list);
  };

  const applyFilters = () => {
    dispatch(loadFilterReferalData(filterArray, PageSize, currentPage, setIsLoading));
  };

  useEffect(() => {
    applyFilters(filterArray, setIsLoading)
  }, [filterArray, setIsLoading])

  const clearSerachBySelect = () => {
    setFromDate(new Date(Date.now() - 30 * 24 * 60 * 60 * 1000));
    setToDate(new Date());
    setFilterArray([]); // Clear the filter array
  }

  

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>

              <div className="page-content">
                <div style={{ position: "relative" }}>
                  <Row className='search-row'>
                    <Col md={3} className='cat-col'>
                      <div className='page-header-text-div'>
                      <div className="back-btn" onClick={goToSettingPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <h6 className='page-header-text' style={{ color: "#04a96d" }}>SALESBEE REFERRAL</h6>
                      </div>
                    </Col>
                    <Col md={5} className='cat-col'>
                      <div className='input-div'>
                        <input type="search" placeholder='Find a Referal Code' className='form-control setting-input'
                          value={searchValue} onChange={(e) => setSearchData(e.target.value)}
                        />
                        <i className='fa fa-search search-icon' ></i>
                      </div>
                    </Col>
                    <Col md={4} className='cat-col'>
                      <div className="search-bar-header" style={{ float: "right" }}>
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div className="header-filter-btn" onClick={() => setOpenFilterTab(!openFilterTab)}>
                            <p className="card-head">
                              <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                            </p>
                          </div>
                          <SbAddBtn onClickEffect={() => setShow(!show)} />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab &&
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={4}>
                            <div className="clients-filter-input-boxs filter-box-div">
                            <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                                <span className="input-group-text" id="from-label">
                                  From - To
                                </span>
                                <span className="date-picker-with-icon">
                                  <DatePicker
                                    selected={fromDate}
                                    className="select from-date"
                                    id="date-range-picker"
                                    dateFormat="dd/MM/yyyy"
                                    selectsRange 
                                    startDate={fromDate}
                                    endDate={toDate}
                                    onChange={setSelectSearchDrop}
                                    maxDate={new Date()}
                                  />
                                </span>
                                <span><i className="fa fa-calendar-o calander-icon" style={{ right: "15px" }} /></span>
                              </div>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <SbCancelBtn onClickEffect={clearSerachBySelect}
                                btnName="Clear" />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  }
                </div>



                {/*TABLE START */}
                <RefferalTable
                  tableHeader={tableHeader}
                  //   baseFareList={baseFareList}
                  // isLoading={isLoading}
                  salesBeeRefferal={salesBeeRefferal}
                  editRefferal={editRefferal}
                />
                {/*TABLE END */}



                {/* ADD & EDIT PRODUCT MODEL START */}
                {show && (
                  <AddRefferal
                    show={show}
                    salesBeeReferelId={salesBeeReferelId}
                    handleClose={handleClose}
                    applyFilters={applyFilters}
                  />
                )}
                {/* ADD & EDIT PRODUCT MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default Refferal;


