import React, { useEffect, useState } from 'react'
import Sidenavbar from '../Sidenavbar'
import Topbar from '../Topbar';
import '../../styles/subscriptionmodule.scss'
import { loadAllSubscriptions, loadGetDistance, loadNotification, loadPostDistance, loadPostMenuItems, loadSubscription, loadSubscriptionBySubscriber } from '../../redux/action';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'react-bootstrap';
import { SBSaveUpdateBtn } from '../SbButtons/SbAddBtn';
import SbCancelBtn from '../SbButtons/SbCancelBtn';

let matrix = [
    { id: 1, name: "Google Maps" },
    { id: 2, name: "Salesbee(GPS)" },
    { id: 3, name: "Manual" },
    { id: 4, name: "Odo meter" },
    { id: 0, name: "None" }
]

let notificationOptions = [
    { label: "WhatsApp", value: "AllowWhatsApp" },
    { label: "SMS", value: "SMSNoticification" },
    { label: "Email", value: "AllowEmail" },
    { label: "OTP Notification", value: "AllowOTP" }
]

function MeetingManagement(props) {

    let moduleId = props.moduleId;
    let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
    let selectedSubscribe = props.selectedSubscribe;
    let selectedRole = props.selectedRole;
    let dispatch = useDispatch()
    const { sbcrBySubscriber } = useSelector(state => state.data)
    const { distanceMatrix } = useSelector(state => state.data)

    const [matrixValue, setMatrixValue] = useState(2)
    const [notifValue, setNotifValue] = useState([])

    const notification = (e, item) => {
        if (e.target.checked) {
            setNotifValue([...notifValue, item])
        } else {
            setNotifValue(notifValue.filter(name => name !== item))
        }
    }

    const travelizeAuth = {
        "Content-Type": "application/json",
        Travelize_Authentication: userDetails,
    };

    useEffect(() => {
        if (moduleId && selectedSubscribe.value && selectedRole.roleId) {
            dispatch(loadSubscriptionBySubscriber(selectedSubscribe.value, moduleId, selectedRole.roleId))
        }
        if (distanceMatrix) {
            let notifArray = []
            setMatrixValue(distanceMatrix.distanceMatrix)
            if (distanceMatrix.allowEmail === 1) {
                notifArray.push("AllowEmail")
            }
            if (distanceMatrix.allowWhatsApp === 1) {
                notifArray.push("AllowWhatsApp")
            }
            if (distanceMatrix.allowOTP === 1) {
                notifArray.push("AllowOTP")
            }
            if (distanceMatrix.smsNoticification === 1) {
                notifArray.push("SMSNoticification")
            }
            setNotifValue(notifArray)
        }

    }, [dispatch, moduleId, selectedSubscribe, distanceMatrix, selectedRole])

    let iconColor = [
        "#F22B29",
        "#003FDF",
        "#DF11F1",
        "#785589",
        "#1EA1FF",
        "#D97A0A",
        "#ffcc99",
        "#9999ff",
        "#ff6699",
        "#660033",
        "#cc00ff",
    ]

    const [menuCollapse, setMenuCollapse] = useState(false);
    const [toggleshift, setToggleshift] = useState({ style: "" });
    const { allSubcriptions } = useSelector(state => state.data)

    const [menuItems, setMenuItems] = useState([])
    const [submenuAtt, setSubmenuAtt] = useState([])
    const [submenuClm, setSubmenuClm] = useState([])
    const [submenuStg, setSubmenuStg] = useState([])

    useEffect(() => {
        dispatch(loadSubscription())
        dispatch(loadAllSubscriptions())
        if (selectedSubscribe) {
            dispatch(loadGetDistance(selectedSubscribe.value))
        }
    }, [selectedSubscribe])

    useEffect(() => {
        let activities = [];
        let subActAtt = [];
        let subActClm = [];
        let subActStng = [];
        let subscriptionList = [...sbcrBySubscriber]
        if (subscriptionList?.length != 0) {

            subscriptionList?.map((item) => {
                if (item.parentModuleName === null) {
                    activities.push({ items: item, subItem: [] })
                }
                else if (item.parentModuleName === "Attendance") {
                    subActAtt.push(item)
                }
                else if (item.parentModuleName === "Claims") {
                    subActClm.push(item)
                }
                else if (item.parentModuleName === "Settings") {
                    subActStng.push(item)
                }
            })


        } else {
            activities.push({ items: { moduleName: "Dashboard" }, subItem: [] })
        }
        setMenuItems(activities)
        setSubmenuAtt(subActAtt)
        setSubmenuClm(subActClm)
        setSubmenuStg(subActStng)

    }, [allSubcriptions, sbcrBySubscriber])

    useEffect(() => {
        if (menuItems) {
            menuItems.map((activities) => {
                if (activities?.items?.moduleName === "Attendance") {
                    activities.subItem = submenuAtt
                }
                if (activities?.items?.moduleName === "Claims") {
                    activities.subItem = submenuClm
                }
                if (activities?.items?.moduleName === "Settings") {
                    activities.subItem = submenuStg
                }
            })
        }
    }, [menuItems, submenuAtt, submenuClm, submenuStg])

    // const { subcriptionList } = useSelector(state => state.data);

    // const [userList, setUserList] = useState([]);

    // useEffect(() => {
    //     if (subcriptionList) {
    //         let optiondata = []
    //         subcriptionList.map((data) => {
    //             if (data.subscriptionId != null) {
    //                 optiondata.push({
    //                     value: data.subscriptionId, label: data.subscriptionId, client: data?.company?.companyName
    //                 });
    //             }
    //         })
    //         setUserList(optiondata)
    //     }
    // }, [subcriptionList])

    const toggleMenu = () => {
        if (menuCollapse === true) {
            setMenuCollapse(false);
            setToggleshift({ style: "" });
        } else {
            setMenuCollapse(true);
            setToggleshift({ style: "toggle-shift-left" });
        }
    };

    const handleInputChange = (i, status, name) => {
        let list = [...menuItems];
        let statusChange;
        if (status === 1) {
            statusChange = 0;
        } else if (status === 0) {
            statusChange = 1;
        }
        list[i].items.enable = statusChange;
        let attMenu = [...submenuAtt]
        let clmMenu = [...submenuClm]
        let stgMenu = [...submenuStg]
        if (name === "Attendance") {
            if (statusChange === 1) {
                attMenu.map(item => {
                    item.enable = 1
                })
            }
            else {
                attMenu.map(item => {
                    item.enable = 0
                })
            }
        }
        if (name === "Claims") {
            if (statusChange === 1) {
                clmMenu.map(item => {
                    item.enable = 1
                })
            } else {
                clmMenu.map(item => {
                    item.enable = 0
                })
            }
        }
        if (name === "Settings") {
            if (statusChange === 1) {
                stgMenu.map(item => {
                    item.enable = 1
                })
            } else {
                stgMenu.map(item => {
                    item.enable = 0
                })
            }
        }
        setMenuItems(list)
    }

    const subMenuInputChange = (i, status, parent) => {
        let menulist = [...menuItems]
        let statusChange;
        if (status === 1) {
            statusChange = 0
        } else if (status === 0) {
            statusChange = 1
        }
        if (parent === "Attendance") {
            let list = [...submenuAtt];

            list[i].enable = statusChange;
            setSubmenuAtt(list)
            let isFound = list.some(element => {
                if (element.enable === 1) {
                    return true;
                }
                return false;
            });

            if (isFound) {

                menulist.map(item => {
                    if (item.items.moduleName === parent && item.items.enable === 0) {
                        item.items.enable = 1;
                    }
                })
                // setMenuItems(updatemenuItems)
            } else {
                menulist.map(item => {
                    if (item.items.moduleName === parent && item.items.enable === 1) {
                        item.items.enable = 0;
                    }
                })
            }
        } else if (parent === "Claims") {
            let list = [...submenuClm];
            list[i].enable = statusChange;
            setSubmenuClm(list)
            let isFound = list.some(element => {
                if (element.enable === 1) {
                    return true;
                }
                return false;
            });

            if (isFound) {
                menulist.map(item => {
                    if (item.items.moduleName === parent && item.items.enable === 0) {
                        item.items.enable = 1;
                    }
                })
            } else {
                menulist.map(item => {
                    if (item.items.moduleName === parent && item.items.enable === 1) {
                        item.items.enable = 0;
                    }
                })
            }
        } else if (parent === "Settings") {
            let list = [...submenuStg];
            list[i].enable = statusChange;
            setSubmenuStg(list)
            let isFound = list.some(element => {
                if (element.enable === 1) {
                    return true;
                }
                return false;
            });

            if (isFound) {
                menulist.map(item => {
                    if (item.items.moduleName === parent && item.items.enable === 0) {
                        item.items.enable = 1;
                    }
                })
            } else {
                menulist.map(item => {
                    if (item.items.moduleName === parent && item.items.enable === 1) {
                        item.items.enable = 0;
                    }
                })
            }
        }
    }

    const [mainMenuList, setMainMenuList] = useState([])
    useEffect(() => {
        if (menuItems.length !== 0 && sbcrBySubscriber.lenght !== 0) {
            let mainMenu = []
            sbcrBySubscriber.filter(element => {
                menuItems.map(item => {
                    if (item.items.moduleName === element.moduleName) {
                        mainMenu.push(element)
                    }
                })
            });
            setMainMenuList(mainMenu)

        }
    }, [menuItems, sbcrBySubscriber])

    let submitMenu = [...mainMenuList, ...submenuAtt, ...submenuClm, ...submenuStg]
    const sortSubmitData = [...submitMenu].sort((a, b) => a.moduleID - b.moduleID);

    const SaveModules = (e) => {
        e.preventDefault()
        var postData = {
            method: "POST",
            mode: "cors",
            headers: travelizeAuth,
            body: JSON.stringify(sortSubmitData),
        };
        dispatch(loadPostMenuItems(postData))
    }

    const SaveDistance = (e) => {
        e.preventDefault()

        var postDistance = {
            "subscriptionID": selectedSubscribe.value,
            "distanceMatrix": matrixValue,
        }

        var postRequest = {
            method: "POST",
            mode: "cors",
            headers: travelizeAuth,
            body: JSON.stringify(postDistance),
        };
        dispatch(loadPostDistance(postRequest))
    }


    const SaveNotification = (e) => {
        e.preventDefault()
        const lastIndex = notifValue.length - 1;
        notifValue.map((item, i) => {

            if (i === lastIndex) {
                var postNotification = {
                    subscriptionID: selectedSubscribe.value,
                    permissionName: item,
                    status: "1"
                }
                var postRequest = {
                    method: "POST",
                    mode: "cors",
                    headers: travelizeAuth,
                    body: JSON.stringify(postNotification),
                };
                dispatch(loadNotification(postRequest, lastIndex))
            } else {
                var postNotificationData = {
                    "subscriptionID": selectedSubscribe.value,
                    "permissionName": item,
                    "status": "1"
                }
                var postRequestData = {
                    method: "POST",
                    mode: "cors",
                    headers: travelizeAuth,
                    body: JSON.stringify(postNotificationData),
                };
                dispatch(loadNotification(postRequestData))
            }
        })




    }

    const backPage = () => {
        props.setModuleData("")
    }

    return (
        <div id="main-page">
            {/* top bar and sidebar start */}
            <div className="top-and-sidebar">
                <Sidenavbar
                    menuCollapse={menuCollapse}
                    toggleshift={toggleshift}
                />

                <Topbar
                    toggleMenu={toggleMenu}
                    menuCollapse={menuCollapse}
                    toggleshift={toggleshift}
                />
            </div>
            {/* top bar and sidebar end */}

            {/* page content start */}
            <div className="page-wrapper">
                <div className={`main-body ${toggleshift.style}`}>
                    <div className="page-content">
                        <div className="user-table">
                            <Row className='search-row'>
                                <Col md={4} className='cat-col'>
                                    <div className='page-header-text-div'>
                                    <div className="back-btn" onClick={backPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                                    <h6 className='page-header-text' style={{ color: "#04a96d" }}>{props.moduleData} Module</h6>
                                    </div>
                                </Col>
                                <Col md={4} className='cat-col'>
                                    <div className="page-title" style={{textAlign: "center", color: "#ffb900"}}>
                                        {selectedSubscribe.label}-
                                        {selectedSubscribe.client}({selectedRole.label})
                                    </div>
                                </Col>
                                <Col md={4} className='cat-col'>
                                </Col>
                                </Row>

                            <div className="table-responsive meetings-scrollbar" >
                                <div className='menu-div-header'>
                                    <h2 style={{ fontSize: '20px', fontWeight: '700' }}>Assign Modules</h2>
                                </div>
                                <div>
                                    <div className='menu-div-header'>
                                        <h5 style={{ fontSize: '16px', fontWeight: '700' }}>List of Modules</h5>
                                    </div>
                                    <div className='menu-item-div'>
                                        <Row >
                                            {menuItems.length !== 0 && menuItems.map((item, i) => (
                                                <Col md={3} key={i} >
                                                    <div className="sb-module-card">
                                                        <Row className='module-head-div'>
                                                            <Col xs={7} >
                                                                <div style={{ color: iconColor[i] }}>
                                                                    <p className='menu-name'>
                                                                        {item.items.moduleName === "Dashboard" ? <i className='fa fa-desktop'></i> :
                                                                            item.items.moduleName === "Users" ? <i className='fa fa-group'></i> :
                                                                                item.items.moduleName === "Attendance" ? <i className='fa fa-calendar-check-o'></i> :
                                                                                    item.items.moduleName === "Leaves" ? <i className='bi bi-ui-checks fa'></i> :
                                                                                        item.items.moduleName === "Meetings" ? <i className='fa fa-handshake-o'></i> :
                                                                                            item.items.moduleName === "Clients" ? <i className='fa fa-user-circle'></i> :
                                                                                                item.items.moduleName === "Products" ? <i className='fa fa-cube'></i> :
                                                                                                    item.items.moduleName === "Spares" ? <i className='bi bi-currency-dollar fa'></i> :
                                                                                                        item.items.moduleName === "Claims" ? <i className='bi bi-currency-dollar fa'></i> :
                                                                                                            item.items.moduleName === "Settings" && <i className='fa fa-cog'></i>}
                                                                        &nbsp;&nbsp;{item.items.moduleName}</p>
                                                                </div>
                                                            </Col>
                                                            <Col xs={5} className="toggle-btn-div">
                                                                <div className="subacribe-toggle w-100">
                                                                    <Form.Group className="facility-details">
                                                                        <div className={`checkbox ${item.items.enable === 1 && "checkbox--on"}`}
                                                                            name="status"
                                                                            onClick={(e) => handleInputChange(i, item.items.enable, item.items.moduleName)}>
                                                                            <div className="checkbox__ball">
                                                                            </div>
                                                                        </div>
                                                                    </Form.Group>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <div className='d-flex justify-content-center'>
                                                            <hr align="center" className='sb-hr'></hr>
                                                        </div>
                                                        <div id="submenu-section-div" className={`section-sunmenus ${item.subItem.length > 3 ? "scroll" : "submenus"}`} >
                                                            {item.subItem.length === 0 ?
                                                                <div className="submenu-row d-flex">
                                                                    <h5 style={{ color: "#110C1C", fontSize: "15px", fontWeight: "700" }}>No submenus available</h5>
                                                                </div>
                                                                :
                                                                <div style={{ width: "100%" }}>
                                                                    {item.subItem.map((subItem, index) => (
                                                                        <Row key={index} className="submenu-row">
                                                                            <Col xs={7} >
                                                                                <div>
                                                                                    <p className='submenu-name'>{subItem.moduleName}</p>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xs={5} className="toggle-btn-div">
                                                                                <div className="subacribe-toggle w-100">
                                                                                    <Form.Group className="facility-details">
                                                                                        <div className={`checkbox ${subItem.enable === 1 && "checkbox--on"}`}
                                                                                            name="status"
                                                                                            onClick={(e) => subMenuInputChange(index, subItem.enable, item.items.moduleName)}>
                                                                                            <div className="checkbox__ball">
                                                                                            </div>
                                                                                        </div>
                                                                                    </Form.Group>
                                                                                </div>

                                                                            </Col>
                                                                        </Row>
                                                                    ))}
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                            ))}
                                        </Row>
                                    </div>
                                    <div className='module-btn-div' align="center">
                                        <div className="user-save-button">
                                            <SBSaveUpdateBtn btnName="Save" onClickEffect={SaveModules} />
                                        </div>
                                        <div className="user-save-button">
                                            <SbCancelBtn btnName="Reset" />
                                        </div>
                                    </div>
                                </div>
                               
                                {props.moduleData === "Location Management" || props.moduleData === "Location Management\r\n" &&
                                    <div>
                                         <hr></hr>
                                        <div className='menu-div-header'>
                                            <h5 style={{ fontSize: '16px', fontWeight: '700' }}>Distance Matrix</h5>
                                        </div>
                                        <div className='menu-item-div '>
                                            <Row className='matrix-div'>
                                                {matrix.map((item, i) => (
                                                    <Col md={2} key={i} >
                                                        <div className='sb-matrix-div'>
                                                            <input
                                                                type="checkbox"
                                                                className="styled-checkbox" id={"styled-checkbox-" + i}
                                                                checked={matrixValue === item.id}
                                                                onChange={() => setMatrixValue(item.id)}
                                                            />
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <span style={{ fontSize: '16px', fontWeight: '700' }}>{item.name}</span>
                                                        </div>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </div>
                                        <div className='module-btn-div' align="center">
                                            <div className="user-save-button">
                                                <SBSaveUpdateBtn btnName="Save" onClickEffect={SaveDistance} />
                                            </div>
                                            <div className="user-save-button">
                                                <SbCancelBtn btnName="Reset" /> 
                                            </div>
                                        </div>
                                    </div>
                                }
                                
                                {props.moduleData === "Client Management" &&
                                    <div>
                                        <hr></hr>
                                            <div className='menu-div-header'>
                                                <h5 style={{ fontSize: '16px', fontWeight: '700' }}>Notification Gateway</h5>
                                            </div>
                                            <div className='menu-item-div '>
                                                <Row className='matrix-div'>
                                                    {notificationOptions.map((item, i) => (
                                                        <Col md={3} key={i} >
                                                            <div className='sb-matrix-div'>
                                                                <input
                                                                    type="checkbox"
                                                                    className="styled-checkbox" id={"styled-checkbox-" + i}
                                                                    checked={notifValue.includes(item.value)}
                                                                    // selected={notifValue.includes(item.value)}
                                                                    onChange={(e) => notification(e, item.value)}
                                                                />
                                                                &nbsp;&nbsp;&nbsp;&nbsp;
                                                                <span style={{ fontSize: '16px', fontWeight: '700' }}>{item.label}</span>
                                                            </div>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </div>
                                            
                                            <div className='module-btn-div' align="center">
                                                <div className="user-save-button">
                                                    <SBSaveUpdateBtn btnName="Save" onClickEffect={SaveNotification} />
                                                </div>
                                                <div className="user-save-button">
                                                    <SbCancelBtn btnName="Reset" />
                                                </div>
                                            </div>
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* page content end */}
        </div>
    )
}

export default MeetingManagement;
