import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Adddropdowndata.scss";
import { baseURL } from "../BaseUrl";
import Select from "react-select";
import * as swal from "../Consturl/SwalAlert";
import AccessDenied from "../../AccessDenied";
import AlertModel from "../AlertModel";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import SbCancelBtn from "../SbButtons/SbCancelBtn";

export default function AddDropdownData() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [countryList, setCountryList] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [countryId, setCountryId] = useState("");
  const [country, setCountry] = useState(0);
  const [zoneId, setZoneId] = useState(0);
  const [stateId, setStateId] = useState(0);
  const [newCountry, setNewCountry] = useState("");
  const [newState, setNewState] = useState("");
  const [newCity, setNewCity] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [zoneIdFilter, setZoneIdFilter] = useState(0);
  const [stateIdFilter, setStateIdFilter] = useState(0);
  const [userRegionStates, setUserRegionStates] = useState([]);
  const [userRegionCities, setUserRegionCities] = useState([]);

  const [alertMessage, setAlertMessage] = useState({
    alertType: "",
    message: "",
  });

  let userLoginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  // GET API DATA START
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Zone/GetUserRegionsResult/`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          let regionResult = res.result;
          setUserRegionStates(regionResult.allStates);
          setUserRegionCities(regionResult.allCities);
          //   setCountryCodeList(
          //     regionResult?.allCountryCode.map((data) => {
          //       return { value: data.code, label: data.code };
          //     })
          //   );
          setCountryList(
            regionResult?.allCountry.map((data) => {
              return { value: data.countryID, label: data.countryName };
            })
          );
          setZoneList(
            regionResult?.allZones.map((data) => {
              return { value: data.zoneId, label: data.zoneName };
            })
          );
          setStateList(
            regionResult?.allStates.map((data) => {
              return { value: data.stateId, label: data.stateName };
            })
          );
          setCityList(
            regionResult?.allCities.map((data) => {
              return { value: data.cityId, label: data.cityName };
            })
          );
        } else {
          setCountryList([]);
          setZoneList([]);
          setStateList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };

  // GET API DATA END

  const addCountryNametoCountryId = () => {
    let newCountries = [
      {
        countryName: newCountry,
        code: countryId,
      },
    ];
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(newCountries),
    };
    fetch(`${baseURL}/api/Zone/AddCountryCode`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("ZONE::", res.result);
        if (res.success) {
          swal.showAlert("Success", `${res.message}`, "success");
          window.location.reload();
        } else {
          swal.showAlert("Fail", `${res.errorMessage}`, "warning");
        }
      });
  };

  const addStatesToZone = () => {
    let newStates = [
      {
        stateName: newState,
        zoneId: zoneId,
        countryID: country,
      },
    ];
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(newStates),
    };
    fetch(`${baseURL}/api/Zone/AddStates`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("ZONE::", res.result);
        if (res.success) {
          swal.showAlert("Success", `${res.message}`, "success");
          window.location.reload();
        } else {
          swal.showAlert("Fail", `${res.errorMessage}`, "warning");
        }
      });
  };

  const addCityToState = () => {
    let newCities = [
      {
        stateId: stateId,
        cityName: newCity,
      },
    ];
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(newCities),
    };
    fetch(`${baseURL}/api/Zone/AddCities`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("ZONE::", res.result);
        if (res.success) {
          swal.showAlert("Success", `${res.message}`, "success");
          window.location.reload();
        } else {
          swal.showAlert("Fail", `${res.errorMessage}`, "warning");
        }
      });
  };

  const clearSerachBySelect = () => {
    setCountry(0);
    setZoneId(0);
    setStateId(0);
    setZoneIdFilter(0);
    setStateIdFilter(0);
    setNewState("");
    setNewCity("");
  };
  // Page navigation
  let navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userLoginDetails?.subscriptionId == "SUB0000000001" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
              <div className="clients-table">
              <Row className='search-row'>
                <Col md={3} className='cat-col'>
                  <div className='page-header-text-div'>
                    <div className="back-btn" onClick={goToBackPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                    <h6 className='page-header-text' style={{ color: "#04a96d" }}>ADDING DATA TO DROPDOWNS</h6>
                  </div>
                </Col>
                <Col md={9} className='cat-col'>
                  <div className="search-bar-header" style={{ float: "right" }}>
                    <div className="page-top-bar-dropdowns page-top-Filters">
                      <SbCancelBtn
                      btnName="Clear"
                        onClickEffect={clearSerachBySelect} />
                    </div>
                  </div>
                </Col>
              </Row>
              </div>

                {/* USER TABLE START */}
                <div className="googleapi-table">

                  <div className="table-responsive adddropdowndata-scrollbar">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="add-state-cities">
                          <div className="add-country-state-city-part">
                            <div className="add-country">
                              <h6>ADD COUNTRY</h6>
                              <div className="select-dropdown">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control form add-state-input mb-2"
                                  placeholder="Enter Country Code"
                                  value={countryId}
                                  onChange={(e) => setCountryId(e.target.value)}
                                />
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control form add-state-input mb-2"
                                  placeholder="Add New Country"
                                  value={newCountry}
                                  onChange={(e) =>
                                    setNewCountry(e.target.value)
                                  }
                                />
                                <button
                                  className="btn btn-update-zone"
                                  onClick={addCountryNametoCountryId}
                                >
                                  UPDATE COUNTRY
                                </button>
                              </div>
                            </div>
                            <div className="add-state">
                              <h6>ADD STATE</h6>
                              <div className="select-dropdown">
                                <Select
                                  className="select-add-user-model mb-2"
                                  classNamePrefix="react-select-list"
                                  options={countryList}
                                  placeholder="Select Country"
                                  value={countryList.value}
                                  onInputChange={countryList.label}
                                  onChange={(data) => setCountry(data.value)}
                                  isSearchable={true}
                                />
                                <Select
                                  className="select-add-user-model mb-2"
                                  options={zoneList}
                                  placeholder="Select Zone"
                                  value={zoneList.value}
                                  onInputChange={zoneList.label}
                                  onChange={(data) => setZoneId(data.value)}
                                  isSearchable={true}
                                />
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control form add-state-input mb-2"
                                  placeholder="Add New State"
                                  value={newState}
                                  onChange={(e) => setNewState(e.target.value)}
                                />
                                <button
                                  className="btn btn-update-zone"
                                  onClick={addStatesToZone}
                                >
                                  UPDATE STATE
                                </button>
                              </div>
                            </div>
                            <div className="add-city">
                              <h6>ADD CITY</h6>
                              <div className="select-dropdown">
                                <Select
                                  className="select-add-user-model mb-2"
                                  options={stateList}
                                  placeholder="Select State"
                                  value={stateList.value}
                                  onInputChange={stateList.label}
                                  onChange={(data) => setStateId(data.value)}
                                  isSearchable={true}
                                />
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control form add-state-input mb-2"
                                  placeholder="Add New City"
                                  value={newCity}
                                  onChange={(e) => setNewCity(e.target.value)}
                                />
                                <button
                                  className="btn btn-update-zone"
                                  onClick={addCityToState}
                                >
                                  UPDATE CITY
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="country-table">
                          <div className="country-head">COUNTRY LIST</div>
                          {countryList.map((data, i) => (
                            <div className="country-list" key={i}>
                              {data?.label}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-md-3">
                        {" "}
                        <div className="state-table">
                          <div className="top-sticky">
                            <Select
                              className="select-add-user-model mb-2"
                              options={zoneList}
                              placeholder="Select Zone"
                              value={zoneList.value}
                              onInputChange={zoneList.label}
                              onChange={(data) => setZoneIdFilter(data.value)}
                              isSearchable={true}
                            />
                            <div className="country-head">STATE LIST</div>
                          </div>
                          <div className="table-height">
                            {userRegionStates
                              .filter((state) => state.zoneId == zoneIdFilter)
                              .map((data, i) => (
                                <div className="country-list" key={i}>
                                  {data?.stateName}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        {" "}
                        <div className="city-table">
                          <div className="top-sticky">
                            <Select
                              className="select-add-user-model mb-2"
                              options={stateList}
                              placeholder="Select State"
                              value={stateList.value}
                              onInputChange={stateList.label}
                              onChange={(data) => setStateIdFilter(data.value)}
                              isSearchable={true}
                            />
                            <div className="country-head">CITY LIST</div>
                          </div>
                          <div className="table-height">
                            {userRegionCities
                              .filter((city) => city.stateId == stateIdFilter)
                              .map((data, i) => (
                                <div className="country-list" key={i}>
                                  {data?.cityName}
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>
            {/* MAIN BODY END */}
            {showAlert ? (
              <AlertModel
                showAlert={showAlert}
                alertMessage={alertMessage}
                closeAlertModel={closeAlertModel}
              />
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}
