// Topbar page Breadcome
export const Get_BreadCome_Data = "Get_BreadCome_Data";
export const Get_Admin_Access = "Get_Admin_Access";
export const Get_Total_Data = "Get_Total_Data";
// Topbar page Breadcome
export const Get_Main_Modules = "Get_Main_Modules";
export const Add_Designation = "Add_Designation";
export const Get_Subscription = "Get_Subscription";
export const Get_Subscr_Dropdown = "Get_Subscr_Dropdown";
export const Get_All_Subscription = "Get_All_Subscription";
export const Get_Subscription_Module = "Get_Subscription_Module";
export const Get_All_Menus = "Get_All_Menus";
export const Get_All_Id_Subs = "Get_All_Id_Subs";
export const Get_Roles_DropDown = "Get_Roles_DropDown";

export const Post_Menus_Subscription = "Post_Menus_Subscription";

export const Get_Track_User = "Get_Track_User";

export const Get_Successs_Msg = "Get_Successs_Msg";
export const Get_Distance_Matrix = "Get_Distance_Matrix";

// Dashboard page Graph Api's
export const Get_GraphData = "Get_GraphData";
export const Get_GraphCount = "Get_GraphCount";
// Dashboard page Graph Api's

// Category page
export const Get_Client_Category = "Get_Client_Category";
export const Client_Category_DropDown = "Client_Category_DropDown";
export const Update_Client_Category = "Update_Client_Category";
export const Post_Client_Category = "Post_Client_Category";
// Category page

// Statge page
export const Get_Client_Stage = "Get_Client_Stage";
export const Stage_DropDown = "Stage_DropDown";
export const Post_Client_Stage = "Post_Client_Stage";
export const Update_Client_Stage = "Update_Client_Stage";
// Statge page

// Custom fields page
export const Post_Custom_Fields = "Post_Custom_Fields";
export const Get_Custom_Fields = "Get_Custom_Fields";
export const Get_Single_Filed = "Get_Single_Filed";
export const Update_Custom_Fields = "Update_Custom_Fields";
// Custom fields page

// Company page
export const Get_Company_Data = "Get_Company_Data";
export const Get_Single_Client = "Get_Single_Client";
export const Post_Other_Contact = "Post_Other_Contact";
export const Get_Other_Contact = "Get_Other_Contact";
export const ContactBy_Company_Drop = "ContactBy_Company_Drop";
export const Post_Comapy_Data = "Post_Comapy_Data";
export const Update_Company_Data = "Update_Company_Data";
export const Client_Dropdown = "Client_Dropdown";
// Company page

// Holidays page
export const Get_Holiday_List = "Get_Holiday_List";
export const Post_Holiday_List = "Post_Holiday_List";
export const Single_Holiday_Data = "Single_Holiday_Data";
export const Update_Holiday_Data = "Update_Holiday_Data";
// Holidays page

// Shift page
export const Get_Shift_List = "Get_Shift_List";
export const Post_Shift_List = "Post_Shift_List";
// Shift page

// Client complaint page
export const Get_Client_Complains = "Get_Client_Complains";
export const Post_Client_Complaint = "Post_Client_Complaint";
export const Simgle_Client_Complaint = "Simgle_Client_Complaint";
export const Update_Client_Complaint = "Update_Client_Complaint";
// Client complaint page

// Products page
export const Get_Products_List = "Get_Products_List";
export const Get_Single_Product = "Get_Single_Product";
export const Get_Product_DropDown = "Get_Product_DropDown";
export const Post_Product_Data = "Post_Product_Data";
export const Update_Product_Data = "Post_Product_Data";
export const Get_Cart_List = "Get_Cart_List";
// Products page

// Orders Page
export const Get_Orders = "Get_Orders";
export const Get_Single_Orders = "Get_Single_Orders";
export const Get_ProductsBy_Order = "Get_ProductsBy_Order";
export const Get_Order_History = "Get_Order_History";
export const Update_Product_Qty = "Update_Product_Qty";
// Orders Page

// Meetings Page
export const Get_Meetings_Data = "Get_Meetings_Data";
// Meetings Page

// Product Category page
export const Get_ProdCategory_Dropdown = "Get_ProdCategory_Dropdown";
export const Get_Prod_Sub_Category = "Get_Prod_Sub_Category";
// Product Category page

// Designation Page
export const Get_Designation_Dropdown = "Get_Designation_Dropdown";
export const Get_Designation_Department = "Get_Designation_Department";
// Designation Page

// Basefare page
// mot dropdown
export const Get_Mot_Dropdown = "Get_Mot_Dropdown";
// mot dropdown
export const Get_BaseFare_List = "Get_BaseFare_List";
export const Post_Basefare = "Post_Basefare";
export const Get_Single_BaseFare = "Get_Single_BaseFare";
export const Update_Basefare = "Update_Basefare";
// Basefare page

// Departmaent Page
export const Get_Department_Dropdown = "Get_Department_Dropdown";
// Departmaent Page

// Get Apis for Getting State, Zone, City, Country
export const Get_Zones = "Get_Zones";
export const Get_States_ByZoneId = "Get_States_ByZoneId";
export const Get_City_ByStateID = "Get_City_ByStateID";
// Get Apis for Getting State, Zone, City, Country

// Upload Single image as base64
export const Upload_Single_Image = "Upload_Single_Image";
// Upload Single image as base64

// Super Adimin Pages
// Google credits
export const Get_Google_Credits = "Get_Google_Credits";
export const Get_API_Dropdown = "Get_API_Dropdown";
export const Get_Credits_Count = "Get_Credits_Count";
export const Get_API_Credits = "Get_API_Credits";
export const GET_APT_HITS = "GET_APT_HITS";
export const Post_Google_Credits = "Post_Google_Credits";
export const Post_Api_Credits = "Post_Api_Credits";
export const Get_GoogleApiName_Drop = "Get_GoogleApiName_Drop";
export const Get_Single_ApiCredit = "Get_Single_ApiCredit";
export const Get_Exception = "Get_Exception";
export const Get_Mobile_Exception = "Get_Mobile_Exception";
// Google credits
// Super Adimin Pages

// User Page
export const Get_User_Dropdown = "Get_User_Dropdown";
export const Get_User_Dropdown_By_Company = "Get_User_Dropdown_By_Company";
export const Get_User_Regions = "Get_User_Regions";
// User Page

// GET USER REGION
export const Get_Country_List = "Get_Country_List";
export const Get_CountryCode_List = "Get_CountryCode_List";
// GET USER REGION

// Googel Hit Count
// export const Get_Hit_Count = "Get_Hit_Count";
// Googel Hit Count

// Subscription Pages
export const get_Subscription_Type_Dropdown = "get_Subscription_Type_Dropdown"
export const Update_Subscription = "Update_Subscription";
// Subscription Pages

// Refferal Pages
export const get_SalesBee_Refferal = "get_SalesBee_Refferal"
export const post_SalesBee_Refferal = "post_SalesBee_Refferal"
export const update_SalesBee_Refferal = "update_SalesBee_Refferal"
export const Get_Single_Refferal_Data = "Get_Single_Refferal_Data"
// Refferal Pages
