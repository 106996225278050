import React, { useState, useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import "../../styles/Tracker.scss";
import profileimg from "../../images/profile-1.png";
import meetrouteimg from "../../images/destination.png";
import NoDataImg from "../../images/no-data-found.gif";
import { baseURL } from "../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import GoogleMapComp from "../GoogleMapComp";
// import { travelizeAuth } from "./HeaderAuthentication";
import Select from "react-select";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function TrackMeetings(props) {
  const [userList, setUserList] = useState([]);
  const [filterUserId, setFilterUserId] = useState(props.userID);
  const [date, setDate] = useState(new Date(props.meetingdetailsDate));
  const [time, setTime] = useState("12:00");
  const [trackMeetingDetails, setTrackMeetingDetails] = useState("");
  const [checkInLocation, setCheckInLocation] = useState({
    lat: null,
    lng: null,
    location: null,
    time: null,
  });
  const [meetingCordinates, setMeetingCordinates] = useState([]);

  const [meetingslatLan, setMeetingsLatLan] = useState({
    lat: null,
    lng: null,
  });

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("USER-DROPDOWN::", res.result);
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      });
  };

  useEffect(() => {
    fetchMeetingsOnMap();
  }, [filterUserId, date]);

  const fetchMeetingsOnMap = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Meeting/GetMeetingCounts?FromDate=${moment(date).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(date).format(
        "DD/MM/YYYY"
      )}&UserId=${filterUserId}&ManagerID=`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          console.log("MEETING-DETAILS-ON-MAP::", res.result);
          setTrackMeetingDetails(res.result);
        } else {
          console.log("MEETING-DETAILS-ERROR::", res);
          setTrackMeetingDetails("");
        }
      });
  };

  useEffect(() => {
    if (trackMeetingDetails) {
      let meetingsCoordinate = [];
      // let coordinates = trackMeetingDetails.mapCoordinate;
      for (var i = 0; i < trackMeetingDetails.mapCoordinate.length; i++) {
        if (
          trackMeetingDetails.mapCoordinate[i].meetingType === "CheckIn" ||
          trackMeetingDetails.mapCoordinate[i].meetingType ===
            "CheckIn-CheckOut"
        ) {
          setCheckInLocation({
            lat: +trackMeetingDetails.mapCoordinate[i].checkInLoc.latitude,
            lng: +trackMeetingDetails.mapCoordinate[i].checkInLoc.longitude,
            location: trackMeetingDetails.mapCoordinate[i].checkInLoc.location,
            time: trackMeetingDetails.mapCoordinate[i].checkinTime,
          });

          setMeetingsLatLan({
            lat: +trackMeetingDetails.mapCoordinate[i].checkInLoc.latitude,
            lng: +trackMeetingDetails.mapCoordinate[i].checkInLoc.longitude,
          });
        } else if (trackMeetingDetails.mapCoordinate[i].meetingID != null) {
          meetingsCoordinate.push({
            lat: +trackMeetingDetails.mapCoordinate[i].clientLoc.latitude,
            lng: +trackMeetingDetails.mapCoordinate[i].clientLoc.longitude,
            location: trackMeetingDetails.mapCoordinate[i].clientLoc.location,
            clientName: trackMeetingDetails.mapCoordinate[i].clientName,
            meetingType: trackMeetingDetails.mapCoordinate[i].meetingType,
            meetingDate: trackMeetingDetails.mapCoordinate[i].date,
          });
        }
      }
      setMeetingCordinates(meetingsCoordinate);
    }
  }, [filterUserId, date, trackMeetingDetails]);

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setTime("12:00");
    setDate(new Date());
  };


  return (
    <div id="main-page">
      {/* MAIN BODY START */}
      <div className="page-wrapper">
        {/* PAGE CONTENT START */}
        {props.viewonmap ? (
          <div className={`main-body ${props.toggleshift.style}`}>
            <div className="page-content">

              {/* Filter and Header Section */}
            <div className="user-table">
              <Row className='search-row'>
                <Col md={3} className='cat-col'>
                  <div className='page-header-text-div'>
                    <div className="back-btn" onClick={props.goBacktomeetings}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                    <h6 className='page-header-text' style={{ color: "#04a96d" }}>TRACK MEETINGS</h6>
                  </div>
                </Col>
                <Col md={9} className='cat-col'>
                  <div className="search-bar-header" style={{ float: "right" }}>
                    <div className="page-top-bar-dropdowns page-top-Filters">
                      <div className="header-filter-btn" onClick={() => setFilter(!filter)}>
                        <p className="card-head">
                          <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>

              {filter &&
                <div className="page-filter-section">
                  <div style={{ width: "100%" }}>
                    <Row>
                      <Col md={8}>
                        <div className="clients-filter-input-boxs filter-box-div">
                          <div style={{ width: "100%", padding: "0px 5px" }}>
                          <Select
                      className="react-select-container-list"
                      classNamePrefix="react-select-list"
                      options={userList}
                      placeholder="Select User"
                      value={userList.value}
                      onInputChange={userList.label}
                      onChange={(data) => setFilterUserId(data.value)}
                      isSearchable={true}
                      // isClearable={true}
                    />
                          </div>
                          <div style={{ width: "100%", padding: "0px 5px" }}>
                          <span className="date-picker-with-icon">
                      <DatePicker
                        selected={date}
                        className="select single-date"
                        dateFormat="dd/MM/yyyy"
                        // selectsEnd
                        // startDate={date}
                        // endDate={toDate}
                        // minDate={fromDate}
                        onChange={(date) => setDate(date)}
                        maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>
                          </div>
                          
                          <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                          <SbCancelBtn onClickEffect={clearSerachBySelect}
                            btnName="Clear" />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              }
            </div>
            {/* Filter and Header Section */}


              {/* TRACKER TABLE START */}
              <div className="tracker-table">
                
                <div className="table-responsive tracker-tabel-scroll">
                  <table className="table trackers-list-table">
                    <thead className="thead-light trackers-list-thead">
                      <tr>
                        <th>NAME</th>
                        <th>DATE</th>
                        <th>DIRECT VISITS</th>
                        <th>SCHEDULED</th>
                        <th>ACTIONS</th>
                      </tr>
                    </thead>
                    <tbody>
                      {trackMeetingDetails !== "" ? (
                        <tr className="track-list-row-data">
                          <td>
                            <span className="track-list-name d-flex">
                              <img
                                className="table-data-track-imgages"
                                src={profileimg}
                                alt="John Doe"
                              />
                              <h6>{trackMeetingDetails?.userFullName}</h6>
                            </span>
                          </td>
                          <td>
                            {moment(
                              trackMeetingDetails?.mapCoordinate[0]?.date
                            ).format("DD/MM/YYYY")}
                          </td>
                          <td>{trackMeetingDetails?.directVisitCount}</td>
                          <td>{trackMeetingDetails?.scheduledCount}</td>
                          <td>
                            <span
                              className="td-print-column d-flex"
                              onClick={handlePrint}
                            >
                              <i className="bi bi-printer"></i>Print
                            </span>
                          </td>
                        </tr>
                      ) : (
                        <tr className="no-data-found">
                          ------ NO DATA FOUND ------
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* TRACKER TABLE END */}
              <div className="track-user-meeting-page">
                {sessionStorage.getItem("distanceMatrixID") == 1 &&
                meetingCordinates &&
                meetingCordinates.length > 0 &&
                meetingslatLan.lat &&
                meetingslatLan.lng ? (
                  <div className="row view-on-map" ref={componentRef}>
                    <div className="col-md-9">
                      <div className="google-map-box" ref={componentRef}>
                        <GoogleMapComp
                          meetingslatLan={meetingslatLan}
                          pageType="TrackMeetings"
                          markerPositions={meetingCordinates}
                          checkInLocation={checkInLocation}
                        />
                      </div>
                    </div>

                    <div className="col-md-3 trak-user-meeting-details-table track-user-meet-scroll">
                      {meetingCordinates.map((data, i) => (
                        <div className="card view-on-map-card" key={i}>
                          <div className="card-header ">
                            <span className="meeting-detail-name d-flex">
                              <i className="bi bi-caret-right-fill" />
                              <img
                                className="table-data-meeting-track-user-img"
                                src={profileimg}
                                alt="profile-image"
                              />
                              <span className="meeting-track-user-detail">
                                <h6>{data?.clientName}</h6>
                                <p>
                                  {data?.meetingType} -{i + 1}
                                </p>
                              </span>
                              <img
                                className="meet-route-img"
                                src={meetrouteimg}
                                alt="profile-image"
                              />
                            </span>
                          </div>
                          <div className="card-body meeting-full-address">
                            <p>{data?.meetingDate}</p>
                            <p>{data?.location}</p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <>
                    <img src={NoDataImg} className="mCS_img_loaded" />
                  </>
                )}
              </div>
              {/* PAGE CONTENT END*/}
            </div>
          </div>
        ) : (
          ""
        )}
        {/* MAIN BODY END */}
      </div>
    </div>
  );
}

export default TrackMeetings;
