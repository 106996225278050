import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import { Form } from 'react-bootstrap';

function DashboardTable(props) {
    return (
        <div className={`sb-table-div sb-dashboard-table`}>
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }} >
                    <Table stickyHeader aria-label="sticky table table-responsive">
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    <TableCell key={i} className={`thead-cell`}
                                        style={{ minWidth: "50px" }}>
                                        <div className={`dashbord-cell ${(name !== "User Name") && "action-div-class"}`}>
                                            {(name === "Direct") && <img
                                                src="../images/dashboard/Directvisit.svg"
                                                alt="schedule-meet-icon"
                                                title="Schedule Visit"
                                                className="fa-handshake"
                                            />}
                                            {(name === "Scheduled") && <img
                                                src="../images/dashboard/Scheduled.svg"
                                                alt="direct-meet-icon"
                                                title="Direct Visit"
                                                className="fa-handshake"
                                            />}
                                            {(name === "My Visit") && <img
                                                src="../images/dashboard/Myvisit.svg"
                                                alt="direct-meet-icon"
                                                title="Direct Visit"
                                                className="fa-handshake"
                                            />}
                                            &nbsp;{name}
                                        </div>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody className='custom-table-body'>
                            {props.dBMeetingDetails && props.dBMeetingDetails.map((data, i) => (
                                <TableRow align="center" key={i}>
                                    <TableCell align="left">
                                        {data?.fullName}
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            data?.directVisitMeetings
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            data?.scheduleMeetings
                                        }
                                    </TableCell>
                                    <TableCell align="center">
                                        {data?.myVisitMeetings}
                                    </TableCell>
                                    <TableCell align="center">
                                        {data?.totalMeeting}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default DashboardTable;
