import React, { useState, useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import "../../styles/CommonStyle.scss";
import "../../styles/Tracker.scss";
import { baseURL } from "../BaseUrl";
import profileimg from "../../images/profile-1.png";
import GoogleMapComp from "../GoogleMapComp";
import { Col, Row } from "react-bootstrap";

function TrackuserRoute(props) {
  const [meetingRouteDetails, setMeetingRouteDetails] = useState([]);

  const routePositions = [
    { lat: 15.3509769, lng: 75.1406829 },
    { lat: 15.3563233, lng: 75.1430117 },
    { lat: 15.3563517, lng: 75.1431867 },
    { lat: 15.3647073, lng: 75.1478713 },
    { lat: 15.3648516, lng: 75.1480492 },
  ];

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const [routelatLan, setRouteLatLan] = useState({
    lat: null,
    lng: null,
  });

  const [startLatLan, setStartLatLan] = useState({
    lat: null,
    lng: null,
  });

  const [endLatLan, setEndLatLan] = useState({
    lat: null,
    lng: null,
  });

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  useEffect(() => {
    fetchMeetingsRoute();
  }, []);

  const fetchMeetingsRoute = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Activity/GetMeetingActivities/${props.meetingId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("MEETING-ROUTE-RESPONSE::", res.result);
          setMeetingRouteDetails(res.result);
          setRouteLatLan({
            lat: parseFloat(
              res.result.mapCordinates[0].cordLoc.latitude ?? null
            ),
            lng: parseFloat(
              res.result.mapCordinates[1].cordLoc.longitude ?? null
            ),
          });
          if (
            res.result.mapCordinates[0].startPoint.trim() === "Yes" &&
            res.result.mapCordinates[0].endPoint.trim() === "No"
          ) {
            setStartLatLan({
              lat: parseFloat(
                res.result.mapCordinates[0].cordLoc.latitude ?? null
              ),
              lng: parseFloat(
                res.result.mapCordinates[0].cordLoc.longitude ?? null
              ),
            });
          }
          if (
            res.result.mapCordinates[1].startPoint.trim() == "No" &&
            res.result.mapCordinates[1].endPoint.trim() == "Yes"
          ) {
            setEndLatLan({
              lat: parseFloat(
                res.result.mapCordinates[1].cordLoc.latitude ?? null
              ),
              lng: parseFloat(
                res.result.mapCordinates[1].cordLoc.longitude ?? null
              ),
            });
          } else {
            setStartLatLan({ lat: "", lng: "" });
            setEndLatLan({ lat: "", lng: "" });
          }
        } else {
          setMeetingRouteDetails("");
        }
      });
  };

  return (
    <div id="main-page">
      <>
        {/* MAIN BODY START */}
        <div className="page-wrapper">
          {/* PAGE CONTENT START */}
          {props.viewonuserroute ? (
            <div className={`main-body ${props.toggleshift.style}`}>
              <div className="page-content">
              <div className="user-table">
              <Row className='search-row'>
                <Col md={3} className='cat-col'>
                  <div className='page-header-text-div'>
                    <div className="back-btn" onClick={props.goBacktomeetingsdetails}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                    <h6 className='page-header-text' style={{ color: "#04a96d" }}>TRACK USER ROUTE</h6>
                  </div>
                </Col>
                <Col md={9} className='cat-col'>
                  <div className="search-bar-header" style={{ float: "right" }}>
                    
                  </div>
                </Col>
              </Row>
              </div>
                {/* TRACKER TABLE START */}
                <div className="tracker-table">
                  
                  <div className="table-responsive tracker-tabel-scroll">
                    <table className="table trackers-list-table">
                      <thead className="thead-light trackers-list-thead">
                        <tr>
                          <th>NAME</th>
                          <th>MOBILE</th>
                          <th>DATE</th>
                          <th>KMS. TRAVELLED</th>
                          <th>ACTIONS</th>
                        </tr>
                      </thead>

                      <tbody>
                        {meetingRouteDetails !== "" ? (
                          <tr
                            key={meetingRouteDetails?.meetingID}
                            className="track-list-row-data"
                          >
                            <td>
                              <span className="track-list-name d-flex">
                                <img
                                  className="table-data-track-imgages"
                                  src={profileimg}
                                  alt="John Doe"
                                />
                                <h6>{meetingRouteDetails?.fullName}</h6>
                              </span>
                            </td>
                            <td>{meetingRouteDetails?.mobile}</td>
                            <td>
                              {meetingRouteDetails?.mapCordinates?.length > 0
                                ? meetingRouteDetails?.mapCordinates[0]
                                    ?.cordDate
                                : ""}
                            </td>
                            <td>{meetingRouteDetails?.distanceTravelled}</td>
                            <td>
                              <span
                                className="td-print-column d-flex"
                                onClick={handlePrint}
                              >
                                <i className="bi bi-printer" />
                                Print
                              </span>
                            </td>
                          </tr>
                        ) : (
                          <tr>-----NO DATA FOUND-----</tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* TRACKER TABLE END */}
                <div className="trakuser-route-map">
                  <div className="row view-on-map">
                    {/* {alert(sessionStorage.getItem("distanceMatrixID"))} */}
                    {sessionStorage.getItem("distanceMatrixID") == 1 &&
                    startLatLan.lat &&
                    startLatLan.lng &&
                    endLatLan.lat &&
                    endLatLan.lng ? (
                      <div className="col-md-12 track-user-google-map">
                        <div className="google-map-box" ref={componentRef}>
                          <GoogleMapComp
                            routelatLan={routelatLan}
                            startLatLan={startLatLan}
                            endLatLan={endLatLan}
                            pageType="TrackuserRoute"
                            routePositions={routePositions}
                          />
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>

                {/* PAGE CONTENT END*/}
              </div>
            </div>
          ) : (
            ""
          )}
          {/* MAIN BODY END */}
        </div>
      </>
    </div>
  );
}

export default TrackuserRoute;
