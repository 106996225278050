import { TableCell, TableRow } from '@mui/material';
import React from 'react'

function OnScrollLoading(props) {
    return (
                // <div id="container" className="page-loader">
                //     <svg viewBox="0 0 100 100">
                //         <defs>
                //             <filter id="shadow">
                //                 <feDropShadow dx="0" dy="0" stdDeviation="1.5"
                //                     flood-color="#fc6767" />
                //             </filter>
                //         </defs>
                //         <circle id="spinner" cx="50" cy="50" r="45" />
                //     </svg>
                // </div>
                <div id="preloader">
                    <div id="loading-wrapper" class="show">
                        <div id="loading-text">
                            <img src="../../images/DefaultImages/loading-logo.svg" alt="salesbee-logo" />
                        </div>
                        <div id="loading-content"></div>
                    </div>
                </div>
    )
}

export default OnScrollLoading;

export const handleScroll = (event, clientComplaints, totalCount, setLoading, setPageSize, PageSize) => {
    if(clientComplaints){
        if (clientComplaints.length == totalCount) {
            event.preventDefault();
        } else {
            const element = event.target;
            // Do something when the element is scrolled
    
            const isCloseToEnd = element.scrollTop + element.clientHeight >= element.scrollHeight - 1;
            if (!isCloseToEnd) {
                event.preventDefault();
            } else {
                if (isCloseToEnd) {
                    event.preventDefault();
                    // console.log(props.PageSize + 10)
                    if (clientComplaints.length < totalCount) {
                        event.preventDefault();
                        setLoading(true);
                        setPageSize(PageSize + 10)
                    }
                }
            }
        }
    }

};
