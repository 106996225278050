import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/TrackClients.scss";
import { Col, Form, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { baseURL } from "../../BaseUrl";
// import { travelizeAuth } from "../HeaderAuthentication";
import NoDataImg from "../../../images/no-data-found.gif";
import GoogleMapComp from "../../GoogleMapComp";
import Select from "react-select";
import { optionsDistanceList } from "../../DropdownsData";
import AccessDenied from "../../../AccessDenied";
import SbCancelBtn from "../../SbButtons/SbCancelBtn";

function TrackClients() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [userList, setUserList] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [searchval, setSearchval] = useState("");
  const [distance, setDistance] = useState(1);
  const [trackClientDetails, setTrackClientDetails] = useState([]);
  const [clientCordinates, setClientCordinates] = useState([]);
  const [clientsCount, setClientsCount] = useState(0);
  const [recentLocation, setRecentLocation] = useState({
    lat: null,
    lng: null,
  });
  const [baseLocation, setBaseLocation] = useState({
    lat: null,
    lng: null,
    location: null,
  });

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("USER-DROPDOWN::", res.result);
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      });
  };

  useEffect(() => {
    searchClient();
  }, [filterUserId, distance]);

  const searchClient = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Client/GetNearByClientMap?UserId=${filterUserId}&Radius=${distance}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          // console.log("TRACK-CLIENT-RES::", res.result);
          setTrackClientDetails(res.result);
          setClientsCount(res.recordCount - 2);
        } else {
          setTrackClientDetails("");
        }
      });
  };

  useEffect(() => {
    if (trackClientDetails) {
      let clientCoordinate = [];
      // let coordinates = trackClientDetails.mapCoordinate;
      for (var i = 0; i < trackClientDetails.length; i++) {
        if (trackClientDetails[i].clientName.trim() === "RecentLocation") {
          setRecentLocation({
            lat: JSON.parse(trackClientDetails[i].clientLoc.latitude),
            lng: JSON.parse(trackClientDetails[i].clientLoc.longitude),
          });
        } else if (trackClientDetails[i].clientName.trim() === "BaseLocation") {
          setBaseLocation({
            lat: JSON.parse(trackClientDetails[i].clientLoc.latitude),
            lng: JSON.parse(trackClientDetails[i].clientLoc.longitude),
            location: trackClientDetails[i].clientLoc.location,
          });
        } else if (trackClientDetails[i].addedbyUserFullName.trim() != "NA") {
          clientCoordinate.push({
            lat: JSON.parse(trackClientDetails[i].clientLoc.latitude),
            lng: JSON.parse(trackClientDetails[i].clientLoc.longitude),
            location: trackClientDetails[i].clientLoc.location,
            clientName: trackClientDetails[i].clientName,
            distnace: trackClientDetails[i].distance,
            contactPerson: trackClientDetails[i].contactPersonName,
          });
        }
      }
      setClientCordinates(clientCoordinate);
    }
  }, [filterUserId, distance, trackClientDetails]);

  const clearSerachBySelect = () => {
    setFilterUserId("");
    setDistance(1);
    setClientsCount(0);
  };

  // Page navigation
  let navigate = useNavigate();
  const goToPage = () => {
    navigate("/dashboard");
  };
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation


  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}
          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>

              {/* page Breadcome */}
              <div className='page-header-div'>
                <p ><span className='breadcome-link' style={{ color: "#04a96d" }} onClick={goToPage} >Dashboard</span>&nbsp;&nbsp;<i className='fa fa-angle-right'></i>&nbsp;&nbsp;<span className='breadcome-link' onClick={goToSettingPage}>Settings</span>&nbsp;&nbsp;<i className='fa fa-angle-right'></i>&nbsp;&nbsp;<span className='breadcome-text'>Track Client</span></p>
              </div>
              {/* page Breadcome */}

              <div className="page-content">
                {/* Filter and Header Section */}
                <div className="user-table">
                  <Row className='search-row'>
                    <Col md={2} className='cat-col'>
                      <div className='page-header-text-div'>
                        <h6 className='page-header-text' style={{ color: "#04a96d" }}> TRACK CLIENT </h6>
                      </div>
                    </Col>
                    <Col md={3} className="cat-col">
                      <span className="clients-count">
                        <h6 className="client-count-head">
                          CLIENTS COUNT : {clientsCount}
                        </h6>
                      </span>
                    </Col>
                    <Col md={7} className="cat-col">
                      <div
                        className="search-bar-header"
                        style={{ float: "right" }}
                      >
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div
                            className="header-filter-btn"
                            onClick={() => setFilter(!filter)}
                          >
                            <p className="card-head">
                              <i className="bi bi-sliders" />
                              &nbsp;&nbsp;Filters
                            </p>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {filter && (
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={6}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  options={userList}
                                  placeholder="Select User"
                                  value={
                                    filterUserId === "" ? "" : userList.value
                                  }
                                  onInputChange={userList.label}
                                  onChange={(data) =>
                                    setFilterUserId(data.value)
                                  }
                                  isSearchable={true}
                                  // isClearable={true}
                                />
                              </div>
                              <div
                                style={{ width: "100%", padding: "0px 5px" }}
                              >
                                <Select
                                  className="react-select-container-list"
                                  classNamePrefix="react-select-list"
                                  // defaultValue={optionsRoleList[0].label}
                                  options={optionsDistanceList}
                                  placeholder="Select Distance"
                                  value={
                                    distance === 1
                                      ? ""
                                      : optionsDistanceList.value
                                  }
                                  onInputChange={optionsDistanceList.label}
                                  onChange={(data) => setDistance(data.value)}
                                  isSearchable={true}
                                  // isClearable={true}
                                />
                              </div>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <SbCancelBtn
                                onClickEffect={clearSerachBySelect}
                                btnName="Clear"
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  )}
                </div>
                {/* Filter and Header Section */}
                {/* TRACKER TABLE END */}
                {trackClientDetails !== "" && trackClientDetails !== null ? (
                  <div className="row track-client view-on-map">
                    <div className="col-md-9 track-client-page-google-map">
                      <div className="google-map-box">
                        <GoogleMapComp
                          pageType="TrackClients"
                          recentLocation={recentLocation}
                          clientCordinates={clientCordinates}
                          baseLocation={baseLocation}
                        />
                      </div>
                    </div>
                    <div className="col-md-3 track-client-page-client-visits">
                      <div className="input-group mt-1 client-track-search">
                        <input
                          type="search"
                          placeholder="Search"
                          className="form-control search"
                          onChange={(e) => setSearchval(e.target.value)}
                        />
                        <i className="fa fa-search" />
                      </div>
                      <div className="track-client-meet-detail-scroll">
                        {trackClientDetails
                          .filter((cl) =>
                            cl.clientName.toLowerCase().includes(searchval)
                          )
                          .map((data) => (
                            <div className="card view-on-map-card">
                              <div className="card-body track-client-meeting-address">
                                <span className="track-client-meeting-detail-name d-flex">
                                  <i className="bi bi-caret-right-fill" />
                                  <span className="meeting-track-client-user-detail">
                                    <h6>
                                      <b>Name :</b> {data?.clientName}
                                    </h6>
                                  </span>
                                </span>
                                <p>
                                  <b>Location :</b> {data?.clientLoc?.location}
                                </p>
                                <p>
                                  <b>Distance :</b> {data?.distance}
                                </p>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <img
                    src={NoDataImg}
                    className="mCS_img_loaded track-clients"
                    style={{ width: "98%" }}
                  />
                )}
                {/* PAGE CONTENT END*/}
                <div className="track-client-page-download-btn">
                  <button
                    type="button"
                    className="btn btn-download-excel"
                    // onClick={exportExcel}
                  >
                    <i className="fa fa-download" /> &nbsp; Download
                  </button>
                </div>
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default TrackClients;
