import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Attendance.scss";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import moment from "moment";
import ReactPaginate from "react-paginate";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { optionsPageSizeList } from "../DropdownsData";
import {
  SkeletonStdTableBody,
  SkeletonStdTableHead,
} from "../Skeleton Loading/SkeletonStdTable";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import { useNavigate } from "react-router-dom";
import { Col, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import SbDropDownBtn from "../SbButtons/SbDropDownBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { loadPageBreadCome } from "../../redux/action";
import { useDispatch } from "react-redux";
import OnScrollLoading, { handleScroll } from '../OnScrollLoading';

function AttendanceMonthly() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  /* HEADER AUTH END */
  let dispatch = useDispatch();
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [filterUserId, setFilterUserId] = useState("");
  const [userList, setUserList] = useState([]);
  const [monthList, setMonthList] = useState([]);
  const [days, setDays] = useState([]);
  const [attendanceMonthly, setAttendanceMonthly] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalData, setTotalData] = useState(0);
  const [pageDataSize, setPageDataSize] = useState("");

  const handlePageClick = (data) => {
    setCurrentPage(data?.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  //* PAGINATION END *//

  //* SET MONTH AND YEAR START *//
  const current = new Date();
  const currentYear = current.getFullYear();
  const currentMonth = current.getMonth() + 1;
  const newMonth = new Date().toLocaleString("en-us", {
    month: "short",
    year: "numeric",
  });

  const [month, setMonth] = useState(currentMonth);
  const [year, setYear] = useState(currentYear);
  const [monthYear, setMonthYear] = useState(newMonth);
  const [currentMonthYear, setCurrentMonthYear] = useState("");
  //* SET MONTH AND YEAR END *//

  //* GET DAY MONTH START *//
  useEffect(() => {
    getDaysByMonth();
  }, [monthYear]);

  const getDaysByMonth = () => {
    const nDate = new Date(monthYear);
    const nMonth = nDate.getMonth() + 1;
    const nYear = nDate.getFullYear();
    setMonth(nMonth);
    setYear(nYear);
  };
  //* GET DAY MONTH START *//

  //* FETCH DROPDOWN DATA START *//
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data?.userId, label: data?.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    fetch(`${baseURL}/api/Attendance/BindMonthYearDropDown/0`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setMonthList(
            res.result.map((data) => {
              return { value: data, label: data };
            })
          );
        } else {
          setMonthList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH DROPDOWN DATA END *//

  //* FETCH DAYS BY MONTH START *//
  useEffect(() => {
    fetchDaysByMonth();
  }, [month, year]);

  const fetchDaysByMonth = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Attendance/GetAllDatesOfMonth?Month=${month}&Year=${year}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDays(res?.result);
          setIsLoading(false);
          setLoading(false);
        } else {
          setDays("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH DAYS BY MONTH END *//

  //* FETCH ATTENDANCE DATA START *//
  useEffect(() => {
    let breadCome = { mainpage: "Complaints" }
    dispatch(loadPageBreadCome(breadCome))
    fetchAttendanceMonthly();
  }, [PageSize, currentPage, month, year, filterUserId]);

  const fetchAttendanceMonthly = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Attendance/GetMonthlyAttendance?CurrentPage=${currentPage}&PageSize=${PageSize}&userId=${filterUserId}&Month=${month}&Year=${year}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setAttendanceMonthly(res?.result);
          setPageDataSize(res?.result?.length);
          setTotalData(res?.recordCount[0]);
          setPageCount(Math.ceil(res?.recordCount[0] / PageSize));
          setIsLoading(false);
          setLoading(false);
        } else {
          setAttendanceMonthly("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH ATTENDANCE DATA END *//

  //* DEFAULT SELECT SET START *//
  useEffect(() => {
    if (monthYear !== null && monthYear !== "") {
      monthList?.map((mntyr) => {
        if (monthYear == mntyr?.value) {
          setCurrentMonthYear(mntyr);
        }
      });
    }
  }, [monthYear, monthList]);
  //* DEFAULT SELECT SET START *//

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setMonthYear(newMonth);
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportExcelAttendance = () => {
    exportExcel(
      `${baseURL}/api/Export/MonthlyAttendanceExcelExport?UserId=${filterUserId}&Month=${month}&Year=${year}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      `MonthlyAttendance-${filterUserId}-M-${month}-Y-${year}.xlsx`
    );
  };
  //* EXPORT FILE END *//

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            <>
              <Sidenavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />

              <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />

              {/* MAIN BODY START */}
              <div className="page-wrapper">
                {/* PAGE CONTENT START */}
                <div className={`main-body ${toggleshift.style}`}>

                  <div className="page-content">

                    {/* Filter and Header Section */}
                    <div className="user-table">
                        <Row className='search-row'>
                          <Col md={3} className='cat-col'>
                            <div className='page-header-text-div'>
                            <div className="back-btn" onClick={goToSettingPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                              <h6 className='page-header-text' style={{ color: "#04a96d" }}>ATTENDANCE-MONTHLY</h6>
                            </div>
                          </Col>
                          <Col md={9} className='cat-col'>
                            <div className="search-bar-header" style={{ float: "right" }}>
                              <div className="page-top-bar-dropdowns page-top-Filters">
                                <div className="header-filter-btn" onClick={() => setFilter(!filter)}>
                                  <p className="card-head">
                                    <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                                  </p>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>

                        {filter &&
                          <div className="page-filter-section">
                            <div style={{ width: "100%" }}>
                              <Row>
                                <Col md={6}>
                                  <div className="clients-filter-input-boxs filter-box-div">
                                    <div style={{width: "100%", padding: "0px 5px"}}>
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        options={userList}
                                        placeholder="Select User"
                                        value={filterUserId === "" ? "" : userList.value}
                                        onInputChange={userList.label}
                                        onChange={(data) => setFilterUserId(data.value)}
                                        isSearchable={true}
                                      />
                                    </div>
                                    <div style={{width: "100%", padding: "0px 5px"}}>
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        defaultValue={monthList[null]}
                                        options={monthList}
                                        placeholder="Select Month"
                                        value={currentMonthYear}
                                        onInputChange={monthList.label}
                                        onChange={(data) => setMonthYear(data.value)}
                                        isSearchable={true}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col md={2}>
                                  <div className="clients-filter-input-boxs filter-box-div">
                                    <SbCancelBtn onClickEffect={clearSerachBySelect}
                                      btnName="Clear" />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        }
                      </div>
                      {/* Filter and Header Section */}

                    {/* USER TABLE START */}
                    <div className="attendance-table">
                      <div className="table-responsive attendance-monthly-scroll"
                      onScroll={(e) => handleScroll(e, attendanceMonthly, totalData, setLoading, setPageSize, PageSize)}
                      >
                        <table className="table attendance-list-table">
                          <thead className="thead-light attendance-list-thead">
                            {isLoading ? (
                              <SkeletonStdTableHead />
                            ) : (
                              <tr>
                                <th>NAME</th>
                                {days !== "" ? (
                                  days?.map((day, i) => (
                                    <th key={i}>{day?.onlyDate}</th>
                                  ))
                                ) : (
                                  <th>Please refresh the page</th>
                                )}
                              </tr>
                            )}
                          </thead>
                          <tbody>
                            {isLoading ? (
                              <>
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                                <SkeletonStdTableBody />
                              </>
                            ) : attendanceMonthly !== "" &&
                              attendanceMonthly !== null ? (
                              attendanceMonthly.map((data) => (
                                <>
                                  <tr
                                    key={data?.RowNumber}
                                    className="attendance-list-row-data"
                                  >
                                    <td>{data?.UserFullName}</td>
                                    {days.map((day, i) => (
                                      <React.Fragment key={i}>
                                        <td>
                                          {data[day?.onlyDate] !== "-" ? (
                                            <div>
                                              {/* {data[`${day?.onlyDate}`]} <br /> */}
                                              <div>
                                              {data[`${day?.onlyDate}`].split('-').map((time, i) => (
                                                <div key={i}>{time}</div>
                                              ))}
                                              </div>
                                              {`${day?.onlyDate}_Leave` !==
                                                "-" &&
                                                data[day?.onlyDate] !== "Sun" && (
                                                <strong
                                                  className={
                                                    data[
                                                      `${day?.onlyDate}_Leave`
                                                    ] == "HDLR" ||
                                                      data[
                                                      `${day?.onlyDate}_Leave`
                                                      ] == "FDLR" || data[`${day?.onlyDate}_Leave`] ==
                                                      "Applied"
                                                      ? "leave-type-request"
                                                      : "leave-type-approved"
                                                  }
                                                >
                                                  {
                                                    data[
                                                    `${day?.onlyDate}_Leave`
                                                    ]
                                                  }
                                                </strong>
                                              ) }
                                            </div>
                                          ) : data[`${day?.onlyDate}_Leave`] ==
                                            "HDLR" ||
                                            data[`${day?.onlyDate}_Leave`] ==
                                            "FDLR" || data[`${day?.onlyDate}_Leave`] ==
                                            "Applied" ? (
                                            <span className="leave-type-request">
                                              <strong>
                                                {data[`${day?.onlyDate}_Leave`]}
                                              </strong>
                                            </span>
                                          ) : data[`${day?.onlyDate}_Leave`] ==
                                            "HDLA" ||
                                            data[`${day?.onlyDate}_Leave`] ==
                                            "FDLA" || data[`${day?.onlyDate}_Leave`] ==
                                            "Applied" ? (
                                            <span className="leave-type-approved">
                                              <strong>
                                                {data[`${day?.onlyDate}_Leave`]}
                                              </strong>
                                            </span>
                                          ) :
                                          data[`${day?.onlyDate}_HolidayName`] !== "-" ?
                                            <OverlayTrigger
                                              placement="bottom"
                                              delay={{ show: 250, hide: 300 }}
                                              overlay={
                                                <Tooltip id={`tooltip-remarks`} >
                                                  {data[`${day?.onlyDate}_HolidayName`]}
                                                </Tooltip>
                                              }
                                            >
                                              <span
                                                className="status-badge complete-status"
                                              >
                                                H
                                              </span>
                                            </OverlayTrigger>
                                            : (
                                            <span>
                                              {data[`${day?.onlyDate}`]}
                                            </span>
                                          )}
                                        </td>
                                      </React.Fragment>
                                    ))}
                                  </tr>
                                </>
                              ))
                            ) : (
                              <tr className="no-data-found">
                                ------ NO DATA FOUND ------
                              </tr>
                            )
                            }
                             {loading &&  
                              <OnScrollLoading />
                            }
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* USER TABLE END */}

                    {/* PAGINATION START */}
                    <div className="download-and-pagination">
                      <div className="data-count-per-page">
                        {pageDataFrom} - {pageDataTill} of {totalData}
                      </div>
                      <button
                        type="button"
                        className="btn btn-download-excel"
                        onClick={exportExcelAttendance}
                      >
                        <i className="fa fa-download" /> &nbsp; Download
                      </button>
                      <span className="user-pagination">
                        <ReactPaginate
                          breakLabel="..."
                          nextLabel={<i className="fa fa-angle-double-right" />}
                          onPageChange={handlePageClick}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={2}
                          pageCount={pageCount}
                          previousLabel={
                            <i className="fa fa-angle-double-left" />
                          }
                          renderOnZeroPageCount={null}
                          containerClassName={"pagination"}
                          subContainerClassName={"pages pagination"}
                          activeClassName={"active"}
                          disabledClassName={"disabled"}
                        />
                      </span>
                    </div>
                    {/* PAGINATION END */}

                    {/* PAGE CONTENT END*/}
                  </div>
                </div>

                {/* MAIN BODY END */}
              </div>
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default AttendanceMonthly;
