import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/Clients.scss";
import GoogleMapAddressModel from "../GoogleMapAddressModel";
import * as HeaderAuthentication from "../HeaderAuthentication";
import Select from "react-select";
import Geocode from "react-geocode";
import DefaultLogo from "../../images/defalut-logo.png";
import { React_Google_API_Key } from "../../GoogleApiKey";
import { LoadScript } from "@react-google-maps/api";
import AlertModel from "../AlertModel";
import { useDispatch, useSelector } from "react-redux";
import {
  loadCityList,
  loadClientCategoryDrop,
  loadClientStageGrop,
  loadPostCompanyData,
  loadStateList,
  loadUpdateCompanyData,
  loadUploadSingleImage,
  loadZoneList,
} from "../../redux/action";
import { useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { baseURL } from "../BaseUrl";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { FloatingLabel } from "react-bootstrap";
Geocode.setApiKey(React_Google_API_Key);

function AddClientModel(props) {
  let editClientId = props.editClientId;
  const hiddenFileInput = useRef(null);

  let dispatch = useDispatch();
  const { singleClient } = useSelector((state) => state.data);

  const [imgFile, setImgFile] = useState("");
  const [imgFileName, setImgFileName] = useState("");

  const [selectedCategory, setSelectedCategory] = useState({
    value: 0,
    label: "",
  });
  const [selectedStage, setSelectedStage] = useState({ value: 0, label: "" });
  const [country, setCountry] = useState({ value: 0, label: "" });
  const [zoneId, setZoneId] = useState({ value: 0, label: "" });
  const [countryList, setCountryList] = useState([]);
  const [statusFlag, setStatusFlag] = useState(true);

  let cntyList = [{ value: 1, label: "India" }];

  useEffect(() => {
    dispatch(loadClientCategoryDrop());
    dispatch(loadClientStageGrop());
    props.setIsLoading(true);
    dispatch(loadZoneList(props.setIsLoading));
    setCountryList(cntyList);
    setLocation("");
  }, []);

  const { categoryDropdown } = useSelector((state) => state.data);
  const { stageDropdown } = useSelector((state) => state.data);
  const { zoneList } = useSelector((state) => state.data);
  const { stateList } = useSelector((state) => state.data);
  const { cityList } = useSelector((state) => state.data);

  useEffect(() => {
    if (editClientId) {
      if (singleClient) {
        if (
          singleClient.countryID !== undefined &&
          singleClient.countryID !== 0
        ) {
          setCountry({ value: 1, label: "India" });
        }
        if (singleClient) {
          setClientData(singleClient);
          if (singleClient.enabledStatus == 1) {
            setStatusFlag(true);
          } else {
            setStatusFlag(false);
          }
          setLatLan({
            lat: Number(singleClient?.clientLoc?.latitude),
            lng: Number(singleClient?.clientLoc?.longitude),
          });
          setLocation(singleClient?.clientLoc?.location);
          setAddress(singleClient?.clientLoc?.location);
        }

        let categoryArray = [...categoryDropdown];
        categoryArray.map((category) => {
          if (category.value === singleClient.categoryID) {
            setSelectedCategory(category);
          }
        });
        let stageArray = [...stageDropdown];
        stageArray.map((stage) => {
          if (stage.value === singleClient.stageID) {
            setSelectedStage(stage);
          }
        });

        let zoneArray = [...zoneList];

        zoneArray.map((zone) => {
          if (zone.value === singleClient.zoneID) {
            setZoneId(zone);
            dispatch(loadStateList(zone.value));
          }
        });
      }
    }
  }, [editClientId, singleClient, zoneList]);

  const [stateId, setStateId] = useState({ value: 0, label: "" });

  useEffect(() => {
    if (singleClient) {
      if (stateList) {
        stateList.map((state) => {
          if (state.value === singleClient.stateID) {
            setStateId(state);
            dispatch(loadCityList(state.value));
          }
        });
      }
    }
  }, [stateList]);

  const [cityId, setCityId] = useState({ value: 0, label: "" });

  useEffect(() => {
    if (singleClient) {
      if (cityList) {
        cityList.map((city) => {
          if (city.value === singleClient.cityID) {
            setCityId(city);
          }
        });
      }
    }
  }, [cityList]);

  const [clientName, setClientName] = useState("");
  const [contPersonName, setContPersonName] = useState("");
  const [mobileNum, setMobileNum] = useState("");
  const [email, setEmail] = useState("");
  const [landLine, setLandLine] = useState("");
  const [address, setAddress] = useState("");
  const [enableStatus, setEnableStatus] = useState(1);
  const [categoryId, setCategoryId] = useState(0);
  const [category, setCategory] = useState("");

  const [stageId, setStageId] = useState(0);
  const [stage, setStage] = useState("");

  const [pinCode, setPinCode] = useState("");
  const [location, setLocation] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [googleMapsModel, setGoogleMapsModel] = useState(false);
  const [latLan, setLatLan] = useState({
    lat: null,
    lng: null,
  });

  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState({
    alertType: "",
    message: "",
  });
  const [fieldReq, setFieldReq] = useState(false);

  useEffect(() => {
    let list = { ...clientLocation };
    if (latLan.lat == null && latLan.lng == null) {
      getLocation();
    } else {
      list.latitude = JSON.stringify(latLan.lat);
      list.longitude = JSON.stringify(latLan.lng);
    }

    if (location) {
      list.location = location;
    }

    clientData.clientLoc = list;
  }, [latLan, location]);

  const getLocation = () => {
    if (!navigator.geolocation) {
      alert("Geolocation is not supported for your browser");
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        setLatLan({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    }
  };

  const closeAlertModel = () => {
    setShowAlert(!showAlert);
  };

  const openGoogleMaps = () => {
    setGoogleMapsModel(!googleMapsModel);
    setAddress("");
  };

  const [localImage, setLocalImage] = useState("");

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);
    setImgFileName(event.target.files[0].name);

    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  // set state for post client data
  const [clientLocation, setClientLocation] = useState({
    latitude: "",
    longitude: "",
    location: "",
  });

  const [clientData, setClientData] = useState({
    clientName: "",
    logo: "",
    mobile: "",
    clientLoc: clientLocation,
    email: "",
    contactPersonName: "",
    landline: "",
    countryID: "",
    zoneID: "",
    stateID: "",
    cityID: "",
    pincode: "",
    categoryID: "",
    clientCategoryName: "",
    stageID: "",
    clientStageName: "",
    enabledStatus: 1,
  });
  // set state for post client data

  // Onchange Event
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      const re = /^[0-9\b]+$/;
      if (e.target.value === "" || re.test(value)) {
        setClientData({ ...clientData, [name]: parseInt(value) });
      } else {
        e.preventDefault();
      }
      
    } else {
      setClientData({ ...clientData, [name]: value });
    }
  };

  const habdleEnableStatus = (e) => {
    if (e === 1) {
      setStatusFlag(true);
      clientData.enabledStatus = 1;
    } else if (e === 0) {
      setStatusFlag(false);
      clientData.enabledStatus = 0;
    }
  };
  // Onchange Event
  console.log(clientData, "clientData");

  // handle select
  const handleSelectChange = (data, type) => {
    if (type === "category") {
      setSelectedCategory(data);
      clientData.categoryID = data.value;
      clientData.clientCategoryName = data.label;
    } else if (type === "stage") {
      setSelectedStage(data);
      clientData.stageID = data.value;
      clientData.clientStageName = data.label;
    } else if (type === "country") {
      setCountry(data);
      clientData.countryID = data.value;
    } else if (type === "zone") {
      setZoneId(data);
      dispatch(loadStateList(data.value));
      clientData.zoneID = data.value;
    } else if (type === "state") {
      setStateId(data);
      clientData.stateID = data.value;
      dispatch(loadCityList(data.value));
    } else if (type === "city") {
      setCityId(data);
      clientData.cityID = data.value;
    }
  };
  // handle select

  const closeModal = () => {
    props.handleClose();
    props.fetchClientData();
    props.setEditClientId("");
    setLocation("");
  };

  //  ADDING CLIENT START
  const addClientDetails = (e) => {
    e.preventDefault();

    if (
      // imgFile !== "" &&
      clientData.clientName !== "" &&
      clientData.contactPersonName !== "" &&
      Math.abs(clientData?.mobile).toString().length === 10 &&
      clientData.categoryID &&
      clientData.stageID &&
      location !== "" &&
      clientData.countryID &&
      clientData.zoneID &&
      clientData.stateID
    ) {
      const formData = new FormData();
      formData.append("Files", imgFile);
      formData.append("Directory", "Company");
      formData.append("FileNamePrefix", clientData.clientName);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: HeaderAuthentication.salesbeeImgAuth,
        body: formData,
      };
      let actionType;
      if (editClientId) {
        actionType = "update-data";
      } else {
        actionType = "post-data";
      }

      if (clientData.city) {
        delete clientData.city;
      }
      if (imgFile !== "") {
        dispatch(
          loadUploadSingleImage(
            requestOption,
            clientData,
            closeModal,
            actionType
          )
        );
      } else {
        const clientDetails = {
          client: clientData,
        };
        let requestOption = {
          method: "POST",
          mode: "cors",
          headers: HeaderAuthentication.postTravelizeAuth,
          body: JSON.stringify(clientDetails),
        };
        if (editClientId) {
          dispatch(loadUpdateCompanyData(requestOption, closeModal));
        } else {
          dispatch(loadPostCompanyData(requestOption, closeModal));
        }
      }
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required...!",
      });
    }
  };
  //  ADDING CLIENT END

  // GOOGLE MAPS LOCATION START

  useEffect(() => {
    if (searchVal) {
      Geocode.fromAddress(searchVal).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;

          setLatLan({
            lat: lat,
            lng: lng,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [searchVal]);

  const onMarkerDragEnd = (e) => {
    setLatLan({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  };

  const searchLocation = (event) => {
    setSearchVal(event.target.value);
  };

  useEffect(() => {
    if (latLan.lat !== null && latLan.lat !== "") {
      Geocode.fromLatLng(latLan.lat, latLan.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;

          setAddress(address);
          setLocation(address);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      addressFromGoogle();
    }
  }, [latLan]);

  const addressFromGoogle = () => {
    if (googleMapsModel) {
      openGoogleMaps();
    }
  };

  // GOOGLE MAPS LOCATION END

  return (
    <div>
      <Modal
        show={props.addClientmodelShow}
        onHide={props.handleClose}
        className="main_model add-edit-clients-model"
      >
        <div className="add-edit-client-heading">
          <h6 className="add-edit-client-head">ADD COMPANY</h6>
          <i className="fa fa-times" onClick={closeModal} />
        </div>
        <form onSubmit={addClientDetails}>
          <div className="add-edit-client">
            <div className="company-name">
              {props.isLoading ? (
                <Skeleton />
              ) : (
                <div className="row">
                  <div className="col-md-5 mb-2 d-flex align-items-center">
                    <div className="d-flex">
                      <div className="main-logo-image">
                        {editClientId && singleClient ? (
                          <img
                            className="product-images"
                            src={
                              localImage
                                ? localImage
                                : singleClient.logo
                                ? `${baseURL}/${singleClient.logo}`
                                : DefaultLogo
                            }
                            alt=""
                          />
                        ) : (
                          <img
                            className="product-images"
                            src={localImage ? localImage : DefaultLogo}
                            alt="Product Images"
                          />
                        )}
                        <div>
                          <button
                            type="button"
                            className="btn upload-logo"
                            onClick={uploadClick}
                          >
                            <img
                              src="../images/uploadImage/camera.svg"
                              alt=""
                            />
                          </button>
                          <input
                            id="getFile"
                            type="file"
                            name="filetobase64"
                            accept="image/*"
                            className="btn-primary upload"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 ">
                    <div className="mb-3">
                       <FloatingLabel label='Company Name' className={`${clientData.clientName || "" ? "float-input-visible" : "float-hidden float-input"}`}>
                      <input
                        type="text"
                        className="form-control form add-client-input"
                        id="exampleFormControlInput1"
                        placeholder="Company Name"
                        name="clientName"
                        value={clientData?.clientName || ""}
                        onChange={handleInputChange}
                        autoComplete="off"
                      />
                      </FloatingLabel>
                      {fieldReq && clientData?.clientName === "" && (
                        <small
                          id={`Error`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message} <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        </small>
                      )}
                    </div>
                    <div className="mb-3">
                    <FloatingLabel label='Contact Person Name' className={`${clientData.contactPersonName || "" ? "float-input-visible" : "float-hidden float-input"}`}>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control form add-client-input"
                        id="exampleFormControlInput1"
                        placeholder="Contact Person Name"
                        name="contactPersonName"
                        value={clientData?.contactPersonName || ""}
                        onChange={handleInputChange}
                      />
                      </FloatingLabel>
                      {fieldReq && clientData?.contactPersonName === "" && (
                        <small
                          id={`Error`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message} <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        </small>
                      )}
                    </div>

                    <div className="mb-3">
                    <FloatingLabel label='Mobile Number' className={`${clientData.mobile || "" ? "float-input-visible" : "float-hidden float-input"}`}>
                      <input
                        type="text"
                        autoComplete="off"
                        className="form-control form add-client-input"
                        id="exampleFormControlInput1"
                        placeholder="Mobile Number"
                        name="mobile"
                        value={clientData?.mobile || ""}
                        maxLength={10}
                        // onChange={(e) => checkValidation(e)}
                        onChange={handleInputChange}
                      />
                      </FloatingLabel>
                      {fieldReq &&
                      Math.abs(clientData?.mobile).toString().length !== 10 ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {`${alertMessage.message} - 10 Numbers`} <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="mb-3">
                    <FloatingLabel label='Email' className={`${clientData.email || "" ? "float-input-visible" : "float-hidden float-input"}`}>
                      <input
                        type="email"
                        autoComplete="off"
                        className="form-control form add-client-input"
                        id="exampleFormControlInput1"
                        placeholder="Email"
                        name="email"
                        value={clientData?.email || ""}
                        // onChange={(e) => setEmail(e.target.value)}
                        onChange={handleInputChange}
                      />
                      </FloatingLabel>
                      {fieldReq && clientData?.email === "" && (
                        <small
                          id={`Error`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message} <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {props.isLoading ? (
                <Skeleton />
              ) : (
                <div className="row">
                  <div className="col-md-6 mb-3">
                  <FloatingLabel label='Landline' className={`${clientData.landline || "" ? "float-input-visible" : "float-hidden float-input"}`}>
                    <input
                      type="number"
                      autoComplete="off"
                      className="form-control form add-client-input"
                      id="exampleFormControlInput1"
                      placeholder="Landline"
                      name="landline"
                      value={clientData?.landline || ""}
                      // onChange={(e) => setLandLine(e.target.value)}
                      onChange={handleInputChange}
                    />
                    </FloatingLabel>
                  </div>
                  <div className="col-md-6 mb-3">
                  <FloatingLabel label='Select Category' className={`${selectedCategory.value ? "float-visible-select" : "float-hidden float-select"}`}>
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      options={categoryDropdown}
                      placeholder="Select Category"
                      value={
                        selectedCategory.value
                          ? selectedCategory
                          : categoryDropdown.value
                      }
                      onInputChange={categoryDropdown.label}
                      // onChange={(data) => handleCategoryChange(data)}
                      onChange={(data) => handleSelectChange(data, "category")}
                      isSearchable={true}
                    />
                    </FloatingLabel>
                    {fieldReq && (clientData.categoryID === "" || clientData.categoryID === 0) && (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {alertMessage.message} <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      </small>
                    )}
                  </div>
                </div>
              )}
              {props.isLoading ? (
                <Skeleton />
              ) : (
                <div className="row">
                  <div className="col-md-6 mb-3">
                  <FloatingLabel label='Select Stage' className={`${selectedStage.value ? "float-visible-select" : "float-hidden float-select"}`}>
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      options={stageDropdown}
                      placeholder="Select Stage"
                      value={
                        selectedStage.value
                          ? selectedStage
                          : stageDropdown.value
                      }
                      onInputChange={stageDropdown.label}
                      // onChange={(data) => handleStageChange(data)}
                      onChange={(data) => handleSelectChange(data, "stage")}
                      isSearchable={true}
                    />
                    </FloatingLabel>
                    {fieldReq && (clientData.stageID === "" || clientData.stageID === 0) && (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {alertMessage.message} <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      </small>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                  <FloatingLabel label='Select Country' className={`${country.value ? "float-visible-select" : "float-hidden float-select"}`}>
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      options={countryList}
                      placeholder="Select Country"
                      value={country.value ? country : countryList.value}
                      onInputChange={countryList.label}
                      // onChange={(data) => setCountry(data.value)}
                      onChange={(data) => handleSelectChange(data, "country")}
                      isSearchable={true}
                    />
                    </FloatingLabel>
                    {fieldReq && clientData.countryID === "" ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {alertMessage.message} <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
              {props.isLoading ? (
                <Skeleton />
              ) : (
                <div className="row">
                  <div className="col-md-6 mb-3">
                  <FloatingLabel label='Select Zone' className={`${zoneId.value ? "float-visible-select" : "float-hidden float-select"}`}>
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      options={zoneList}
                      placeholder="Select Zone"
                      value={zoneId.value ? zoneId : zoneList.value}
                      onInputChange={zoneList.label}
                      // onChange={(data) => setZoneId(data.value)}
                      onChange={(data) => handleSelectChange(data, "zone")}
                      isSearchable={true}
                    />
                    </FloatingLabel>
                    {fieldReq && clientData.zoneID === "" ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {alertMessage.message} <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 mb-2">
                  <FloatingLabel label='Select State' className={`${stateId.value ? "float-visible-select" : "float-hidden float-select"}`}>
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      options={stateList}
                      placeholder="Select State"
                      value={stateId.value ? stateId : stateList.value}
                      onInputChange={stateList.label}
                      // onChange={(data) => setStateId(data.value)}
                      onChange={(data) => handleSelectChange(data, "state")}
                      isSearchable={true}
                    />
                    </FloatingLabel>
                    {fieldReq && clientData.stateID === "" ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {alertMessage.message} <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
              {props.isLoading ? (
                <Skeleton />
              ) : (
                <div className="row">
                  <div className="col-md-6 mb-2">
                  <FloatingLabel label='Select State' className={`${cityId.value ? "float-visible-select" : "float-hidden float-select"}`}>
                    <Select
                      className="react-select-container-list model-select"
                      classNamePrefix="react-select-list"
                      options={cityList}
                      placeholder="Select City"
                      value={cityId.value ? cityId : cityList.value}
                      onInputChange={cityList.label}
                      // onChange={(data) => setCityId(data.value)}
                      onChange={(data) => handleSelectChange(data, "city")}
                      isSearchable={true}
                      isDisabled={!stateId && true}
                    />
                    </FloatingLabel>
                    {fieldReq && cityId.value === 0 ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {alertMessage.message} <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 mb-2">
                  <FloatingLabel label='PinCode' className={`${clientData.pincode ? "float-input-visible" : "float-hidden float-input"}`}>
                    <input
                      type="number"
                      autoComplete="off"
                      className="form-control form add-client-input"
                      id="exampleFormControlInput1"
                      placeholder="PinCode"
                      name="pincode"
                      value={clientData?.pincode}
                      onChange={handleInputChange}
                    />
                    </FloatingLabel>
                  </div>
                </div>
              )}
              {props.isLoading ? (
                <Skeleton />
              ) : (
                <div className="row">
                  <div className="mb-2 client-location-by-map">
                  <FloatingLabel label='Street Address/House No./Locality' className={`${location ? "float-input-visible" : "float-hidden float-input"}`}>
                    <textarea
                      rows="3"
                      cols="20"
                      name="usrtxt"
                      wrap="hard"
                      className="form-control form add-client-input location"
                      id="exampleFormControlInput1"
                      placeholder="Street Address/House No./Locality"
                      value={location}
                      readOnly={true}
                      // onChange={(e) => setLocation(e)}
                    />
                    </FloatingLabel>
                    <i
                      className="bi bi-geo-alt"
                      title="Google Location"
                      onClick={openGoogleMaps}
                    />
                  </div>
                  {fieldReq && location === "" ? (
                    <small
                      id={`Error${alertMessage.alertType}`}
                      className="form-text text-muted "
                    >
                      {alertMessage.message} <i class="fa fa-exclamation-circle" aria-hidden="true"></i>
                    </small>
                  ) : (
                    ""
                  )}
                </div>
              )}
              {props.isLoading ? (
                <Skeleton />
              ) : (
                <div className="mt-2">
                  {editClientId && (
                    <span className="enable-status d-flex">
                      <h6>Enable</h6>
                      <span className="enable-radio d-flex">
                        <div className="form-check enable-radio-status">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={statusFlag}
                            onChange={() => habdleEnableStatus(1)}
                          />
                          <label className="form-check-label">Yes</label>
                        </div>
                        <div className="form-check enable-radio-status">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked={!statusFlag}
                            onChange={() => habdleEnableStatus(0)}
                          />
                          <label className="form-check-label">No</label>
                        </div>
                      </span>
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="btns-save-cancel">
            <SBSaveUpdateBtn btnName={editClientId ? "Update" : "Save"} />
            <SbCancelBtn onClickEffect={closeModal} btnName="Cancel" />
          </div>
        </form>
      </Modal>
      {googleMapsModel === true && (
        // <LoadScript googleMapsApiKey={React_Google_API_Key}>
        <GoogleMapAddressModel
          googleMapsModel={googleMapsModel}
          addressFromGoogle={addressFromGoogle}
          openGoogleMaps={openGoogleMaps}
          latLan={latLan}
          searchLocation={searchLocation}
          searchVal={searchVal}
          onMarkerDragEnd={onMarkerDragEnd}
          address={address}
        />
      )}
      <>
        {showAlert ? (
          <AlertModel
            showAlert={showAlert}
            alertMessage={alertMessage}
            closeAlertModel={closeAlertModel}
          />
        ) : (
          ""
        )}
      </>
    </div>
  );
}

export default AddClientModel;
