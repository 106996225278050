import React, { useState, useEffect } from "react";
import "../../styles/Attendance.scss";
import profileimg from "../../images/profile-1.png";
// import { travelizeAuth } from "./HeaderAuthentication";
import moment from "moment";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { baseURL } from "../BaseUrl";
import { exportExcel } from "../DownloadFile";
import { optionsPageSizeList } from "../DropdownsData";
import Select from "react-select";
import { SkeletonforClaimHead } from "../Skeleton Loading/SkeletonPendingClaim";
import SkeletonRejected from "../Skeleton Loading/SkeletonRejected";

function RejectedClaimManager(props) {
  const [fromDate, setFromDate] = useState(new Date(props.fromDate));
  const [toDate, setToDate] = useState(new Date(props.toDate));
  const [rejectedClaimDeatils, setRejectedClaimDeatils] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  useEffect(() => {
    fetchRejectedClaimData();
  }, [fromDate, toDate, PageSize, currentPage]);

  // GET MANAGER APPROVED FOR APPROVAL CLAIM DETAILS START
  const fetchRejectedClaimData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/OSClaim/Get?FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format("DD/MM/YYYY")}&UserID=${
        props.claimUserId
      }&StatusIDList=44,47&SearchStatusType=Claim&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        console.log("REJECTED-CLAIM-DATA::", res.result);
        if (res.success) {
          setRejectedClaimDeatils(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setRejectedClaimDeatils("");
          setIsLoading(false);
        }
      });
  };
  // GET MANAGER APPROVED FOR APPROVAL CLAIM DETAILS END

  const clearSerachBySelect = () => {
    setFromDate(new Date(props.fromDate));
    setToDate(new Date(props.toDate));
  };

  const exportExcelClaim = () => {
    exportExcel(
      `${baseURL}/api/Export/OSUserWiseClaimExcelExport?FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&UserID=${
        props.claimUserId
      }&StatusIDList=44,47&SearchStatusType=Claim&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "OSRejected_Manager_ClaimData.xlsx"
    );
  };

  return (
    <div id="main-page">
      {props.rejectedClaim ? (
        <>
          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}

            {/* USER TABLE START */}
            <div className="claims-table">
              <div className="seleting-menus">
                <div className="page-title claim-approval">
                  Rejected Claim by Manager
                </div>
                <div className="page-top-bar-dropdowns">
                  <div className="input-group from-to-date-select">
                    <span className="input-group-text" id="from-label">
                      From
                    </span>
                    <span className="date-picker-with-icon">
                      <DatePicker
                        selected={fromDate}
                        className="select from-date"
                        id="from-datepicker"
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        // startDate={fromDate}
                        endDate={toDate}
                        onChange={(date) => setFromDate(date)}
                        maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>

                    <span className="input-group-text" id="to-label">
                      To
                    </span>
                    <span className="date-picker-with-icon">
                      <DatePicker
                        selected={toDate}
                        className="select to-date"
                        id="from-datepicker"
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        // startDate={fromDate}
                        endDate={toDate}
                        minDate={fromDate}
                        onChange={(date) => setToDate(date)}
                        maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                    </span>
                  </div>

                  <button
                    type="button"
                    className="btn btn-search-track"
                    onClick={clearSerachBySelect}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-eraser"> </i>
                      <h6>Clear</h6>
                    </span>
                  </button>

                  <Select
                    className="react-select-container-list"
                    classNamePrefix="react-select-list"
                    // defaultValue={optionsRoleList[0].label}
                    options={optionsPageSizeList}
                    placeholder="Show Per Page"
                    value={optionsPageSizeList.value}
                    onInputChange={optionsPageSizeList.label}
                    onChange={(data) => setPageSize(data.value)}
                    isSearchable={true}
                    // isClearable={true}
                  />

                  <button
                    type="button"
                    className="btn btn-add-user"
                    onClick={props.goBackToClaimfromRejectedPage}
                  >
                    <span className="btn-inline-item d-flex">
                      <i className="fa fa-arrow-left"> </i>
                      <h6>Back</h6>
                    </span>
                  </button>
                </div>
              </div>

              {isLoading ? (
                <SkeletonforClaimHead />
              ) : (
                <div className="pendingapproval-records">
                  <div className="claim-user-details-img">
                    <img
                      className="pendingapproval-user-imgage"
                      src={profileimg}
                      alt="User Image"
                    />
                    <div className="pendingapproval-username-details">
                      <span>
                        <b>User Name :</b> &nbsp;{" "}
                        {rejectedClaimDeatils[0]?.fullName}
                      </span>
                      <span>
                        <b>Email ID :</b> &nbsp;
                        {rejectedClaimDeatils[0]?.email}
                      </span>
                      <span>
                        <b>Contact No.:</b> &nbsp;{" "}
                        {rejectedClaimDeatils[0]?.countryCode}{" "}
                        {rejectedClaimDeatils[0]?.contact}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="table-responsive rejected-claim-scroll">
                <table className="table attendance-list-table">
                  <thead className="thead-light attendance-list-thead">
                    <tr>
                      <th>Sl.No.</th>
                      <th>ClaimedDate</th>
                      <th>FromLocation</th>
                      <th>ToLocation</th>
                      <th>FromDate</th>
                      <th>ToDate</th>
                      <th>No.Of Days</th>
                      <th>Amount</th>
                      <th>Rejected Amount</th>
                      <th>RejectedDate</th>
                      <th>Status</th>
                      <th>RejectedBy</th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <>
                        <SkeletonRejected />
                        <SkeletonRejected />
                        <SkeletonRejected />
                        <SkeletonRejected />
                        <SkeletonRejected />
                        <SkeletonRejected />
                        <SkeletonRejected />
                      </>
                    ) : rejectedClaimDeatils !== "" &&
                      rejectedClaimDeatils.length > 0 ? (
                      rejectedClaimDeatils.map((data, i) => (
                        <tr className="attendance-list-row-data">
                          <td>{i + 1}</td>
                          <td>
                            {/* {moment(data?.claimDate).format("DD/MM/YYYY")} */}
                            {data?.claimDate}
                          </td>
                          <td>{data?.fromLocation}</td>
                          <td>{data?.toLocation}</td>
                          <td>
                            {/* {moment(data?.fromDate).format("DD/MM/YYYY")} */}
                            {data?.fromDate}
                          </td>
                          <td>
                            {/* {moment(data?.toDate).format("DD/MM/YYYY")} */}
                            {data?.toDate}
                          </td>
                          <td>{data?.noOfDays} </td>
                          <td>{data?.totalAmount}</td>
                          <td>
                            {data?.byManager?.rejectedTotalClaimAmount ?? 0}
                          </td>
                          <td>
                            {/* {moment(data?.byManager?.date).format("DD/MM/YYYY")} */}
                            {data?.byManager?.date}
                          </td>
                          <td>{data?.byManager?.status}</td>
                          <td>{data?.byManager?.fullName}</td>
                        </tr>
                      ))
                    ) : (
                      <div>
                        <span className="text-center" style={{ width: "100%" }}>
                          --- NO DATA FOUND ---
                        </span>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* USER TABLE END */}

            <div className="download-and-pagination">
              <button
                type="button"
                className="btn btn-download-excel"
                onClick={exportExcelClaim}
              >
                <i className="fa fa-download" /> &nbsp; Download
              </button>
              <span className="user-pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<i className="fa fa-angle-double-right" />}
                  onPageChange={handlePageClick}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel={<i className="fa fa-angle-double-left" />}
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  disabledClassName={"disabled"}
                />
              </span>
            </div>

            {/* PAGE CONTENT END*/}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default RejectedClaimManager;
