import { TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";

function SbTableHeader(props) {
  return (
    <thead className="custom-table-header  meetings-list-thead">
      <tr>
        {props.tableHeader.map((item, i) => (
          <th
            key={i}
            className={`thead-cell ${
              (item === "STATUS" || item === "ACTION") && "table-align"
            }`}
            style={{ minWidth: "50px" }}
          >
            {item === "MRP" && (
              <span>
                (
                <i className="fa fa-inr" style={{ fontSize: "12px" }} />)
              </span>
            )}
            {item}
            {/* <thead className="thead-light users-list-thead">
      <tr>
        {props.tableHeader.map((item, i) => (
          <th key={i} className={(item === "STATUS" || item === "ACTION") && "table-align"}>{item === "MRP" && <span>(
            <i className="fa fa-inr" style={{ fontSize: "12px" }} />
            )</span>}{item}
          </th>
        ))}
      </tr>
    </thead> */}
          </th>
        ))}
      </tr>
    </thead>
  );
}

export default SbTableHeader;
