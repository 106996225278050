import React, { useState, useEffect, useRef } from "react";
import Sidenavbar from "../../Sidenavbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Accountsettings.scss";
import Topbar from "../../Topbar";
import { baseURL } from "../../BaseUrl";
import { Link, useNavigate } from "react-router-dom";
// import { travelizeAuth } from "../HeaderAuthentication";
import { Form } from "react-bootstrap";
import Multiselect, { components } from "multiselect-react-dropdown";
import { loggedInRole } from "../../LoginRole";

function AccountSettings() {
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [filterUserId, setFilterUserId] = useState("");
  const [selectedUserCount, setSelectedUserCount] = useState([]);
  const [mappingType, setMappingType] = useState(
    resPassData?.subscription?.clientMapType
  );
  const [meetingRange, setMeetingRange] = useState(resPassData?.allowMeetings);
  const [kilometerUpdateMessage, setKilometerUpdateMessage] = useState("");
  const [twoLevelClaimRes, setTwoLevelClaimRes] = useState("");
  const [mappingSettingRes, setMappingSettingRes] = useState("");

  const [twoLevelClaim, setTwoLevelClaim] = useState(
    resPassData?.subscription?.twoLevelClaim
  );

  let navigate = useNavigate();

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const setRangeforMeetingSubmit = () => {
    let requestoption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify({ status: meetingRange }),
    };
    fetch(`${baseURL}/api/Subscription/UpdateAllowMeeting`, requestoption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("MEETING-RANGE-RES::", res);
        if (res.success) {
          setKilometerUpdateMessage(res.message);
          getLoginUserDetails();
        } else {
          setKilometerUpdateMessage(res.errorMessage);
        }
      });
    setTwoLevelClaimRes("");
  };

  const onValueChange = (e) => {
    setMeetingRange(e.target.value);
  };

  const onTwoLevelClaimChange = (e) => {
    setTwoLevelClaim(e.target.value);
  };

  const onMappingChange = (e) => {
    setMappingType(e.target.value);
  };

  const twoLevelClaimApprove = () => {
    let requestoption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify({ status: twoLevelClaim }),
    };
    fetch(
      `${baseURL}/api/Subscription/UpdateTwoLevelClaimStatus`,
      requestoption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("TWO-LEVEL-CLAIM-RES::", res);
        if (res.success) {
          setTwoLevelClaimRes(res.message);
          getLoginUserDetails();
        } else {
          setTwoLevelClaimRes(res.errorMessage);
        }
      });
    setKilometerUpdateMessage("");
  };

  const mappingTypeSetting = () => {
    let mapType = {
      subscriptionID: resPassData.subscriptionId,
      clientMapType: JSON.parse(mappingType),
    };
    let requestoption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(mapType),
    };
    fetch(`${baseURL}/api/Subscription/UpdateClientMapType`, requestoption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("MAPPINGTYPE-SETTINGS-RES::", res);
        if (res.success) {
          setMappingSettingRes(res.message);
          getLoginUserDetails();
        } else {
          setMappingSettingRes(res.errorMessage);
        }
      });
    setKilometerUpdateMessage("");
    setTwoLevelClaimRes("");
  };

  const getLoginUserDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/DashBoard/GetLoginUserDetails`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        // console.log("USER-LOGIN-DETAILS::", res.result);
        const userLoginDetails = res.result;
        sessionStorage.setItem("usrlgndtls", JSON.stringify(userLoginDetails));
      });
    setTimeout(() => setKilometerUpdateMessage(""), 2000);
    setTimeout(() => setTwoLevelClaimRes(""), 2000);
    setTimeout(() => setMappingSettingRes(""), 2000);
    navigate("/settings/accountsettings");
  };

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {/* SIDEBAR START */}
      <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
      {/* SIDEBAR END */}

      {/* TOPBAR START */}
      <Topbar
        toggleMenu={toggleMenu}
        menuCollapse={menuCollapse}
        toggleshift={toggleshift}
      />
      {/* TOPBAR END */}

      {/* MAIN BODY START */}
      <div className="page-wrapper">
        {/* PAGE CONTENT START */}

        <div className={`main-body ${toggleshift.style}`}>
          <div className="page-content">
            {/* USER TABLE START */}
            <div className="account-settings-page">
              <span className="seleting-menus">
                <div
                  className="back-btn"
                  onClick={goToBackPage}
                >
                  <img
                    src="../../../../images/DefaultImages/back-btn-arrow.svg"
                    alt=""
                  />
                </div>
                <span className="page-title">ACCOUNT SETTINGS</span>
              </span>
              <div className="account-settings-page-body">
                <div className="mapping-page-added-list">
                  {loggedInRole !== 2 ? (
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12 meeting-range-part mt-3">
                        <div className="meeting-range-head">
                          <h6>Meeting Range</h6>
                        </div>
                        <div className="mt-2">
                          <span className="fz13">
                            Allow the users to submit a meeting from range?
                          </span>
                          <span className="allow-meeting-range-select-radio d-flex">
                            <div className="form-check meeting-range-enable-radio-status">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="meeting-range-outside-area"
                                id="meetRange500"
                                value={0.6}
                                onChange={onValueChange}
                                checked={meetingRange == 0.6 ? true : false}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="meetRange500"
                              >
                                500 Meter
                              </label>
                            </div>
                            <div className="form-check meeting-range-enable-radio-status">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="meeting-range-outside-area"
                                id="meetRange1km"
                                value={1}
                                onChange={onValueChange}
                                checked={meetingRange == 1 ? true : false}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="meetRange1km"
                              >
                                1 KM
                              </label>
                            </div>
                            <div className="form-check meeting-range-enable-radio-status">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="meeting-range-outside-area"
                                id="meetRange2km"
                                value={2}
                                onChange={onValueChange}
                                checked={meetingRange == 2 ? true : false}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="meetRange2km"
                              >
                                2 KM
                              </label>
                            </div>
                            <div className="form-check meeting-range-enable-radio-status">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="meeting-range-outside-area"
                                id="norestriction"
                                value={0}
                                onChange={onValueChange}
                                checked={meetingRange == 0 ? true : false}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="norestriction"
                              >
                                No Restriction
                              </label>
                            </div>
                          </span>
                          <div className="input-group mt-3 mb-3">
                            <button
                              className="btn btn-success meeting-range-update"
                              onClick={setRangeforMeetingSubmit}
                            >
                              Update Range
                            </button>
                            {kilometerUpdateMessage != "" && (
                              <small
                                id="kilometerMsgSuccess"
                                className="form-text text-muted"
                              >
                                {kilometerUpdateMessage}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="col-lg-6 col-md-6 col-sm-12 claim-two-level-approval-part mt-3">
                        <div className="claim-two-aprroval">
                          <h6>Claim-Two Level Approval</h6>
                        </div>
                        <div className="mt-2">
                          <span className="fz13">
                            {" "}
                            You need claim approval for Admin level?
                          </span>
                          <span className="claim-two-level approval-enable-radio d-flex">
                            <div className="form-check approval-enable-radio-status">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="claim-two-approve"
                                id="claimadminYes"
                                value="Yes"
                                onChange={(e) => onTwoLevelClaimChange(e)}
                                checked={twoLevelClaim == "Yes" ? true : false}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="claimadminYes"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="form-check approval-enable-radio-status">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="claim-two-approve"
                                id="claimadminNo"
                                value="No"
                                onChange={(e) => onTwoLevelClaimChange(e)}
                                checked={twoLevelClaim == "No" ? true : false}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="claimadminNo"
                              >
                                No
                              </label>
                            </div>
                          </span>
                          <div className="input-group mt-3 mb-3">
                            <button
                              className="btn btn-success claim-approval-update"
                              onClick={twoLevelClaimApprove}
                            >
                              Update
                            </button>
                            {twoLevelClaimRes !== "" && (
                              <small
                                id="twolevelclaimMsgSuccess"
                                className="form-text text-muted"
                              >
                                {twoLevelClaimRes}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 set-client-mapping-settings mt-5">
                      <div className="client-mapping-setting-title">
                        <h6>Client Mapping</h6>
                      </div>
                      <div className="mt-2">
                        <span className="fz13">
                          {" "}
                          Please choose below option for client mapping type{" "}
                        </span>
                        <span className="allow-meeting-range-select-radio d-flex">
                          <div className="form-check meeting-range-enable-radio-status">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="use-mapping-type"
                              id="mappingYes"
                              value={-1}
                              onChange={onMappingChange}
                              checked={mappingType == -1 ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="mappingYes"
                            >
                              Use Mapping
                            </label>
                          </div>
                          <div className="form-check meeting-range-enable-radio-status">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="use-mapping-type"
                              id="addedbyUser"
                              value={0}
                              onChange={onMappingChange}
                              checked={mappingType == 0 ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="addedbyUser"
                            >
                              Added by User
                            </label>
                          </div>

                          <div className="form-check meeting-range-enable-radio-status">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="use-mapping-type"
                              id="noRule"
                              value={6}
                              onChange={onMappingChange}
                              checked={mappingType == 6 ? true : false}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="noRule"
                            >
                              No Rule <br />
                              <small className="fz12">
                                (All Company to All Users)
                              </small>
                            </label>
                          </div>
                        </span>
                        <div className="input-group mt-3 mb-3">
                          <button
                            className="btn btn-success mappingtype-update"
                            onClick={mappingTypeSetting}
                          >
                            Update
                          </button>
                          {mappingSettingRes !== "" && (
                            <small
                              id="twolevelclaimMsgSuccess"
                              className="form-text text-muted"
                            >
                              {mappingSettingRes}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* USER TABLE END */}

            {/* PAGE CONTENT END*/}
          </div>
        </div>
      </div>
      {/* MAIN BODY END */}
    </div>
  );
}

export default AccountSettings;
