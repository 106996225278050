import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/BaseFare.scss";
import { Col, Form, Row } from "react-bootstrap";
import { baseURL } from "../../BaseUrl";
import { exportExcel } from "../../DownloadFile";
import Select from "react-select";
import {
  optionsPageSizeList,
} from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import Skeleton from "react-loading-skeleton";
import { SkeletonMeetPurpose } from "../../Skeleton Loading/SkeletonMeetPurpose";
import { useNavigate, useParams } from "react-router-dom";
import { getSingleBasefare, loadBaseFare, loadClientDropDown, loadDesignationDrop, loadFilteredBaseFare, loadGoogleCredits, loadMotDrop, loadSingleBaseFare } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import AddApiCredits from "./AddApiCredits";
import ApiCreditsTable from "./ApiCreditsTable";
import moment from "moment";
import DatePicker from "react-datepicker";
import SbAddBtn from "../../SbButtons/SbAddBtn";

function ApiCredits() {

  const { key } = useParams();

  let tableHeader = [
    "SL.NO",
    "COMPANY",
    "API NAME",
    "API CREDIT FROM",
    "API CREDIT TO",
    "TOTAL CREDITS",
    "AVAILABLE CREDITS",
    "CONSUMED",
    "DAYS LEFT",
    "ACTION"
  ]

  let dispatch = useDispatch();
  const {ApiCreditList} = useSelector(state => state.data);
  const {clientDropdown} = useSelector(state => state.data);
  const {apiDropdown} = useSelector(state => state.data);

  const [ApiCreditData, setApiCreditData] = useState([])

  useEffect(() => {
    if(key){
      setApiCreditData(ApiCreditList.filter(data => {
        return data.ApiName.includes("Google")
      }))
    } else {
      setApiCreditData(ApiCreditList)
    }
  }, [key, ApiCreditList])

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [activeStatus, setActiveStatus] = useState(2);
  const [selectDesignation, setSelectDesignation] = useState("");
  const [MotId, setMotId] = useState(0);
  const [basefareId, setBasefareId] = useState("");
  const [EditmotID, setEditMotID] = useState("");
  const [clickType, setClickType] = useState("");
  const [show, setShow] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [PageSize, setPageSize] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [searchValue, setSearchValue] = useState('')
  const [filterArray, setFilterArray] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [filterClientName, setFilterClientName] = useState({value: "", label: ""})

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const setSearchData = (value) => {
    let list;
    if(filterArray.length !== 0){
      list = filterArray.filter(item => item.key !== "Key")
    } else {
      list = []
    }
    if(value){
      list.push({key: "Key", value: value})
    } else {
      list = filterArray.filter(item => item.key !== "Key")
    }
    setSearchValue(value)
    setFilterArray(list)
  }

  const setSelectSearchDrop = (data, type) => {
    let list;
    if (type === "ClientName") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "ClientID");
      } else {
        list = [];
      }
      list.push({ key: "ClientID", value: data.value });
      setFilterClientName(data);
    } else if (type === "From") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "FromDate");
      } else {
        list = [];
      }
      list.push({ key: "FromDate", value: moment(new Date(data)).format("DD/MM/YYYY") });
      setFromDate(data);
    } else if (type === "To") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "ToDate");
      } else {
        list = [];
      }
      list.push({ key: "ToDate", value: moment(new Date(data)).format("DD/MM/YYYY") });
      setToDate(data);
    }
    setFilterArray(list);
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    dispatch(loadClientDropDown())
    fetchAPICredits()
  }, [filterArray, PageSize, currentPage, setLoading]);

  const fetchAPICredits = () => {
    let Flag = "GetApiCredits"
    dispatch(loadGoogleCredits(Flag, setIsLoading, PageSize, currentPage, filterArray, setLoading))
  }

  const [apiDataId, setApiDataId] = useState('')
  const editAPICredit = (apiId) => {
    setApiDataId(apiId)
    setShow(true)
  }

  const clearSerachBySelect = () => {
    setFilterClientName({value: "", label: ""})
    setSearchValue("");
    setFilterArray([])
    setFromDate(new Date());
    setToDate(new Date());
  };

  const exportExcelBaseFare = () => {
    // exportExcel(
    //   `${baseURL}/api/Export/MotBaseFareExcelExport?PageSize=100&CurrentPage=1`,
    //   "BaseFare.xlsx"
    // );
  };

  // Page navigation
  let navigate = useNavigate();
  const onClickNavigate = () => {
    if(key){
      navigate(`/googleapikeylist/${key}`)
    } else {
      navigate(`/googleapikeylist`)
    }
  }

  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  const [openFilterTab, setOpenFilterTab] = useState(false)

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              
              <div className="page-content">

            <div style={{position: "relative"}}>
              <Row className='search-row'>
              <Col md={3} className='cat-col'>
                <div className='page-header-text-div'>
                <div className="back-btn" onClick={goToBackPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                  <h6 className='page-header-text' style={{ color: "#04a96d" }}>API CREDITS</h6>
                </div>
              </Col>
              <Col md={5} className='cat-col'>
                <div className='input-div'>
                  <input type="text" placeholder='Find a Key' className='form-control setting-input'
                      value={searchValue} onChange={(e) => setSearchData(e.target.value)} 
                      />
                  <i className='fa fa-search search-icon' ></i>
                </div>
              </Col>
              <Col md={4} className='cat-col'>
                <div className="search-bar-header" style={{float: "right"}}>
                  <div className="page-top-bar-dropdowns page-top-Filters">
                      <div className="header-filter-btn" onClick={() => setOpenFilterTab(!openFilterTab)}>
                        <p className="card-head">
                        <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                        </p>
                      </div>
                      <SbAddBtn onClickEffect={() => setShow(!show)} />
                  </div>
                </div>
              </Col>
            </Row>
              
            {openFilterTab &&
            <Row className='page-filter-section'>
              <Col md={10}>
                <Row>
                <Col md={4}>
                    <Select
                      className="react-select-container-list"
                      classNamePrefix="react-select-list"
                      options={clientDropdown}
                      placeholder="Select Client"
                      value={filterClientName.value && filterClientName}
                      onInputChange={clientDropdown.label}
                      onChange={(data) => setSelectSearchDrop(data, "ClientName")}
                      isSearchable={true}
                    />
                  </Col>
                <Col md={3}>
                  <div className="input-group from-to-date-select">
                      <span
                        className="input-group-text"
                        id="from-label">
                        From
                      </span>
                      <span className="date-picker-with-icon">
                        <DatePicker
                          selected={fromDate || new Date()}
                          className="select from-date"
                          id="from-datepicker"
                          dateFormat="dd/MM/yyyy"
                          selectsEnd
                          onChange={(date) => setSelectSearchDrop(date, "From")}
                          maxDate={toDate}
                        />
                        <i className="fa fa-calendar-o calander-icon" />
                      </span>
                    </div>
                  </Col>
                  <Col md={3}>
                  <div className="input-group from-to-date-select">
                      <span
                        className="input-group-text"
                        id="to-label">
                        To
                      </span>
                      <span className="date-picker-with-icon">
                        <DatePicker
                          selected={toDate || new Date()}
                          className="select to-date"
                          id="from-datepicker"
                          dateFormat="dd/MM/yyyy"
                          selectsEnd
                          minDate={fromDate}
                          onChange={(date) => setSelectSearchDrop(date, "To")}
                          maxDate={new Date()}
                        />
                        <i className="fa fa-calendar-o calander-icon" />
                      </span>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={2} align="center">
                <button
                  type="button"
                  className="btn btn-search-track"
                  onClick={clearSerachBySelect} >
                  <span className="btn-inline-item d-flex">
                    <i className="fa fa-eraser"> </i>
                    <h6>Clear</h6>
                  </span>
                </button>
              </Col>
            </Row>
      }
            </div>

                {/*TABLE START */}
                <ApiCreditsTable tableHeader={tableHeader}
              ApiCreditList={ApiCreditData}
              isLoading={isLoading}
              onClickNavigate={onClickNavigate}
              editAPICredit={editAPICredit}
               />
                {/*TABLE END */}

                <div className="download-and-pagination">
                <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        options={optionsPageSizeList}
                        placeholder="Show Per Page"
                        value={optionsPageSizeList.value}
                        onInputChange={optionsPageSizeList.label}
                        onChange={(data) => setPageSize(data.value)}
                        isSearchable={true}
                      // isClearable={true}
                      />
                  <button
                    type="button"
                    className="btn btn-download-excel"
                    onClick={exportExcelBaseFare}
                  >
                    <i className="fa fa-download" /> &nbsp; Download
                  </button>
                </div>

                {/* ADD & EDIT PRODUCT MODEL START */}
                {show && <AddApiCredits fetchAPICredits={fetchAPICredits}
                  apiDataId={apiDataId} 
                  show={show} EditmotID={EditmotID} basefareId={basefareId} setBasefareId={setBasefareId}
                  handleClose={handleClose} setApiDataId={setApiDataId}
                //   clickType={clickType} setClickType={setClickType}
                />
}
                {/* ADD & EDIT PRODUCT MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default ApiCredits;
