import React, { useState, useEffect } from "react";
import "../../styles/Attendance.scss";
import profileimg from "../../images/profile-1.png";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import moment from "moment";
import ReactPaginate from "react-paginate";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ViewClaimAttachmentmodel from "./ViewClaimAttachmentmodel";
import { exportExcel } from "../DownloadFile";
import { optionsPageSizeList } from "../DropdownsData";
import Select from "react-select";
import {
  SkeletonforClaimHead,
  SkeletonPendingClaimHead,
  SkeletonPendingTableData,
} from "../Skeleton Loading/SkeletonPendingClaim";
import SkeletonApproved from "../Skeleton Loading/SkeletonApproved";
import * as swal from "../Consturl/SwalAlert";
import { Col, Row } from "react-bootstrap";
import SbCancelBtn from "../SbButtons/SbCancelBtn";

function ApprovedClaimByManager(props) {
  const [fromDate, setFromDate] = useState(new Date(props.fromDate));
  const [toDate, setToDate] = useState(new Date(props.toDate));
  const [approvedClaimDeatils, setApprovedClaimDeatils] = useState([]);
  const [attachmentUrl, setAttachmentUrl] = useState("");
  const [claimAttachmentmodel, setClaimAttachmentmodel] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const handleCloseviewattachment = () => setClaimAttachmentmodel(false);

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState("");
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // PAGINATION END

  useEffect(() => {
    fetchApprovedClaimData();
  }, [fromDate, toDate, PageSize, currentPage]);

  // GET MANAGER APPROVED FOR APPROVAL CLAIM DETAILS START
  const fetchApprovedClaimData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/Claim/Get?FromDate=${moment(fromDate).format(
        "DD/MM/YYYY"
      )}&ToDate=${moment(toDate).format("DD/MM/YYYY")}&UserID=${
        props.claimUserId
      }&StatusIDList=43,47&SearchStatusType=Claim&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setApprovedClaimDeatils(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
        } else {
          setApprovedClaimDeatils("");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // console.log("ERROR :", error);
        swal.showAlert("Fail", `${error}`, "warning");
        // setErrorMessage(error.message);
      });
  };
  // GET MANAGER APPROVED FOR APPROVAL CLAIM DETAILS END

  const clearSerachBySelect = () => {
    setFromDate(new Date(props.fromDate));
    setToDate(new Date(props.toDate));
  };

  const exportExcelClaim = () => {
    exportExcel(
      `${baseURL}/api/Export/ClaimDetailExcelExport?UserID=${
        props.claimUserId
      }&StatusIDList=43,47&SearchStatusType=Claim&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Approved-ClaimData.xlsx"
    );
  };

  const exportUserwiseReport = () => {
    exportExcel(
      `${baseURL}/api/Export/UserWiseClaimExcelExport?UserID=${
        props.claimUserId
      }&StatusIDList=43,47&SearchStatusType=Claim&FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "ApprovedClaim-UserwiseReport.xlsx"
    );
  };

  return (
    <div id="main-page">
      {props.approvedClaimByManager ? (
        <>
          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}

            {/* Filter and Header Section */}
            <div className="user-table">
              <Row className="search-row">
                <Col md={3} className="cat-col">
                  <div className="page-header-text-div">
                    {/* <div className="back-btn" onClick={goToBackPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div> */}
                    <h6
                      className="page-header-text"
                      style={{ color: "#04a96d" }}
                    >
                      Approved Claim by Manager
                    </h6>
                  </div>
                </Col>
                <Col md={9} className="cat-col">
                  <div
                    className="search-bar-header"
                    style={{ float: "right" }}
                  >
                    <div className="page-top-bar-dropdowns page-top-Filters">
                    <div className="cliam-filter-section">
                  <div style={{ width: "100%" }}>
                    <Row>
                      <Col md={10}>
                        <div className="clients-filter-input-boxs filter-box-div">
                          
                          <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                            <span className="input-group-text" id="from-label">
                              From
                            </span>
                            <span className="date-picker-with-icon">
                            <DatePicker
                        selected={fromDate}
                        className="select from-date"
                        id="from-datepicker"
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        // startDate={fromDate}
                        endDate={toDate}
                        onChange={(date) => setFromDate(date)}
                        maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                            </span>
                          </div>
                          <div style={{ width: "100%", padding: "0px 5px" }} className="input-group from-to-date-select">
                            <span className="input-group-text" id="from-label">
                              To
                            </span>
                            <span className="date-picker-with-icon">
                            <DatePicker
                        selected={toDate}
                        className="select to-date"
                        id="from-datepicker"
                        dateFormat="dd/MM/yyyy"
                        selectsEnd
                        // startDate={fromDate}
                        endDate={toDate}
                        minDate={fromDate}
                        onChange={(date) => setToDate(date)}
                        maxDate={new Date()}
                      />
                      <i className="fa fa-calendar-o calander-icon" />
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className="clients-filter-input-boxs filter-box-div">
                          <SbCancelBtn
                            onClickEffect={clearSerachBySelect}
                            btnName="Clear"
                          />
                        </div>

                      </Col>
                    </Row>
                  </div>
                </div>
                    </div>
                  </div>
                </Col>
              </Row>   
            </div>
            {/* Filter and Header Section */}

            {/* USER TABLE START */}
            <div className="approvedclaim-table">
              
              {isLoading ? (
                <SkeletonforClaimHead />
              ) : (
                <div className="pendingapproval-records">
                  <div className="claim-user-details-img">
                    <img
                      className="pendingapproval-user-imgage"
                      src={profileimg}
                      alt=""
                    />
                    <div className="pendingapproval-username-details">
                      <span>
                        <b>User Name :</b> &nbsp;{" "}
                        {approvedClaimDeatils[0]?.fullName}
                      </span>
                      <span>
                        <b>Email ID :</b> &nbsp;{" "}
                        {approvedClaimDeatils[0]?.email}
                      </span>
                      <span>
                        <b>Contact No.:</b> &nbsp;{" "}
                        {approvedClaimDeatils[0]?.countryCode}{" "}
                        {approvedClaimDeatils[0]?.contact}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              <div className="table-responsive approvedclaim-scroll">
                {isLoading ? (
                  <SkeletonPendingTableData />
                ) : approvedClaimDeatils !== "" &&
                  approvedClaimDeatils.length > 0 ? (
                  approvedClaimDeatils?.map((data, i) => (
                    <table className="table attendance-list-table">
                      <thead className="thead-light approved-claim-thead">
                        <tr>
                          <th>
                            {i + 1}. ClaimedDate :{" "}
                            {/* {moment(data?.claimDate).format("DD/MM/YYYY")} */}
                            {data?.claimDate}
                          </th>
                          <th>CheckIn : {data?.checkInTime}</th>
                          <th>CheckOut : {data?.checkOutTime}</th>
                          <th>HrsOnField : {data?.hrsOnField}</th>
                          <th>
                            Visits : {data?.noOfVisit} / KMs :{" "}
                            {data?.kMsTravelled}
                          </th>
                          <th>Status : {data?.byManager?.status}</th>
                        </tr>
                      </thead>
                      <thead className="thead-light approved-claim-thead">
                        <tr>
                          <th>Type</th>
                          <th>Attachment</th>
                          <th>Applied </th>
                          <th>Approved </th>
                          <th>Remarks</th>
                          <th>Approved By</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="attendance-list-row-data">
                          <td>Local</td>
                          <td>
                            {" "}
                            <i
                              className="fa fa-paperclip"
                              onClick={() => {
                                setClaimAttachmentmodel(!claimAttachmentmodel);
                                setAttachmentUrl(
                                  data?.localClaim?.attachment ?? ""
                                );
                              }}
                            />
                          </td>
                          <td>{data?.localClaim?.amount}</td>
                          <td>
                            {data?.byManager?.approvedLocalClaimAmount ?? 0}
                          </td>
                          <td>{data?.localClaim?.remark ?? "NA"}</td>
                          <td>{data?.byManager?.fullName}</td>
                        </tr>
                        <tr className="attendance-list-row-data">
                          <td>Outstation By {data?.osClaim?.mot ?? "NA"}</td>
                          <td>
                            <i
                              className="fa fa-paperclip"
                              onClick={() => {
                                setClaimAttachmentmodel(!claimAttachmentmodel);
                                setAttachmentUrl(
                                  data?.osClaim?.attachment ?? ""
                                );
                              }}
                            />
                          </td>
                          <td>{data?.osClaim?.amount}</td>
                          <td>{data?.byManager?.approvedOSClaimAmount ?? 0}</td>
                          <td>{data?.osClaim?.remark ?? "NA"}</td>
                          <td>{data?.byManager?.fullName}</td>
                        </tr>
                        <tr className="attendance-list-row-data">
                          <td>Food</td>
                          <td>
                            <i
                              className="fa fa-paperclip"
                              onClick={() => {
                                setClaimAttachmentmodel(!claimAttachmentmodel);
                                setAttachmentUrl(
                                  data?.foodClaim?.attachment ?? ""
                                );
                              }}
                            />
                          </td>
                          <td>{data?.foodClaim?.amount}</td>
                          <td>
                            {data?.byManager?.approvedFoodClaimAmount ?? 0}
                          </td>
                          <td>{data?.foodClaim?.remark ?? "NA"}</td>
                          <td>{data?.byManager?.fullName}</td>
                        </tr>
                        <tr className="attendance-list-row-data">
                          <td>Hotel</td>
                          <td>
                            <i
                              className="fa fa-paperclip"
                              onClick={() => {
                                setClaimAttachmentmodel(!claimAttachmentmodel);
                                setAttachmentUrl(
                                  data?.hotelClaim?.attachment ?? ""
                                );
                              }}
                            />
                          </td>
                          <td>{data?.hotelClaim?.amount}</td>
                          <td>
                            {" "}
                            {data?.byManager?.approvedHotelClaimAmount ?? 0}
                          </td>
                          <td>{data?.hotelClaim?.remark ?? "NA"}</td>
                          <td>{data?.byManager?.fullName}</td>
                        </tr>
                        <tr className="attendance-list-row-data">
                          <td>Others</td>
                          <td>
                            <i
                              className="fa fa-paperclip"
                              onClick={() => {
                                setClaimAttachmentmodel(!claimAttachmentmodel);
                                setAttachmentUrl(
                                  data?.othersClaim?.attachment ?? ""
                                );
                              }}
                            />
                          </td>
                          <td>{data?.othersClaim?.amount}</td>
                          <td>
                            {data?.byManager?.approvedOthersClaimAmount ?? 0}
                          </td>
                          <td>{data?.othersClaim?.remark ?? "NA"}</td>
                          <td>{data?.byManager?.fullName}</td>
                        </tr>
                        <tr className="pendingapproval-row-data total-value">
                          <td>
                            {" "}
                            &nbsp; &nbsp;
                            <b>
                              Approved Date :{" "}
                              {/* {moment(data?.byManager?.date).format(
                                "DD/MM/YYYY"
                              )} */}
                              {data?.byManager?.date}
                            </b>
                          </td>
                          <td>
                            <b>TOTAL</b>
                          </td>
                          <td>
                            <b>{data?.totalAmount}</b>
                          </td>
                          <td>
                            <b>{data?.byManager?.approvedTotalClaimAmount}</b>
                          </td>
                          <td></td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  ))
                ) : (
                  <div>
                    <span className="text-center" style={{ width: "100%" }}>
                      --- NO DATA FOUND ---
                    </span>
                  </div>
                )}
              </div>
            </div>
            {/* USER TABLE END */}

            <div className="download-and-pagination">
              <button
                type="button"
                className="btn btn-download-excel"
                onClick={exportExcelClaim}
              >
                <i className="fa fa-download" /> &nbsp; Download
              </button>
              <span className="user-pagination">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={<i className="fa fa-angle-double-right" />}
                  onPageChange={handlePageClick}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={2}
                  pageCount={pageCount}
                  previousLabel={<i className="fa fa-angle-double-left" />}
                  renderOnZeroPageCount={null}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                  disabledClassName={"disabled"}
                />
              </span>
            </div>

            {/* PAGE CONTENT END*/}

            {/* MAIN BODY END */}

            {claimAttachmentmodel ? (
              <ViewClaimAttachmentmodel
                claimAttachmentmodel={claimAttachmentmodel}
                handleCloseviewattachment={handleCloseviewattachment}
                attachmentUrl={attachmentUrl}
              />
            ) : (
              ""
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}

export default ApprovedClaimByManager;
