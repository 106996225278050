import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import "../../styles/AddUserModel.scss";
import profileimg from "../../images/profile-1.png";
import Multiselect from "multiselect-react-dropdown";
import { baseURL } from "../BaseUrl";
// import { travelizeAuth, salesbeeImgAuth } from "./HeaderAuthentication";
import Geocode from "react-geocode";
import { React_Google_API_Key } from "../../GoogleApiKey";
import GoogleMapAddressModel from "../GoogleMapAddressModel";
// import { LoadScript } from "@react-google-maps/api";
import Select from "react-select";
import { FloatingLabel, OverlayTrigger, Tooltip } from "react-bootstrap";
import { optionsRoleListModel, optionsMapByListModel } from "../DropdownsData";
import AlertModel from "../AlertModel";
import InternetIssue from "../InternetIssue";
import * as swal from "../Consturl/SwalAlert";
import { SBSaveUpdateBtn } from "../SbButtons/SbAddBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
Geocode.setApiKey(React_Google_API_Key);

function Editusermodel(props) {
  /* HEADER AUTH START */
  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  const salesbeeImgAuth = { Travelize_Authentication: userDetails };
  /* HEADER AUTH END */

  const [fullname, setFullname] = useState("");
  const [usercode, setUserCode] = useState("");
  const [emailaddress, setEmailAddress] = useState("");
  const [mobilenum, setMobileNum] = useState(0);
  const [role, setRole] = useState("");
  const [location, setLocation] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [manager, setManager] = useState("");
  const [enableStatus, setEnableStatus] = useState();
  const [country, setCountry] = useState("");
  const [shift, setShift] = useState(0);
  const [oldShift, setOldShift] = useState("");
  const [googleMapsModel, setGoogleMapsModel] = useState(false);
  const [imgFile, setImgFile] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState("");
  const [localImage, setLocalImage] = useState(null);
  const [zone, setZone] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [zoneList, setZoneList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [countryCodeList, setCountryCodeList] = useState([]);

  const [managerList, setManagerList] = useState([]);
  const [zoneId, setZoneId] = useState([]);
  const [selectedOldCities, setSelectedOldCities] = useState([]);
  const [shiftList, setShiftList] = useState([]);
  const [imgFileName, setImgFileName] = useState("");
  const [zoneIds, setZoneIds] = useState([]);
  const [stateIds, setStateIds] = useState([]);
  const [cityIds, setCityIds] = useState([]);
  const [countryId, setCountryId] = useState(0);
  const [searchVal, setSearchVal] = useState("");
  const [address, setAddress] = useState("");
  const [mapById, setMapById] = useState(0);
  const [userRegionResult, setUserRegionResult] = useState({});
  const [countryList, setCountryList] = useState([]);
  const [showAlert, setShowAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState({
    alertType: "",
    message: "",
  });
  const [fieldReq, setFieldReq] = useState(false);
  const [latLan, setLatLan] = useState({
    lat: null,
    lng: null,
  });
  const [designationList, setDesignationList] = useState([]);
  const [reportDesignation, setReportDesignation] = useState(0);

  const openGoogleMaps = () => {
    setGoogleMapsModel(!googleMapsModel);
  };

  const addressFromGoogle = () => {
    openGoogleMaps();
  };

  const hiddenFileInput = useRef("");

  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    setImgFile(fileUploaded);
    setImgFileName(event.target.files[0].name);
    const reader = new FileReader();
    reader.readAsDataURL(fileUploaded);
    reader.onloadend = () => {
      setLocalImage(reader.result);
    };
  };

  const uploadClick = () => {
    hiddenFileInput.current.click();
  };

  //* FETCH DROPDOWN DATA START *//
  useEffect(() => {
    fetchDropdownData();
  }, [zoneIds, stateIds]);

  const fetchDropdownData = () => {
    let stateResponse = userRegionResult?.allStates;
    setStateList(
      stateResponse
        ?.filter((z) => zoneIds.includes(z.zoneId))
        ?.map((item) => item) ?? []
    );
    let cityResponse = userRegionResult?.allCities;
    setCityList(
      cityResponse
        ?.filter((s) => stateIds.includes(s.stateId))
        ?.map((item) => item) ?? []
    );
  };

  useEffect(() => {
    fetchZoneStateCityselectedList();
  }, [props.userID, reportDesignation]);

  const fetchZoneStateCityselectedList = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    // SHIFT LIST API CALL
    fetch(`${baseURL}/api/Zone/BindShiftDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setShiftList(
            res.result.map((data) => {
              return { value: data.shiftID, label: data.shiftName };
            })
          );
        } else {
          setShiftList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
    // SHIFT LIST API CALL
    fetch(`${baseURL}/api/Designation/BindDesignationDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDesignationList(
            res.result.map((data) => {
              return { value: data.designationID, label: data.designationName };
            })
          );
        } else {
          setDesignationList([]);
        }
      });
    fetch(
      `${baseURL}/api/User/BindTopManagerDropDown/${reportDesignation}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setManagerList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setManagerList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });

    fetch(
      `${baseURL}/api/Zone/GetUserRegionsResult/?userId=${props.userID}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        let regionResult = res.result;
        setCountryList(
          regionResult?.allCountry.map((data) => {
            return { value: data.countryID, label: data.countryName };
          })
        );
        setCountryCodeList(
          regionResult?.allCountryCode.map((data) => {
            return { value: data.code, label: data.code };
          })
        );
        // setShiftList(res.result.allShift);
        setZoneList(regionResult?.allZones);
        setUserRegionResult(regionResult);
        setCountryId(regionResult?.selectedCountryID[0]);
        setCountry(regionResult?.selectedCountry?.countryName);
        let zones = [
          ...new Set(regionResult.userRegions.map((z) => z.zoneName)),
        ];
        let states = [
          ...new Set(regionResult.userRegions.map((s) => s.stateName)),
        ];
        let cities = [
          ...new Set(regionResult.userRegions.map((c) => c.cityName)),
        ];
        setSelectedOldCities(cities);
        setZone(
          regionResult.allZones.filter((z) => zones.includes(z.zoneName))
        );
        setState(
          regionResult.allStates.filter((s) => states.includes(s.stateName))
        );
        setCity(
          regionResult.allCities.filter((c) => cities.includes(c.cityName))
        );
        let zonesId = [...new Set(res.result.userRegions.map((z) => z.zoneId))];

        let statesId = [
          ...new Set(regionResult.userRegions.map((s) => s.stateId)),
        ];
        let citiesId = [
          ...new Set(regionResult.userRegions.map((c) => c.cityId)),
        ];
        setZoneIds(zonesId);
        setStateIds(statesId);
        setCityIds(citiesId);
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH DROPDOWN DATA END *//

  // MULTI SELECT DROPDOWN FUNCTION START
  const handleSelectZone = (selectedList) => {
    setZone(selectedList);
    setZoneIds(selectedList.map((z) => z.zoneId));
  };
  const handleRemoveZone = (selectedList) => {
    setZone(selectedList);
    setZoneIds(selectedList.map((z) => z.zoneId));
  };
  const handleSelectState = (selectedList) => {
    setState(selectedList);
    setStateIds(selectedList.map((s) => s.stateId));
  };
  const handleRemoveState = (selectedList) => {
    setState(selectedList);
    setStateIds(selectedList.map((s) => s.stateId));
  };
  const handleSelectCity = (selectedList) => {
    setCity(selectedList);
    setCityIds(selectedList.map((s) => s.cityId));
  };
  const handleRemoveCity = (selectedList) => {
    setCity(selectedList);
    setCityIds(selectedList.map((s) => s.cityId));
  };
  // MULTI SELECT DROPDOWN FUNCTION END

  // FETCH USERDETAILS FOR EDIT START
  useEffect(() => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/UserDetails/${props.userID}`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        setFullname(res.result?.fullName);
        setUserCode(res.result?.code);
        setEmailAddress(res.result?.email);
        setMobileNum(res.result?.contact);
        setRole(res.result?.roleId);
        setLocation(res.result?.baseLoc?.location);
        setLatLan({
          lat: JSON.parse(res.result?.baseLoc?.latitude),
          lng: JSON.parse(res.result?.baseLoc?.longitude),
        });
        setCountryCode(res.result?.countryCode);
        setManager(res.result?.managerId);
        setEnableStatus(res.result?.enableStatus);
        setProfileImageUrl(res.result?.profilePic);
        setShift(res.result?.shiftID);
        setOldShift(res.result?.shiftID);
        setMapById(res.result?.mapType);
        setReportDesignation(res.result?.desingation?.designationID);
      })
      .catch((error) => {
        console.error("ERROR:---", error);
        setErrorMessage(error.message);
      });
  }, [props.userID]);
  // FETCH USERDETAILS FOR EDIT END

  //  ADDING ZONE STATE AND CITY BY USER START
  const addZoneStateCity = (resUserId) => {
    const zoneStateCity = city.map((item) => {
      return { ...item, userId: resUserId };
    });
    state.forEach((s_state) => {
      if (
        zoneStateCity.filter((zsc) => zsc.stateId == s_state.stateId).length ==
        0
      ) {
        zoneStateCity.push({ ...s_state, userId: resUserId });
      }
    });
    zone.forEach((z_Zone) => {
      if (
        zoneStateCity.filter((zsc) => zsc.zoneId == z_Zone.zoneId).length == 0
      ) {
        zoneStateCity.push({ ...z_Zone, userId: resUserId });
      }
    });

    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(zoneStateCity),
    };
    fetch(`${baseURL}/api/Zone/AddUserRegions`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          swal.showAlert("Success", `${res.message}`, "success");
        } else {
          swal.showAlert("Fail", `${res.errorMessage}`, "warning");
        }
      });
  };
  //  ADDING ZONE STATE AND CITY BY USER END

  //  ADDING PROFILE IMAGE BY USER START
  const editProfileImg = (e) => {
    e.preventDefault();
    if (imgFile !== null && imgFile !== "") {
      const formData = new FormData();
      formData.append("image", imgFile);
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: salesbeeImgAuth,
        body: formData,
      };
      fetch(
        `${baseURL}/api/Upload/UploadProfile?directory=Profile`,
        requestOption
      )
        .then((res) => res.json())
        .then((res) => {
          if (res.success) {
            var imgUrl = res.result[0];
            editUser(imgUrl);
          } else {
            setProfileImageUrl("");
            setImgFileName(`${res.errorMessage}`);
          }
        });
    } else {
      editUser();
    }
  };
  //  ADDING PROFILE IMAGE BY USER END

  const [countryVal, setCountryVal] = useState("");
  const [designationVal, setDesignationVal] = useState("");
  const [managerVal, setManagerVal] = useState("");
  const [countryName, setCountryName] = useState("");
  const [shiftVal, setShiftVal] = useState("");
  const [mapByVal, setMapByVal] = useState("");

  // VALIDATIONS START
  const checkValidation = (e) => {
    e.preventDefault();
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      setMobileNum(e.target.value);
    } else {
      setFieldReq(true);
      setAlertMessage({ alertType: "fails", message: "Accept only numbers" });
    }
  };
  // VALIDATIONS END

  // MAPPING BY USER START
  const postuserMapping = () => {
    let userMapData = {
      userID: props.userID,
      mapType: JSON.parse(mapByVal.value),
    };
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
      body: JSON.stringify(userMapData),
    };
    fetch(`${baseURL}/api/User/UpdateClientMaping`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          props.showEditModel();
          props.getUserDetails();
          // swal.showAlert("Success", `${res.message}`, "success");
        } else {
          props.showEditModel();
          swal.showAlert("Fail", `${res.errorMessage}`, "warning");
        }
      });
  };
  // MAPPING BY USER END

  // SET EXISTING VALUE TO DROPDOWN START

  useEffect(() => {
    if (countryCode !== null && countryCode !== "") {
      countryCodeList.map((contry) => {
        if (countryCode == contry.value) {
          setCountryVal(contry);
        }
      });
    }
    if (reportDesignation !== null && reportDesignation !== "") {
      designationList.map((designation) => {
        if (reportDesignation == designation.value) {
          setDesignationVal(designation);
        }
      });
    }
    if (manager !== null && manager !== "") {
      managerList.map((mngrlist) => {
        if (manager == mngrlist.value) {
          setManagerVal(mngrlist);
        }
      });
    }
    if (countryId !== null && countryId !== "") {
      countryList.map((cntrList) => {
        if (countryId == cntrList.value) {
          setCountryName(cntrList);
        }
      });
    }
    if (shift !== null && shift !== "") {
      shiftList.map((shifts) => {
        if (shift == shifts.value) {
          setShiftVal(shifts);
        }
      });
    }

    if (mapById !== null && mapById !== "") {
      optionsMapByListModel.map((maplist) => {
        if (mapById == maplist.value) {
          setMapByVal(maplist);
        }
      });
    }
  }, [
    manager,
    countryCode,
    reportDesignation,
    countryId,
    shift,
    shiftList,
    mapById,
    managerList,
  ]);
  // SET EXISTING VALUE TO DROPDOWN END

  // EDIT USER START
  const editUser = (imgUrl) => {
    if (
      countryCode !== "" &&
      mobilenum.toString().length == 10 &&
      role !== "" &&
      location !== "" &&
      country !== "" &&
      stateIds !== "" &&
      stateIds.length > 0 &&
      zoneIds !== "" &&
      zoneIds.length > 0 &&
      cityIds !== "" &&
      cityIds.length > 0
    ) {
      const editUserDetails = {
        desingation: {
          designationID: reportDesignation,
          // departmentID: departmentId,
          // role: {
          //   roleID: role,
          // },
        },
        // roleId: ,
        // registrationID: "",
        // password: "",
        userId: props.userID,
        fullName: fullname,
        email: emailaddress,
        profilePic: imgUrl,
        countryCode: countryCode,
        contact: JSON.parse(mobilenum),
        code: usercode,
        enableStatus: enableStatus,
        allowMeetings: "YES",
        managerId: manager,
        shiftID: JSON.parse(shift),
        mapType: mapById,
        baseLoc: {
          latitude: JSON.stringify(latLan.lat),
          longitude: JSON.stringify(latLan.lng),
          location: location,
        },
      };
      let requestOption = {
        method: "POST",
        mode: "cors",
        headers: travelizeAuth,
        body: JSON.stringify(editUserDetails),
      };

      if (oldShift != shift) {
        // alert("Hello Shift Changed");
        let requestOptionShift = {
          method: "POST",
          mode: "cors",
          headers: travelizeAuth,
          body: JSON.stringify({
            userID: props.userID,
            shiftID: JSON.parse(shift),
          }),
        };
        fetch(`${baseURL}/api/User/UpdateShift`, requestOptionShift)
          .then((res) => res.json())
          .then((res) => {
            // console.log("SHIFT-CHANGE-RES::", res);
          });
      }
      // let IsCityChange = false;
      // // check if new city added
      // city.forEach((x) => {
      //   if (!selectedOldCities.some((y) => x.cityId == y.cityId)) {
      //     IsCityChange = true;
      //   }
      // });
      // if (!IsCityChange) {
      //   //check if city deleted
      //   selectedOldCities.forEach((x) => {
      //     if (!city.some((y) => x.cityId == y.cityId)) IsCityChange = true;
      //   });
      // }

      fetch(`${baseURL}/api/User/EditUser`, requestOption)
        .then((res) => res.json())
        .then((res) => {
          // console.log("EDIT-USER-RES::", res);
          if (res.success) {
            let resUserId = props.userID;
            // if (IsCityChange) {
            deleteOldRegion(resUserId);
            // }
            // addZoneStateCity(resUserId);
            props.showEditModel();
            props.getUserDetails();
            swal.showAlert("Success", `${res.message}`, "success");
          } else {
            props.showEditModel();
            swal.showAlert("Fail", `${res.errorMessage}`, "warning");
          }
          postuserMapping();
        });
    } else {
      setFieldReq(true);
      setAlertMessage({
        alertType: "fails",
        message: "Required",
      });
    }
  };
  // EDIT USER END

  // DELETE OLD REGION START
  const deleteOldRegion = (resUserId) => {
    let requestOption = {
      method: "POST",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Zone/DeleteUserRegions?userId=${resUserId}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        addZoneStateCity(resUserId);
      })
      .catch((error) => {
        // console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  // DELETE OLD REGION END

  // GOOGLE MAPS LOCATION START
  useEffect(() => {
    if (searchVal) {
      Geocode.fromAddress(searchVal).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          // console.log("ADDRESS-RESPONSE:::", response.results[0]);
          setLatLan({
            lat: +lat,
            lng: +lng,
          });
        },
        (error) => {
          console.error(error);
        }
      );
    }
  }, [searchVal]);

  const onMarkerDragEnd = (e) => {
    setLatLan({
      lat: +e.latLng.lat(),
      lng: +e.latLng.lng(),
    });
  };

  const searchLocation = (event) => {
    setSearchVal(event.target.value);
  };

  useEffect(() => {
    if (latLan) {
      Geocode.fromLatLng(latLan.lat, latLan.lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          // console.log("GOOGLE-ADDRESS-PLACEHOLDER:", address);
          setAddress(response.results[0].formatted_address);
          setLocation(address);
        },
        (error) => {
          console.error(error);
        }
      );
    } else {
      addressFromGoogle();
    }
  }, [latLan]);

  // GOOGLE MAPS LOCATION END

  return (
    <div>
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          <Modal
            show={props.editusermodelshow}
            // onHide={props.showEditModel}
            className="main_model"
          >
            <div className="add-user-heading">
              <h6 className="myinvoive-head">EDIT USER</h6>
              <i className="fa fa-times" onClick={props.showEditModel} />
            </div>
            <form onSubmit={editProfileImg}>
              <div className="container model-add-user-body">
                <div className="row add-user">
                  <div className="col-md-5">
                    <div className="d-flex">
                      {/* <div className="add_user-image">
                        {localImage ? (
                          <img
                            src={localImage}
                            alt="Uploaded Image"
                            className="add-user-image"
                          />
                        ) : (
                          <img
                            className="add-user-image"
                            src={
                              profileImageUrl !== "" &&
                              profileImageUrl !== null &&
                              profileImageUrl !== "no_img.png"
                                ? `${baseURL}/${profileImageUrl}`
                                : profileimg
                            }
                            alt="Profile Image"
                          />
                        )}
                        <button
                          type="button"
                          className="btn btn-add-user-img"
                          onClick={uploadClick}
                        >
                          <span className="btn-inline-items">
                            <i className="fa fa-user-plus"> </i>
                            <h6>Edit Profile Picture</h6>
                          </span>
                        </button>
                        <input
                          id="getFile"
                          type="file"
                          name="filetobase64"
                          accept="image/*"
                          className="btn-primary upload"
                          ref={hiddenFileInput}
                          onChange={handleChange}
                          style={{ display: "none" }}
                        />
                      </div> */}
                      <div className="main-logo-image">
                        {localImage ? (
                          <img
                            src={localImage}
                            alt=""
                            className="product-images"
                          />
                        ) : (
                          <img
                            className="product-images"
                            src={
                              profileImageUrl !== "" &&
                              profileImageUrl !== null &&
                              profileImageUrl !== "no_img.png"
                                ? `${baseURL}/${profileImageUrl}`
                                : profileimg
                            }
                            alt=""
                          />
                        )}
                        <div>
                          <button
                            type="button"
                            className="btn upload-logo"
                            onClick={uploadClick}
                          >
                            <img
                              src="../images/uploadImage/camera.svg"
                              alt=""
                            />
                          </button>
                          <input
                            id="getFile"
                            type="file"
                            name="filetobase64"
                            accept="image/*"
                            className="btn-primary upload"
                            ref={hiddenFileInput}
                            onChange={handleChange}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="img-file-name">{imgFileName}</div> */}
                  </div>
                  <div className="col-md-7">
                    <div className="mb-3">
                      <FloatingLabel
                        label="Full Name"
                        className={`${
                          fullname
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          className="form-control form add-user-input"
                          id="exampleFormControlInput1"
                          required
                          // placeholder={user.fullName}
                          value={fullname}
                          onChange={(e) => setFullname(e.target.value)}
                        />
                      </FloatingLabel>
                    </div>
                    <div className="mb-3">
                      <FloatingLabel
                        label="User Code"
                        className={`${
                          usercode
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="text"
                          className="form-control form add-user-input"
                          id="exampleFormControlInput1"
                          value={usercode}
                          onChange={(e) => setUserCode(e.target.value)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                    <div className="mb-3">
                      <FloatingLabel
                        label="E-Mail Address"
                        className={`${
                          emailaddress
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <input
                          type="email"
                          className="form-control form add-user-input"
                          id="exampleFormControlInput1"
                          value={emailaddress}
                          onChange={(e) => setEmailAddress(e.target.value)}
                          required
                        />
                      </FloatingLabel>
                    </div>
                    <span className="input-mobile-field d-flex mb-3">
                      <div className="country-code">
                        <FloatingLabel
                          label="Code"
                          className={`${
                            countryCode
                              ? "float-visible-select"
                              : "float-hidden float-select"
                          }`}
                        >
                          <Select
                            className="react-select-container-list code-select"
                            classNamePrefix="react-select-list"
                            defaultValue={countryCodeList[null]}
                            options={countryCodeList}
                            placeholder="Code"
                            value={countryVal}
                            onInputChange={countryCodeList.label}
                            onChange={(data) => setCountryCode(data.value)}
                            isSearchable={true}
                            required
                          />
                        </FloatingLabel>
                      </div>
                      <div className="mobile-num">
                        <FloatingLabel
                          label="Mobile"
                          className={`${
                            mobilenum
                              ? "float-input-visible"
                              : "float-hidden float-input"
                          }`}
                        >
                          <input
                            type="tel"
                            className="form-control form add-user-input"
                            id="exampleFormControlInput1"
                            placeholder="Mobile"
                            maxLength={10}
                            value={mobilenum}
                            onChange={(e) => checkValidation(e)}
                            required
                          />
                        </FloatingLabel>
                      </div>
                    </span>
                    {fieldReq && mobilenum.toString().length != 10 ? (
                      <small
                        id={`Error${alertMessage.alertType}`}
                        className="form-text text-muted "
                      >
                        {`${alertMessage.message} - 10 Numbers`}
                      </small>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row">
                    <div className="mb-3">
                      <FloatingLabel
                        label="Designation"
                        className={`${
                          reportDesignation
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          defaultValue={designationList[null]}
                          options={designationList}
                          placeholder="Designation"
                          value={designationVal}
                          onInputChange={designationList.label}
                          onChange={(data) => setReportDesignation(data.value)}
                          isSearchable={true}
                        />
                      </FloatingLabel>

                      {(fieldReq && reportDesignation === "") ||
                      (fieldReq && reportDesignation === 0) ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-3">
                      <FloatingLabel
                        label="Select Reporting"
                        className={`${
                          manager
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          defaultValue={managerList[null]}
                          options={managerList}
                          placeholder="Select Manager"
                          value={managerVal}
                          onInputChange={managerList.label}
                          onChange={(data) => setManager(data.value)}
                          isSearchable={true}
                        />
                      </FloatingLabel>
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-2 input-address-location">
                      <FloatingLabel
                        label="Location"
                        className={`${
                          location
                            ? "float-input-visible"
                            : "float-hidden float-input"
                        }`}
                      >
                        <textarea
                          rows="3"
                          cols="20"
                          name="locationtxt"
                          wrap="hard"
                          className="form-control form-textarea add-user-input"
                          id="exampleFormControlInput1"
                          placeholder="Location"
                          value={location}
                          readOnly={true}
                          // onChange={(e) => setLocation(e.target.value)}
                        />
                      </FloatingLabel>
                      {/* </StandaloneSearchBox> */}
                      {fieldReq && location === "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message}
                        </small>
                      ) : (
                        ""
                      )}
                      <OverlayTrigger
                        placement={"right"}
                        delay={{ show: 250, hide: 300 }}
                        overlay={
                          <Tooltip id={`tooltip-googlemap`}>
                            Google Maps
                          </Tooltip>
                        }
                      >
                        <div>
                          <i
                            className="bi bi-geo-alt"
                            onClick={openGoogleMaps}
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <FloatingLabel
                        label="Select Country"
                        className={`${
                          countryName
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Select
                          className="react-select-container-list model-select"
                          classNamePrefix="react-select-list"
                          defaultValue={countryList[null]}
                          options={countryList}
                          placeholder="Select Country"
                          value={countryName ? countryName : countryList.value}
                          onInputChange={countryList.label}
                          onChange={(data) => setCountry(data.value)}
                        />
                      </FloatingLabel>
                      {fieldReq && country === "" ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-6 mb-3">
                      <FloatingLabel
                        label="Select Zone"
                        className={`${
                          zone.length !== 0
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Multiselect
                          className="select-add-user-model zone"
                          options={zoneList} // Options to display in the dropdown
                          selectedValues={zone} // Preselected value to persist in dropdown
                          onSelect={handleSelectZone} // Function will trigger on select event
                          onRemove={handleRemoveZone} // Function will trigger on remove event
                          showCheckbox={true}
                          displayValue="zoneName" // Property name to display in the dropdown options
                          placeholder={"Select Zone"}
                          emptyRecordMsg="No options available"
                          avoidHighlightFirstOption={true}
                          closeIcon="cancel"
                          showArrow={true}
                          selectionLimit={2}
                        />
                      </FloatingLabel>
                      {fieldReq && zoneIds.length <= 0 ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="row">
                    <div className="mb-3">
                      <FloatingLabel
                        label="Select States"
                        className={`${
                          state.length !== 0
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Multiselect
                          className="select-add-user-model"
                          options={stateList} // Options to display in the dropdown
                          selectedValues={state} // Preselected value to persist in dropdown
                          onSelect={handleSelectState} // Function will trigger on select event
                          onRemove={handleRemoveState} // Function will trigger on remove event
                          showCheckbox={true}
                          displayValue="stateName" // Property name to display in the dropdown options
                          placeholder={"Select States"}
                          emptyRecordMsg="No options available"
                          avoidHighlightFirstOption={true}
                          closeIcon="cancel"
                          groupBy="zoneName"
                          showArrow={true}
                        />
                      </FloatingLabel>
                      {fieldReq && stateIds.length <= 0 ? (
                        <small
                          id={`Error${alertMessage.alertType}`}
                          className="form-text text-muted "
                        >
                          {alertMessage.message}
                        </small>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="mb-3">
                      <FloatingLabel
                        label="Select Cities"
                        className={`${
                          city.length !== 0
                            ? "float-visible-select"
                            : "float-hidden float-select"
                        }`}
                      >
                        <Multiselect
                          className="select-add-user-model"
                          options={cityList} // Options to display in the dropdown
                          selectedValues={city} // Preselected value to persist in dropdown
                          onSelect={handleSelectCity} // Function will trigger on select event
                          onRemove={handleRemoveCity} // Function will trigger on remove event
                          showCheckbox={true}
                          displayValue="cityName" // Property name to display in the dropdown options
                          placeholder={"Select Cities"}
                          emptyRecordMsg="No options available"
                          avoidHighlightFirstOption={true}
                          closeIcon="cancel"
                          groupBy="stateName"
                          showArrow={true}
                        />
                      </FloatingLabel>
                    </div>
                  </div>
                  <div className="mb-3">
                    <FloatingLabel
                      label="Select Shift"
                      className={`${
                        shift
                          ? "float-visible-select"
                          : "float-hidden float-select"
                      }`}
                    >
                      <Select
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        defaultValue={shiftList[null]}
                        options={shiftList}
                        placeholder="Select Shift"
                        value={shiftVal}
                        onInputChange={shiftList.label}
                        onChange={(data) => setShift(data.value)}
                        isSearchable={true}
                      />
                    </FloatingLabel>
                  </div>

                  <div className="mb-3">
                    <FloatingLabel
                      label="Map By Method"
                      className={`${
                        mapByVal.value
                          ? "float-visible-select"
                          : "float-hidden float-select"
                      }`}
                    >
                      <Select
                        className="react-select-container-list model-select"
                        classNamePrefix="react-select-list"
                        defaultValue={optionsMapByListModel[null]}
                        options={optionsMapByListModel}
                        placeholder="Map User By :"
                        value={mapByVal.value && mapByVal}
                        onInputChange={optionsMapByListModel.label}
                        onChange={(data) => setMapByVal(data)}
                        isSearchable={true}
                      />
                    </FloatingLabel>
                  </div>
                  <div className="mt-3">
                    <span className="enable-status d-flex">
                      <h6>Enable</h6>
                      <span className="enable-radio d-flex">
                        <div className="form-check enable-radio-status">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                            checked={enableStatus == 1 ? true : false}
                            onChange={() => {
                              setEnableStatus(1);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="form-check enable-radio-status">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            checked={enableStatus == 0 ? true : false}
                            onChange={() => {
                              setEnableStatus(0);
                            }}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            No
                          </label>
                        </div>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="btns-save-cancel">
                {showAlert ? (
                  <div className={`response-message ${alertMessage.alertType}`}>
                    <p>{alertMessage.message}</p>
                  </div>
                ) : (
                  ""
                )}
                <SBSaveUpdateBtn btnName="Update" />
                <SbCancelBtn
                  btnName="Cancel"
                  onClickEffect={props.showEditModel}
                />
              </div>
            </form>
          </Modal>
          {googleMapsModel ? (
            // <LoadScript googleMapsApiKey={React_Google_API_Key}>
            <GoogleMapAddressModel
              googleMapsModel={googleMapsModel}
              addressFromGoogle={addressFromGoogle}
              openGoogleMaps={openGoogleMaps}
              latLan={latLan}
              searchLocation={searchLocation}
              searchVal={searchVal}
              onMarkerDragEnd={onMarkerDragEnd}
              address={address}
            />
          ) : (
            // </LoadScript>
            ""
          )}
        </>
      )}
    </div>
  );
}

export default Editusermodel;
