import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Designation.scss";
import { Col, Form, Row } from "react-bootstrap";
import { baseURL } from "../../BaseUrl";
// import { travelizeAuth } from "../HeaderAuthentication";
import ReactPaginate from "react-paginate";
import moment from "moment";
import { exportExcel } from "../../DownloadFile";
import Select from "react-select";
import { optionsStatusList, optionsPageSizeList } from "../../DropdownsData";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import Skeleton from "react-loading-skeleton";
import AddDepartment from "../Department/AddDepartment";
import {
  SkeletonStdTableHead,
  SkeletonStdTableBody,
} from "../../Skeleton Loading/SkeletonStdTable";
import AddDesignation from "./AddDesignation";
import EditDesignation from "./EditDesignation";
import SbAddBtn, { SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import { loadPageBreadCome } from "../../../redux/action";
import { useDispatch } from "react-redux";
import SbCancelBtn, { SbBackBtn } from "../../SbButtons/SbCancelBtn";
import { useNavigate } from "react-router-dom";
import DesignationTable from "./DesignationTable";

export default function Designation() {
  const [menuCollapse, setMenuCollapse] = useState(false);
  const [editDesignation, setEditDesignation] = useState(false);
  const [departmentList, setDepartmentList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [designationData, setDesignationData] = useState([]);
  // console.log(designationData, "designationData");
  const [activeStatus, setActiveStatus] = useState(2);
  const [designationId, setDesignationId] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);
  const [editDesignationID, setEditDesignationID] = useState(0);
  const [roleId, setRoleId] = useState(0);
  const [show, setShow] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [isLoading, setIsLoading] = useState(true);

  let tableHeader = [
    {name: "SL.NO.", align: "center", width: "80px"},
    {name: "DESIGNATION", align: "left", width: "150px"},
    {name: "LEVEL", align: "center", width: "80px"},
    {name: "DEPARTMENT", align: "left", width: "150px"},
    {name: "ROLE", align: "left", width: "150px"},
    {name: "REPORTING", align: "left", width: "150px"},
    {name: "DESCRIPTION", align: "left", width: "200px"},
    {name: "ADDED BY", align: "left", width: "150px"},
    {name: "STATUS", align: "center", width: "120px"},
    {name: "ACTION", align: "center", width: "120px"}
  ];

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let dispatch = useDispatch();

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalemployee, setTotalemployee] = useState(0);
  const [pageDataSize, setPageDataSize] = useState(0);
  const [itemOffset, setItemOffset] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);
 
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  const handleClose = () => {
    setShow(false);
    setEditDesignation(false);
  };

  useEffect(() => {
    let breadCome = { mainpage: "Designation" }
    dispatch(loadPageBreadCome(breadCome))
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/Department/BindDepartmentDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setDepartmentList(
            res.result.map((data) => {
              return {
                value: data.departmentID,
                label: data.departmentName,
              };
            })
          );
        } else {
          setDepartmentList([]);
        }
      });
    fetch(`${baseURL}/api/Role/BindRolesDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setRoleList(
            res.result.map((data) => {
              return { value: data.roleID, label: data.roleName };
            })
          );
        } else {
          setRoleList([]);
        }
      });
  };

  // GET  PAGE START
  useEffect(() => {
    fetchDesignations();
  }, [
    designationId,
    roleId,
    departmentId,
    activeStatus,
    PageSize,
    currentPage,
  ]);

  const fetchDesignations = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Designation/Get?DesignationID=${designationId}&RoleID=${roleId}&DepartmentID=${departmentId}&Status=${activeStatus}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        // console.log("DEPARTMENT::", res.result);
        if (res.success) {
          setDesignationData(res.result);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setTotalData(res.recordCount[0]);
          setIsLoading(false);
          setLoading(false)
        } else {
          setDesignationData("");
          setIsLoading(false);
          setLoading(false)
        }
      });
  };
  // GET  PAGE END

  const [openFilterTab, setOpenFilterTab] = useState(false);
  const clearSerachBySelect = () => {
    setDepartmentId(0);
    setDesignationId(0);
    setRoleId(0);
    setActiveStatus(2);
  };

  const exportExcelDownload = () => {
    exportExcel(
      `${baseURL}/api/Export/DesignationExcelExport?DesignationID=${designationId}&RoleID=${roleId}&DepartmentID=${departmentId}&Status=${activeStatus}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Designations.xlsx"
    );
  };

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">

                {/* Header and Filter Section */}
                <div style={{ position: "relative" }}>
                  <Row className='search-row'>
                    <Col md={3} className='cat-col'>
                      <div className='page-header-text-div'>
                        <div className="back-btn" onClick={goToSettingPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                        <h6 className='page-header-text' style={{ color: "#04a96d" }}>DESIGNATION</h6>
                      </div>
                    </Col>
                    <Col md={9} className='cat-col'>
                      <div className="search-bar-header" style={{ float: "right" }}>
                        <div className="page-top-bar-dropdowns page-top-Filters">
                          <div className="header-filter-btn" onClick={() => setOpenFilterTab(!openFilterTab)}>
                            <p className="card-head">
                              <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                            </p>
                          </div>
                          <SbAddBtn onClickEffect={() => setShow(!show)} />
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {openFilterTab &&
                    <div className="page-filter-section">
                      <div style={{ width: "100%" }}>
                        <Row>
                          <Col md={8}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <div style={{ width: "100%", padding: "0px 5px" }}>
                              <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={departmentList[0].label}
                        options={departmentList}
                        placeholder="Select Department"
                        value={departmentId == 0 ? "" : departmentList.value}
                        onInputChange={departmentList.label}
                        onChange={(data) => setDepartmentId(data.value)}
                        isSearchable={true}
                      />
                              </div>
                              <div style={{ width: "100%", padding: "0px 5px" }}>
                              <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={roleList[0].label}
                        options={roleList}
                        placeholder="Select Role"
                        value={roleId == 0 ? "" : roleList.value}
                        onInputChange={roleList.label}
                        onChange={(data) => setRoleId(data.value)}
                        isSearchable={true}
                      />
                              </div>
                              <div style={{ width: "100%", padding: "0px 5px" }}>
                              <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        // defaultValue={optionsRoleList[0].label}
                        options={optionsStatusList}
                        placeholder="Select Status"
                        value={activeStatus == 2 ? "" : optionsStatusList.value}
                        onInputChange={optionsStatusList.label}
                        onChange={(data) => setActiveStatus(data.value)}
                        isSearchable={true}
                      />
                              </div>

                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="clients-filter-input-boxs filter-box-div">
                              <SbCancelBtn onClickEffect={clearSerachBySelect}
                                btnName="Clear" />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  }
                </div>
                {/* Header and Filter Section */}
                <DesignationTable
                  tableHeader={tableHeader}
                  designationData={designationData}
                  isLoading={isLoading}
                  setEditDesignation={setEditDesignation}
                  editDesignation={editDesignation}
                  setEditDesignationID={setEditDesignationID}
                  loading={loading}
                  setPageSize={setPageSize}
                  PageSize={PageSize}
                  setLoading={setLoading}
                  totalData={totalData}
                />
                {/* USER TABLE START */}
                <div className="designation-table">
                  <div className="download-and-pagination">
                    <Select
                      className="react-select-container-list"
                      classNamePrefix="react-select-list"
                      // defaultValue={optionsRoleList[0].label}
                      options={optionsPageSizeList}
                      placeholder="Show Per Page"
                      value={optionsPageSizeList.value}
                      onInputChange={optionsPageSizeList.label}
                      onChange={(data) => setPageSize(data.value)}
                      isSearchable={true}
                    />
                    <SbDownloadBtn onClickEffect={exportExcelDownload} />
                    <div className="user-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i className="fa fa-angle-double-right" />}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <i className="fa fa-angle-double-left" />
                        }
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                      />
                    </div>
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* ADD MODEL START */}
                {show ? (
                  <AddDesignation
                    show={show}
                    handleClose={handleClose}
                    fetchDesignations={fetchDesignations}
                  />
                ) : (
                  ""
                )}
                {/* ADD MODEL END */}

                {/* EDIT MODEL START */}
                {editDesignation ? (
                  <EditDesignation
                    editDesignation={editDesignation}
                    editDesignationID={editDesignationID}
                    handleClose={handleClose}
                    fetchDesignations={fetchDesignations}
                  />
                ) : (
                  " "
                )}
                {/* EDIT  MODEL END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}
