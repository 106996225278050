import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import React from "react";
import { OverlayTrigger, Tooltip, Image } from "react-bootstrap";
import SkeletonTableData from "../Skeleton Loading/SkeletonTableData";
import bikeicon from "../../images/bike.svg";
import caricon from "../../images/car.svg";
import busicon from "../../images/bus.svg";
import autoicon from "../../images/auto.svg";
import OnScrollLoading, { handleScroll } from "../OnScrollLoading";
import { dateFormatLocal } from "../FormattedAmount";

function MeetingTable(props) {

    return (
        <div className="sb-table-div sb-table-setion">
            <Paper
                sx={{ width: "100%", overflow: "hidden" }}
                className="table-main-div"
            >
                <TableContainer sx={{ maxHeight: 440 }}
                    onScroll={(e) => handleScroll(e, props.meetingDetails, props.totalCount, props.setLoading, props.setPageSize, props.PageSize)}>
                    <Table stickyHeader aria-label="sticky table table-responsive">
                        <TableHead className="custom-table-header">
                            <TableRow className="table-row">
                                {props.tableHeader.map((name, i) => (
                                    <TableCell
                                        key={i} align={name.align}
                                        className={`thead-cell-products`}
                                        style={{ minWidth: `${name.width}` }}
                                    >
                                        {name.name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ? (
                            <TableBody>
                                {props.tableHeader.map((name, i) => (
                                    <SkeletonTableData key={i} />
                                ))}
                            </TableBody>
                        ) : (
                            <>
                                {!props.meetingDetails ||
                                    props.meetingDetails.length === 0 ? (
                                    <TableBody>
                                        <TableRow align="center" >
                                            <TableCell align="center" colSpan={props.tableHeader.length}>
                                                <div style={{ padding: "20px 20px" }}>
                                                    <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                                                    <span>No Data Found...!</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                ) : (
                                    <TableBody style={{ position: "relative" }}>
                                        {props.meetingDetails.map((data, i) => (
                                            <TableRow
                                                align="center"
                                                key={i}
                                                className="client-list-row-data table-row"
                                            >
                                                <TableCell>
                                                    <p>
                                                        <b className="color-green">
                                                            {data?.fullName}
                                                        </b>
                                                    </p>
                                                    <p>{data?.userCode}</p>
                                                    <p>{data?.stateName}</p>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="client-meet d-flex">
                                                        <p>
                                                            <b className="color-green">
                                                                {data?.clientName} -{" "}
                                                            </b>
                                                        </p>
                                                        &nbsp;
                                                        <p className="client-meet-status">
                                                            {data?.meetingType}
                                                        </p>
                                                    </span>
                                                    <i className="fa fa-user mtdtls-icon" />
                                                    &nbsp;
                                                    {data?.contactPersonName ??
                                                        data?.contactPerson ??
                                                        data?.clientName}
                                                    <br />
                                                    <i className="fa fa-mobile mtdtls-icon" />{" "}
                                                    &nbsp;
                                                    {data?.contactPersonNumber ?? data?.contact}
                                                    <br />
                                                    <i className="fa fa-thumb-tack mtdtls-icon" />{" "}
                                                    &nbsp;
                                                    {data?.clientName === "Check-Out"
                                                        ? data?.endLoc?.location
                                                        : data?.clientLoc?.location}
                                                    <span className="client-meet-location">
                                                        <p className="client-meet-lat">
                                                            <span className="color-yellow"><i className="bi bi-geo-alt" /></span>
                                                            {data?.startLoc?.latitude ?? "NA"},
                                                            {data?.startLoc?.longitude ?? "NA"}
                                                        </p>
                                                        <p className="client-meet-lan">
                                                            <span className="color-yellow"><i className="bi bi-geo-alt" /></span>
                                                            {data?.endLoc?.latitude ?? "NA"},
                                                            {data?.endLoc?.longitude ?? "NA"}
                                                        </p>
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    <p>{dateFormatLocal(data?.meetingDate)}, {data?.meetingTime}</p>
                                                </TableCell>
                                                <TableCell>
                                                    <span className="client-meet-purpose d-flex">
                                                        <p>
                                                            <b>Purpose :</b>
                                                        </p>
                                                        &nbsp;
                                                        <p className="client-meet-purpose-status">
                                                            {data?.purpose ?? "NA"}
                                                        </p>
                                                    </span>
                                                    <p>
                                                        In Time :{" "}
                                                        {data?.inTime === null ||
                                                            data?.inTime === ""
                                                            ? "NA"
                                                            : data?.inTime}
                                                    </p>
                                                    <p>
                                                        Out Time :{" "}
                                                        {data?.outTime === null ||
                                                            data?.outTime === ""
                                                            ? "NA"
                                                            : data?.outTime}
                                                    </p>
                                                    <p>
                                                        <b>Duration : </b> &nbsp;{data?.timeSpent}
                                                    </p>
                                                    <p>
                                                        <b>Distance Travelled : </b>
                                                        &nbsp;
                                                        {data?.distanceTravelled === null ||
                                                            data?.distanceTravelled === ""
                                                            ? 0
                                                            : data?.distanceTravelled}
                                                    </p>
                                                    <p>
                                                        <b>Manual Route : </b> &nbsp;
                                                        {data?.setRootStatus ?? "NA"}
                                                    </p>
                                                </TableCell>
                                                <TableCell >
                                                    <div style={{ textAlign: "left" }}>
                                                        <Image
                                                            src={
                                                                data?.mot == "Bike" ||
                                                                    data?.mot == "bike"
                                                                    ? bikeicon
                                                                    : data?.mot == "Car" ||
                                                                        data?.mot == "car"
                                                                        ? caricon
                                                                        : data?.mot == "Bus" ||
                                                                            data?.mot == "bus"
                                                                            ? busicon
                                                                            : data?.mot == "Auto" ||
                                                                                data?.mot == "auto"
                                                                                ? autoicon
                                                                                : data?.mot == "NA" ||
                                                                                    data?.mot == null
                                                                                    ? ""
                                                                                    : ""
                                                            }
                                                            className={
                                                                data?.mot == "Bus" ||
                                                                    data?.mot == "bus" ||
                                                                    data?.mot == "NA" ||
                                                                    data?.mot == null
                                                                    ? "mot-icon-sml"
                                                                    : "mot-icon"
                                                            }
                                                        />
                                                        <br />
                                                        {data?.mot}
                                                    </div>
                                                </TableCell>
                                                <TableCell>
                                                    {data?.lessRemark === "NA" ? (
                                                        data?.lessRemark
                                                    ) : (
                                                        <OverlayTrigger
                                                            placement={"bottom"}
                                                            delay={{ show: 250, hide: 300 }}
                                                            overlay={
                                                                <Tooltip id={`tooltip-remarks`}>
                                                                    {data?.remark}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <div>{data?.lessRemark}</div>
                                                        </OverlayTrigger>
                                                    )}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <p className={`status-badge ${data?.status}`}>
                                                        {data?.status === "PCancelled"
                                                            ? "Pre-Cancelled"
                                                            : data?.status === "ReSchedule"
                                                                ? "Re-Scheduled"
                                                                : data?.status}
                                                    </p>
                                                </TableCell>
                                                <TableCell align="center">
                                                    <span
                                                        className="meeting-detail-action-td"
                                                        style={{
                                                            display:
                                                                data.meetingType === "Check-Out" ||
                                                                    data.status === "Check-Out" ||
                                                                    data.status === "PCancelled" ||
                                                                    data.status === "Pcancelled" ||
                                                                    data.status === "Pending" ||
                                                                    data.status === "pending" ||
                                                                    data.status === "ReSchedule" ||
                                                                    data.status === "Reschedule" ||
                                                                    data.status === "Expired" ||
                                                                    data.status === "expired"
                                                                    ? "none"
                                                                    : "block",
                                                        }}
                                                    >
                                                        <button
                                                            onClick={() => {
                                                                props.setMeetingattachmentmodel(
                                                                    !props.meetingattachmentmodel
                                                                );
                                                                props.setMeetingId(data?.meetingID);
                                                            }}
                                                            className="meeting-details-view"
                                                        >
                                                            <i className="fa fa-paperclip" /> View
                                                        </button>
                                                        <button
                                                            className="meetingdetail-viewroute-link"
                                                            onClick={() => {
                                                                props.setViewonuserroute(!props.viewonuserroute);
                                                                props.setMeetingId(data?.meetingID);
                                                            }}
                                                        >
                                                            <i className="bi bi-geo-alt-fill"></i>{" "}
                                                            View Route
                                                        </button>
                                                    </span>
                                                </TableCell>
                                            </TableRow>
                                        ))}{props.loading &&
                                            <OnScrollLoading />
                                        }

                                    </TableBody>
                                )}
                            </>
                        )}
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    );
}

export default MeetingTable;
