import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import { Form } from 'react-bootstrap';

function ShiftTable(props) {
    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }} >
                    <Table stickyHeader aria-label="sticky table table-responsive">
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell key={i} className={`thead-cell ${(name === "Action" || name === "Sl.No" || name === "Is Night Shift") && "action-class"}`}
                                        style={{ minWidth: "50px" }}>

                                        {name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {!props.shiftData ?
                            <TableBody>
                                <TableRow align="center" >
                                    <TableCell>
                                        No data found...!
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                            :
                            <TableBody>
                                {props.shiftData.filter((val) =>
                                    val?.shiftName
                                        .toLowerCase()
                                        .includes(props.searchValue)
                                ).map((data, i) => (
                                    <TableRow align="center" key={i} className='client-list-row-data'>
                                        <TableCell align='center' >
                                            {i + 1}
                                        </TableCell>
                                        <TableCell>
                                            {data.shiftName}
                                        </TableCell>
                                        <TableCell>
                                            {data.shiftCheckInTime}
                                        </TableCell>
                                        <TableCell>
                                            {data.shiftCheckOutTime}
                                        </TableCell>
                                        <TableCell align="center">
                                            <>{data.isNightShift === 1 ? "Yes" : "No"}</>
                                        </TableCell>
                                        <TableCell className='action-class'>
                                            <button
                                                onClick={(e) => props.handleEdit(data?.shiftID)}
                                                className="btn client-history-link"
                                            >
                                                <i className="fa fa-edit" /> Edit
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>


                        }
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default ShiftTable
