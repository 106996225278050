import React, { useState, useEffect } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/CommonStyle.scss";
import "../../styles/Clients.scss";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import AddClientModel from "./AddClientModel";
import { baseURL } from "../BaseUrl";
import AddBulkClient from "./AddBulkClient";
import ClientHistory from "./ClientHistory";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { optionsStatusList } from "../DropdownsData";
import { loggedInRole } from "../LoginRole";
import AccessDenied from "../../AccessDenied";
import MappedToUsers from "./MappedToUsers";
import SbDropDownBtn from "../SbButtons/SbDropDownBtn";
import ViewClient from "./ViewClient";
import { loadClientCategoryDrop, loadClientStageGrop, loadCompanyData, loadFilterCompanyData, loadGetSingleClient, loadPageBreadCome } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import ClientTable from "./ClientTable";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";

function Clients() {

  let dispatch = useDispatch();
  const { companyData } = useSelector(state => state.data);
  const { categoryDropdown } = useSelector(state => state.data);
  const { stageDropdown } = useSelector(state => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [editclientmodel, setEditclientmodel] = useState(false);
  const [stateList, setStateList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [activeStatus, setActiveStatus] = useState('');
  const [selectCategory, setSelectCategory] = useState("");
  const [selectStage, setSelectStage] = useState('');
  const [filterUserId, setFilterUserId] = useState("");
  const [managerId, setManagerId] = useState("");
  const [stateName, setStateName] = useState("");
  const [searchval, setSearchval] = useState("");
  const [clientId, setClientId] = useState(0);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [addClientmodelShow, setAddClientmodelShow] = useState(false);
  const [addBulkClientModel, setAddBulkClientModel] = useState(false);
  const [historyclientmodel, setHistoryclientmodel] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showMappedTo, setShowMappedTo] = useState(false);

  let tableHeader = [
    {name: "COMPANY NAME", align: "left", width: "200px"},
    {name: "CONTACT PERSON", align: "left", width: "150px"},
    {name: "MOBILE / EMAIL", align: "left", width: "150px"},
    {name: "CATEGORY / STAGE", align: "left", width: "200px"},
    {name: "LOCATION", align: "left", width: "300px"},
    {name: "ADDED BY", align: "left", width: "150px"},
    {name: "STATUS", align: "center", width: "100px"},
    {name: "ACTION", align: "center", width: "150px"}
  ]

  const [totalCounts, setTotalCounts] = useState([]);

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));

  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const handleCloseBulkClient = () => {
    setAddBulkClientModel(!addBulkClientModel);
  };

  const handleClose = () => {
    setAddClientmodelShow(false);
    setEditclientmodel(false);
    setShowMappedTo(false);
  };

  const openAddModal = () => {
    setAddClientmodelShow(true);
  }

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDataSize, setPageDataSize] = useState("");
  const [searchValue, setSearchValue] = useState('')
  const [filterArray, setFilterArray] = useState([])

  const setSearchData = (value) => {
    let list;
    if(filterArray.length !== 0){
      list = filterArray.filter(item => item.key !== "Text")
    } else {
      list = []
    }
    if(value){
      list.push({key: "Text", value: value})
    } else {
      list = filterArray.filter(item => item.key !== "Text")
    }
    setSearchValue(value)
    setFilterArray(list)
  }

  const setSelectSearchDrop = (data, type) => {
    let list;
    if(type === "user"){
      if(filterArray.length !== 0){
        list = filterArray.filter(item => item.key !== "UserID")
      } else {
        list = []
      }
      list.push({key: "UserID", value: data.value})
      setFilterUserId(data)
    } else if(type === "state"){
      if(filterArray.length !== 0){
        list = filterArray.filter(item => item.key !== "StateName")
      } else {
        list = []
      }
      list.push({key: "StateName", value: data.value})
      setStateName(data)
    } else if(type === "status"){
      if(filterArray.length !== 0){
        list = filterArray.filter(item => item.key !== "EnabledStatus")
      } else {
        list = []
      }
      list.push({key: "EnabledStatus", value: data.value})
      setActiveStatus(data)
    } else if(type === "category"){
      if(filterArray.length !== 0){
        list = filterArray.filter(item => item.key !== "Category")
      } else {
        list = []
      }
      list.push({key: "Category", value: data.value})
      setSelectCategory(data)
    } else if(type === "stage"){
      if(filterArray.length !== 0){
        list = filterArray.filter(item => item.key !== "Stage")
      } else {
        list = []
      }
      list.push({key: "Stage", value: data.value})
      setSelectStage(data)
    }
    setFilterArray(list)
  }

  // PAGINATION END
  const [editClientId, setEditClientId] = useState("");

  const editClientDetails = (clientId) => {
    dispatch(loadGetSingleClient(clientId))
    setEditClientId(clientId)
    setAddClientmodelShow(!addClientmodelShow);
  }

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let breadCome = { mainpage: "Company" }
    dispatch(loadPageBreadCome(breadCome))
    fetchDropdownData();
    fetchClientData(filterArray, PageSize, currentPage)
    dispatch(loadClientCategoryDrop())
    dispatch(loadClientStageGrop())
  }, [filterArray, PageSize, currentPage]);

  const fetchClientData = () => {
    if(filterArray.length === 0){
      dispatch(loadCompanyData(PageSize, currentPage, setPageCount, setTotalCounts, setIsLoading, setLoading))
    } else {
      setIsLoading(true)
      dispatch(loadFilterCompanyData(filterArray, currentPage, PageSize, setPageCount, setTotalCounts, setIsLoading, setLoading))
    }
  }

  const fetchDropdownData = () => {
    let requestOptionZone = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      });
    fetch(`${baseURL}/api/Zone/GetStates/`, requestOptionZone)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setStateList(
            res.result.map((data) => {
              return { value: data.stateName, label: data.stateName };
            })
          );
        } else {
          setStateList([]);
        }
      });
  };

  const [showModal, setShowModal] = useState(false);

  const handleView = (e) => {
    setShowModal(true);
    setClientId(e);

  }

  const goBacktoClients = () => {
    setHistoryclientmodel(!historyclientmodel);
  };

  const clearSerachBySelect = () => {
    setFilterArray([])
    setSearchval("");
    setFilterUserId("");
    setStateName("");
    setActiveStatus(2);
    setSelectCategory("");
    setSelectStage("");
  };

  const exportExcelClient = () => {
    exportExcel(
      `${baseURL}/api/Export/ClientExcelExport?UserID=${filterUserId}&Text=${searchval}&ClientID=${clientId}&ManagerID=${managerId}&EnabledStatus=${activeStatus}&StateName=${stateName}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Clients.xlsx"
    );
  };

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  return (
    <div id="main-page">
      {/* view details modal */}
      {showModal && (
        <ViewClient
          showModal={showModal}
          setShowModal={setShowModal}
          clientId={clientId}
        />
      )}
      {/* view details modal */}

      {userDetails !== null && userDetails !== "" && loggedInRole != 2 ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            {(!historyclientmodel && !viewModal) ? (
              <div className={`main-body ${toggleshift.style}`}>
                
                <div className="page-content">
                  {/* USER TABLE START */}
                  <div className="clients-table">
                    <Row className='search-row'>
                      <Col md={3} className='cat-col'>
                        <div className='page-header-text-div'>
                        <div className="back-btn" onClick={goToSettingPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                          <h6 className='page-header-text' style={{ color: "#04a96d" }}>COMPANY ({totalCounts[0]})</h6>
                        </div>
                      </Col>
                      <Col md={5} className='cat-col'>
                        <div className='input-div'>
                          <input type="text" placeholder='Find a Client' className='form-control setting-input'
                              value={searchValue} onChange={(e) => setSearchData(e.target.value)} 
                              />
                          <i className='fa fa-search search-icon' ></i>
                        </div>
                      </Col>
                      <Col md={4} className='cat-col'>
                        <div className="search-bar-header" style={{ float: "right" }}>
                          <div className="page-top-bar-dropdowns page-top-Filters">
                            <div className="header-filter-btn" onClick={() => setFilter(!filter)}>
                              <p className="card-head">
                                <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                              </p>
                            </div>
                            <SbDropDownBtn openAddModal={openAddModal} pageName="Client" addBulkModel={handleCloseBulkClient} />
                          </div>
                        </div>
                      </Col>
                    </Row>

                    {filter &&
                      <div className="page-filter-section">
                        <div style={{ width: "100%" }}>
                          <Row>
                                <Col md={10}>
                                  <div className="clients-filter-input-boxs filter-box-div">
                                    <div style={{width: "100%", padding: "0px 5px"}}>
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        options={userList}
                                        placeholder="Select User"
                                        value={filterUserId !== "" && filterUserId}
                                        onInputChange={userList.label}
                                        onChange={(data) => setSelectSearchDrop(data, "user")}
                                        isSearchable={true}
                                      // isClearable={true}
                                      />
                                    </div>
                                    <div style={{width: "100%", padding: "0px 5px"}}>
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        options={stateList}
                                        placeholder="Select State"
                                        value={stateName !== "" && stateName}
                                        onInputChange={stateList.label}
                                        onChange={(data) => setSelectSearchDrop(data, "state")}
                                        isSearchable={true}
                                      // isClearable={true}
                                      />
                                    </div>
                                    <div style={{width: "100%", padding: "0px 5px"}}>
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        // defaultValue={optionsRoleList[0].label}
                                        options={optionsStatusList}
                                        placeholder="Select Status"
                                        value={
                                          activeStatus && activeStatus
                                        }
                                        onInputChange={optionsStatusList.label}
                                        onChange={(data) => setSelectSearchDrop(data, "status")}
                                        isSearchable={true}
                                      // isClearable={true}
                                      />
                                    </div>
                                    <div style={{width: "100%", padding: "0px 5px"}}>
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        // defaultValue={optionsRoleList[0].label}
                                        options={categoryDropdown}
                                        placeholder="Select Category"
                                        value={
                                          selectCategory && selectCategory
                                        }
                                        onInputChange={categoryDropdown.label}
                                        onChange={(data) => setSelectSearchDrop(data, "category")}
                                        isSearchable={true}
                                      // isClearable={true}
                                      />
                                    </div>
                                    <div style={{width: "100%", padding: "0px 5px"}}>
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        // defaultValue={optionsRoleList[0].label}
                                        options={stageDropdown}
                                        placeholder="Select Stage"
                                        value={
                                          selectStage && selectStage
                                        }
                                        onInputChange={stageDropdown.label}
                                        onChange={(data) => setSelectSearchDrop(data, "stage")}
                                        isSearchable={true}
                                      // isClearable={true}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col md={2}>
                                  <div className="clients-filter-input-boxs filter-box-div">
                                    <SbCancelBtn onClickEffect={clearSerachBySelect}
                                      btnName="Clear" />
                                  </div>
                                </Col>
                              </Row>
                        </div>
                      </div>
                    }

                    <ClientTable tableHeader={tableHeader}
                      clientData={companyData} isLoading={isLoading}
                      handleView={handleView} setShowMappedTo={setShowMappedTo} showMappedTo={showMappedTo}
                      setClientId={setClientId} setEditclientmodel={setEditclientmodel} editclientmodel={editclientmodel}
                      setHistoryclientmodel={setHistoryclientmodel} historyclientmodel={historyclientmodel}
                      editClientDetails={editClientDetails}
                      loading={loading}
                      setPageSize={setPageSize}
                      PageSize={PageSize}
                      setLoading={setLoading} />

                  </div>
                  {/* USER TABLE END */}
                  <div className="download-and-pagination">
                  <SbDownloadBtn btnName="Download" onClickEffect={exportExcelClient} />
                  </div>
                  {/* ADD CLIENT MODEL START */}
                  {addClientmodelShow && (
                    <AddClientModel
                      addClientmodelShow={addClientmodelShow}
                      isLoading={isLoading}
                      setIsLoading={setIsLoading}
                      handleClose={handleClose}
                      fetchClientData={fetchClientData}
                      editClientId={editClientId} setEditClientId={setEditClientId}
                    />
                  )}
                  {/* ADD CLIENT MODEL END */}

                  {/* ADD BULK CLIENT MODEL START */}
                  {addBulkClientModel && (
                    <AddBulkClient
                      show={addBulkClientModel}
                      handleCloseBulkClient={handleCloseBulkClient}
                    />
                  )}

                  {/* ADD BULK CLIENT MODEL END */}

                  {/* EDIT CLIENT MODEL START */}
                  {/* {editclientmodel && (
                    <EditclientsModel
                      editclientmodel={editclientmodel}
                      handleClose={handleClose}
                      clientId={clientId}
                      fetchClientDetails={fetchClientDetails}
                    />
                  )} */}
                  {/* EDIT CLIENT MODEL END */}

                  {/* MAPPEDTO USER MODEL START */}
                  {showMappedTo && (
                    <MappedToUsers
                      showMappedTo={showMappedTo}
                      handleClose={handleClose}
                      clientId={clientId}
                      filterUserId={filterUserId}
                    />
                  )}
                  {/* MAPPEDTO USER MODEL END */}

                  {/* PAGE CONTENT END*/}
                </div>
              </div>
            ) : (
              <>
                {historyclientmodel && (
                  <ClientHistory
                    historyclientmodel={historyclientmodel}
                    toggleshift={toggleshift}
                    goBacktoClients={goBacktoClients}
                    clientId={clientId}
                  // fetchClientDetails={fetchClientDetails}
                  />
                )}
              </>
            )}
          </div>
          {/* MAIN BODY END */}
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default Clients;
