import React, { useState, useEffect } from "react";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Activities.scss";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { baseURL } from "../../BaseUrl";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { exportExcel } from "../../DownloadFile";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import Select from "react-select";
import { loggedInRole } from "../../LoginRole";
import AccessDenied from "../../../AccessDenied";
import InternetIssue from "../../InternetIssue";
import { Col, Row } from "react-bootstrap";
import { loadPageBreadCome } from "../../../redux/action";
import { useDispatch } from "react-redux";
import SbCancelBtn, { SbBackBtn } from "../../SbButtons/SbCancelBtn";
import ActivityTable from "./ActivityTable";
import { SBSaveUpdateBtn, SbDownloadBtn } from "../../SbButtons/SbAddBtn";
import { optionsPageSizeList } from "../../DropdownsData";
import ActivityListModal from "./ActivityListModal";


function Activities() {
  /* HEADER AUTH START */
  let animLength = 100;
  const newArray = Array.from({ length: animLength });

  let tableHeader = [
    { name: "TIME", align: "left", width: "150px" },
    { name: "LOCATION", align: "left", width: "250px" },
    { name: "BATTERY", align: "left", width: "100px" },
    { name: "SIGNAL", align: "center", width: "100px" },
    { name: "MOCK", align: "center", width: "150px" },
  ];

  let dispatch = useDispatch()
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [userList, setUserList] = useState([]);
  const [deviceInfo, setDeviceInfo] = useState("");
  const [date, setDate] = useState(new Date());
  const [activitiesByUser, setActivitiesByUser] = useState([]);
  const [filterUserId, setFilterUserId] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  let navigate = useNavigate();

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // Lazy Loading
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState(0);

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDataSize, setPageDataSize] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  //* PAGINATION END *//

  const navigateToBack = () => {
    navigate(-1);
  };

  //* FETCH SELECT DROPDOWN START *//
  useEffect(() => {
    fetchDropdownData();
  }, []);

  const fetchDropdownData = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(`${baseURL}/api/User/BindUsersDropDown`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUserList(
            res.result.map((data) => {
              return { value: data.userId, label: data.userFullName };
            })
          );
        } else {
          setUserList([]);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH SELECT DROPDOWN END *//

  //* FETCH DEVICE INFO START *//
  useEffect(() => {
    let breadCome = { mainpage: "DashBoard", page: "Department", subPage: "" }
    dispatch(loadPageBreadCome(breadCome))
    fetchDeviceInfo();
  }, [filterUserId]);

  const fetchDeviceInfo = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(`${baseURL}/api/User/GetDeviceInfo/${filterUserId}`, requestOption)
      .then((res) => res.json())
      .then((res) => {
        setDeviceInfo(res.result);
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH DEVICE INFO END *//

  //* FETCH ACTIVITIES START *//
  useEffect(() => {
    fetchActivitiesByNetwork();
  }, [filterUserId, date, PageSize, currentPage]);

  const fetchActivitiesByNetwork = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };
    fetch(
      `${baseURL}/api/Activity/GetActivities?userId=${filterUserId}&Date=${moment(
        date
      ).format("DD/MM/YYYY")}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setActivitiesByUser(res.result);
          setPageDataSize(res.result.length);
          setTotalData(res.recordCount[0]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
          setLoading(false);
        } else {
          setActivitiesByUser("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* FETCH ACTIVITIES END *//

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId();
    setDate(new Date());
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportExcelActiivity = () => {
    exportExcel(
      `${baseURL}/api/Export/ActivtyExcelExport?UserID=${filterUserId}&Date=${moment(
        date
      ).format("DD/MM/YYYY")}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      "Activites.xlsx"
    );
  };
  //* EXPORT FILE END *//

  const [show, setShow] = useState(false)
  const openMapModal = () => {
    setShow(true)
  }

  const handleClose = () => {
    setShow(false)
  }

  // Page navigation
  const Navigate = useNavigate();
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" && loggedInRole !== 2 ? (
            <>
              {/* SIDEBAR START */}
              <Sidenavbar
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* SIDEBAR END */}

              {/* TOPBAR START */}
              <Topbar
                toggleMenu={toggleMenu}
                menuCollapse={menuCollapse}
                toggleshift={toggleshift}
              />
              {/* MAIN BODY START */}
              <div className="page-wrapper">
                {/* PAGE CONTENT START */}
                <div className={`main-body ${toggleshift.style}`}>
                  <div className="page-content">

                    {/* Filter and Header Section */}
                    <div className="user-table">
                      <Row className="search-row">
                        <Col md={6} className="cat-col">
                          <div className="page-header-text-div">
                            <div
                              className="back-btn"
                              onClick={goToBackPage}
                            >
                              <img
                                src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                alt=""
                              />
                            </div>
                            <h6
                              className="page-header-text"
                              style={{ color: "#04a96d" }}
                            >ACTIVITIES
                            </h6>
                          </div>
                        </Col>
                        <Col md={6} className="cat-col">
                          <div
                            className="search-bar-header"
                            style={{ float: "right" }}
                          >
                            <div className="page-top-bar-dropdowns page-top-Filters">
                              <Row style={{ width: "100%" }}>
                                <Col md={9}>
                                  <div className="clients-filter-input-boxs filter-box-div d-flex">
                                    <div
                                      style={{ width: "100%", padding: "0px 5px" }}
                                    >
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        options={userList}
                                        placeholder="Select User"
                                        value={filterUserId ? userList.label : ""}
                                        onInputChange={userList.label}
                                        onChange={(data) => setFilterUserId(data.value)}
                                        isSearchable={true}
                                      // isClearable={true}
                                      />
                                    </div>
                                    <div
                                      style={{ width: "100%", padding: "0px 5px" }}
                                    >
                                      <DatePicker
                                        selected={date}
                                        className="select single-date"
                                        dateFormat="dd/MM/yyyy"
                                        // selectsEnd
                                        // startDate={date}
                                        // endDate={toDate}
                                        // minDate={fromDate}
                                        onChange={(date) => setDate(date)}
                                        maxDate={new Date()}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col md={3}>
                                  <div className="clients-filter-input-boxs filter-box-div">
                                    <SbCancelBtn
                                      onClickEffect={clearSerachBySelect}
                                      btnName="Clear"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                    {/* Filter and Header Section */}

                    <div className="row user-activity-location-device">
                      <div className="col-md-3 device-info-part">

                        <img src="../../../images/uploadImage/Mobile-device.png" alt="" />

                        <div className="device-info-table">
                          <div className="device-details">
                            {/* {newArray.map((anim, i) => (
                              <div class='particle' key={i}></div>
                            ))} */}
                            <div>
                              <p className="color-yellow"><b>About device</b></p>
                            </div>
                            <div className="modal-update">
                              <div style={{ width: "50%" }}>
                                <h6 className="device-brand">{deviceInfo?.modal ? deviceInfo?.modal : "..."}</h6>
                              </div>
                              <div style={{ width: "50%", padding: "2px 10px" }}>
                                <p>Up to date</p>
                                <p className="color-green" style={{ fontSize: "11px" }}> {deviceInfo?.osVersion ? deviceInfo?.osVersion : "..."}</p>
                              </div>
                            </div>
                            <Row>
                              <Col md={6} className="p-0">
                                <div className="device-name">
                                  <div>
                                    <p className="color-green">Device Name</p>
                                    <p><b>{deviceInfo?.name ?? "..."}</b></p>
                                  </div>
                                </div>
                              </Col>
                              <Col md={6} className="p-0">
                                <div className="device-storage">
                                  <div>
                                    <p className="color-green">Storage</p>
                                    <p ><b>{deviceInfo?.ram ?? "..."}</b></p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <table className="device-table">
                              <tbody className="device-info-table-data">
                                <tr>
                                  <th>Device</th>
                                  <td align="right"><b>{deviceInfo?.name ?? "..."}</b></td>
                                </tr>
                                <tr>
                                  <th scope="row">Model</th>
                                  <td align="right"><b>{deviceInfo?.modal ?? "NA"}</b></td>
                                </tr>
                                <tr>
                                  <th scope="row">OS</th>
                                  <td align="right"><b>{deviceInfo?.os ?? "..."}</b></td>
                                </tr>
                                <tr>
                                  <th scope="row">Version</th>
                                  <td align="right"><b>{deviceInfo?.osVersion ?? "..."}</b></td>
                                </tr>
                                <tr>
                                  <th scope="row">RAM</th>
                                  <td align="right"><b>{deviceInfo?.ram ?? "..."}</b></td>
                                </tr>
                                <tr>
                                  <th scope="row">Battery Tech</th>
                                  <td align="right"><b>{deviceInfo?.batteryTech ?? "..."}</b></td>
                                </tr>
                                <tr>
                                  <th scope="row">Battery Power</th>
                                  <td align="right"><b>{deviceInfo?.batteryPower ?? "..."}</b></td>
                                </tr>
                                <tr>
                                  <th scope="row">Battery Health</th>
                                  <td align="right"><b>{deviceInfo?.batteryHealth ?? "..."}</b></td>
                                </tr>
                                <tr>
                                  <th scope="row">Rooted</th>
                                  <td align="right"><b>{deviceInfo?.rooted ?? "..."}</b></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9 user-activity-details-part">
                        <div className="user-activity-details user-activity-data-scroll">
                          <ActivityTable
                            tableHeader={tableHeader}
                            activitiesByUser={activitiesByUser}
                            loading={loading}
                            setPageSize={setPageSize}
                            PageSize={PageSize}
                            setLoading={setLoading}
                            totalData={totalData}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="download-and-pagination">
                      {/* <SBSaveUpdateBtn btnName="View On Map" onClickEffect={openMapModal} /> */}
                      {activitiesByUser.length > 10 &&
                        <Select
                          className="react-select-container-list"
                          classNamePrefix="react-select-list"
                          // defaultValue={optionsRoleList[0].label}
                          options={optionsPageSizeList}
                          placeholder="Show Per Page"
                          value={optionsPageSizeList.value}
                          onInputChange={optionsPageSizeList.label}
                          onChange={(data) => setPageSize(data.value)}
                          isSearchable={true}
                        // isClearable={true}
                        />
                      }
                      {activitiesByUser.length > 10 &&
                        <div className="data-count-per-page">
                          {pageDataFrom} - {pageDataTill} of {totalData}
                        </div>
                      }
                      <SbDownloadBtn onClickEffect={exportExcelActiivity} />
                      {activitiesByUser.length > 10 &&
                        <span className="user-pagination">
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel={<i className="fa fa-angle-double-right" />}
                            onPageChange={handlePageClick}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={2}
                            pageCount={pageCount}
                            previousLabel={
                              <i className="fa fa-angle-double-left" />
                            }
                            renderOnZeroPageCount={null}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            disabledClassName={"disabled"}
                          />
                        </span>
                      }
                    </div>

                    {/* PAGE CONTENT END*/}
                  </div>
                </div>

                {/* Open Map modal */}
                {show && <ActivityListModal show={show} handleClose={handleClose}
                  activitiesByUser={activitiesByUser} />}
                {/* Open Map modal */}

                {/* MAIN BODY END */}
              </div>
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default Activities;
