import React, { useEffect, useState } from "react";
import "./App.css";
import "../src/MediaFiles/Media.scss";
import { Routes, Route } from "react-router-dom";
import Login from "./Login";
import ForgotPassword from "./Components/ForgotPassword";
import ResetPasswordPage from "./Components/ResetPasswordPage";
import CheckSubscription from "./Components/CheckSubscription";
import Dashboard from "./Components/Dashboard/Dashboard";
import TrackByUser from "./Components/Dashboard/TrackByUser";
import Users from "./Components/Users/Users";
import ViewSubordinates from "./Components/Users/ViewSubbordinates";
import Tracker from "./Components/Tracker/Tracker";
import AttendanceMonthly from "./Components/Attendance/AttendanceMonthly";
import AttendanceFullday from "./Components/Attendance/AttendanceFullday";
import Leaves from "./Components/Leaves/Leaves";
import Meetings from "./Components/Meetings/Meetings";
import Clients from "./Components/Clients/Clients";
import LocalClaim from "./Components/LocalClaim/LocalClaim";
import MeetingDetailsModel from "./Components/LocalClaim/MeetingDetailsModel";
import Schedule from "./Components/Settings/Schedule/Schedule";
import TrackClients from "./Components/Settings/TrackClients/TrackClients";
import MeetingPurpose from "./Components/Settings/MeetingPurpose/MeetingPurpose";
import BaseFare from "./Components/Settings/BaseFare/BaseFare";
import Profile from "./Components/Settings/Profile/Profile";
import AccountDetails from "./Components/Settings/AccountDetails/AccountDetails";
import PageNotFound from "./PageNotFound";
import CreateSubscription from "./Components/PrivatePages/CreateSubscription";
import OutStationClaim from "./Components/OutStationClaim/OutStationClaim";
import KilometerRefreshPage from "./Components/PrivatePages/KilometerRefreshPage";
import ExceptionHandler from "./Components/PrivatePages/ExceptionHandler";
import KilometerRefreshAttendance from "./Components/PrivatePages/KilometerRefreshAttendance";
import SubscriptionDetails from "./Components/PrivatePages/SubscriptionDetails";
import SessionTimeout from "./Components/SessionTimeout";
import ClaimsManager from "./Components/LocalClaim/ClaimsManager";
import ClaimsAdmin from "./Components/LocalClaim/ClaimsAdmin";
import PendingforApproval from "./Components/LocalClaim/PendingforApproval";
import AccessDenied from "./AccessDenied";
import AccountSettings from "./Components/Settings/AccountSettings/AccountSettings";
import DeviceDetails from "./Components/Settings/DeviceDetails/DeviceDetails";
import Products from "./Components/Products/Products";
import Spares from "./Components/Spares/Spares";
import AddDropdownData from "./Components/PrivatePages/AddDropdownData";
import Department from "./Components/Settings/Department/Department";
import Designation from "./Components/Settings/Designation/Designation";
import MeetingManagement from "./Components/SubscriptionModule/MeetingManagement";
import Role from "./Components/Settings/Role/Role";
import Amc from "./Components/AMC/Amc";
import SuscriberPage from "./Components/SubscriptionModule/SuscriberPage";
import ProjectManagement from "./Components/SubscriptionModule/ProjectManagement";
import HumanResource from "./Components/SubscriptionModule/HumanResource";
import OrderManagement from "./Components/SubscriptionModule/OrderManagement";
import StockManagement from "./Components/SubscriptionModule/StockManagement";
import WarehouseManagement from "./Components/SubscriptionModule/WarehouseManagement";
import ComplaintManagement from "./Components/SubscriptionModule/ComplaintManagement";
import DealerManagement from "./Components/SubscriptionModule/DealerManagement";
import DistributionManagement from "./Components/SubscriptionModule/DistributionManagement";
import SettingPage from "./Components/Settings/SettingPage";
import UserTrackerPage from "./Components/UserTrackerPage";
import ClientCategory from "./Components/Settings/Category/ClientCategory";
import ClientStage from "./Components/Settings/Stage/ClientStage";
import Complaints from "./Components/Complaints/Complaints";
import Projects from "./Components/PMS/Projects/Projects";
import Phase from "./Components/PMS/Phase/Phase";
import Tasks from "./Components/PMS/Tasks/Tasks";
import Reports from "./Components/PMS/Reports/Reports";
import PaymentDetails from "./Components/PMS/Reports/PaymentDetails";
import TextForm from "./Components/TextForm";
import Holiday from "./Components/Settings/Holidays/Holiday";
import Shift from "./Components/Settings/Shift/Shift";
import ProductCategory from "./Components/Settings/Product Category/ProductCategory";
import Orders from "./Components/Orders/Orders";
import GoogleCredits from "./Components/SuperAdmin/GoogleCredits/GoogleCredits";
import ApiCredits from "./Components/SuperAdmin/ApiCredits/ApiCredits";
import CartPage from "./Components/Products/CartPage";
import RoutePlan from "./Components/Routes/RoutePlan";
import OrderDetails from "./Components/Orders/OrderDetails";
import GoogleAPIHitCount from "./Components/SuperAdmin/GoogleHitCounts/GoogleAPIHitCount";
import CreateCustomFields from "./Components/Settings/CustomFields/CreateCustomFields";
import OrderHistory from "./Components/Orders/OrderHistory";
import ExceptionPage from "./Components/SuperAdmin/Exceptions/ExceptionPage";
import MobileException from "./Components/SuperAdmin/MobileExceptions/MobileException";
import UnAutherized from "./Components/UnAutherized";
import ProductsViewPage from "./Components/Products/ProductsViewPage";
import Activities from "./Components/SuperAdmin/UserActivity/Activities";
import SuperAdminPage from "./Components/SuperAdmin/SuperAdminPage";
import LeaveType from "./Components/Settings/LeaveType/LeaveType";
import Refferal from "./Components/SuperAdmin/Referal/Refferal";

function App() {
  const resPassData = JSON.parse(sessionStorage.getItem("usrlgndtls"));
  const loginId = sessionStorage.getItem("loginId");

  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    let activities = [];

    if (resPassData) {
      resPassData?.subscription?.modules.map((item) => {
        activities.push({ name: item.moduleName });
      });
    }
    setMenuItems(activities);
    // alert(loginId)
  }, []);

  return (
    <div className="App">
      <SessionTimeout />

      <>
        {loginId ? (
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/login" element={<Dashboard />} />
            <Route path="/forgotpassword" element={<Dashboard />} />
            <Route path="/resetpassword" element={<Dashboard />} />
            <Route path="/userTracker" element={<UserTrackerPage />} />
            <Route path="/userTracker/:id" element={<UserTrackerPage />} />
            <Route path="/textForm" element={<TextForm />} />
            <Route
              path="/settings/superadminpage"
              element={<SuperAdminPage />}
            />
            <Route path="/refferal" element={<Refferal />} />
            <Route path="/googlecredits" element={<GoogleCredits />} />
            <Route path="/apicredits" element={<ApiCredits />} />
            <Route path="/apicredits/:key" element={<ApiCredits />} />

            <Route exact path="/settings/leavetype" element={<LeaveType />} />

            <>
              {resPassData ? (
                <>
                  {menuItems.length !== 0 &&
                    menuItems.map((items, i) => (
                      <React.Fragment key={i}>
                        {items.name === "Dashboard" && (
                          <Route
                            exact
                            path="/dashboard"
                            element={<Dashboard />}
                          />
                        )}
                        <Route
                          exact
                          path="/useractivities"
                          element={<Activities />}
                        />
                        <Route exact path="/tracker" element={<Tracker />} />
                        <Route
                          exact
                          path="/trackbyuser/:id"
                          element={<TrackByUser />}
                        />
                        <Route
                          exact
                          path="/trackbyuser/:id/:checkout"
                          element={<TrackByUser />}
                        />
                        {items.name === "Users" && (
                          <Route exact path="/users" element={<Users />} />
                        )}
                        <Route
                          exact
                          path="/viewsubordinates"
                          element={<ViewSubordinates />}
                        />
                        {items.name === "Monthly" && (
                          <Route
                            exact
                            path="/attendance/monthly"
                            element={<AttendanceMonthly />}
                          />
                        )}
                        {items.name === "Fullday" && (
                          <Route
                            exact
                            path="/attendance/fullday"
                            element={<AttendanceFullday />}
                          />
                        )}
                        {items.name === "Client Complaint" && (
                          <Route
                            exact
                            path="/complaints"
                            element={<Complaints />}
                          />
                        )}
                        {items.name === "Leaves" && (
                          <Route exact path="/leaves" element={<Leaves />} />
                        )}
                        {items.name === "Orders" && (
                          <>
                            <Route exact path="/orders" element={<Orders />} />
                            <Route
                              exact
                              path="/orderDetails/:orderId"
                              element={<OrderDetails />}
                            />
                          </>
                        )}
                        {items.name === "Meetings" && (
                          <Route
                            exact
                            path="/meetings"
                            element={<Meetings />}
                          />
                        )}
                        {/* <Route exact path="/meetings/:usermeetId" element={<Meetings />} /> */}
                        {items.name === "Clients" && (
                          <Route exact path="/company" element={<Clients />} />
                        )}
                        {items.name === "Products" && (
                          <>
                            <Route
                              exact
                              path="/products"
                              element={<Products />}
                            />
                            <Route
                              exact
                              path="/products/:prodId"
                              element={<ProductsViewPage />}
                            />
                          </>
                        )}
                        {items.name === "Products" && (
                          <Route
                            exact
                            path="/Cartpage"
                            element={<CartPage />}
                          />
                        )}
                        {items.name === "Spares" && (
                          <Route exact path="/spares" element={<Spares />} />
                        )}
                        {items.name === "Settings" && (
                          <Route
                            exact
                            path="/settings"
                            element={<SettingPage />}
                          />
                        )}
                        {items.name === "Routes" && (
                          <Route path="/routes" element={<RoutePlan />} />
                        )}
                        {/* {items.name === "Amc" && */}
                        <Route exact path="/amc" element={<Amc />} />

                        <Route
                          exact
                          path="/pms/projects"
                          element={<Projects />}
                        />
                        <Route exact path="/pms/phase" element={<Phase />} />
                        <Route exact path="/pms/tasks" element={<Tasks />} />
                        <Route
                          exact
                          path="/pms/reports"
                          element={<Reports />}
                        />
                        <Route
                          exact
                          path="/pms/paymentdetails"
                          element={<PaymentDetails />}
                        />
                        {/* } */}
                        {items.name === "Local Claims" && (
                          <Route
                            exact
                            path="/localclaim"
                            element={<LocalClaim />}
                          />
                        )}
                        {/* <Route exact path="/claims/manager" element={<ClaimsManager />} />
              <Route exact path="/claims/admin" element={<ClaimsAdmin />} /> */}
                        {items.name === "Outstation Claims" && (
                          <Route
                            exact
                            path="/outstationclaim"
                            element={<OutStationClaim />}
                          />
                        )}
                        <Route
                          exact
                          path="/meetingsmodel"
                          element={<MeetingDetailsModel />}
                        />
                        {items.name === "Schedule" && (
                          <Route
                            exact
                            path="/settings/schedule"
                            element={<Schedule />}
                          />
                        )}
                        {items.name === "Track Clients" && (
                          <Route
                            exact
                            path="/settings/trackclients"
                            element={<TrackClients />}
                          />
                        )}
                        {items.name === "Department" && (
                          <Route
                            exact
                            path="/settings/department"
                            element={<Department />}
                          />
                        )}
                        {items.name === "Designation" && (
                          <Route
                            exact
                            path="/settings/designation"
                            element={<Designation />}
                          />
                        )}
                        {items.name === "Meeting Purpose" && (
                          <Route
                            exact
                            path="/settings/meetingpurpose"
                            element={<MeetingPurpose />}
                          />
                        )}
                        {items.name === "Basefare" && (
                          <Route
                            exact
                            path="/settings/basefare"
                            element={<BaseFare />}
                          />
                        )}
                        {items.name === "Profile" && (
                          <Route
                            exact
                            path="/settings/profile"
                            element={<Profile />}
                          />
                        )}
                        {items.name === "Account Settings" && (
                          <Route
                            exact
                            path="/settings/accountsettings"
                            element={<AccountSettings />}
                          />
                        )}
                        {items.name === "Account Details" && (
                          <Route
                            exact
                            path="/settings/accountdetails"
                            element={<AccountDetails />}
                          />
                        )}
                        {/* {items.name === "Account Details" && */}
                        <Route
                          exact
                          path="/freetier"
                          element={<CreateSubscription />}
                        />
                        <Route
                          exact
                          path="/Trial"
                          element={<CreateSubscription />}
                        />
                        {/* } */}
                        {items.name === "Device Details" && (
                          <Route
                            exact
                            path="/settings/devicedetails"
                            element={<DeviceDetails />}
                          />
                        )}
                        {items.name === "Client Category" && (
                          <Route
                            path="/settings/clientCategory"
                            element={<ClientCategory />}
                          />
                        )}
                        {items.name === "Client Stage" && (
                          <Route
                            path="/settings/clientStage"
                            element={<ClientStage />}
                          />
                        )}
                        {items.name === "Custom Field" && (
                          <Route
                            path="/settings/customField"
                            element={<CreateCustomFields />}
                          />
                        )}
                        {items.name === "Holiday" && (
                          <Route
                            path="/settings/Holiday"
                            element={<Holiday />}
                          />
                        )}
                        {items.name === "Shift" && (
                          <Route path="/settings/Shift" element={<Shift />} />
                        )}
                        <Route
                          exact
                          path="/kmrefresh/meetings"
                          element={<KilometerRefreshPage />}
                        />
                        <Route
                          exact
                          path="/kmrefresh/attendance"
                          element={<KilometerRefreshAttendance />}
                        />
                        <Route
                          exact
                          path="/allsubscriptiondetails"
                          element={<SubscriptionDetails />}
                        />
                        <Route
                          exact
                          path="/adddropdowndata"
                          element={<AddDropdownData />}
                        />
                        <Route
                          exact
                          path="/googleapikeylist"
                          element={<GoogleAPIHitCount />}
                        />
                        <Route
                          exact
                          path="/googleapikeylist/:key"
                          element={<GoogleAPIHitCount />}
                        />
                        <Route
                          exact
                          path="/exceptionhandler"
                          element={<ExceptionHandler />}
                        />
                        <Route
                          exact
                          path="/subscriptionmodule"
                          element={<MeetingManagement />}
                        />
                        <Route
                          exact
                          path="/projectManagement"
                          element={<ProjectManagement />}
                        />
                        <Route
                          exact
                          path="/humamResource"
                          element={<HumanResource />}
                        />
                        <Route
                          exact
                          path="/orderManagement"
                          element={<OrderManagement />}
                        />
                        <Route
                          exact
                          path="/stockManagement"
                          element={<StockManagement />}
                        />
                        <Route
                          exact
                          path="/warehouseManagement"
                          element={<WarehouseManagement />}
                        />
                        <Route
                          exact
                          path="/complaintManagement"
                          element={<ComplaintManagement />}
                        />
                        <Route
                          exact
                          path="/dealerManagement"
                          element={<DealerManagement />}
                        />
                        <Route
                          exact
                          path="/distributionManagement"
                          element={<DistributionManagement />}
                        />
                        <Route
                          exact
                          path="/settings/roles"
                          element={<Role />}
                        />
                        <Route
                          exact
                          path="/settings/ProductCategory"
                          element={<ProductCategory />}
                        />
                      </React.Fragment>
                    ))}
                  <React.Fragment>
                    {resPassData &&
                    resPassData?.desingation?.role?.roleName === "Admin" &&
                    resPassData.subscriptionId === "SUB0000000001" ? (
                      <>
                        <Route
                          path="/settings/superadminpage"
                          element={<SuperAdminPage />}
                        />
                        <Route
                          path="/googlecredits"
                          element={<GoogleCredits />}
                        />
                        <Route path="/exception" element={<ExceptionPage />} />
                        <Route
                          path="/mobileException"
                          element={<MobileException />}
                        />
                        <Route path="/apicredits" element={<ApiCredits />} />
                        <Route
                          path="/apicredits/:key"
                          element={<ApiCredits />}
                        />
                      </>
                    ) : (
                      <>
                        <Route
                          path="/settings/superadminpage"
                          element={<PageNotFound />}
                        />
                        <Route
                          path="/googlecredits"
                          element={<PageNotFound />}
                        />
                        <Route path="/exception" element={<PageNotFound />} />
                        <Route
                          path="/mobileException"
                          element={<PageNotFound />}
                        />
                        <Route path="/apicredits" element={<PageNotFound />} />
                        <Route
                          path="/apicredits/:key"
                          element={<PageNotFound />}
                        />
                      </>
                    )}
                    <Route exact path="*" element={<UnAutherized />} />
                    <Route
                      exact
                      path="/subscription"
                      element={<SuscriberPage />}
                    />
                  </React.Fragment>
                </>
              ) : (
                <>
                  <Route exact path="/dashboard" element={<Dashboard />} />
                  <Route exact path="*" element={<PageNotFound />} />
                </>
              )}
            </>
          </Routes>
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetpassword" element={<ResetPasswordPage />} />
            <Route
              exact
              path="/subscriptionexpiry"
              element={<CheckSubscription />}
            />
            <Route exact path="/freetier" element={<CreateSubscription />} />
            <Route exact path="/Trial" element={<CreateSubscription />} />
          </Routes>
        )}
      </>
    </div>
  );
}

export default App;
