import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react';
import { SkeletonMeetPurpose } from '../../Skeleton Loading/SkeletonMeetPurpose';
import OnScrollLoading, { handleScroll } from "../../OnScrollLoading";
import { useSelector } from 'react-redux';

function BaseFareTable(props) {

    const { totalCount } = useSelector(state => state.data);

    return (
        <div className="sb-table-div sb-table-setion">
            <Paper sx={{ width: '100%', overflow: 'hidden' }} className='table-main-div'>
                <TableContainer sx={{ maxHeight: 440 }}
                    onScroll={(e) => handleScroll(e, props.baseFareList, totalCount, props.setLoading, props.setPageSize, props.PageSize)}
                >
                    <Table stickyHeader aria-label="sticky table table-responsive" className='basefare-table'>
                        <TableHead className='custom-table-header'>
                            <TableRow>
                                {props.tableHeader.map((name, i) => (
                                    < TableCell key={i} className={`thead-cell-products ${(name === "ACTION" || name === "STATUS") && "action-class"}`}
                                    >
                                        {name}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        {props.isLoading ?
                            <>{props.tableHeader.map((name, i) => (
                                <SkeletonMeetPurpose key={i} />
                            ))}
                            </>
                            :
                            <>
                                {props.baseFareList && props.baseFareList.length === 0 ?
                                    <TableBody>
                                        <TableRow align="center" >
                                            <TableCell align="center" colSpan={props.tableHeader.length}>
                                                <div style={{ padding: "20px 20px" }}>
                                                    <img src="../../../../images/DefaultImages/NodataFound.jpg" alt="" style={{ width: "10%" }} /><br />
                                                    <span>No Data Found...!</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                    :
                                    <TableBody>
                                        {props.baseFareList && props.baseFareList.map((data, i) => (
                                            <TableRow align="center" key={i} className='client-list-row-data'>
                                                <TableCell>
                                                    <span style={{ cursor: "pointer", color: "#04a96d" }} onClick={() => props.editBasefare(data.customeBaseFareID, data.motID, "view")}>
                                                        <b>{data.motName}</b>
                                                    </span>
                                                </TableCell>
                                                <TableCell>
                                                    {(data.designationLevel !== 0 && data.designationLevel !== "") ? <>L{data.designationLevel}-{data.designationName}</> : "-"}
                                                </TableCell>
                                                <TableCell>
                                                    {data.motType}
                                                </TableCell>
                                                <TableCell>
                                                    {data.isPolicyAvaialble === false ? data.motBaseFare : data.baseFareAmount}
                                                </TableCell>
                                                <TableCell>
                                                    {data.motUnit}
                                                </TableCell>
                                                <TableCell>
                                                    {data.isPolicyAvaialble === false ? <span style={{ fontSize: "11px", color: "rgb(255 191 19)" }}>Policy is Not Available </span> : <>{data.dayLimit}</>}
                                                </TableCell>
                                                <TableCell>
                                                    {data.addedByUserFullName}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {data.status === 1 ? <span className='complete-status status-badge'>Active</span> : <span className='pending-status status-badge'>Inactive</span>}
                                                </TableCell>
                                                <TableCell className='action-class'>
                                                    <button
                                                        onClick={() => props.editBasefare(data.customeBaseFareID, data.motID)}
                                                        className="btn client-history-link" >
                                                        {data.customeBaseFareID === 0 ? <span className='add-policy status-badge'>Add Policy</span> : <span><i className="bi bi-pencil-fill"></i> Edit</span>}
                                                    </button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {props.loading &&
                                            <OnScrollLoading />
                                        }
                                    </TableBody>

                                }
                            </>
                        }
                    </Table>
                </TableContainer>
            </Paper>
        </div>
    )
}

export default BaseFareTable;
