import React, { useEffect, useState } from "react";
import Sidenavbar from "../Sidenavbar";
import Topbar from "../Topbar";
import "../../styles/Users.scss";
import "../../styles/CommonStyle.scss";
import { Col, Row } from "react-bootstrap";
import Addusermodel from "./Addusermodel";
import Editusermodel from "./Editusermodel";
import ViewSubordinates from "./ViewSubbordinates";
import { baseURL } from "../BaseUrl";
import AddBulkUser from "./AddBulkUser";
import UserHistory from "./UserHistory";
import { exportExcel } from "../DownloadFile";
import Select from "react-select";
import { optionsStatusList } from "../DropdownsData";
import AccessDenied from "../../AccessDenied";
import InternetIssue from "../InternetIssue";
import {
  loadUserDropdown,
  loadDesignationDrop,
  loadDepartmentDrop,
  loadRolesDropDown,
  loadPageBreadCome,
} from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import SbDropDownBtn from "../SbButtons/SbDropDownBtn";
import SbCancelBtn from "../SbButtons/SbCancelBtn";
import UserTable from "./UserTable";
import { SbDownloadBtn } from "../SbButtons/SbAddBtn";
import { useNavigate } from "react-router-dom";

function Users() {
  /* HEADER AUTH START */
  const userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  const AdminAccess = sessionStorage.getItem("AdminAccess");
  const travelizeAuth = {
    "Content-Type": "application/json",
    Travelize_Authentication: userDetails,
  };
  /* HEADER AUTH END */

  let tableHeader = [
    { name: "NAME", align: "left", width: "200px" },
    { name: "CONTACT DETAILS", align: "left", width: "150px" },
    { name: "DESIGNATION", align: "left", width: "150px" },
    { name: "ROLE", align: "left", width: "120px" },
    { name: "DEPARTMENT", align: "left", width: "150px" },
    { name: "REPORTING", align: "left", width: "180px" },
    { name: "STATUS", align: "center", width: "100px" },
    { name: "ACTION", align: "center", width: "150px" },
  ];

  let dispatch = useDispatch();
  const { userDropdown } = useSelector((state) => state.data);
  const { desigationdrop } = useSelector((state) => state.data);
  const { departmentDrop } = useSelector((state) => state.data);
  const { rolesDropdown } = useSelector((state) => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [addusermodelshow, setAddusermodelshow] = useState(false);
  const [addBulkUserModel, setAddBulkUserModel] = useState(false);
  const [editusermodelshow, setEditusermodelshow] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  const [users, setUsers] = useState([]);
  const [filterUserId, setFilterUserId] = useState("");
  const [editUserId, setEditUserId] = useState("");
  const [activeUserCount, setActiveUserCount] = useState("");
  const [activeStatus, setActiveStatus] = useState(2);
  const [totalUserCount, setTotalUserCount] = useState(0);
  const [managerId, setManagerId] = useState("");
  const [managerName, setManagerName] = useState("");
  const [roleId, setRoleId] = useState(0);
  const [subordinateActive, setSubordinateActive] = useState(false);
  const [userHistoryPage, setUserHistoryPage] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [reportDesignation, setReportDesignation] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  const goBacktoUser = () => {
    setSubordinateActive(!subordinateActive);
  };

  const userHistoryView = () => {
    setUserHistoryPage(!userHistoryPage);
  };

  const handleClose = () => {
    setAddusermodelshow(!addusermodelshow);
  };

  const showEditModel = () => {
    setEditusermodelshow(!editusermodelshow);
  };

  const handleCloseBulkUserModel = () => {
    setAddBulkUserModel(!addBulkUserModel);
  };

  //* PAGINATION START *//
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDataSize, setPageDataSize] = useState(0);

  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  //* PAGINATION END *//

  //* FETCH DROPDOWN DATA START *//

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let breadCome = { mainpage: "Users" };
    dispatch(loadPageBreadCome(breadCome));
    dispatch(loadUserDropdown());
    dispatch(loadDesignationDrop());
    dispatch(loadDepartmentDrop());
    dispatch(loadRolesDropDown());
  }, []);

  //* FETCH DROPDOWN DATA END *//

  //* GET USER DETAIL START  *//
  useEffect(() => {
    getUserDetails();
  }, [
    PageSize,
    currentPage,
    filterUserId,
    reportDesignation,
    departmentId,
    roleId,
    activeStatus,
  ]);

  const getUserDetails = () => {
    let requestOption = {
      method: "GET",
      mode: "cors",
      headers: travelizeAuth,
    };

    fetch(
      `${baseURL}/api/User/UserDetails?userId=${filterUserId}&ManagerID=${managerId}&DesignationID=${reportDesignation}&DepartmentID=${departmentId}&RoleID=${roleId}&enableStatus=${activeStatus}&pageSize=${PageSize}&currentPage=${currentPage}`,
      requestOption
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setUsers(res.result);
          setTotalUserCount(res.recordCount[0]);
          setPageDataSize(res.result.length);
          setActiveUserCount(res.recordCount[1]);
          setPageCount(Math.ceil(res.recordCount[0] / PageSize));
          setIsLoading(false);
          setLoading(false);
        } else {
          setUsers("");
          setIsLoading(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log("ERROR :", error);
        setErrorMessage(error.message);
      });
  };
  //* GET USER DETAIL END *//

  //* CLEAR SELECT START *//
  const clearSerachBySelect = () => {
    setFilterUserId("");
    setManagerId("");
    setReportDesignation(0);
    setDepartmentId(0);
    setRoleId(0);
    setActiveStatus(2);
  };
  //* CLEAR SELECT END *//

  //* EXPORT FILE START *//
  const exportUserDetails = () => {
    exportExcel(
      `${baseURL}/api/Export/UserExcelExport?userId=${filterUserId}&ManagerID=${managerId}&DesignationID=${reportDesignation}&DepartmentID=${departmentId}&RoleID=${roleId}&enableStatus=${activeStatus}&pageSize=${PageSize}&currentPage=${currentPage}`,
      "Users.xlsx"
    );
  };
  //* EXPORT FILE END *//

  // Page navigation
  let navigate = useNavigate();
  const goToSettingPage = () => {
    navigate(-1);
  };
  // page navigation

  // Open Filter div
  const [filter, setFilter] = useState(false);
  // Open Filter div

  return (
    <div id="main-page">
      {errorMessage ? (
        <InternetIssue />
      ) : (
        <>
          {userDetails !== null && userDetails !== "" ? (
            <>
              <div className="top-and-sidebar">
                <Sidenavbar
                  menuCollapse={menuCollapse}
                  toggleshift={toggleshift}
                />

                <Topbar
                  toggleMenu={toggleMenu}
                  menuCollapse={menuCollapse}
                  toggleshift={toggleshift}
                />
              </div>
              {/* MAIN BODY START */}

              {/* PAGE CONTENT START */}
              <div className="page-wrapper">
                {!subordinateActive && !userHistoryPage ? (
                  <div className={`main-body ${toggleshift.style}`}>
                    <div className="page-content">
                      {/* Filter and Header Section */}
                      <div className="user-table">
                        <Row className="search-row">
                          <Col md={3} className="cat-col">
                            <div className="page-header-text-div">
                              <div
                                className="back-btn"
                                onClick={goToSettingPage}
                              >
                                <img
                                  src="../../../../images/DefaultImages/back-btn-arrow.svg"
                                  alt=""
                                />
                              </div>
                              <h6
                                className="page-header-text"
                                style={{ color: "#04a96d" }}
                              >
                                {" "}
                                USERS ({activeUserCount})
                              </h6>
                            </div>
                          </Col>
                          <Col md={9} className="cat-col">
                            <div
                              className="search-bar-header"
                              style={{ float: "right" }}
                            >
                              <div className="page-top-bar-dropdowns page-top-Filters">
                                <div
                                  className="header-filter-btn"
                                  onClick={() => setFilter(!filter)}
                                >
                                  <p className="card-head">
                                    <i className="bi bi-sliders" />
                                    &nbsp;&nbsp;Filters
                                  </p>
                                </div>
                                {AdminAccess && (
                                  <SbDropDownBtn
                                    openAddModal={handleClose}
                                    pageName="User"
                                    addBulkModel={handleCloseBulkUserModel}
                                  />
                                )}
                              </div>
                            </div>
                          </Col>
                        </Row>

                        {filter && (
                          <div className="page-filter-section">
                            <div style={{ width: "100%" }}>
                              <Row>
                                <Col md={10}>
                                  <div className="clients-filter-input-boxs filter-box-div">
                                    <div
                                      style={{
                                        width: "100%",
                                        padding: "0px 5px",
                                      }}
                                    >
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        options={userDropdown}
                                        placeholder="Select User"
                                        value={
                                          filterUserId == ""
                                            ? ""
                                            : userDropdown.value
                                        }
                                        onInputChange={userDropdown.label}
                                        onChange={(data) =>
                                          setFilterUserId(data.value)
                                        }
                                        isSearchable={true}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        width: "100%",
                                        padding: "0px 5px",
                                      }}
                                    >
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        defaultValue={desigationdrop[null]}
                                        options={desigationdrop}
                                        placeholder="Designation"
                                        value={
                                          reportDesignation == 0
                                            ? ""
                                            : desigationdrop.value
                                        }
                                        onInputChange={desigationdrop.label}
                                        onChange={(data) =>
                                          setReportDesignation(data.value)
                                        }
                                        isSearchable={true}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        width: "100%",
                                        padding: "0px 5px",
                                      }}
                                    >
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        options={departmentDrop}
                                        placeholder="Department"
                                        value={
                                          departmentId == 0
                                            ? ""
                                            : departmentDrop.value
                                        }
                                        onInputChange={departmentDrop.label}
                                        onChange={(data) =>
                                          setDepartmentId(data.value)
                                        }
                                        isSearchable={true}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        width: "100%",
                                        padding: "0px 5px",
                                      }}
                                    >
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        // defaultValue={optionsRoleList[0].label}
                                        options={rolesDropdown}
                                        placeholder="Select Role"
                                        value={
                                          roleId == 0 ? "" : rolesDropdown.value
                                        }
                                        onInputChange={rolesDropdown.label}
                                        onChange={(data) =>
                                          setRoleId(data.value)
                                        }
                                        isSearchable={true}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        width: "100%",
                                        padding: "0px 5px",
                                      }}
                                    >
                                      <Select
                                        className="react-select-container-list"
                                        classNamePrefix="react-select-list"
                                        // defaultValue={optionsRoleList[0].label}
                                        options={optionsStatusList}
                                        placeholder="Select Status"
                                        value={
                                          activeStatus == 2
                                            ? ""
                                            : optionsStatusList.value
                                        }
                                        onInputChange={optionsStatusList.label}
                                        onChange={(data) =>
                                          setActiveStatus(data.value)
                                        }
                                        isSearchable={true}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col md={2}>
                                  <div className="clients-filter-input-boxs filter-box-div">
                                    <SbCancelBtn
                                      onClickEffect={clearSerachBySelect}
                                      btnName="Clear"
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        )}
                      </div>
                      {/* Filter and Header Section */}

                      {/* USER TABLE START */}
                      <UserTable
                        tableHeader={tableHeader}
                        users={users}
                        isLoading={isLoading}
                        totalUserCount={totalUserCount}
                        loading={loading}
                        setPageSize={setPageSize}
                        PageSize={PageSize}
                        setLoading={setLoading}
                        setManagerId={setManagerId}
                        setManagerName={setManagerName}
                        goBacktoUser={goBacktoUser}
                        showEditModel={showEditModel}
                        setEditUserId={setEditUserId}
                        userHistoryView={userHistoryView}
                      />
                      <div className="user-table">
                        {/* PAGINATION START */}
                        <div className="download-and-pagination">
                          <SbDownloadBtn
                            btnName="Download"
                            onClickEffect={exportUserDetails}
                          />
                        </div>
                        {/* PAGINATION END */}
                      </div>
                      {/* USER TABLE END */}

                      {/* PAGE CONTENT END*/}
                    </div>
                  </div>
                ) : (
                  <>
                    {subordinateActive ? (
                      <ViewSubordinates
                        roleId={roleId}
                        managerId={managerId}
                        managerName={managerName}
                        toggleshift={toggleshift}
                        goBacktoUser={goBacktoUser}
                        subordinateActive={subordinateActive}
                      />
                    ) : (
                      <>
                        {userHistoryPage ? (
                          <UserHistory
                            userHistoryView={userHistoryView}
                            toggleshift={toggleshift}
                            userId={editUserId}
                          />
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </>
                )}
                <>
                  {/* ADD USER MODEL START */}
                  {addusermodelshow ? (
                    <Addusermodel
                      getUserDetails={getUserDetails}
                      addusermodelshow={addusermodelshow}
                      handleClose={handleClose}
                    />
                  ) : (
                    ""
                  )}

                  {/* ADD USER MODEL END */}

                  {/* ADD BULK USER MODEL START */}
                  {addBulkUserModel ? (
                    <AddBulkUser
                      addBulkUserModel={addBulkUserModel}
                      handleCloseBulkUserModel={handleCloseBulkUserModel}
                    />
                  ) : (
                    ""
                  )}
                  {/* ADD BULK USER MODEL END */}

                  {/* EDIT USER MODEL START */}
                  {editusermodelshow ? (
                    <Editusermodel
                      userID={editUserId}
                      getUserDetails={getUserDetails}
                      editusermodelshow={editusermodelshow}
                      showEditModel={showEditModel}
                    />
                  ) : (
                    ""
                  )}
                  {/* EDIT USER MODEL END */}
                </>
                {/* MAIN BODY END */}
              </div>
            </>
          ) : (
            <AccessDenied />
          )}
        </>
      )}
    </div>
  );
}

export default Users;
