import { baseURL } from "../Components/BaseUrl";
import * as types from "./actionType";
import * as HeaderAuthentication from "../Components/HeaderAuthentication";
import * as swal from "../Components/Consturl/SwalAlert";

// TopBar page Breadcome Value
const getBeadComeData = (pageData) => ({
  type: types.Get_BreadCome_Data,
  payload: pageData,
});
const getAdminAccess = (access) => ({
  type: types.Get_Admin_Access,
  payload: access,
});

const getToataldataCount = (totalCount) => ({
  type: types.Get_Total_Data,
  payload: totalCount,
})
// TopBar page Breadcome Value
const getSubscription = (subcriptionList) => ({
  type: types.Get_Subscription,
  payload: subcriptionList,
});
const getSubscrDropdown = (subCrDropdown) => ({
  type: types.Get_Subscr_Dropdown,
  payload: subCrDropdown,
});
const getRolesDropDown = (rolesDropdown) => ({
  type: types.Get_Roles_DropDown,
  payload: rolesDropdown,
});
export const getSuccessMessage = (successMsg) => ({
  type: types.Get_Successs_Msg,
  payload: successMsg,
});

const getAllSubscriptions = (allSubcriptions) => ({
  type: types.Get_All_Subscription,
  payload: allSubcriptions,
});
const getMainModules = (mainModules) => ({
  type: types.Get_Main_Modules,
  payload: mainModules,
});
const getSubscriptionBySubscriber = (sbcrBySubscriber) => ({
  type: types.Get_Subscription_Module,
  payload: sbcrBySubscriber,
});
const getAllSubScriPtion = (allSubsById) => ({
  type: types.Get_All_Id_Subs,
  payload: allSubsById,
});
const getAllMenus = (allMenus) => ({
  type: types.Get_All_Menus,
  payload: allMenus,
});

// const postMenuItems = () => ({
//   type: types.Post_Menus_Subscription,
// });
const getTrackData = (trackData) => ({
  type: types.Get_Track_User,
  payload: trackData,
});
const getDistanceMatrix = (distanceMatrix) => ({
  type: types.Get_Distance_Matrix,
  payload: distanceMatrix,
});

// Dashboard page Graph API's
const getGraphData = (graphData) => ({
  type: types.Get_GraphData,
  payload: graphData,
});
const getGraphCount = (graphCount) => ({
  type: types.Get_GraphCount,
  payload: graphCount,
});
// Dashboard page Graph API's

// Category page
const getClientCaregory = (clientCategoryData) => ({
  type: types.Get_Client_Category,
  payload: clientCategoryData,
});
const getClientCaregoryDropDown = (categoryDropdown) => ({
  type: types.Client_Category_DropDown,
  payload: categoryDropdown,
});
const updateClientCategoryData = () => ({
  type: types.Update_Client_Category,
});
const postClientCategoryData = () => ({
  type: types.Post_Client_Category,
});
// Category page

// Stage page
const getClientStage = (clientStageData) => ({
  type: types.Get_Client_Stage,
  payload: clientStageData,
});
const clientStageDropdown = (stageDropdown) => ({
  type: types.Stage_DropDown,
  payload: stageDropdown,
});

const postClientStageData = () => ({
  type: types.Post_Client_Stage,
});
const updateClientStageData = () => ({
  type: types.Update_Client_Stage,
});
// Stage page

// Custom Field page
const postCustomField = () => ({
  type: types.Post_Custom_Fields,
});
const getCustomFields = (customFields) => ({
  type: types.Get_Custom_Fields,
  payload: customFields,
});
const getSingleField = (singleField) => ({
  type: types.Get_Single_Filed,
  payload: singleField,
});
const updateCustomField = () => ({
  type: types.Update_Custom_Fields,
});
// Custom Field page

// Company page
const getCompanyData = (companyData) => ({
  type: types.Get_Company_Data,
  payload: companyData,
});
const getSingleClient = (singleClient) => ({
  type: types.Get_Single_Client,
  payload: singleClient,
});

const postOtherContact = () => ({
  type: types.Post_Other_Contact,
});

const getOtherContact = (otherContacts) => ({
  type: types.Get_Other_Contact,
  payload: otherContacts,
});
const getContactByCompanyDrop = (contactByCompanyDrop) => ({
  type: types.ContactBy_Company_Drop,
  payload: contactByCompanyDrop,
});

const postCompanyData = () => ({
  type: types.Post_Comapy_Data,
});
const updateCompanyData = () => ({
  type: types.Update_Company_Data,
});

const getClientDropDown = (clientDropdown) => ({
  type: types.Client_Dropdown,
  payload: clientDropdown,
});
// Company page

// Holiday List Page
const getHolidayList = (holidayList) => ({
  type: types.Get_Holiday_List,
  payload: holidayList,
});

const postHolidayList = () => ({
  type: types.Post_Holiday_List,
});

const getSingleHoliday = (singleHoliday) => ({
  type: types.Single_Holiday_Data,
  payload: singleHoliday,
});

const updateHolidayList = () => ({
  type: types.Update_Holiday_Data,
});
// Holiday List Page

// Shift Page
const getShiftData = (shiftData) => ({
  type: types.Get_Shift_List,
  payload: shiftData,
});

const postShiftData = () => ({
  type: types.Post_Shift_List,
});
// Shift Page

// Client Compalin Page
const getClientComplaint = (clientComplaints) => ({
  type: types.Get_Client_Complains,
  payload: clientComplaints,
});
const postClientComplaint = () => ({
  type: types.Post_Client_Complaint,
});
const getSingleClientCompalint = (singleClientCompaint) => ({
  type: types.Simgle_Client_Complaint,
  payload: singleClientCompaint,
});
const updateClientCompalint = () => ({
  type: types.Update_Client_Complaint,
});
// Client Compalin Page

// Products Page
const getProductsList = (productData) => ({
  type: types.Get_Products_List,
  payload: productData,
});
const getSingleProduct = (singleProduct) => ({
  type: types.Get_Single_Product,
  payload: singleProduct,
});
const getProductsDropdown = (productDropdown) => ({
  type: types.Get_Product_DropDown,
  payload: productDropdown,
});
const postProductData = () => ({
  type: types.Post_Product_Data,
});
const updateProductData = () => ({
  type: types.Update_Product_Data,
});

export const getCartListData = (cartList) => ({
  type: types.Get_Cart_List,
  payload: cartList,
});
// Products Page

// Orders Page
const getOrders = (oderDetails) => ({
  type: types.Get_Orders,
  payload: oderDetails,
});
const getSingleOrder = (singleOrder) => ({
  type: types.Get_Single_Orders,
  payload: singleOrder,
});
const getProducsByOrder = (productsByOrder) => ({
  type: types.Get_ProductsBy_Order,
  payload: productsByOrder,
});
const getOrderHistory = (orderHistory) => ({
  type: types.Get_Order_History,
  payload: orderHistory,
});
const updateOrderQty = () => ({
  type: types.Update_Product_Qty,
});
// Orders Page

// Meeting Page
const getMeetingData = (meetingData) => ({
  type: types.Get_Meetings_Data,
  payload: meetingData,
});
// Meeting Page

// Product Category Page
const getProdCategoryDrop = (prodCategoryDrop) => ({
  type: types.Get_ProdCategory_Dropdown,
  payload: prodCategoryDrop,
});
const getProdSubCategory = (prodSubcategory) => ({
  type: types.Get_Prod_Sub_Category,
  payload: prodSubcategory,
});
// Product Category Page

// Designation Page
const getDesignationDrop = (desigationdrop) => ({
  type: types.Get_Designation_Dropdown,
  payload: desigationdrop,
});
const getdepartDesignation = (departDesignation) => ({
  type: types.Get_Designation_Department,
  payload: departDesignation,
});
// Designation Page

// Basefare page
// mot dropdown
const getMotDrop = (motDrop) => ({
  type: types.Get_Mot_Dropdown,
  payload: motDrop,
});
// mot dropdown
const getBaseFare = (baseFareList) => ({
  type: types.Get_BaseFare_List,
  payload: baseFareList,
});

const addBadseFareTolist = () => ({
  type: types.Post_Basefare,
});

export const getSingleBasefare = (singleBaseFire) => ({
  type: types.Get_Single_BaseFare,
  payload: singleBaseFire,
});

const updateBasefare = () => ({
  type: types.Update_Basefare,
});
// Basefare page

// Department Page
const getDepartmentDrop = (departmentDrop) => ({
  type: types.Get_Department_Dropdown,
  payload: departmentDrop,
});
// Department Page

// Get Apis for Zone, Country, State, City
const getZoneList = (zoneList) => ({
  type: types.Get_Zones,
  payload: zoneList,
});

const getStateList = (stateList) => ({
  type: types.Get_States_ByZoneId,
  payload: stateList,
});

const getCityList = (cityList) => ({
  type: types.Get_City_ByStateID,
  payload: cityList,
});
// Get Apis for Zone, Country, State, City

// Upload Imagez
// const uploadSingeImage = (imgUrl) => ({
//   type: types.Upload_Single_Image,
//   payload: imgUrl,
// });
// Upload Imagez

// SuperAdmin Page
// Google credit page
const getGoogleCredits = (googleCreditList) => ({
  type: types.Get_Google_Credits,
  payload: googleCreditList,
});
const getApiNameDrop = (apiDropdown) => ({
  type: types.Get_API_Dropdown,
  payload: apiDropdown,
});
const getCreditCount = (creditCount) => ({
  type: types.Get_Credits_Count,
  payload: creditCount,
});
const getAPICredits = (ApiCreditList) => ({
  type: types.Get_API_Credits,
  payload: ApiCreditList,
});
const getGoogleApiHitCount = (ApiHitList) => ({
  type: types.GET_APT_HITS,
  payload: ApiHitList,
});
const addGoogleCredits = () => ({
  type: types.Post_Google_Credits,
});
const postAPICredits = () => ({
  type: types.Post_Api_Credits,
});
const getGoogleApiNameDropdown = (apiNameDropdown) => ({
  type: types.Get_GoogleApiName_Drop,
  payload: apiNameDropdown,
});
const getSingleApiData = (apiSingleData) => ({
  type: types.Get_Single_ApiCredit,
  payload: apiSingleData,
});
const getExceptionData = (apiExceptionData) => ({
  type: types.Get_Exception,
  payload: apiExceptionData,
});
const getMobileExceptionData = (apiMobileExceptionData) => ({
  type: types.Get_Mobile_Exception,
  payload: apiMobileExceptionData,
});
// Google credit page
// SuperAdmin Page

// User Page
const getUserDropdown = (userDropdown) => ({
  type: types.Get_User_Dropdown,
  payload: userDropdown,
});
const getUserDropdownByCompany = (userDropdownByCompany) => ({
  type: types.Get_User_Dropdown_By_Company,
  payload: userDropdownByCompany,
});

const getUserRegionResult = (userRegionResult) => ({
  type: types.Get_User_Regions,
  payload: userRegionResult,
});
// User Page

// Google Hit Count
// const getHitCount = (hitCount) => ({
//   type: types.Get_Hit_Count,
//   payload: hitCount
// })
// Google Hit Count

// Subscription Pages
const updateSubscription = () =>({
  type: types.Update_Subscription
})
const getSubscriptionDrop = (subscriptionTypeDropdown) => ({
  type: types.get_Subscription_Type_Dropdown,
  payload: subscriptionTypeDropdown
})
// Subscription Pages
// Refferal Pages
const getSalesBeeRefferal = (salesBeeRefferal) => ({
  type: types.get_SalesBee_Refferal,
  payload:salesBeeRefferal
})
const postSalesBeeRefferal = () => ({
  type: types.post_SalesBee_Refferal,
})
const upDateSalesBeeRefferal = () =>({
  type: types.update_SalesBee_Refferal
})
const getSingleRefferalData = (apiSingleRefferalData) => ({
  type: types.Get_Single_Refferal_Data,
  payload: apiSingleRefferalData,
});
// Refferal Pages

// topbar page breadcome
export const loadPageBreadCome = (braedCome) => {
  return function (dispatch) {
    dispatch(getBeadComeData(braedCome));
  };
};
export const loadAdminAccess = (access) => {
  return function (dispatch) {
    dispatch(getAdminAccess(access));
  };
};
// topbar page breadcome

// Service call for page
export const loadSubscription = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Subscription/GetAllSubscription?PageSize=1000`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp.result, "resp")
        dispatch(getSubscription(resp.result));
      })
      .catch((err) => {
        // swal.showAlert("Fail", `${err}`, "warning");
      });
  };
};

export const loadSubsrDropdown = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Subscription/BindCompanyDropdown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(
            getSubscrDropdown(
              resp.result.map((data) => {
                return {
                  value: data.subscriptionID,
                  label: data.companyName,
                };
              })
            )
          );
        }
      })
      .catch((err) => {});
  };
};
export const loadRolesDropDown = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Role/BindRolesDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp.result, "resp")
        dispatch(
          getRolesDropDown(
            resp.result.map((data) => {
              return {
                value: data.roleID,
                label: data.roleName,
                roleId: data.roleID,
              };
            })
          )
        );
      })
      .catch((err) => {
        // swal.showAlert("Fail", `${err}`, "warning");
      });
  };
};

export const loadAllSubscriptions = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/SubscriptionModule/GetMainModule`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp, "resp")
        dispatch(getAllSubscriptions(resp.result));
      })
      .catch((err) => {
        // swal.showAlert("Fail", `${err}`, "warning");
      });
  };
};
export const loadMainModules = (SubscriptionID) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/SubscriptionModule/GetSubscriptionMainModule?SubscriptionID=${SubscriptionID}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp, "resp")
        dispatch(getMainModules(resp.result));
      })
      .catch((err) => {
        // swal.showAlert("Fail", `${err}`, "warning");
      });
  };
};
export const loadSubscriptionBySubscriber = (sbctptnId, moduleId, roleId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/SubscriptionModule/GetSubscriptionModule?SubscriptionID=${sbctptnId}&MainModuleID=${moduleId}&RoleID=${roleId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp, "resp")
        dispatch(getSubscriptionBySubscriber(resp.result));
      })
      .catch((err) => {});
  };
};
export const loadSubscriptionBySubsId = (sbctptnId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/SubscriptionModule/GetSubscriptionModule?SubscriptionID=${sbctptnId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getAllSubScriPtion(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadAllMenus = (moduleId) => {
  return function (dispatch) {
    // fetch(`${baseURL}/api/SubscriptionModule/Get`,
    //  HeaderAuthentication.getRequestOptions)
    fetch(
      `${baseURL}/api/SubscriptionModule/GetSubscriptionModule?MainModuleID=${moduleId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp, "resp")
        dispatch(getAllMenus(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadPostMenuItems = (postData) => {
  return function (dispatch) {
    // fetch(`${baseURL}/api/SubscriptionModule/Get`,
    //  HeaderAuthentication.getRequestOptions)
    fetch(
      `${baseURL}/api/SubscriptionModule/BulkAddSubscriptionModule`,
      postData
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.Success === false) {
          swal.showAlert("Fails", "Something went wrong...!", "warning");
        } else {
          swal.showAlert(
            "Success",
            "Menu Items Added successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {});
  };
};
export const loadTrackData = (tokenData) => {
  return function (dispatch) {
    // fetch(`${baseURL}/api/SubscriptionModule/Get`,
    //  HeaderAuthentication.getRequestOptions)
    fetch(
      `${baseURL}/api/Annonymus/GetCordsUserID?Token=${tokenData}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getTrackData(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadGetDistance = (subscriptionId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Subscription/GetAllPermissionStatus/${subscriptionId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        // console.log(resp, "distance")
        dispatch(getDistanceMatrix(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadPostDistance = (postRequest) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Subscription/UpdateDistanceMatrix`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          swal.showAlert(
            "Success",
            "Distance Matrix Updated successfully...!",
            "success"
          );
        } else {
          swal.showAlert("Fails", "Something went wrong...!", "warning");
        }
      })
      .catch((err) => {});
  };
};
export const loadNotification = (postRequest, lastIndex) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Subscription/UpdatePermissionStatus`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errors) {
          swal.showAlert("Fails", "Something went wrong...!", "warning");
        } else {
          if (lastIndex) {
            swal.showAlert(
              "Success",
              "Permission Status Updated successfully...!",
              "success"
            );
          }
        }
      })
      .catch((err) => {});
  };
};

// DashBoard Page Graph API's
export const loadGraphData = (graphName, sort, setIsLoading) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/DashBoard/GetContributionsGraph?GraphName=${graphName}&Sort=${sort}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getGraphData(resp.result));
        setIsLoading(false);
      })
      .catch((err) => {});
  };
};
export const loadGraphCount = (sort) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/DashBoard/GetContributionsGraph?GraphName=ModuleDataCount&Sort=${sort}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getGraphCount(resp.result));
      })
      .catch((err) => {});
  };
};
// DashBoard Page Graph API's

// Category Page
// api/ClientCategory/Get
export const loadClientCategory = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientCategory/Get`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getClientCaregory(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadClientCategoryDrop = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientCategory/BindDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getClientCaregoryDropDown(
            resp.result.map((data) => {
              return { value: data.categoryID, label: data.name };
            })
          )
        );
      })
      .catch((err) => {});
  };
};

// /api/ClientCategory/AddCategory
export const postClientCategory = (postRequest, cancelModale) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/ClientCategory/AddCategory`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(postClientCategoryData());
          dispatch(loadClientCategory());
          cancelModale();
          swal.showAlert(
            "Success",
            "Category Added Successfully...!",
            "success"
          );
        } else {
          swal.showAlert("Fails", "Something went wrong...!", "warning");
        }
      })
      .catch((err) => {});
  };
};

// /api/ClientCategory/UpdateCategory
export const updateClientCategory = (updateData, setBtnStatus) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/ClientCategory/UpdateCategory`, updateData)
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(updateClientCategoryData(resp));
        dispatch(loadClientCategory());
        swal.showAlert("Success", "Status Update Successfully...!", "success");
        setBtnStatus(false);
      })
      .catch((err) => {});
  };
};
// Category page

// Stage page
export const loadClientStage = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientStage/Get`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getClientStage(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadClientStageGrop = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientStage/BindDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          clientStageDropdown(
            resp.result.map((data) => {
              return { value: data.stageID, label: data.name };
            })
          )
        );
      })
      .catch((err) => {});
  };
};

// /api/ClientStage/AddStage
export const postClientStage = (postRequest, cancelModale) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/ClientStage/AddStage`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errors) {
          swal.showAlert("Fails", "Something went wrong...!", "warning");
        } else {
          dispatch(postClientStageData());
          dispatch(loadClientStage());
          cancelModale();
          swal.showAlert("Success", "Stage Added Successfully...!", "success");
        }
      })
      .catch((err) => {});
  };
};

//  /api/ClientStage/UpdateStage
export const updateClientStage = (updateData, setBtnStatus) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/ClientStage/UpdateStage`, updateData)
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(updateClientStageData(resp));
        dispatch(loadClientStage());
        swal.showAlert("Success", "Status Update Successfully...!", "success");
        setBtnStatus(false);
      })
      .catch((err) => {});
  };
};
// Stage page

// Custom Field page
export const loadCustomFields = (setIsLoading) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/CustomeFields/Get`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getCustomFields(resp.result));
        setIsLoading(false);
      })
      .catch((err) => {});
  };
};
export const loadPostCustomfields = (postRequest, cancelModale) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/CustomeFields/AddCustomeFields`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errorMessage) {
          swal.showAlert("Fails", "Something went wrong...!", "warning");
        } else {
          cancelModale();
          dispatch(postCustomField());
          swal.showAlert(
            "Success",
            "Custom Field Created Successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {});
  };
};

export const loadSingleField = (fieldId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/CustomeFields/Get/${fieldId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getSingleField(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadUpdateCustomfields = (updateRequest, cancelModale) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/CustomeFields/UpdateCustomeFields`, updateRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errorMessage) {
          swal.showAlert("Failis", "Something went wrong...!", "warning");
        } else {
          cancelModale();
          dispatch(updateCustomField());
          swal.showAlert(
            "Success",
            "Custom Field Updated Successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {});
  };
};
// Custom Field page

// Company page service
export const loadCompanyData = (
  PageSize,
  currentPage,
  setPageCount,
  setTotalCounts,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Client/Get?PageSize=${PageSize}&CurrentPage=${currentPage}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getCompanyData(resp.result));
        dispatch(getToataldataCount(resp.recordCount[0]))
        setPageCount(Math.ceil(resp.recordCount[0] / PageSize));
        setTotalCounts(resp.recordCount);
        setIsLoading(false);
        setLoading(false);
      })
      .catch((err) => {});
  };
};
export const loadFilterCompanyData = (
  filterArray,
  currentPage,
  PageSize,
  setPageCount,
  setTotalCounts,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    let param = "";
    if(filterArray.length !== 0){
      filterArray.map((item, i) => {
        if (i === 0 && !param) {
          return param = item.key + "=" + item.value;
        } else {
          return param = param + "&" + item.key + "=" + item.value;
        }
      });
    }

    fetch(
      `${baseURL}/api/Client/Get?${param}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getCompanyData(resp.result));
        dispatch(getToataldataCount(resp.recordCount[0]))
        setPageCount(Math.ceil(resp.recordCount[0] / PageSize));
        setTotalCounts(resp.recordCount);
        setIsLoading(false);
        setLoading(false);
      })
      .catch((err) => {});
  };
};
export const loadGetSingleClient = (clientId) => {
  return function (dispatch) {
    // alert(id)
    // if (id !== null) {
    fetch(
      `${baseURL}/api/Client/Get/${clientId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getSingleClient(resp.result));
      })
      .catch((err) => {});
    // } else {
    //   dispatch(getSingleClient({}))
    // }
  };
};

export const loadPostOtherConact = (
  postOtherConact,
  closeContactForm
) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientContactPerson/AddClientContactPerson`,
      postOtherConact
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(postOtherContact());
          closeContactForm();
          swal.showAlert("Success", "Conact Added Successfully...!", "success");
          
        } else {
          swal.showAlert(
            "Fail",
            "Something went wrong...! please try again",
            "warning"
          );
        }
      })
      .catch((err) => {});
  };
};

export const loadOtherContacts = (clientId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientContactPerson/Get?ClientID=${clientId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getOtherContact(resp.result));
        dispatch(getContactByCompanyDrop(
          resp.result.map((client) => {
            return { label: client.name, value: client.contactPersonID };
          })
        ))
      })
      .catch((err) => {});
  };
};

export const loadPostCompanyData = (postRequest, closeModal) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Client/AddClient`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(postCompanyData());
          closeModal();
          swal.showAlert("Success", "Client Added Successfully...!", "success");
        } else {
          swal.showAlert("Fails", `Something went wrong,  ${resp.errorMessage}`, "warning");
          closeModal();
        }
      })
      .catch((err) => {});
  };
};

export const loadUpdateCompanyData = (postRequest, closeModal) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Client/EditClient`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          closeModal();
          dispatch(getSingleClient({}));
          dispatch(updateCompanyData());
          swal.showAlert(
            "Success",
            "Client Updated Successfully...!",
            "success"
          );
        } else {
          closeModal();
          swal.showAlert("Fails", `Something went wrong,  ${resp.errorMessage}`, "warning");
        }
      })
      .catch((err) => {});
  };
};

export const loadClientDropDown = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Client/BindClientDropdown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getClientDropDown(
            resp.result.map((client) => {
              return { label: client.clientName, value: client.clientId };
            })
          )
        );
      });
  };
};
// Company page service

// Holiday List page
export const loadHolidayList = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Holidays/Get`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getHolidayList(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadPostHoliday = (postRequest, cancelModale) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Holidays/AddHolidays`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errorMessage) {
          swal.showAlert("Fail", `${resp.errorMessage}`, "warning");
        } else {
          dispatch(postHolidayList());
          dispatch(loadHolidayList());
          cancelModale();
          swal.showAlert(
            "Success",
            "Holiday List Added Successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {});
  };
};

export const loadSingleHoliday = (HolidaysID) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Holidays/Get/${HolidaysID}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getSingleHoliday(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadUpdateHoliday = (updateRequest, cancelModale) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Holidays/UpdateHolidays`, updateRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errorMessage) {
          swal.showAlert("Fail", `${resp.errorMessage}`, "warning");
        } else {
          dispatch(updateHolidayList());
          dispatch(loadHolidayList());
          cancelModale();
          swal.showAlert(
            "Success",
            "Holiday List Updated Successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {});
  };
};
// Holiday List page

// Shift page
export const loadShiftData = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Zone/GetShifts`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getShiftData(resp.result));
      })
      .catch((err) => {});
  };
};

export const loadPostShift = (postRequest, cancelModale, shiftId) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Zone/AddShift`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errorMessage) {
          swal.showAlert("Fail", `${resp.errorMessage}`, "warning");
        } else {
          dispatch(postShiftData());
          cancelModale();
          dispatch(loadShiftData());
          swal.showAlert(
            "Success",
            shiftId
              ? "Shift Updated Successfully...!"
              : "Shift Saved Successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {});
  };
};
// Shift page

// Client Complaint Page
export const loadClientComplaint = (
  filterArray,
  currentPage,
  PageSize,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        return param = param + "&" + item.key + "=" + item.value;
      });
    }
    fetch(
      `${baseURL}/api/ClientComplaint/Get?CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
            dispatch(getToataldataCount(resp.recordCount[0]))
            dispatch(getClientComplaint(resp.result));
            setLoading(false);
            if (setIsLoading) {
              setIsLoading(false);
            }
      })
      .catch((err) => {});
  };
};

export const loadUploadComplaintImages = (
  imgurlPost,
  complaintData,
  modleClose,
  compaintId,
  type
) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Upload/UploadAttachmentImage`, imgurlPost)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          let mainImage = resp.result[0];
          if(type && type === "status"){
            complaintData.solutionImage = mainImage;
          } else {
            complaintData.issueImage = mainImage;
          }
          
          var postRequest = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(complaintData),
          };
          if(compaintId){
            dispatch(updateComplaintStatus(postRequest, modleClose, "update"))
          } else {
            dispatch(loadPostClientComplaint(postRequest, modleClose))
          }
        } else {
          alert("Something Went wrong...!");
        }
      });
  };
};

export const loadPostClientComplaint = (postRequest, modleClose) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/ClientComplaint/AddComplaints`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errorMessage) {
          swal.showAlert("Fail", `${resp.errorMessage}`, "warning");
          modleClose();
        } else {
          dispatch(postClientComplaint());
          modleClose();
          swal.showAlert(
            "Success",
            "Complaint created successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {});
  };
};

export const loadSingleClientCompalint = (compaintId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/ClientComplaint/Get/${compaintId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getSingleClientCompalint(resp.result));
      })
      .catch((err) => {});
  };
};

export const updateComplaintStatus = (postRequest, closeUpdateModal, type) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/ClientComplaint/UpdateComplaints`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(updateClientCompalint());
          closeUpdateModal();
          swal.showAlert(
            "Success",
            `${
              type === "status"
                ? "Status Updated successfully...!"
                : type === "update" ?
                "Successfully updated complaint details...!"
                : "Successfully assigned to new user...!"
            }`,
            "success"
          );
          
        } else {
          closeUpdateModal();
          swal.showAlert("Fail", `${resp.errorMessage}`, "warning");
        }
      })
      .catch((err) => {});
  };
};
// Client Complaint Page

// Products Page
export const loadProductsData = (currentPage, PageSize, setIsLoading, setLoading) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Product/Get?CurrentPage=${currentPage}&PageSize=${PageSize}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getProductsList(resp.result));
        dispatch(getToataldataCount(resp.recordCount[0]));
        if (setIsLoading) {
          setIsLoading(false);
        }
        if (setLoading) {
          setLoading(false);
        }
      })
      .catch((err) => {});
  };
};

export const loadSingleProduct = (ProductID, setIsLoading) => {
  return function(dispatch){
    if(ProductID !== null){
    fetch(`${baseURL}/api/Product/Get/${ProductID}`, HeaderAuthentication.getRequestOptions)
    .then(resp => resp.json())
    .then(resp => {
      dispatch(getSingleProduct(resp.result))
      if(setIsLoading){
        setIsLoading(false)
      }
    })
    .catch(err => {
      
    })
  } else {
    dispatch(getSingleProduct({}))
  }
  }
}

export const loadProductsDropDown = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Product/BindDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getProductsDropdown(
            resp.result.map((product) => {
              return {
                value: product.productID,
                label: product.productName,
                code: product.productCode,
              };
            })
          )
        );
      })
      .catch((err) => {});
  };
};

export const loadSearchProducts = (
  filterArray,
  currentPage,
  PageSize,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    let param = "";
    filterArray.map((item, i) => {
      if (i === 0 && !param) {
        return param = item.key + "=" + item.value;
      } else {
        return param = param + "&" + item.key + "=" + item.value;
      }
    });

    fetch(
      `${baseURL}/api/Product/Get?${param}&CurrentPage=${currentPage}&PageSize=${PageSize}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getProductsList(resp.result));
        dispatch(getToataldataCount(resp.recordCount[0]));
        if (setIsLoading) {
          setIsLoading(false);
        }
        if (setLoading) {
          setLoading(false);
        }
      })
      .catch((err) => {});
  };
};

export const loadPostProduct = (postRequest, closeModal) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Product/AddProduct`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(postProductData());
          closeModal();
          swal.showAlert("Success", "Product Added Successfully", "success");
        } else {
          swal.showAlert("Fails", "Something Went Wrong...!", "warning");
        }
      })
      .catch((err) => {});
  };
};
export const loadUpdateProduct = (postRequest, closeModal) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Product/UpdateProduct`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(updateProductData());
          closeModal();
          swal.showAlert("Success", "Product Details Updated Successfully", "success");
        } else {
          swal.showAlert("Fails", "Something Went Wrong...!", "warning");
        }
      })
      .catch((err) => {});
  };
};
// Products Page

// Orders Page
export const loadOrders = (subId, currentPage, PageSize, setIsLoading) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/OrderManagement/Get?SubscriptionID=${subId}&CurrentPage=${currentPage}&PageSize=${PageSize}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getOrders(resp.result));
        setIsLoading(false);
      })
      .catch((err) => {});
  };
};

export const loadSearchOrders = (
  subId,
  filterArray,
  currentPage,
  PageSize,
  setIsLoading,
  setLoading
) => {
  return function (dispatch) {
    let param = "";
    filterArray.map((item, i) => {
      return param = param + "&" + item.key + "=" + item.value;
    });

    fetch(
      `${baseURL}/api/OrderManagement/Get?SubscriptionID=${subId}${param}&CurrentPage=${currentPage}&PageSize=${PageSize}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getToataldataCount(resp.recordCount[0]));
        dispatch(getOrders(resp.result));
        setLoading(false);
        if (setIsLoading) {
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  };
};

export const loadSingleOrderData = (OrderID, setIsLoading) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/OrderManagement/Get/${OrderID}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getSingleOrder(resp.result));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  };
};
export const loadProductsDataByIdOrderId = (OrderID, setIsLoading) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/OrderManagement/GETBYID_Detail/${OrderID}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getProducsByOrder(resp.result));
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });
  };
};

export const loadOrderHistory = (orderId, ItemId, setTableLoading) => {
  return function (dispatch) {
    if(orderId === null){
      dispatch(getOrderHistory([]));
    } else {
    fetch(
      `${baseURL}/api/OrderManagement/GETBYID_History/${orderId}/${ItemId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getOrderHistory(resp.result));
          setTableLoading(false);
        } else {
          setTableLoading(false);
        }
      });
    }
  };
};

export const loadUpdateOrderQty = (updateRequest, pageRefresh, status) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/OrderManagement/UpdateOrders`, updateRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(updateOrderQty());
          if (status) {
            swal.showAlert(
              "Success",
              "To Be Dispatched quantity has successfully sent",
              "success"
            );
          }
          pageRefresh();
        } else {
          swal.showAlert(
            "Fail",
            "Something went Wrong," +
              resp.errorMessage +
              "Please try again later...!",
            "warning"
          );
        }
      })
      .catch((err) => {});
  };
};

// Orders Page

// Meeting Page
export const loadMeetingData = () => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Meeting/Get`, HeaderAuthentication.getRequestOptions)
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getMeetingData(resp.result));
      })
      .catch((err) => {});
  };
};
// Meeting Page

// Product Category Page
export const loadProdCategoryDrop = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Category/BindParentCategoryDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getProdCategoryDrop(
            resp.result.map((category) => {
              return { label: category.name, value: category.categoryID };
            })
          )
        );
      })
      .catch((err) => {});
  };
};

export const loadProdSubCategory = (ParentCategoryID) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Category/BindSubCategoryDropDown/${ParentCategoryID}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getProdSubCategory(
            resp.result.map((subCategory) => {
              return {
                label: subCategory.name,
                value: subCategory.categoryID,
                parent: subCategory.parentCategoryID,
              };
            })
          )
        );
      });
  };
};
// Product Category Page

// Designation page
export const loadDesignationDrop = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Designation/BindDesignationDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getDesignationDrop(
            resp.result.map((designation) => {
              return {
                label: designation.designationName,
                value: designation.designationID,
                level: designation.designationLevel,
              };
            })
          )
        );
      })
      .catch((err) => {});
  };
};

export const loadDepasrtDesignation = (departID) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Designation/Get?DepartmentID=${departID}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getdepartDesignation(
            resp.result.map((desigantion) => {
              return {
                label: desigantion.designationName,
                value: desigantion.designationID,
              };
            })
          )
        );
      })
      .catch((err) => {});
  };
};
// Designation page

// Basefare page
// mot dropdown
export const loadMotDrop = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Mot/BindMotDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getMotDrop(
            resp.result.map((mot) => {
              return { label: mot.motName, value: mot.motID, type: mot.type };
            })
          )
        );
      })
      .catch((err) => {});
  };
};

// mot dropdown
export const loadBaseFare = (filterArray, currentPage, PageSize, setIsLoading, setLoading) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        return param = param + "&" + item.key + "=" + item.value;
      });
    }
    fetch(
      `${baseURL}/api/CustomeBaseFare/Get?CurrentPage=${currentPage}&PageSize=${PageSize}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getToataldataCount(resp.recordCount[0]));
        dispatch(getBaseFare(resp.result));
        setLoading(false);
        if (setIsLoading) {
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  };
};

export const AddbaseFare = (postRequest, closeAddModal) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/CustomeBaseFare/AddCustomeBaseFare`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (!resp.success) {
          swal.showAlert("Fails", "Something went wrong...!", "warning");
        } else {
          swal.showAlert(
            "Success",
            "Basefare Added successfully...!",
            "success"
          );
          dispatch(addBadseFareTolist());
          closeAddModal();
        }
      })
      .catch((err) => {});
  };
};

export const loadSingleBaseFare = (baseId) => {
  return function (dispatch) {
    if (baseId == null) {
      dispatch(getSingleBasefare({}));
    } else {
      fetch(
        `${baseURL}/api/CustomeBaseFare/Get/${baseId}`,
        HeaderAuthentication.getRequestOptions
      )
        .then((resp) => resp.json())
        .then((resp) => {
          dispatch(getSingleBasefare(resp.result));
        })
        .catch((err) => {});
    }
  };
};

export const loadUpdateBasefare = (requestOption, closeAddModal) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/CustomeBaseFare/UpdateCustomeBaseFare`, requestOption)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errorMessage) {
          swal.showAlert("Fails", "Something went wrong...!", "failure");
        } else {
          closeAddModal();
          dispatch(updateBasefare());
          swal.showAlert(
            "Success",
            "Basefare Details Updated successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {});
  };
};
// Basefare page

// Department page
export const loadDepartmentDrop = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Department/BindDepartmentDropDown`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getDepartmentDrop(
            resp.result.map((data) => {
              return {
                value: data.departmentID,
                label: data.departmentName,
              };
            })
          )
        );
      })
      .catch((err) => {});
  };
};
// Department page

// Get Apis for Zone, country, State, City
export const loadZoneList = (setIsLoading) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Zone/GetZones`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getZoneList(
            resp.result.map((data) => {
              return { value: data.zoneId, label: data.zoneName };
            })
          )
        );
        setIsLoading(false);
      })
      .catch((err) => {});
  };
};

export const loadStateList = (zoneId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Zone/GetStates?ZoneId=${zoneId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getStateList(
            resp.result.map((data) => {
              return { value: data.stateId, label: data.stateName };
            })
          )
        );
        // setIsLoading(false)
      })
      .catch((err) => {});
  };
};

export const loadCityList = (stateId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Zone/GetCities?StateId=${stateId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(
          getCityList(
            resp.result.map((data) => {
              return { value: data.cityId, label: data.cityName };
            })
          )
        );
        // setIsLoading(false)
      })
      .catch((err) => {});
  };
};
// Get Apis for Zone, country, State, City

// Upload Images
export const loadUploadSingleImage = (
  postRequest,
  postData,
  closeModal,
  actionType
) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Upload/UploadAttachmentImage`, postRequest)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          let imgUrl = resp.result[0];
          postData.logo = imgUrl;
          const clientDetails = {
            client: postData,
          };
          // console.log(postData)
          let requestOption = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(clientDetails),
          };
          if (actionType === "post-data") {
            dispatch(loadPostCompanyData(requestOption, closeModal));
          } else if (actionType === "update-data") {
            dispatch(loadUpdateCompanyData(requestOption, closeModal));
          }
        } else {
          alert(resp.errorMessage);
        }
      })
      .catch((err) => {});
  };
};

export const loadUploadProductImages = (
  postUploadData,
  imgFile,
  productData,
  productId,
  closeModel,
  sentGalImages
) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/Upload/UploadAttachmentImage`, postUploadData)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          let mainImage;
          let galleryImages;
          let stringLinks = [];
          if (imgFile) {
            mainImage = resp.result[0].toString();
            galleryImages = resp.result.slice(1);
            galleryImages.map((image) => {
              return stringLinks.push(image);
            });
          } else {
            if (!productData.image) {
              mainImage = "";
            } else {
              mainImage = productData.image;
            }
            galleryImages = resp.result;
            galleryImages.map((image) => {
              return stringLinks.push(image);
            });
          }

          productData.image = mainImage;
          productData.galaryImage = "";
          let uploadImages = [...sentGalImages, ...stringLinks];
          productData.galaryImages = uploadImages;
          console.log(productData, "productData")
          let postProduct = {
            method: "POST",
            mode: "cors",
            headers: HeaderAuthentication.postTravelizeAuth,
            body: JSON.stringify(productData),
          };
          if (productId) {
            dispatch(loadUpdateProduct(postProduct, closeModel));
          } else {
            dispatch(loadPostProduct(postProduct, closeModel));
          }
        } else {
          alert(resp.errorMessage);
        }
      })
      .catch((er) => {});
  };
};
// Upload Images

// Super admin pages
// Google credits page
export const loadGoogleCredits = (
  Flag,
  setIsLoading,
  PageSize,
  currentPage,
  filterArray,
  setLoading
) => {
  return function (dispatch) {
    let param = "";
    if (filterArray.length !== 0) {
      filterArray.map((item, i) => {
        if (i === 0 && !param) {
          return param = item.key + "=" + item.value;
        } else {
          return param = param + "&" + item.key + "=" + item.value;
        }
      });
    }

    fetch(
      `${baseURL}/api/HitCount/GetApiKeyHitAndCreaditsCount?ByFlag=${Flag}${param}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          if (Flag === "GetGoogleApiKeyCredits") {
            dispatch(getGoogleCredits(resp.result));
            dispatch(getToataldataCount(resp.recordCount[0]));
            setLoading(false);

            dispatch(
              getApiNameDrop(
                resp.result.map((data, i) => {
                  return { value: i, label: data.GoogleApiKey };
                })
              )
            );
          } else if (Flag === "GetApiCredits") {
            dispatch(getToataldataCount(resp.recordCount[0]));
            dispatch(getAPICredits(resp.result));
            setIsLoading(false);
            setLoading(false);
            dispatch(
              getGoogleApiNameDropdown(
                resp.result.map((data) => {
                  return { value: data.ApiName, label: data.ApiName };
                })
              )
            );
          } else if (Flag === "googleapikeyhitcountbysub") {
            dispatch(getGoogleApiHitCount(resp.result));
          } else if (Flag === "GetException") {
            dispatch(getExceptionData(resp.result));
          } else if (Flag === "GetMobileException") {
            dispatch(getMobileExceptionData(resp.result));
          }
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      })
      .catch((err) => {});
  };
};

export const loadCreditsCount = (
  Flag,
  PageSize,
  currentPage,
) => {
  return function (dispatch) {

    fetch(
      `${baseURL}/api/HitCount/GetApiKeyHitAndCreaditsCount?ByFlag=${Flag}&PageSize=${PageSize}&CurrentPage=${currentPage}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getCreditCount(resp.result))
        }
      })
      .catch((err) => {});
  };
};

export const loadPostGoggleApis = (postRequest, modalClose, googleCreditId) => {
  return function (dispatch) {
    if (googleCreditId) {
      fetch(`${baseURL}/api/HitCount/UpdateGoogleCreditApi`, postRequest)
        .then((resp) => {
          return resp.json();
        })
        .then((resp) => {
          if (resp.success) {
            dispatch(addGoogleCredits());
            modalClose();
            swal.showAlert(
              "Success",
              "Google API Credits Updated successfully...!",
              "success"
            );
          } else {
            modalClose();
            swal.showAlert(
              "Fail",
              "Something went wrong please try again...!",
              "failure"
            );
          }
        })
        .catch((err) => {});
    } else {
      fetch(`${baseURL}/api/HitCount/AddGoogle_CreditApi`, postRequest)
        .then((resp) => {
          return resp.json();
        })
        .then((resp) => {
          if (resp.success) {
            dispatch(addGoogleCredits());
            modalClose();
            swal.showAlert(
              "Success",
              "Google API Credits Created successfully...!",
              "success"
            );
          } else {
            modalClose();
            swal.showAlert(
              "Fail",
              "Something went wrong please try again...!",
              "failure"
            );
          }
        })
        .catch((err) => {});
    }
  };
};

export const loadPostApiCredits = (postData, closeAddModal, apiDataId) => {
  return function (dispatch) {
    if (apiDataId) {
      fetch(`${baseURL}/api/HitCount/UpdateApiCredits`, postData)
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.success) {
            dispatch(postAPICredits());
            closeAddModal();
            swal.showAlert(
              "Success",
              "API Credits Updated successfully...!",
              "success"
            );
          } else {
            closeAddModal();
            swal.showAlert(
              "Fail",
              "Something went wrong please try again...!",
              "failure"
            );
          }
        })
        .catch((err) => {});
    } else {
      fetch(`${baseURL}/api/HitCount/AddApiCredits`, postData)
        .then((resp) => resp.json())
        .then((resp) => {
          if (resp.success) {
            dispatch(postAPICredits());
            closeAddModal();
            swal.showAlert(
              "Success",
              "API Credits Added successfully...!",
              "success"
            );
          } else {
            closeAddModal();
            swal.showAlert(
              "Fail",
              "Something went wrong please try again...!",
              "failure"
            );
          }
        })
        .catch((err) => {});
    }
  };
};

export const loadSingleApiData = (apiId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/HitCount/GetApiCreadits/${apiId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getSingleApiData(resp.result));
        }
      })
      .catch((err) => {});
  };
};

// export const loadGoogleApiNameDropdown = () => {
//   return function(dispatch){
//     fetch(`${baseURL}/api/HitCount/BindApiCreditsDropDown`, HeaderAuthentication.getRequestOptions)
//     .then(resp => resp.json())
//     .then(resp => {
//       console.log(resp.result, "asadsdaf")
//       if(resp.success){
//         dispatch(getGoogleApiNameDropdown(resp.result.map((data) => {
//           return { value: data.apiCreditsID, label: data.apiCreditsName };
//         })))
//       }
//     })
//   }
// }
// Google credits page
// Super admin pages

// User Page
export const loadUserDropdown = (company) => {
  return function (dispatch) {
    let param = "";
    if (company) {
      param = "?CompanyName=" + company;
    }
    fetch(
      `${baseURL}/api/User/BindUsersDropDown${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          if (company) {
            dispatch(
              getUserDropdownByCompany(
                resp.result.map((data) => {
                  return { value: data.userId, label: data.userFullName };
                })
              )
            );
          } else {
            dispatch(
              getUserDropdown(
                resp.result.map((data) => {
                  return { value: data.userId, label: data.userFullName };
                })
              )
            );
          }
        }
      });
  };
};

export const loadUserRegion = () => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/Zone/GetUserRegionsResult`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getUserRegionResult(resp.result));
        }
      });
  };
};

// User Page

// Google Hit Count
// export const loadHitCount = () => {
//   return function(dispatch){
//     fetch(`${baseURL}`)
//   }
// }
// Google Hit Count

// Subscription Pages
export const loadSubscriptionDropdown = () => {
  return function(dispatch){
    fetch(`${baseURL}/api/Subscription/BindSubscriptionTypeDropdown`, HeaderAuthentication.getRequestOptions)
    .then(resp => resp.json())
    .then(resp => {
      dispatch(getSubscriptionDrop(resp.result.map(subscription => {
        return {label: subscription.subscriptionName, value: subscription.subscriptionTypeID}
      })))
    })
    .catch(err => {
    })
  }
}

export const loadUpdateSubscription = (postRequest, closeModal) =>{
  return function(dispatch){
    fetch(`${baseURL}/api/Subscription/UpdateSubscription`, postRequest)
    .then(resp => resp.json())
    .then(resp => {
      if(resp.success){
        dispatch(updateSubscription())
        closeModal();
        swal.showAlert("Success", "Subscription Updated Successfully", "success")
      } else{
        swal.showAlert("Fails", "Something Went Wrong...!", "warning")
      }
    })
    .catch(err => {
    })
  }
}
// Subscription Pages

// Refferal Pages
// export const loadRefferalData = (setIsLoading) => {
//   return function (dispatch) {
//     fetch(`${baseURL}/api/SalesBeeReferel/Get?Status=1`, HeaderAuthentication.getRequestOptions)
//       .then((resp) => resp.json())
//       .then((resp) => {
//         dispatch(getSalesBeeRefferal(resp.result));
//         // if (setIsLoading) {
//         //   setIsLoading(false);
//         // }
//       })
//       .catch((err) => {});
//   };
// };
export const addRefferalData = (postData,closeAddModal) =>{
  return function(dispatch){
    fetch(`${baseURL}/api/SalesBeeReferel/AddSalesBeeReferel`,postData)
    .then((res)=>{return res.json()})
    .then((res)=>{
      if(res.result){
        dispatch(postSalesBeeRefferal())
        closeAddModal()
        swal.showAlert('Success', 'Added successfully','success')
      }
      else{
        swal.showAlert('Error',"Failed to add",'error');
      }
    })
    .catch((err)=>{})
  }
}
export const loadUpdateSalesBeeRefferal = (requestOption, closeAddModal) => {
  return function (dispatch) {
    fetch(`${baseURL}/api/SalesBeeReferel/UpdateSalesBeeReferel`, requestOption)
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.errorMessage) {
          swal.showAlert("Fails", "Something went wrong...!", "failure");
        } else {
          closeAddModal();
          dispatch(upDateSalesBeeRefferal());
          swal.showAlert(
            "Success",
            "Refferal Details Updated successfully...!",
            "success"
          );
        }
      })
      .catch((err) => {});
  };
};
export const loadSingleRefferalData = (refId) => {
  return function (dispatch) {
    fetch(
      `${baseURL}/api/SalesBeeReferel/Get/${refId}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.success) {
          dispatch(getSingleRefferalData(resp.result));
        }
      })
      .catch((err) => {});
  };
};

export const loadFilterReferalData = (
  filterArray,
  PageSize, currentPage,
  setIsLoading
) => {
  return function (dispatch) {
    let param = "";
    if(filterArray.length !== 0){
      filterArray.map((item, i) => {
        return param = param + "&" + item.key + "=" + item.value;
      });
    }
    fetch(
      `${baseURL}/api/SalesBeeReferel/Get?$PageSize=${PageSize}&CurrentPage=${currentPage}${param}`,
      HeaderAuthentication.getRequestOptions
    )
      .then((resp) => resp.json())
      .then((resp) => {
        dispatch(getSalesBeeRefferal(resp.result));
        setIsLoading(false);
      })
      .catch((err) => { });
  };
};
// Refferal Pages

// Service call for pages end
