import React, { useState, useEffect } from "react";
import Sidenavbar from "../../Sidenavbar";
import Topbar from "../../Topbar";
import "../../../styles/CommonStyle.scss";
import "../../../styles/Meetings.scss";
import { baseURL } from "../../BaseUrl";
// import { travelizeAuth } from "./HeaderAuthentication";
import ReactPaginate from "react-paginate";
import moment from "moment";
import DatePicker from "react-datepicker";
import Select from "react-select";
import "react-datepicker/dist/react-datepicker.css";
import AccessDenied from "../../../AccessDenied";
import { exportExcel } from "../../DownloadFile";
import { loadClientDropDown, loadGoogleCredits } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import HitCountTable from "./HitCountTable";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

function GoogleAPIHitCount() {

  const { key } = useParams();
  let tableHeader = [
    {name: "Sl.No", align: "center", width: "80px"},
    {name: "Company", align: "left", width: "150px"},
    {name: "API Key", align: "left", width: "200px"},
    {name: "From Date", align: "left", width: "150px"},
    {name: "To Date", align: "left", width: "150px"},
    {name: "Last Hit Date", align: "left", width: "150px"},
  ]
  const numbersArray = Array.from({ length: 31 }, (_, index) => index + 1);
  const {clientDropdown} = useSelector(state => state.data);

  const [menuCollapse, setMenuCollapse] = useState(false);
  const [toggleshift, setToggleshift] = useState({ style: "" });
  // const [searchApiKey, setSearchApiKey] = useState("");
  const [searchValue, setSearchValue] = useState('')
  const [filterArray, setFilterArray] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [filterClientName, setFilterClientName] = useState({value: "", label: ""})

  let userLoginDetails = JSON.parse(sessionStorage.getItem("usrlgndtls"));

  let userDetails = JSON.parse(sessionStorage.getItem("usdtls"));
  let dispatch = useDispatch();
  
  const {ApiHitList} = useSelector(state => state.data);
  console.log(ApiHitList, "ApiHitList")
 
  const [ApiCreditData, setApiCreditData] = useState([])
  
  useEffect(() => {
    if(key){
      if(ApiHitList){
        setApiCreditData(ApiHitList.filter(data => {
          return data.GoogleApiKey.includes(key)
        }))
      } 
    } else {
      setApiCreditData(ApiHitList)
    }
  }, [key, ApiHitList])
  

  const setSearchData = (value) => {
    let list;
    if(filterArray.length !== 0){
      list = filterArray.filter(item => item.key !== "Key")
    } else {
      list = []
    }
    if(value){
      list.push({key: "Key", value: value})
    } else {
      list = filterArray.filter(item => item.key !== "Key")
    }
    setSearchValue(value)
    setFilterArray(list)
  }

  const setSelectSearchDrop = (data, type) => {
    let list;
    if (type === "ClientName") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "ClientID");
      } else {
        list = [];
      }
      list.push({ key: "ClientID", value: data.value });
      setFilterClientName(data);
    } else if (type === "From") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "FromDate");
      } else {
        list = [];
      }
      list.push({ key: "FromDate", value: moment(new Date(data)).format("DD/MM/YYYY") });
      setFromDate(data);
    } else if (type === "To") {
      if (filterArray.length !== 0) {
        list = filterArray.filter((item) => item.key !== "ToDate");
      } else {
        list = [];
      }
      list.push({ key: "ToDate", value: moment(new Date(data)).format("DD/MM/YYYY") });
      setToDate(data);
    }
    setFilterArray(list);
  };

  const toggleMenu = () => {
    if (menuCollapse === true) {
      setMenuCollapse(false);
      setToggleshift({ style: "" });
    } else {
      setMenuCollapse(true);
      setToggleshift({ style: "toggle-shift-left" });
    }
  };

  // PAGINATION START
  const [PageSize, setPageSize] = useState(20);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageDataSize, setPageDataSize] = useState("");
  const [itemOffset, setItemOffset] = useState(1);
  const handlePageClick = (data) => {
    setCurrentPage(data.selected + 1);
  };
  const pageDataFrom = currentPage * PageSize - PageSize + 1;
  const pageDataTill = pageDataFrom + pageDataSize - 1;
  // console.log("From:", pageDataFrom, "TO", pageDataTill);
  // PAGINATION END

  // GET GOOGLEAPI KEYS PAGE START
  useEffect(() => {
    dispatch(loadClientDropDown())
    let Flag = "googleapikeyhitcountbysub"
    dispatch(loadGoogleCredits(Flag, setIsLoading, PageSize, currentPage, filterArray))
  }, [filterArray, PageSize, currentPage]);
  // GET GOOGLEAPI KEYS PAGE END

  const clearSerachBySelect = () => {
    setFilterClientName({value: "", label: ""})
    setSearchValue("");
    setFilterArray([])
    setFromDate(new Date());
    setToDate(new Date());
  };

  const exportExcelGoogleAPIHitCount = () => {
    exportExcel(
      `${baseURL}/api/Export/GoogleApiKeyHitCount?FromDate=${moment(
        fromDate
      ).format("DD/MM/YYYY")}&ToDate=${moment(toDate).format(
        "DD/MM/YYYY"
      )}&Key=${searchValue}`,
      "GoogleAPI-HitCounts.xlsx"
    );
  };

  // Page navigation
  let navigate = useNavigate();
  // Page navigation
  const goToBackPage = () => {
    navigate(-1);
  };
  // page navigation
  // page navigation

  const [openFilterTab, setOpenFilterTab] = useState(false)

  return (
    <div id="main-page">
      {userLoginDetails?.subscriptionId == "SUB0000000001" ? (
        <>
          {/* SIDEBAR START */}
          <Sidenavbar menuCollapse={menuCollapse} toggleshift={toggleshift} />
          {/* SIDEBAR END */}

          {/* TOPBAR START */}
          <Topbar
            toggleMenu={toggleMenu}
            menuCollapse={menuCollapse}
            toggleshift={toggleshift}
          />
          {/* TOPBAR END */}

          {/* MAIN BODY START */}
          <div className="page-wrapper">
            {/* PAGE CONTENT START */}
            <div className={`main-body ${toggleshift.style}`}>
              <div className="page-content">
              <div style={{position: "relative"}}>
              <Row className='search-row'>
              <Col md={3} className='cat-col'>
                <div className='page-header-text-div'>
                <div className="back-btn" onClick={goToBackPage}><img src="../../../../images/DefaultImages/back-btn-arrow.svg" alt="" /></div>
                  <h6 className='page-header-text' style={{ color: "#04a96d" }}>GOOGLE API KEY</h6>
                </div>
              </Col>
              <Col md={5} className='cat-col'>
                <div className='input-div'>
                  <input type="text" placeholder='Find a Key' className='form-control setting-input'
                      value={searchValue} onChange={(e) => setSearchData(e.target.value)} 
                      />
                  <i className='fa fa-search search-icon' ></i>
                </div>
              </Col>
              <Col md={4} className='cat-col'>
                <div className="search-bar-header" style={{float: "right"}}>
                  <div className="page-top-bar-dropdowns">
                      <div className="header-filter-btn" onClick={() => setOpenFilterTab(!openFilterTab)}>
                        <p className="card-head">
                        <i className="bi bi-sliders" />&nbsp;&nbsp;Filters
                        </p>
                      </div>

                      {/* <button
                        type="button"
                        className="btn btn-add-basefare"
                        onClick={() => setShow(!show)}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-plus-square" /> &nbsp;
                          <h6>Add New</h6>
                        </span>
                      </button> */}
                  </div>
                </div>
              </Col>
            </Row>
              
            {openFilterTab &&
            <Row className='page-filter-section'>
              <Col md={10}>
                <Row className="clients-filter-input-boxs">
                <Col md={4}>
                    <Select
                      className="react-select-container-list"
                      classNamePrefix="react-select-list"
                      options={clientDropdown}
                      placeholder="Select Client"
                      value={filterClientName.value && filterClientName}
                      onInputChange={clientDropdown.label}
                      onChange={(data) => setSelectSearchDrop(data, "ClientName")}
                      isSearchable={true}
                    />
                  </Col>
                  <Col md={3}>
                  <div className="input-group from-to-date-select">
                      <span
                        className="input-group-text"
                        id="from-label"
                      >
                        From
                      </span>
                      <span className="date-picker-with-icon">
                        <DatePicker
                          selected={fromDate || new Date()}
                          className="select from-date"
                          id="from-datepicker"
                          dateFormat="dd/MM/yyyy"
                          selectsEnd
                          onChange={(date) => setSelectSearchDrop(date, "From")}
                          maxDate={toDate}
                        />
                        <i className="fa fa-calendar-o calander-icon" />
                      </span>
                    </div>
                  </Col>
                  <Col md={3}>
                  <div className="input-group from-to-date-select">
                      <span
                        className="input-group-text"
                        id="to-label"
                      >
                        To
                      </span>
                      <span className="date-picker-with-icon">
                        <DatePicker
                          selected={toDate || new Date()}
                          className="select to-date"
                          id="from-datepicker"
                          dateFormat="dd/MM/yyyy"
                          selectsEnd
                          minDate={fromDate}
                          onChange={(date) => setSelectSearchDrop(date, "To")}
                          maxDate={new Date()}
                        />
                        <i className="fa fa-calendar-o calander-icon" />
                      </span>
                    </div>
                  </Col>
                  {/* <Col md={4}>
                  <Select
                        className="react-select-container-list"
                        classNamePrefix="react-select-list"
                        options={desigationdrop}
                        placeholder="Select Designation"
                        value={
                          !selectDesignation ? "" : desigationdrop.value
                        }
                        onInputChange={desigationdrop.label}
                        onChange={(data) => setSelectDesignation(data.value)}
                        isSearchable={true}
                      />
                  </Col> */}
                </Row>
              </Col>
              <Col md={2} align="center">
                <button
                        type="button"
                        className="btn btn-search-track"
                        onClick={clearSerachBySelect}
                      >
                        <span className="btn-inline-item d-flex">
                          <i className="fa fa-eraser"> </i>
                          <h6>Clear</h6>
                        </span>
                      </button>
              </Col>
            </Row>
      }
            </div>

                {/* USER TABLE START */}
                <div className="googleapi-table">
                  <HitCountTable tableHeader={tableHeader}
                    numbersArray={numbersArray}
                    ApiHitList={ApiCreditData}
                    isLoading={isLoading}
                    // onClickNavigate={onClickNavigate} 
                  />

                  <div className="download-and-pagination">
                    <button
                      type="button"
                      className="btn btn-download-excel"
                      onClick={exportExcelGoogleAPIHitCount}
                    >
                      <i className="fa fa-download" /> &nbsp; Download
                    </button>
                    <span className="user-pagination">
                      <ReactPaginate
                        breakLabel="..."
                        nextLabel={<i className="fa fa-angle-double-right" />}
                        onPageChange={handlePageClick}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                          <i className="fa fa-angle-double-left" />
                        }
                        renderOnZeroPageCount={null}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        disabledClassName={"disabled"}
                      />
                    </span>
                  </div>
                </div>
                {/* USER TABLE END */}

                {/* PAGE CONTENT END*/}
              </div>
            </div>

            {/* MAIN BODY END */}
          </div>
        </>
      ) : (
        <AccessDenied />
      )}
    </div>
  );
}

export default GoogleAPIHitCount;
